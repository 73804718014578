import React, { Component } from 'react';
import MidOfficeMenu from './mid-office-menu';
class DashboardHotelPage extends Component {
  render() {
    return (
      <div>
        <div className="admin-tabs">
          <div className="inner-header">
            <div className="shape-header ml-5">
              <div className="left">D</div>
              <div className="right">
                <h6 className="heading">Mid Office - Dashboard</h6>
                <p className="text">Has been the industry’s standard dummy text ever since the 1500s.</p>
              </div>
            </div>
          </div>
          <div className="booking-bg">
            <div className="p-padding">
              <div className="row">
                <div className="col-6">
                  <div className="my-card">
                    <div className="my-padding">
                      <div className="heading">
                        <i className="icon ion-md-globe"></i><h5 className="d-inline-block">Booking Requests</h5>
                      </div>
                      <MidOfficeMenu />
                    </div>
                    <div className="container">
                      <div className="quality">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="border-right some-class">
                              <h6>Quality Check</h6>
                              <p><i className="icon ion-md-cog"></i><span>10</span></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="border-right some-class">
                              <h6>Queue Processing</h6>
                              <p><i className="icon ion-md-beer"></i><span>90</span></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="some-class">
                              <h6>Vendor Queue</h6>
                              <p><i className="icon ion-md-beer"></i><span className="border-right px-2 mr-2">20</span>Details</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="container">
                      <div className="quality">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="border-right some-class">
                              <h6>Domestic</h6>
                              <p><span>10</span></p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="some-class">
                              <h6>International</h6>
                              <p><span>10</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-card">
                    <div className="my-padding">
                      <div className="heading">
                        <i className="icon ion-md-globe"></i><h5 className="d-inline-block">Offline Requests</h5>
                      </div>
                    </div>
                    <div className="container">
                      <div className="quality mt-0">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="border-right some-class">
                              <h6>Total Request</h6>
                              <p><i className="icon ion-md-cog"></i><span>10</span></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="border-right some-class">
                              <h6>Pending</h6>
                              <p><i className="icon ion-md-beer"></i><span>90</span></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="some-class">
                              <h6>Pending</h6>
                              <p><i className="icon ion-md-beer"></i><span>20</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-card">
                    <div className="my-padding">
                      <div className="heading">
                        <i className="icon ion-md-globe"></i><h5 className="d-inline-block">Cancellation Request</h5>
                      </div>
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className="border-top-0">Flight</th>
                              <th className="border-top-0">Hotel</th>
                              <th className="border-top-0">Bus</th>
                              <th className="border-top-0">Train</th>
                              <th className="border-top-0">Car</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>10</td>
                              <td>10</td>
                              <td>10</td>
                              <td>10</td>
                              <td>10</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="my-card">
                    <div className="my-padding">
                      <div className="heading">
                        <i className="icon ion-md-globe"></i><h5 className="d-inline-block">Reschedule Request</h5>
                      </div>
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className="border-top-0">Flight</th>
                              <th className="border-top-0">Hotel</th>
                              <th className="border-top-0">Bus</th>
                              <th className="border-top-0">Train</th>
                              <th className="border-top-0">Car</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>10</td>
                              <td>10</td>
                              <td>10</td>
                              <td>10</td>
                              <td>10</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="my-card">
                    <div className="my-padding">
                      <div className="heading">
                        <i className="icon ion-md-globe"></i><h5 className="d-inline-block">Service Escalations</h5>
                      </div>
                    </div>
                    <div className="container">
                      <div className="quality mt-0">
                        <div className="float-left flash">
                          <i className="icon ion-md-flash"></i>
                        </div>
                        <div className="float-left ml-4">
                          <h6>Awesome, </h6>
                          <p>There are no Escalations at the movement</p>
                        </div>
                        <div className="clearfix"></div>

                      </div>
                    </div>
                  </div>
                  <div className="my-card">
                    <div className="my-padding">
                      <div className="heading">
                        <i className="icon ion-md-globe"></i><h5 className="d-inline-block">Web Check-In</h5>
                      </div>
                    </div>
                    <div className="container">
                      <div className="quality mt-0">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="some-class">
                              <h6>Queue</h6>
                              <p><span>10</span></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                          </div>
                          <div className="col-md-4">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardHotelPage;
