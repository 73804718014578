import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/styles';
import Moment from "react-moment";
import NumberFormat from 'react-number-format';
import { midOfficeServices } from "../../_services";
const container = {
    width: '95%',
    padding: '35px',
    margin: '20px auto',
    backgroundColor: '#fff',
    fontSize: '14px'
};
const ul = {
    width: '100%',
    borderBottom: '2px solid rgba(0, 0, 0, .2)',
    padding: '20px 0px'
};
const li = {
    listStyle: 'none',
    display: 'inline-block',
    width: '33%',
    textAlign: 'left'
};
const invoice = {
    fontWeight: 500,
    fontSize: '28px'
};
const logoimg = {
    height: '60px',
    width: 'auto'
};
const fieldset = {
    border: '1px solid rgba(0, 0, 0, .2)',
    position: 'relative',
    width: '100%'
};
const legend = {
    position: 'absolute',
    top: '-16px',
    fontSize: '16px',
    fontWeight: 500,
    left: '10px',
    backgroundColor: '#fff',
    width: 'auto',
    padding: '0px 5px'
};
const h5 = {
    padding: '15px 15px 10px 15px',
    fontSize: '18px',
    fontWeight: 500
};
const tables = {
    width: '100%'
};
const table = {
    width: '49.7%',
    display: 'inline-block',
    verticalAlign: 'text-top'
};
const th = {
    padding: '10px',
    textTransform: 'uppercase',
    margin: '0px 20px',
    fontSize: '14px'
};
const td = {
    fontWeight: 500,
    fontSize: '14px'
};
const address = {
    opacity: 0.5
};
const itineraryTable = {
    border: '1px solid rgba(0, 0, 0, .2)',
    width: '100%'
};
const itineraryDetails = {
    marginTop: '30px'
};
const tableHead = {
    fontWeight: 'bold',
    fontSize: '16px'
};
const itineraryTh = {
    textTransform: 'uppercase',
    fontWeight: 500,
    padding: '20px 10px',
    backgroundColor: '#f1f1f2',
    border: '1px solid rgba(0, 0, 0, .2)',
    fontSize: '14px'
};
const itineraryTd = {
    padding: '10px',
    border: '1px solid rgba(0, 0, 0, .2)',
    fontSize: '14px'
};
const fontWeightMd = {
    fontWeight: '800'
};
const signature = {
    marginTop: '50px'
};

const floatLeft = {
    float: 'left'
};
const floatRight = {
    float: 'right'
};
const pFont = {
    fontSize: '16px'
};


class FlightETicketPage extends Component {
    state = {
        eTicketData: []
    }
    getTime = (t) => {
        let time = parseInt(t / 60);
        let hours = parseInt(time / 60);
        const minutes = parseInt(time) % 60;
        let days = 0;
        if (hours >= 24) {
            console.log(hours);
            days = parseInt(hours / 24);
            hours = parseInt(hours % 24);
        }
        return (days === 0 ? '' : days + ' days : ') + hours + ' hrs : ' + minutes + ' mins';
    }

    render() {
        const { eTicketData } = this.props;
        console.log(eTicketData);
        return (
            <div style={container}>
                <ul style={ul}>
                    <li style={li}>
                        <img src="/assets/images/curio-clear-logo.png" style={logoimg} alt="logo" />
                    </li>
                    <li style={li}>
                        <p style={invoice}>E-Ticket</p>
                    </li>
                    <li style={li}>
                        <fieldset style={fieldset}>
                            <legend style={legend}>Your E-Ticket Number</legend>
                            <h5 style={h5}>{eTicketData && eTicketData.pnrData? eTicketData.pnrData.ticketNumber:'--'}</h5>
                        </fieldset>
                    </li>
                </ul>

                <div style={tables}>
                    <table style={table}>
                        <tr>
                            <th style={th}>TRAVELLER </th>
                            <td style={td}>{eTicketData ? eTicketData.travellerName : '--'}</td>
                        </tr>
                        <tr>
                            <th style={th}>BOOKING REFERENCE NUMBER </th>
                            <td style={td}>{eTicketData ? eTicketData.tripId : '--'}
                            </td>
                        </tr>
                        <tr>
                            <th style={th}>EMAIL</th>
                            <td style={td}>{eTicketData ? eTicketData.email : '--'}</td>
                        </tr>
                    </table>
                    <table style={table}>
                        <tr>
                            <th style={th}>STATUS</th>
                            <td style={{ ...td, "fontWeight": "bold" }}>--</td>
                        </tr>
                        <tr>
                            <th style={th}>BOOKING DATE </th>
                            <td style={td}>{eTicketData ? eTicketData.bookingDate : '--'}
                            </td>
                        </tr>
                        <tr>
                            <th style={th}>GDS REF</th>
                            <td style={td}>{}</td>
                        </tr>
                    </table>
                </div>
                <div style={itineraryDetails}>
                    <h6 style={tableHead}>{eTicketData.data ? eTicketData.data.flight_airport[eTicketData.data.flight_from].c + ' to ' + eTicketData.data.flight_airport[eTicketData.data.flight_to].c : '--'}<br /></h6>

                    {eTicketData.data && eTicketData.data.fareContent.content.length > 0 && eTicketData.data.fareContent.content.map((con, i) => {
                        return (
                            <>
                                <h6 style={(i === 0) ? { display: "none" } : { textAlign: "center", marginTop: "20px", marginBottom: "20px" }}> <b>
                                    {(con.flight_number === (eTicketData.data.fareContent.content[i - 1] !== undefined ? eTicketData.data.fareContent.content[i - 1].flight_number : '') ? "Flight Stop" : "Change Flight here")}
                                </b></h6>
                                <table style={itineraryTable}>
                                    <tr>
                                        <th style={itineraryTh}>Flight <br /> Number</th>
                                        <th style={itineraryTh}>Carrier</th>
                                        <th style={itineraryTh} colSpan="2">Departing</th>
                                        <th style={{ ...itineraryTh, "color": "green" }}>Duration</th>
                                        <th style={itineraryTh} colSpan="2">Arrival</th>
                                    </tr>
                                    <tr>
                                        <td rowSpan="2" style={itineraryTd}><b>{con.flight_number}</b></td>
                                        <td rowSpan="2" style={itineraryTd}>
                                            <img style={{ "height": "50px", "width": "auto" }} src={con.flight_image} alt="logo" />
                                            <p className="mt-1">{con.flight_operating_airline}</p>
                                        </td>
                                        <td colSpan="2" style={itineraryTd}>
                                            {
                                                con.flight_depature_time + ' - ' +
                                                con.flight_depature_date_utc}
                                        </td>
                                        <td rowSpan="2" style={itineraryTd} >
                                            <p className="text-success">{this.getTime(con.flight_duration) + ' '}</p>
                                            <p>Non Stop</p></td>
                                        <td colSpan="2" style={itineraryTd}>
                                            {con.flight_arrival_time + ' - ' +
                                                con.flight_arrival_date_utc}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" style={itineraryTd}>
                                            {eTicketData.data.flight_airport[con.flight_from_city].c + '-' + '(' + eTicketData.data.flight_airport[con.flight_from_city].n + ')'}
                                        </td>
                                        <td colSpan="2" style={itineraryTd}>
                                            {eTicketData.data.flight_airport[con.flight_to_city].c + '-' + '(' + eTicketData.data.flight_airport[con.flight_to_city].n + ')'}
                                        </td>
                                    </tr>
                                </table>
                                <h6 style={{ ...tableHead, marginTop: "20px" }}>Additional Information</h6>
                                <table style={itineraryTable}>
                                    <thead>
                                        <tr>
                                            <th style={itineraryTh}>Meal</th>
                                            <th style={itineraryTh}>Baggage</th>
                                            <th style={itineraryTh}>Hand Baggage</th>
                                            <th style={itineraryTh}>Flight Equipment</th>
                                            <th style={itineraryTh}>Flight Departure terminal</th>
                                            <th style={itineraryTh}>class</th>
                                            <th style={itineraryTh}>seat</th>
                                            <th style={itineraryTh}>Flight Arrival terminal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={itineraryTd}>--</td>
                                            <td style={itineraryTd}>{"--"}</td>
                                            <td style={itineraryTd}>{"--"}</td>
                                            <td style={itineraryTd}>{con.flight_eqiupment !== '' ? con.flight_eqiupment : '--'}</td>
                                            <td style={itineraryTd}>{con.flight_depature_terminal !== '' ? con.flight_depature_terminal : '--'}</td>
                                            <td style={itineraryTd}>{con.class}<br /></td>
                                            <td style={itineraryTd}>--</td>
                                            <td style={itineraryTd}>{con.flight_arrival_terminal !== '' ? con.flight_arrival_terminal : '--'}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </>
                        )
                    })}

                    {eTicketData.data && eTicketData.data.fareContent && <>
                        <h6 style={{ ...tableHead, marginTop: "20px" }}>Fair Details</h6>
                        <table style={itineraryTable}>
                            <thead>
                                <tr>
                                    <th style={itineraryTh}>Air Fare</th>
                                    <th style={itineraryTh}>Airline tax & surcharges</th>
                                    <th style={itineraryTh}>Total Fare</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={itineraryTd}><NumberFormat value={Math.floor(eTicketData.data.fareContent.fare.flight_base_price * 1000) / 1000} displayType={'text'} thousandSeparator={true} prefix={eTicketData.baseCurrency + ' '} /></td>
                                    <td style={itineraryTd}>
                                        <NumberFormat value={Math.floor(eTicketData.data.fareContent.fare.flight_total_tax * 1000) / 1000} displayType={'text'} thousandSeparator={true} prefix={eTicketData.baseCurrency + ' '} />
                                    </td>
                                    <td style={itineraryTd}>
                                        <NumberFormat value={Math.floor(eTicketData.data.fareContent.fare.flight_total_price * 1000) / 1000} displayType={'text'} thousandSeparator={true} prefix={eTicketData.baseCurrency + ' '} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                    }
                </div>
                <div style={itineraryDetails}>
                    <h6 style={tableHead}>Rules & Conditions</h6>

                    <p style={pFont}>- Use your airline PNR for all your communication for this booking with the airline</p>
                    <p style={pFont}>- All the times
                        indicated are the
                local time at the relevant airport and we recommend you check-in 2 hours prior to departure</p>
                    <p style={pFont}>- All the
                        passengers
                including children and infants, must present valid photo identification at check-in</p>
                    <p style={pFont}>- Carry a printout
                        of this e-ticket
                and present it to the airline counter at the time of check-in</p>
                    <p style={pFont}>- Kindly check airline website for baggage
                information</p>
                </div>
                <div style={signature}>
                    <div style={floatLeft}>
                        <p style={fontWeightMd}>For Curio Technologies(P) Ltd.</p>
                        <p style={{ "opacity": "0.5" }}>MM Square, #5M - 671,1st & 2nd Floor, 5th Main,<br /> OMBR Layout,B-Channasandra,
                    Bangalore(KA)-560043</p>
                    </div>
                    <div style={floatRight}>
                        <p style={{ ...fontWeightMd, "textAlign": "right" }}>Support: +91 10011 91888</p>
                        <p>www.curiotechnologies.com</p>
                    </div>
                    <div style={{ "clear": "both" }}></div>
                </div>
            </div>
        )

    }

}

FlightETicketPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (FlightETicketPage);

