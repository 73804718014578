import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker'; 
import authStore from './_store/auth.store'; 
import commonStore from './_store/common.store'; 
import userStore from './_store/user.store';  
import { Provider } from 'mobx-react';
const stores = { 
    authStore,
    commonStore, 
    userStore, 
  }; 
ReactDOM.render(   
        <Provider {...stores}> 
            <App /> 
        </Provider>,
        document.getElementById('root')
    
);
serviceWorker.unregister();
