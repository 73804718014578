import React from "react";
import FormItems from "./components/Forms";
const ListManagementPage = props => {
  const {
    title,
    className,
    error,
    isStatic,
    state,
    handleChange,
    type,
    btnType,
    handleSubmit,
    listOfItems,
    chooseFolder,
    parentStack,
    handleDoubleClick,
    handleUpdateSubmit,
    detailView,
    breadCum,
    navNew,
    homeNav,
    sideNavOpt,
    pastedItems,
    parentId,
    pasteHere,
    destId,
    handleSearch
  } = props;

  return (
    <div>
      <div className="page-header">
        <h6 className="heading">List Management</h6>
      </div>
      <div className="page-content">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">List Management</h6>
              <p className="text">List Management settings</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="folder-container">
            <div className="heading">
              {type !== "" && type < 4 && (
                <FormItems
                  title={title}
                  className={className}
                  handleChange={handleChange}
                  error={error}
                  state={state}
                  handleUpdateSubmit={handleUpdateSubmit}
                  handleSubmit={handleSubmit}
                  btnType={btnType}
                  isStatic={isStatic}
                  type={type}
                />
              )}
              <div className="row">
                <div className="col-md-6">
                  {isStatic ? (
                    <div className="btn-group-margin">
                      {parentStack.length === 0 ? (
                        <button
                          onClick={e => btnType(1)}
                          className="btn "
                        >
                          New
                        </button>
                      ) : (
                          <button disabled className="btn ">
                            New
                        </button>
                        )}
                      {parentStack.length !== 1 ? (
                        <button className="btn">
                          <i className="icon ion-md-add" />
                        </button>
                      ) : (
                          <button
                            onClick={e => btnType(2)}
                            className="btn btn-action active"
                          >
                            <i className="icon ion-md-add" />
                          </button>
                        )}
                      {parentStack.length !== 1 ? (
                        <button className="btn">
                          <i className="icon ion-md-brush" />
                        </button>
                      ) : (
                          <button
                            onClick={e => btnType(3)}
                            className="btn btn-action active"
                          >
                            <i className="icon ion-md-brush" />
                          </button>
                        )}
                      <button className="btn">
                        <i className="icon ion-md-trash" />
                      </button>
                      <button className="btn">
                        <i className="icon ion-md-cut" />
                      </button>
                      <button className="btn">
                        <i className="icon ion-md-albums" />
                      </button>
                    </div>
                  ) : (
                      <div className="btn-group-margin">
                        {parentStack.length === 0 ? (
                          <button
                            onClick={e => btnType(1)}
                            className="btn "
                          >
                            New
                        </button>
                        ) : (
                            <button disabled className="btn ">
                              New
                        </button>
                          )}
                        {parentStack.length !== 1 ? (
                          <button className="btn">
                            <i className="icon ion-md-add" />
                          </button>
                        ) : (
                            <button
                              onClick={e => btnType(2)}
                              className="btn btn-action active"
                            >
                              <i className="icon ion-md-add" />
                            </button>
                          )}
                        {parentStack.length !== 1 ? (
                          <button className="btn">
                            <i className="icon ion-md-brush" />
                          </button>
                        ) : (
                            <button
                              onClick={e => btnType(3)}
                              className="btn btn-action active"
                            >
                              <i className="icon ion-md-brush" />
                            </button>
                          )}
                        {parentStack.length !== 1 ? (
                          <button className="btn">
                            <i className="icon ion-md-trash" />
                          </button>
                        ) : (
                            <button
                              onClick={e => btnType(4)}
                              className="btn btn-action btn-copy  active"
                            >
                              <i className="icon ion-md-trash" />
                              <span className="new-btn">DELETE</span>
                            </button>
                          )}
                        {parentStack.length < 1 ? (
                          <button className="btn">
                            <i className="icon ion-md-cut" />
                          </button>
                        ) : parentStack.length === listOfItems.length &&
                          parentId === "" ? (
                              <button className="btn">
                                <i className="icon ion-md-cut" />
                              </button>
                            ) : (
                              <button
                                onClick={e => btnType(5)}
                                className="btn btn-action btn-copy  active"
                              >
                                <i className="icon ion-md-cut" />
                                <span className="new-btn">CUT</span>
                              </button>
                            )}
                        {parentStack.length < 1 ? (
                          <button className="btn">
                            <i className="icon ion-md-albums" />
                          </button>
                        ) : parentStack.length === listOfItems.length &&
                          parentId === "" ? (
                              <button className="btn">
                                <i className="icon ion-md-albums" />
                              </button>
                            ) : (
                              <button
                                onClick={e => btnType(6)}
                                className="btn btn-action btn-copy  active"
                              >
                                <i className="icon ion-md-albums" />
                                <span className="new-btn">COPY</span>
                              </button>
                            )}
                      </div>
                    )}
                </div>
                <div className="col-md-4 offset-md-2">
                  <div className="search-custom">
                    {/* <input type="text" name=""
                      onChange={e => handleSearch(e)}
                      className="form-control" placeholder="Search" />
                    <span><i className="material-icons align-middle">search</i></span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="path">
              <button className="btn btn-nothing" onClick={homeNav}>
                <i className="icon ion-md-home" />
              </button>
              {breadCum &&
                breadCum.map((item, key) => {
                  return (
                    <button
                      key={key}
                      onClick={e => navNew(item, key)}
                      className="btn btn-nothing"
                    >
                      <img src="/assets/images/subfolder.svg" alt="" />
                      <span>
                        {item.itemName} ({item.itemCode})
                      </span>
                    </button>
                  );
                })}
            </div>
            <div className="folder-list">
              <div className="row">
                <div className="col-md-8">
                  <div className="column-reset">
                    {listOfItems.length === 0 && (
                      <p className="text-center mt-3">No items found</p>
                    )}
                    {listOfItems &&
                      listOfItems.map((item, key) => {
                        // console.log(listOfItems);
                        return (
                          <div
                            style={
                              item.isStatic
                                ? { border: "1px solid red" }
                                : { border: "none" }
                            }
                            className={
                              parentStack.indexOf(item.id) === -1
                                ? "folder"
                                : "folder selected"
                            }
                            onDoubleClick={e =>
                              handleDoubleClick(item.id, item.isFolder, item)
                            }
                            onClick={e => chooseFolder(item.id, item)}
                            key={key}
                          >
                            <img
                              src={
                                item.isFolder
                                  ? "/assets/images/folder.svg"
                                  : "/assets/images/item.svg"
                              }
                              alt=""
                            />
                            <p className="name">{item.itemName}</p>
                          </div>
                        );
                      })}
                    {pastedItems.length > 0 && (
                      <div className="buttons-set">
                        <button className="btn btn-action">
                          {pastedItems.length} Item(s) in Clipboard
                        </button>
                        {destId !== parentId && (
                          <button
                            onClick={pasteHere}
                            className="btn btn-success"
                          >
                            Paste here
                          </button>
                        )}
                        <button className="btn bg-action inactive ">
                          cancel
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="folder-info">
                    {!detailView && (
                      <div className="empty">
                        <span>
                          <i className="icon ion-md-close" />
                        </span>
                        Nothing Selected
                      </div>
                    )}

                    {listOfItems.length !== 0 && detailView && (
                      <div>
                        <div className="folder-heading">
                          <img
                            src={
                              detailView.children.length !== 0
                                ? "/assets/images/folder.svg"
                                : "/assets/images/item.svg"
                            }
                            alt=""
                          />
                          <div className="right">
                            <h6 className="heading">{detailView.itemName}</h6>
                            <p>Item Code {detailView.itemCode}</p>
                            {detailView.children.length !== 0 && (
                              <p className="text">Parent Folder</p>
                            )}
                            {/* {detailView.children.length === 0 && (
                              <p className="text mb-2">
                                Item Code {detailView.itemCode}
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="custom-dropdown">
                          {detailView.children.length !== 0 && (
                            <button className="btn btn-nothing">
                              Children
                            </button>
                          )}
                          <div className="custom-dropdown-inner white d-block">
                            {detailView.children.map((items, key) => {
                              return (
                                <div key={key} className="folder-heading">
                                  <div>
                                    <img
                                      src={
                                        items.isFolder
                                          ? "/assets/images/folder.svg"
                                          : "/assets/images/item.svg"
                                      }
                                      alt=""
                                    />
                                    <div className="right">
                                      {items.isFolder ? (
                                        <h6
                                          onClick={e =>
                                            sideNavOpt(
                                              items.id,
                                              items.isFolder,
                                              items,
                                              detailView
                                            )
                                          }
                                          className="heading cursor-pointer"
                                        >
                                          {items.itemName}
                                        </h6>
                                      ) : (
                                          <h6 className="heading ">
                                            {items.itemName}
                                          </h6>
                                        )}
                                      {items.children.length !== 0 && (
                                        <p className="text">
                                          {items.children.length} Items{" "}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListManagementPage;
