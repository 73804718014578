import React from "react";
import { NavLink } from "react-router-dom";
import DataType from "./components/Datatype";
import DisplayTypes from "./components/DisplayType";

const CreateFormsFieldsPage = props => {
  const {
    fields,
    createFieldsHandler,
    onChangeHandler,
    onisMandatory,
    onisPiiEncrpt,
    onisPiiNotEncrypt,
    onisNotPii,
    onisFormField,
    oncustomField,
    handleDataType,
    handleDisplayType,
    validator,
    redirect,
  } = props;
  return (
    <div>
      <div className="page-header go-back">
        <h6 className="heading">
          {/* <NavLink
            className="page-back"
            to="/database-settings/admin-form/fields/list">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
          </NavLink> */}
          Back
        </h6>
      </div>
      <div className="page-content">
        <div className="p-padding">
          <div className="form-holder">
            <form onSubmit={createFieldsHandler}>
              <div className="row">
                <div className="col-md-6">
                  <h6 className="sub-heading">
                    <b>Create New Fields</b>
                  </h6>

                  <div className="form-group custom">
                    <input
                      className="form-control"
                      type="text"
                      name="fieldName"
                      placeholder="&nbsp;"
                      onChange={onChangeHandler}
                      value={fields.fieldName}
                    />
                    {validator.message(
                      "Field Name",
                      fields.fieldName,
                      "required|alpha_num_dash_space"
                    )}
                    <label>Field Name</label>
                  </div>

                  <div className="form-group custom">
                    <input
                      className="form-control"
                      type="text"
                      name="labelName"
                      placeholder="&nbsp;"
                      onChange={onChangeHandler}
                      value={fields.labelName}
                    />
                    {validator.message(
                      "Field Name",
                      fields.labelName,
                      "required|alpha_num_dash_space"
                    )}
                    <label>Label Name</label>
                  </div>

                  <div className="form-group custom">
                    <input
                      className="form-control"
                      type="text"
                      name="columnName"
                      placeholder="&nbsp;"
                      onChange={onChangeHandler}
                      value={fields.columnName}
                      style={{ textTransform: "uppercase" }}
                    />
                    {validator.message(
                      "Column Name",
                      fields.columnName,
                      "required|alpha_num_dash_space"
                    )}
                    <label>Column Name</label>
                  </div>

                  <div className="form-group custom">
                    <input
                      className="form-control"
                      type="text"
                      name="tableName"
                      placeholder="&nbsp;"
                      onChange={onChangeHandler}
                      value={fields.tableName}
                      style={{ textTransform: "uppercase" }}
                    />
                    {validator.message(
                      "Table Name",
                      fields.tableName,
                      "required|alpha_num_dash_space"
                    )}
                    <label>Table Name</label>
                  </div>

                  <div className="form-group custom labelup">
                    <label>Data Type</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown">
                        {fields.dataType ? fields.dataType : "Select Data type"}
                      </button>
                      <div className="dropdown-menu">
                        {fields.dataTypes
                          ? fields.dataTypes.map(dataType => {
                            return (
                              <DataType
                                handleDataType={handleDataType}
                                key={dataType.id}
                                id={dataType.id}
                                dataType={dataType.dataType}
                              />
                            );
                          })
                          : "No Data Type found"}
                      </div>
                      {validator.message(
                        "Column Name",
                        fields.dataType,
                        "required"
                      )}
                    </div>
                  </div>

                  <div className="form-group custom labelup">
                    <label>Display Type</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown">
                        {fields.displayType
                          ? fields.displayType
                          : "Select Display type"}
                      </button>
                      <div className="dropdown-menu">
                        {fields.displayTypes
                          ? fields.displayTypes.map(displayType => {
                            return (
                              <DisplayTypes
                                handleDisplayType={handleDisplayType}
                                key={displayType.id}
                                id={displayType.id}
                                displayType={displayType.displayType}
                              />
                            );
                          })
                          : "No Display Type found"}
                      </div>
                    </div>
                  </div>
                  {
                    fields.dataType && fields.dataType !== 'connected_list' && fields.dataType !== 'file' && fields.dataType !== 'boolean' && fields.dataType !== 'checkbox' && fields.dataType !== 'date' ? (
                      <div className="form-group custom">
                        <input
                          className="form-control"
                          type="text"
                          name="maxLength"
                          placeholder="&nbsp;"
                          onChange={onChangeHandler}
                          value={fields.maxLength}
                        />
                        {/* {validator.message(
                    "Max length Name",
                    fields.maxLength,
                    "required|numeric"
                  )} */}
                        <label>MaxLength</label>
                      </div>
                    ) : null
                  }


                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={onisMandatory}
                        checked={fields.isMandatory ? "checked" : null}
                        name="isMandatory"
                      />
                      <span className="slider round" />
                    </label>
                    <span>Mandatory</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={oncustomField}
                        checked={fields.customField ? "checked" : null}
                        name="customField"
                      />
                      <span className="slider round" />
                    </label>
                    <span>Custom Field</span>
                  </div>


                  <div className="add-border">
                    <h6 className="caption-text">Personal information input?</h6>
                    <div className="form-group">
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={onisPiiEncrpt}
                          checked={fields.isPiiEncrpt ? "checked" : null}
                          name="isPiiEncrpt"
                        />
                        <span className="slider round" />
                      </label>
                      <span>Yes It's PII</span>
                    </div>
                    <div className="form-group">
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={onisPiiNotEncrypt}
                          checked={fields.isPiiNotEncrypt ? "checked" : null}
                          name="isPiiNotEncrypt"
                        />
                        <span className="slider round" />
                      </label>
                      <span>
                        Yes It's PII, but not sensitive, Store It without
                        encryption.
                    </span>
                    </div>
                    <div className="form-group">
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={onisNotPii}
                          checked={fields.isNotPii ? "checked" : null}
                          name="isNotPii"
                        />
                        <span className="slider round" />
                      </label>
                      <span>No it's not PII</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-group-margin">
                <button
                  className="btn btn-border-success lg-padding"
                  type="submit">
                  Save
              </button>
                <button
                  className="btn btn-border-basic lg-padding"
                  type="button"
                  onClick={redirect}>
                  Cancel
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateFormsFieldsPage;
