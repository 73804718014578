import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const TableRow = props => {
  const { emailReminderData } = props;
  return (
    <>
      {props.isLoading === true ? (
        <tbody>
        <tr>
          <td>"Please wait..."</td>
        </tr>
      </tbody>
      ) : !emailReminderData.length ? (
        <tbody>
          <tr>
            <td className="text-center" colSpan="6">
              No records found
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {emailReminderData &&
            emailReminderData.map((item, key) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.type}</td>
                <td>{item.frequency}</td>
                <td>
                  <div className="btn-group-margin">
                    <NavLink
                      to={item.isSet == true ? `/database-settings/add-edit-reminder/${item.id}` : '#'}
                      className="btn btn-nothing hover-v"
                    >
                      <i className="material-icons fs-20">edit</i>
                      <span className="hover-name right-top">Edit</span>
                    </NavLink>
                    {/* <button className="btn btn-nothing hover-v">
                      <i className="material-icons fs-20">delete</i>
                      <span className="hover-name right-top">Delete</span>
                    </button> */}
                  </div>
                </td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={item.isSet}
                      onChange={() => props.OnStatusHandler(item)}
                    />
                    <span className="slider round" />
                  </label>
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </>
  );
};

TableRow.propTypes = {
  OnStatusHandler : PropTypes.func,
  activeStatus : PropTypes.bool,
  emailReminderData : PropTypes.array,
  name : PropTypes.string,
  type : PropTypes.string,
  frequency : PropTypes.string,
};
export default TableRow;
