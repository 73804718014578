import React, { Component } from "react"; 

const Menu = props => { 
  const { currentModule, listService,changeModule } = props;
  return (
    <div className="admin-tabs">
      <ul className="nav nav-tabs">
        {listService && listService.map((item, key) => {
          return (
            <li key={key}>
              <button
                className={(currentModule === item.id) ? "active" : ""}
                onClick={e => changeModule(item.id)}>
                {item.serviceModuleName}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  );
}

export default Menu;
