import { http } from "../_helpers";
import { config } from "../env";

const getDetailflight = () => {
  return http
    .get(config.apiMidOfficeLive + "get-mid-office-data")
    .then(data => {
      return data ? data : "";
    });
};
const getCancelledTripsData = () => {
  let url = `${config.apiMidOfficeLive}mid-office/canceled-trips`;
  return http.get(url)
    .then(res => res);
}
const getIssuedCreditsData = () => {
  let url = `${config.apiMidOfficeLive}mid-office/completed-trips`;
  return http.get(url)
    .then(res => res);
}

const getFlightBookingRequest = (data) => {
  return http.get(config.apiMidOfficeLive + `bookings/all-trips/?statusType=${data.statusType}&bookingType=${data.bookingType}&isInternational=${data.isInternational}&search=${data.search}&limit=${data.limit}&page=${data.page}&companyId=${data.companyId ? data.companyId : ''}`)
    .then(data => {
      return data ? data : "";
    });
};
const getHotelBookingRequest = (data) => {
  return http.get(config.apiMidOfficeLive + `bookings/all-trips/?statusType=${data.statusType}&bookingType=${data.bookingType}&isInternational=${data.isInternational}&search=${data.search}&limit=${data.limit}&page=${data.page}&companyId=${data.companyId ? data.companyId : ''}`)
    .then(data => {
      return data ? data : "";
    });
};
const getBusBookingRequest = (data) => {
  return http.get(config.apiMidOfficeLive + `bookings/all-trips/?statusType=${data.statusType}&bookingType=${data.bookingType}&isInternational=${data.isInternational}&search=${data.search}&limit=${data.limit}&page=${data.page}`)
    .then(data => {
      return data ? data : "";
    });
};
const getTrainBookingRequest = (data) => {
  return http.get(config.apiMidOfficeLive + `bookings/all-trips/?statusType=${data.statusType}&bookingType=${data.bookingType}&isInternational=${data.isInternational}&search=${data.search}&limit=${data.limit}&page=${data.page}`)
    .then(data => {
      return data ? data : "";
    });
};

const getTripDetail = data => {
  return http
    .get(config.apiMidOfficeLive + `bookings/get-one-trip/${data.id}`)
    .then(data => {
      return data ? data : "";
    });
};

const getUpdatedPrice = data => {
  return http
    .get(config.apiMidOfficeLive + `trip/get-updated-price/${data.id}`)
    .then(data => {
      return data ? data : "";
    });
}
const getPnrdata = (id) => {
  return http
    .get(config.apiMidOfficeLive + `flight-pnr/get-pnr/${id}`)
    .then(data => {
      return data ? data : "";
    })
}
const getHotelPnrdata = id => {
  return http
    .get(config.apiMidOfficeLive + `hotel-pnr/get-pnr/${id}`)
    .then(data => {
      return data ? data : "";
    })
}
const updatePnrdata = (id, data) => {
  return http
    .patch(config.apiMidOfficeLive + `flight-pnr-status/update-status/${id}`, data)
    .then(data => {
      return data;

    });
};
const updateHotelPnrdata = (id, data) => {
  return http
    .patch(config.apiMidOfficeLive + `hotel-pnr-status/update-status/${id}`, data)
    .then(data => {
      return data;
    })
}
const updateBookingCancelData = (id, data) => {
  return http
    .patch(config.apiMidOfficeLive + `mid-office/cancel-trip/${id}`, data)
    .then(data => {
      return data;
    })
}
const updateQueueType = (id, data) => {
  return http
    .patch(config.apiMidOfficeLive + `booking/update-trip/${id}`, data)
    .then(data => {
      return data;
    })
}
const updateValidateFareData = (id, data) => {
  return http.patch(config.apiMidOfficeLive + `trip-price/update/${id}`, data)
    .then(data => {
      return data;
    })
}
const getPaymentType = () => {
  return http.get(config.apiMidOfficeLive + "payment-types/get-all").then(data => {
    return data;
  })
}
const UpdateAsMarkComplete = (id) => {
  console.log(id);
  let url = `${config.apiMidOfficeLive}booking/complete-booking/${id}`;
  return http.put(url)
    .then(res => res);
}

const getValidateFareData = (id) => {
  let url = `${config.apiMidOfficeLive}hotel-pnr/get-pnr/${id}`;
  return http.get(url)
    .then(res => res);
}
const UpdateHotelFaredata = (id, data) => {
  let url = `${config.apiMidOfficeLive}hotel-pnr-status/update-status/${id}`;
  return http.patch(url, data)
    .then(res => {
      console.log(res);
      return res
    });
}
const UpdateBasicData = (id, data) => {
  let url = `${config.apiMidOfficeLive}hotel-pnr-status/update-status/${id}`;
  return http.patch(url, data)
    .then(res => {
      console.log(res);
      return res
    });
}
const getETicketData = (tripId, type, hotelId) => {
  console.log(tripId, type, hotelId);
  let url = `${config.apiMidOfficeLive}journey/eticket?tripId=${tripId}&bookingType=${type}&hotelId=${hotelId}`;
  return http.get(url)
    .then(res => res);
}

const getVendorsDetails = () => {
  let url = `${config.apiMidOfficeLive}vendors/get-all`;
  return http.get(url)
    .then(data => data);
}
const getUserDetails = (id) => {
  let url = `${config.apiMidOfficeLive}user/details?userId=${id}`;
  return http.get(url)
    .then(data => data);
}

const getETicket = (data) => {
  console.log(data);
  let url = `${config.apiMidOfficeLive}journey/${data.type}?tripId=${data.tripId}&bookingType=${data.bookingType}&hotelId=${data.hotelId}`;
  return http.get(url)
    .then(res => res);
}

const changeVendorType = (id, data) => {
  let url = `${config.apiMidOfficeLive}mid-office/update-vendor/${id}`;
  return http.patch(url, data)
    .then(res => res)
}
const getClientList = () => {
  let url = `${config.apiMidOfficeLive}all-clients`;
  return http.get(url)
    .then(res => res);
}

const getFlightETicketnInvoice = (data) => {
  let url = `${config.apiMidOfficeLive}journey/${data.type1}?tripId=${data.tripId}&journeyId=${data.journeyId}&bookingType=${data.type2}`;
  return http.get(url)
    .then(res => res)
}
const getChosenPassportVisa = (tripId, userId) => {
  let url = `${config.apiUrlSupLive}travel-request/get-trip?userId=${userId}&tripId=${tripId}`;
  return http.get(url)
    .then(res => res)
}

export const midOfficeServices = {
  getDetailflight,
  getIssuedCreditsData,
  getCancelledTripsData,
  getFlightBookingRequest,
  getHotelBookingRequest,
  getTripDetail,
  getUpdatedPrice,
  getPnrdata,
  updatePnrdata,
  getHotelPnrdata,
  updateHotelPnrdata,
  updateBookingCancelData,
  updateQueueType,
  getBusBookingRequest,
  getTrainBookingRequest,
  updateValidateFareData,
  getPaymentType,
  UpdateAsMarkComplete,
  getValidateFareData,
  UpdateHotelFaredata,
  UpdateBasicData,
  getETicketData,
  getVendorsDetails,
  getUserDetails,
  getETicket,
  changeVendorType,
  getClientList,

  getFlightETicketnInvoice,
  getChosenPassportVisa
};
