import { http } from '../_helpers';
import { config } from '../env';



// System List api calls starts

const getSystemList = () => {
  return http.get(config.apiUrlEx + `system-fields`)
    .then(data => {
      return (data) ? data.result : '';
    });
}

const createListItem = (data) => {
  return http.post(config.apiUrlEx + `/system-fields`, data)
    .then(data => {
      return data;
    });
}

const getParentDetailById = (id) => {
  console.log(id);
  return http.get(config.apiUrlEx + `/system-field/${id}`)
    .then(data => {
      console.log(data);
      return (data) ? data.result : '';
    });
}

const updateListItem = (data) => {
  console.log(data);
  return http.patch(config.apiUrlEx + `system-field`, data)
    .then(data => {
      return data;
    });
}

const deleteListItem = (id) => {
  return http.delete(config.apiUrlEx + `system-field/${id}`)
    .then(data => {
      return data;
    });
}
const getSubFields = (parentId) => {
  let url = `${config.apiUrlEx}system-sub-fields/${parentId}`;
  return http.get(url)
    .then(res => res);
}

export const systemListServices = {
  getSystemList,
  createListItem,
  getParentDetailById,
  updateListItem,
  deleteListItem,
  getSubFields
}