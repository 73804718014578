import React, { Component } from 'react';
import TreeNode from './Tree/TreeNode';
import mockData from './Tree/default';
import { toast } from 'react-toastify';
import { masterService } from '../../_services';
import { Input, Select } from '../_common/_forms';
import SimpleReactValidator from 'simple-react-validator';
import { alertConstants } from '../../_constants';

class ManageMenuItemsPage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.updateValidator = new SimpleReactValidator();
    this.permissionValidator = new SimpleReactValidator();
    this.state = {
      menuName: '',
      menuType: '',
      userType: '',
      delegateType:'',
      sequence: '',
      parentId: '',
      serviceModuleId: '',
      planType: '', 
      frontEndUrl: '',
      sequence:'',
      id: '',
    }
  }
  updateParentName = "Parent"
  parentName = "Master"
  selected = 1
  id = ''
  componentDidMount() {
    this.getMenuList()
    this.getService()
    // this.getPlanList()
  }
  planList = []
  getPlanList() {
    masterService.getPlanList()
      .then(
        data => {
          this.planList = data.result;
        }, error => {
          this.loading = false;
          if (error.statusCode === 422) {
            let errors = error.message
            Object.keys(errors).map(key => {
              return (
                toast.error(errors[key])
              );
            })
          } else {
            toast.error(error.message)
          }
        }
      )
  }
  serviceList = []
  getService() {
    masterService.getServiceModuleListFl()
      .then(
        data => {
          this.serviceList = data.result;
        }, error => {
          this.loading = false;
          if (error.statusCode === 422) {
            let errors = error.message
            Object.keys(errors).map(key => {
              return (
                toast.error(errors[key])
              );
            })
          } else {
            toast.error(error.message)
          }
        }
      )
  }
  isAddShow = false;
  idEditShow = false;
  isPermission = false;
  getParent = (e) => {
    this.cancelAdd()
    this.parentName = e.menuName;
    this.selected = e.id
    this.setState({
      parentId: e.id
    })
    this.isAddShow = true;
    this.idEditShow = false;
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  editPanel = async (e) => {
    await this.cancelAdd();
    this.isAddShow = false;
    this.idEditShow = true;
    this.selected = e.id
    this.setState({
      parentId: e.parentId,
      menuName: e.menuName,
      id: e.id,
      menuType: e.menuType,
      frontEndUrl: e.frontEndUrl,
      isGroupAccess: e.isGroupAccess,
      sequence:e.sequence
    })
    console.log(e)
    this.isChecked = e.isGroupAccess
    this.menuTypeList.forEach(item => {
      if (e.menuType === item.type) {
        this.menuType = item.value
      }
    })
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.forceUpdate()
  }
  menuList = [];
  getMenuList() {
    masterService.getMenuList()
      .then(
        data => {
          this.loading = true;
          this.menuList = data.result;
          mockData.default[0].children = this.menuList
          this.forceUpdate()
        }, error => {
          this.loading = false;
          if (error.statusCode === 422) {
            let errors = error.message
            Object.keys(errors).map(key => {
              return (
                toast.error(errors[key])
              );
            })
          } else {
            toast.error(error.message)
          }
        }
      )
  }
  getDelegateUserType = (e) => {
    this.setState({
      delegateType: e
    })
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  menuTypeList = [
    { type: 0, value: "Default" },
    { type: 1, value: "Custom" },
    // { type: 2, value: "Functional" },
    // { type: 3, value: "Page" },
  ]
  permissionMenuName = ''
  editPermission = async (e) => {
    if (this.isPermission === true) {
      await this.cancelAdd();
      // if(window.confirm("Already your changing some menu do you want to discard")){
      //     console.log('asfd')
      // }else{
      //     return
      // }
    }
    this.isAddShow = false;
    this.idEditShow = false;
    this.permissionMenuName = e.menuName;
    this.selected = e.id;
    this.setState({
      id: e.id,
      serviceModuleId: e.serviceModuleId,
      userType: e.userType,
      delegateType: e.delegateType
    })
    this.isPermission = true;
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.forceUpdate();
  }
  updatePermission = (e) => {
    if (this.permissionValidator.allValid()) {
      if(this.state.delegateType){
        var req = {
          userType: this.state.userType,
          serviceModuleId: this.state.serviceModuleId,
          planType: this.state.planType,
          delegateType:this.state.delegateType
        }
      }else{
        var req = {
          userType: this.state.userType,
          serviceModuleId: this.state.serviceModuleId,
          planType: this.state.planType, 
        }
      }
      
      masterService.updateMenuList(this.state.id, req)
        .then(
          data => {
            this.loading = false;
            this.cancelAdd()
            this.getMenuList();
            toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          }, error => {
            this.loading = false;
            if (error.statusCode === 422) {
              let errors = error.message
              Object.keys(errors).map(key => {
                return (
                  toast.error(errors[key])
                );
              })
            } else {
              toast.error(error.message)
            }
          }
        )
    } else {
      this.permissionValidator.showMessages()
      this.forceUpdate();
    }
  }
  menuType = "Menu Type"
  changeType = (e) => {
    this.menuType = e.value;
    this.setState({
      menuType: e.type
    })
    this.forceUpdate()
  }
  selected = ''
  cancelAdd = () => {
    this.selected = ''
    this.menuType = "Menu Type"
    this.isAddShow = false;
    this.idEditShow = false;
    this.isPermission = false;
    this.setState({
      menuName: '',
      menuType: '',
      userType: '',
      sequence: '',
      parentId: '',
      serviceModuleId: '',
      planType: '',
      menuType: '',
      frontEndUrl: '',
      isGroupAccess: false,
      id: ''
    }, () => { })
    this.validator.hideMessages();
    this.forceUpdate()
  }
  listMenuResult = []
  addMenu = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      if (this.state.parentId === null || this.state.parentId === 0) {
        var req = {
          menuName: this.state.menuName,
          menuType: this.state.menuType,
          frontEndUrl: this.state.frontEndUrl,
          sequence:parseInt(this.state.sequence),
          serviceModuleId:this.state.serviceModuleId
        }
      } else {
        var req = {
          menuName: this.state.menuName,
          parentId: this.state.parentId,
          menuType: this.state.menuType,
          frontEndUrl: this.state.frontEndUrl,
          serviceModuleId:this.state.serviceModuleId,
          sequence:parseInt(this.state.sequence)
        }
      }
      masterService.createMenuList(req)
        .then(
          data => {
            this.loading = false;
            this.cancelAdd()
            this.getMenuList();
            toast.success(alertConstants.CREATED_SUCCESSFULLY)
          }, error => {
            this.loading = false;
            if (error.statusCode === 422) {
              let errors = error.message
              Object.keys(errors).map(key => {
                return (
                  toast.error(errors[key])
                );
              })
            } else {
              toast.error(error.message)
            }
          }
        )
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  isChecked = null;
  getFunctModule = () => {
    (this.isChecked === true) ? this.isChecked = false : this.isChecked = true;
    this.setState({
      isGroupAccess: this.isChecked
    })
  }

  updateMenu = (e) => {
    e.preventDefault()
    if (this.updateValidator.allValid()) {
      if (this.state.parentId === null || this.state.parentId == 0) {
        var req = {
          menuName: this.state.menuName,
          menuType: this.state.menuType,
          id: this.state.id,
          frontEndUrl: this.state.frontEndUrl,
          isGroupAccess: this.state.isGroupAccess,
          sequence:parseInt(this.state.sequence)
        }
      } else {
        var req = {
          menuName: this.state.menuName,
          parentId: this.state.parentId,
          menuType: this.state.menuType,
          id: this.state.id,
          frontEndUrl: this.state.frontEndUrl,
          isGroupAccess: this.state.isGroupAccess,
          sequence:parseInt(this.state.sequence)
        }
      }
      masterService.updateMenuList(this.state.id, req)
        .then(
          data => {
            this.loading = false;
            this.cancelAdd()
            this.getMenuList();
            toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          }, error => {
            this.loading = false;
            if (error.statusCode === 422) {
              let errors = error.message
              Object.keys(errors).map(key => {
                return (
                  toast.error(errors[key])
                );
              })
            } else {
              toast.error(error.message)
            }
          }
        )
    } else {
      this.updateValidator.showMessages();
      this.forceUpdate();
    }
  }
  updateParent = (e) => {
    if (this.idEditShow === true) {
      if (e.id == 0) {
        this.setState({
          parentId: null
        })
      } else {
        this.setState({
          parentId: e.parentId
        })
      }
      this.forceUpdate()
    }
  }
  getServiceModule = (e) => {
    this.setState({
      serviceModuleId: e
    })
  }
  getUserType = (e) => {
    this.setState({
      userType: e
    })
  }
  getPlanType = (e) => {
    this.setState({
      planType: e
    })
  }
  render() {
    return (
      <div>
        <div className="page-header">
          <h6 className="heading">Manage Menu Items</h6>
        </div>
        <div className="color-desp">
          <p>
            <span className="color"></span>
            Default - <small>Vertical Menu</small>
          </p>
          <p>
            <span className="color"></span>
            Custom - <small>Top Menu</small>
          </p>
          {/* <p>
            <span className="color"></span>
            Functional - <small>Functional Items</small>
          </p>
          <p>
            <span className="color"></span>
            Pages - <small>Inner Pages</small>
          </p> */}
        </div>
        <div className="page-content">
          <div className="p-padding fix-padding">
            <div className="row">
              <div className="col-md-6">
                <div className="profile-card fix-heigth">
                  <div className="details">
                    <h6 className="sub-heading">Menu Items</h6>
                    <div className="block-overflow">

                      <ul className="menu-items">
                        <TreeNode
                          selected={this.selected}
                          data={mockData.default[0]}
                          getParent={this.getParent}
                          editPanel={this.editPanel}
                          updateParent={this.updateParent}
                          editPermission={this.editPermission}
                        />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {(this.isAddShow === true) &&
                  <div className="profile-card">
                    <form autoComplete="off" onSubmit={this.addMenu}>
                      <div className="details">
                        <h6 className="sub-heading">Add Menu Items</h6>
                        <p>{this.parentName} | Add</p>
                        <Input
                          label="Menu Name"
                          type="text"
                          name="menuName"
                          error={this.validator.message('menuName', this.state.menuName, 'required')}
                          value={this.state.menuName}
                          controlFunc={this.handleChange}
                        />
                        <Input
                          label="URL"
                          type="text"
                          name="frontEndUrl"
                          error={this.validator.message('Url', this.state.frontEndUrl, 'required')}
                          value={this.state.frontEndUrl}
                          controlFunc={this.handleChange}
                        />
                        <Input
                          label="Sequence"
                          type="text"
                          name="sequence"
                          error={this.validator.message('sequence', this.state.sequence, 'required')}
                          value={this.state.sequence}
                          controlFunc={this.handleChange}
                        />
                        <div className="form-group">
                          <Select
                            placeholder={this.menuType}
                            keyName={'value'}
                            className="bg-white"
                            options={this.menuTypeList}
                            controlFunc={e => this.changeType(e, "menuType")}
                            error={this.validator.message('menuType', this.state.menuType, 'required')}
                          />
                        </div>
                        <h6 className="sub-heading">Services</h6>
                      {this.serviceList &&
                        this.serviceList.map((item, key) => {
                          return (
                            <div className="profile-card" key={key}>
                              <div className="details">
                                <div className="row">
                                  <div className="col-md-6">
                                    {item.serviceModuleName}
                                  </div>
                                  <div className="col-md-6 text-right">
                                    <label className="switch">
                                      <input
                                        onClick={e => this.getServiceModule(item.id)}
                                        type="radio"
                                        id="services"
                                        defaultChecked={(this.state.serviceModuleId === item.id) ? true : false}
                                        name="services" />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      {this.validator.message('serviceModule', this.state.serviceModuleId, 'required')}
                        <div className="form-group mt-4">
                          <button type="submit" className="btn btn-border-success sm-padding mr-1">Create</button>
                          <button type="button" onClick={this.cancelAdd} className="btn btn-border-basic sm-padding">Cancel</button>
                        </div>
                      </div>
                    </form>
                  </div>
                }
                {(this.idEditShow === true) &&
                  <div className="profile-card">
                    <form autoComplete="off" onSubmit={this.updateMenu}>
                      <div className="details">
                        <h6 className="sub-heading">Edit Menu Items </h6>
                        <p>Edit</p>
                        <Input
                          label="Menu Name"
                          type="text"
                          name="menuName"
                          error={this.updateValidator.message('menuName', this.state.menuName, 'required')}
                          value={this.state.menuName}
                          controlFunc={this.handleChange}
                        />
                        <Input
                          label="URL"
                          type="text"
                          name="frontEndUrl"
                          error={this.updateValidator.message('Url', this.state.frontEndUrl, 'required')}
                          value={this.state.frontEndUrl}
                          controlFunc={this.handleChange}
                        />
                        <Input
                          label="Sequence"
                          type="text"
                          name="sequence"
                          error={this.validator.message('sequence', this.state.sequence, 'required')}
                          value={this.state.sequence}
                          controlFunc={this.handleChange}
                        />
                        <div className="form-group">
                          <Select
                            placeholder={this.menuType}
                            keyName={'value'}
                            className="bg-white"
                            options={this.menuTypeList}
                            controlFunc={e => this.changeType(e, "menuType")}
                            error={this.updateValidator.message('menuType', this.state.menuType, 'required')}
                          />
                        </div>
                        <div className="form-group">
                          <label className="switch">
                            <input
                              onClick={e => this.getFunctModule()}
                              type="checkbox"
                              id="services"
                              defaultChecked={this.isChecked}
                              name="services" />
                            <span className="slider round"></span>
                          </label>
                          <span>Is Group Access</span>
                        </div>
                        <div className="form-group mb-0">
                          <button type="submit" className="btn btn-border-success sm-padding mr-1">Update</button>
                          <button type="button" onClick={this.cancelAdd} className="btn btn-border-basic sm-padding">Cancel</button>
                        </div>
                      </div>
                    </form>
                  </div>
                }
                {(this.isPermission === true) &&
                  <div className="profile-card">
                    <div className="details">
                      <h6 className="sub-heading">{this.permissionMenuName} | Permissions</h6>
                      <h6 className="sub-heading">Services</h6>
                      {this.serviceList &&
                        this.serviceList.map((item, key) => {
                          return (
                            <div className="profile-card" key={key}>
                              <div className="details">
                                <div className="row">
                                  <div className="col-md-6">
                                    {item.serviceModuleName}
                                  </div>
                                  <div className="col-md-6 text-right">
                                    <label className="switch">
                                      <input
                                        onClick={e => this.getServiceModule(item.id)}
                                        type="radio"
                                        id="services"
                                        defaultChecked={(this.state.serviceModuleId === item.id) ? true : false}
                                        name="services" />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      {this.permissionValidator.message('serviceModule', this.state.serviceModuleId, 'required')}
                      <h6 className="sub-heading">User Type</h6>
                      <div className="profile-card">
                        <div className="details">
                          <div className="row">
                            <div className="col-md-6">
                              User
                            </div>
                            <div className="col-md-6 text-right">
                              <label className="radio-container d-inline-block">
                                <input
                                  defaultChecked={(this.state.userType === 0) ? true : false}
                                  onClick={e => this.getUserType(0)} type="radio" name="radio" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-card">
                        <div className="details">
                          <div className="row">
                            <div className="col-md-6">
                              Admin
                        </div>
                            <div className="col-md-6 text-right">
                              <label className="radio-container d-inline-block">
                                <input
                                  defaultChecked={(this.state.userType === 1) ? true : false}
                                  onClick={e => this.getUserType(1)} type="radio" name="radio" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.permissionValidator.message('userType', this.state.userType, 'required')}                      
                      <h6 className="sub-heading">Delegate</h6>
                      <div className="profile-card">
                        <div className="details">
                          <div className="row">
                            <div className="col-md-6">
                            Delegate User
                        </div>
                            <div className="col-md-6 text-right">
                              <label className="radio-container d-inline-block">
                                <input
                                  defaultChecked={(this.state.delegateType === 1) ? true : false}
                                  onClick={e => this.getDelegateUserType(1)} type="radio" name="radio3" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-card">
                        <div className="details">
                          <div className="row">
                            <div className="col-md-6">
                              Delegate Approver
                        </div>
                            <div className="col-md-6 text-right">
                              <label className="radio-container d-inline-block">
                                <input
                                  defaultChecked={(this.state.delegateType === 2) ? true : false}
                                  onClick={e => this.getDelegateUserType(2)} type="radio" name="radio3" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      

                      {/* <h6 className="sub-heading">Plan Type</h6> */}
                      {this.planList &&
                        this.planList.map((item, key) => {
                          return (
                            <div key={key} className="profile-card">
                              <div className="details">
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    {item.planType}
                                  </div>
                                  <div className="col-md-6 text-right">
                                    <label className="switch">
                                      <input
                                        onClick={e => this.getPlanType(item.id)}
                                        defaultChecked={(this.state.planType === item.id) ? true : false}
                                        name="planType" type="radio" />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                                {/* <div className="form-group custom mb-0">
                            <input type="text" placeholder="&nbsp;" className="form-control" />
                            <label>Number of Rows</label>
                          </div> */}
                              </div>
                            </div>

                          )
                        })
                      }
                      <div className="help-block has-error">
                        {/* {this.permissionValidator.message('planType', this.state.planType, 'required')} */}
                      </div>
                      <div className="form-group mb-0">
                        <button type="submit" onClick={this.updatePermission} className="btn btn-border-success sm-padding mr-1">Update</button>
                        <button type="button" onClick={this.cancelAdd} className="btn btn-border-basic sm-padding">Cancel</button>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageMenuItemsPage;