import React from 'react';

const EmployeePermission = props => {
    const {handleEmployeePermission,id,permission} = props;
    return(
        <button type="button" onClick={e =>handleEmployeePermission(id,permission)} className="dropdown-item">
            {permission}
        </button>
    )
}

export default EmployeePermission;