import React from "react";
import { Input } from "../../../_common/_forms";
const FormItems = props => {
  const {
    error,
    state,
    title,
    //isStatic,
    className,
    handleChange,
    handleSubmit,
    btnType,
    type,
    handleUpdateSubmit,
    dontAllow
  } = props;
  return (
    <div className={className}>
      <div className="box-head">
        <h6>
          <img src="/assets/images/item.svg" />
          <b>{title}</b>
        </h6>
      </div>
      <div className="box-content">
        <div>
          <h6>Item Name</h6>
          <div className="form-group custom bg-white">
            <Input
              type="text"
              name="name"
              value={state.name}
              error={error.message("name", state.name, "required")}
              controlFunc={e => handleChange(e)}
            />
          </div>
        </div>
        <div>
          {(
            <div>
              <h6>Item Code</h6>
              <div className="form-group custom bg-white">
                <Input
                  type="text"
                  name="code"
                  value={state.code}
                  error={error.message("code", state.code, "required")}
                  controlFunc={e => handleChange(e)}
                />
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="btn-group-margin">
            {console.log("TYPE", type)}
            <button
              onClick={type === 3 ? handleUpdateSubmit : handleSubmit}
              className="btn btn-success save-button"
            >
              {type === 3 ? "Update" : "Save"}
            </button>
            <button onClick={btnType} className="btn btn-danger">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormItems;
