import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class DatabaseMenu extends Component {
  render() {
    return (
      <div className="adminmenu">
        <div className="admin-nav">Database Settings</div>
        <ul className="nav-overflow">
          <li className="heading">
            <NavLink
              to="/database-settings/travel-category"
              className="">
              Travel Rule Category
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/travel-rule-builder"
              className="">
              Travel Rule Builder
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/manage-menu-items"
              className="">
              Manage Menu Items
            </NavLink>
          </li>
          {/* <li className="heading">
                        <NavLink to="/database-settings/permission-settings" className="btn btn-nothing">
                            - Permission Settings
                        </NavLink>
                    </li>
                    <li className="heading">
                        <NavLink to="/database-settings/tab-settings" className="btn btn-nothing">
                            - Tab Settings
                        </NavLink>
                    </li> */}

          <li className="heading">
            <NavLink
              to="/database-settings/billing-cycle"
              className="">
              Billing Cycle
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/order-type"
              className="">
              Order Type
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/service-module"
              className="">
              Service Module
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/expense-form-header"
              className="">
              Form Header
            </NavLink>
          </li>

          <li onClick={e => localStorage.removeItem('serviceModule')} className="heading">
            <NavLink
              to="/database-settings/admin-form"
              className="">
              Forms and Fields
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/list-management"
              className="">
              List Management
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/email-administration/list"
              className="">
              Email Admin
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/system-list"
              className="">
              System List
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/email-reminders"
              className="">
              Email Reminder
            </NavLink>
          </li>
          <li className="heading">
            <NavLink
              to="/database-settings/countries-page"
              className="">
              Countries
            </NavLink>
          </li>
          {/* <li className="heading">
                        <NavLink to="/database-settings/countries" className="btn btn-nothing">
                            - Country
                        </NavLink>
                    </li>
                    <li className="heading">
                        <NavLink to="/database-settings/state" className="btn btn-nothing">
                            - State
                        </NavLink>
                    </li>
                    <li className="heading">
                        <NavLink to="/database-settings/city" className="btn btn-nothing">
                            - City
                        </NavLink>
                    </li>
                    <li className="heading">
                        <NavLink to="/database-settings/travel-contition" className="btn btn-nothing">
                            - Travel Contitions
                        </NavLink>
                    </li>  */}
        </ul>
      </div>
    );
  }
}
export default DatabaseMenu;
