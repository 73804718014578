import { http } from '../_helpers';
import { config } from '../env';

const getExpenseCategory = (page, limit, search, company) => {
  return http.get(config.apiUrl + `expense-category?page=${page}&limit=${limit}&search=${search}&companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}
const getExpensesCategory = (company) => {
  return http.get(config.apiUrl + `expense-category?companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}
const createExpenseCategory = (data) => {
  return http.post(config.apiUrl + `expense-category`, data)
    .then(data => {
      return (data) ? data : '';
    });
}
const updateExpenseCategory = (id, data) => {
  return http.patch(config.apiUrl + `expense-category${id}`, data)
    .then(data => {
      return (data) ? data : '';
    });
}
const statusExpenseCategory = (id, data) => {
  return http.put(config.apiUrl + `expense-category/${id}`, data)
    .then(data => {
      return (data) ? data : '';
    });
}
const deleteExpenseCategory = (id) => {
  return http.delete(config.apiUrl + `expense-category/${id}`)
    .then(data => {
      return (data) ? data : '';
    });
}
// expense types
const getExpenseTypesOne = (company) => {
  return http.get(config.apiUrl + `expense-type?companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}

const getExpenseTypesDetail = (id, company) => {
  return http.get(config.apiUrl + `expense-type/${id}?companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}

const getExpenseTypes = (page, limit, search, company) => {
  return http.get(config.apiUrl + `expense-type?page=${page}&limit=${limit}&search=${search}&companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}
const createExpenseTypes = (data) => {
  return http.post(config.apiUrl + `expense-type`, data)
    .then(data => {
      return (data) ? data : '';
    });
}
const updateExpenseTypes = (id, data) => {
  return http.patch(config.apiUrl + `expense-type/${id}`, data)
    .then(data => {
      return (data) ? data : '';
    });
}
const statusExpenseTypes = (id, data) => {
  return http.put(config.apiUrl + `expense-type/${id}`, data)
    .then(data => {
      return (data) ? data : '';
    });
}
const deleteExpenseTypes = (id) => {
  return http.delete(config.apiUrl + `expense-type/${id}`)
    .then(data => {
      return (data) ? data : '';
    });
}

const getPolicies = (page, limit, company) => {
  return http.get(config.apiUrl + `policies?page=${page}&limit=${limit}&companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}

const getAccountStructure = (search, company) => {
  return http.get(config.apiUrl + `accounting-structure?search=${search}&companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}

const addAccountStructure = (data) => {
  return http.post(config.apiUrl + `accounting-structure`, data)
    .then(data => {
      return (data) ? data : '';
    });
}

const deleteAccountStructure = (id) => {
  return http.delete(config.apiUrl + `accounting-structure/${id}`)
    .then(data => {
      return (data) ? data : '';
    });
}

const updateAccountStructure = (id, data) => {
  return http.patch(config.apiUrl + `accounting-structure/${id}`, data)
    .then(data => {
      return (data) ? data : '';
    });
}

// expense type limits

const getExpenseTypeLimit = () => {
  return http.get(config.apiUrl + `accounting-structure`)
    .then(data => {
      return (data) ? data : '';
    });
}

const createExpenseTypeLimit = () => {
  return http.get(config.apiUrl + `accounting-structure`)
    .then(data => {
      return (data) ? data : '';
    });
}

const updateExpenseTypeLimit = () => {
  return http.get(config.apiUrl + `accounting-structure`)
    .then(data => {
      return (data) ? data : '';
    });
}

const deleteExpenseTypeLimit = () => {
  return http.get(config.apiUrl + `accounting-structure`)
    .then(data => {
      return (data) ? data : '';
    });
}

const statusExpenseTypeLimit = () => {
  return http.get(config.apiUrl + `accounting-structure`)
    .then(data => {
      return (data) ? data : '';
    });
}

const createItemization = (data) => {
  return http.post(config.apiUrl + `expense-type-itemization`, data)
    .then(data => {
      return (data) ? data : '';
    });
}

const updateItemization = (id, data) => {
  return http.patch(config.apiUrl + `expense-type-itemization/${id}`, data)
    .then(data => {
      return (data) ? data : '';
    });
}


const getItemization = (page, limit, search, company) => {
  return http.get(config.apiUrl + `expense-type-itemization?page=${page}&limit=${limit}&search=${search}&companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}

const getItemizations = (company) => {
  return http.get(config.apiUrl + `expense-type-itemization?companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}
const statusExpenseItemization = (id, data) => {
  return http.put(config.apiUrl + `expense-type-itemization/${id}`, data)
    .then(data => {
      return (data) ? data : '';
    });
}

const statusAvailExpenseItemization = (id, data) => {
  return http.put(config.apiUrl + `expense-type-itemization/${id}/expense-type`, data)
    .then(data => {
      return (data) ? data : '';
    });
}

const getItemById = (id, company) => {
  return http.get(config.apiUrl + `expense-type-itemization/${id}?companyId=${company}`)
    .then(data => {
      return (data) ? data : '';
    });
}

// list management api calls starts

const getListManagement = (search, parent) => {
  return http.get(config.apiUrlCM + `/list-management?search=${search}&parentId=${parent}`)
    .then(data => {
      return (data) ? data.result : '';
    });
}

const createListItem = (data) => {
  return http.post(config.apiUrlCM + `/list-management`, data)
    .then(data => {
      return data;
    });
}

const getParentDetailById = (id) => {
  return http.get(config.apiUrlCM + `/list-management/${id}`)
    .then(data => {
      return (data) ? data.result : '';
    });
}

const updateListItem = (id, data) => {
  console.log(data);
  return http.patch(config.apiUrlCM + `list-management/${id}`, data)
    .then(data => {
      return data;
    });
}

const deleteListItem = (id) => {
  return http.delete(config.apiUrlCM + `/list-management/${id}`)
    .then(data => {
      return data;
    });
}

const copyListItem = ( data) => {
  return http.patch(config.apiUrlCM + `list-management/copy`, data)
    .then(data => {
      return data;
    });
}
const cutListItem = (data) => {
  return http.patch(config.apiUrlCM + `list-management/cut`, data)
    .then(data => {
      return data;
    });
}

export const expenseServices = {
  getExpenseCategory,
  getExpensesCategory,
  createExpenseCategory,
  updateExpenseCategory,
  statusExpenseCategory,
  deleteExpenseCategory,
  getExpenseTypes,
  createExpenseTypes,
  updateExpenseTypes,
  deleteExpenseTypes,
  statusExpenseTypes,
  getPolicies,
  addAccountStructure,
  getAccountStructure,
  deleteAccountStructure,
  updateAccountStructure,
  getExpenseTypeLimit,
  createExpenseTypeLimit,
  updateExpenseTypeLimit,
  statusExpenseTypeLimit,
  getExpenseTypesOne,
  deleteExpenseTypeLimit,
  createItemization,
  getItemization,
  getItemizations,
  statusExpenseItemization,
  getItemById,
  updateItemization,
  statusAvailExpenseItemization,
  getExpenseTypesDetail,
  getListManagement,
  createListItem,
  getParentDetailById,
  updateListItem,
  deleteListItem,
  copyListItem,
  cutListItem
}