import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { ProjectsListPage, CreateProjectsPage, ViewProjectsPage } from './index'
class ProjectsPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/projects') {
            this.props.history.push('/projects/list')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/projects') {
            this.props.history.push('/projects/list')
        }
    }
    render() {
        return (
            <div className="ProjectsPage">
                <div className="InsideName">
                    <Switch>
                        <Route path='/projects/list' component={ProjectsListPage} />
                        <Route path='/projects/create/:step' component={CreateProjectsPage} />
                        <Route path='/projects/view/:id' component={ViewProjectsPage} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default ProjectsPage;
