import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import DropdownButton from "./components/DropDownButton";
import Menu from "../Menu";


const FieldsPage = props => {
  const {
    fields,
    handlePageChange,
    handleItemChange,
    handleStatusChange,
    closeToggleHandler,
    toggleFieldSelect,
    handleHeaderButtonClick, changeModule, listService, currentModule
  } = props;
  const closeBtn = (
    <button className="close" onClick={closeToggleHandler}>
      &times;
    </button>
  );

  let activeStyle = ["bg-primary text-white"];
  let isItemSelected = fields.selectedField.length;
  let removeButtonStyle = ["btn "];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
  }
  // if(fields.selectedItemId[0]){

  let fieldsData =
    fields.fields &&
    fields.fields.map(field => {
      return (
        <>
          <tr
            key={field.id}
            className={
              fields.selectedField.some(i => i.id === field.id)
                ? activeStyle
                : null
            }>
            <td onClick={toggleFieldSelect.bind(this, field)}>
              {
                field.labelName
              }
            </td>
            <td>{field.dataType}</td>
            <td onClick={toggleFieldSelect.bind(this, field)}>
              {field.columnName}
            </td>
            <td>{field.isMandatory === false ? "NO" : "YES"}</td>
            <td>{field.tableName}</td>
            <td>
              <p className="td-padding">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={e => handleStatusChange(field)}
                    checked={field.activeStatus}
                    value={field.activeStatus}
                  />
                  <span className="slider round" />
                </label>
                {/* <span>{field.activeStatus ? "Active" : "Inactive"}</span> */}
              </p>
            </td>
          </tr>
        </>
      );
    });
  return (
    <div>
      
      <div className="page-header">
        <h6 className="heading">Forms and Fields</h6>
      </div>
      <div className="page-content">
      <Menu changeModule={changeModule}
        listService={listService}
        currentModule={currentModule}
      />
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Fields</h6>
              <p className="text">These are Fields.</p>
            </div>
          </div>
        </div>
        <div className="p-padding pb-0">
          <div className="row">
            <div className="col-4">
              <div className="form-group custom labelup mb-0">
                <label>Form Type</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown">
                    {fields.selectedFormHeader
                      ? fields.selectedFormHeader
                      : "Select Form Type"}
                  </button>
                  <div className="dropdown-menu">
                    {fields.formHeaderName
                      ? fields.formHeaderName.map(formhead => {
                        return (
                          <DropdownButton
                            handleHeaderButtonClick={
                              handleHeaderButtonClick
                            }
                            key={formhead.id}
                            id={formhead.id}
                            name={formhead.formHeaderName}
                          />
                        );
                      })
                      : "No Headers Found"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="p-padding pt-0">
            <div className="admin-tabs">
              <ul className="nav nav-tabs">
                <li>
                  <NavLink
                    className="show"
                    to="/database-settings/admin-form/forms/list">
                    Forms
            </NavLink>
                </li>
                <li>
                  <NavLink
                    className="show"
                    to="/database-settings/admin-form/forms-fields/list"
                    aria-current="page">
                    Forms Fields
            </NavLink>
                </li>
                <li>
                  <NavLink
                    className="show active"
                    to="/database-settings/admin-form/fields/list">
                    Fields
            </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-10">
                  <div className="btn-group-margin">
                    <NavLink
                      to={"/database-settings/admin-form/fields/create-form-fields/" + fields.serviceModuleId}
                      className="btn ">
                      Create New Field
                    </NavLink>
                    <NavLink
                      to={
                        fields.selectedField.length
                          ? `/database-settings/admin-form/forms-fields/modify-form-fields/${
                          fields.selectedField[0].id
                          }`
                          : `#`
                      }
                      className="btn "
                      disabled={isItemSelected ? false : true}
                      >
                      Modify Field
                      {/* <button
                      className="btn "
                        disabled={isItemSelected ? false : true}
                       >
                        Modify Field
                      </button>{" "} */}
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="search-custom">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <i className="icon ion-md-search" />
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Data Type</th>
                    <th>Column Name</th>
                    <th>Mandatory</th>
                    <th>Table Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {fieldsData ? (
                    fieldsData
                  ) : (
                      <tr key="noDataFound">
                        <td colSpan="6">No data found</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
            {fields.fields && (
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown">
                            {fields.itemPerPage}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              onClick={handleItemChange.bind(this, 10)}
                              className="dropdown-item">
                              10
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 20)}
                              className="dropdown-item">
                              20
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 30)}
                              className="dropdown-item">
                              30
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={fields.activePage}
                      itemsCountPerPage={fields.itemPerPage}
                      totalItemsCount={fields.totalItemsCount}
                      pageRangeDisplayed={1}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
export default FieldsPage;
