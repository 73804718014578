import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";

class ServiceWiseReportPage extends Component {
    render() {
        return (
            <div>
                <div className="page-header">
                    <h6 className="heading">Service Wise Report</h6>
                </div>
                <div className="page-content">
                    <div className="p-padding">
                    <ul className="expense-tabs">
                            <li className="active">
                                <a aria-current="page" className="active" href="#">Air</a>
                            </li>
                            <li className="">
                                <a href="#">Hotel</a>
                            </li>
                            <li className="">
                                <a href="#">Car</a>
                            </li>
                            <li className="">
                                <a href="#">Train</a>
                            </li>
                            <li className="">
                                <a href="#">Bus</a>
                            </li>
                        </ul>
                        <div className="table-container m-0 border">                            
                            <form>
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="btn-group-margin">
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    All Clients
                                                </button>
                                                <div className="dropdown-menu">
                                                    <button className="dropdown-item">1</button>
                                                    <button className="dropdown-item">2</button>
                                                </div>
                                            </div>
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    Service
                                                </button>
                                                <div className="dropdown-menu">
                                                    <button className="dropdown-item">Service</button>
                                                    <button className="dropdown-item">Service</button>
                                                </div>
                                            </div>
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    Download
                                                </button>
                                                <div className="dropdown-menu">
                                                    <button className="dropdown-item">Excel</button>
                                                    <button className="dropdown-item">PDF</button>
                                                    <button className="dropdown-item">CSV</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="search-custom">
                                            <input type="text" name="" className="form-control" placeholder="Search" />
                                            <span><i className="material-icons align-middle">search</i></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>CURIO REF</th>
                                            <th>Client Name</th>
                                            <th>Issuance date</th>
                                            <th>PNR</th>
                                            <th>TRAVEL DATE</th>
                                            <th>SECTOR</th>
                                            <th>SUPPLIER</th>
                                            <th>CATEGORY</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><NavLink to="#" className="btn btn-nothing text-primary">CURIO289919</NavLink></td>
                                            <td>FSL</td>
                                            <td>07/04/2010</td>
                                            <td>Patricia Gilbert</td>
                                            <td>07/04/2010</td>
                                            <td>BLR - DEL, DEL - BLR</td>
                                            <td>ClearTrip</td>
                                            <td>Domestic</td>
                                        </tr>
                                        <tr>
                                            <td><NavLink to="#" className="btn btn-nothing text-primary">CURIO289919</NavLink></td>
                                            <td>FSL</td>
                                            <td>07/04/2010</td>
                                            <td>Patricia Gilbert</td>
                                            <td>07/04/2010</td>
                                            <td>BLR - DEL, DEL - BLR</td>
                                            <td>ClearTrip</td>
                                            <td>Domestic</td>
                                        </tr>
                                        <tr>
                                            <td><NavLink to="#" className="btn btn-nothing text-primary">CURIO289919</NavLink></td>
                                            <td>FSL</td>
                                            <td>07/04/2010</td>
                                            <td>Patricia Gilbert</td>
                                            <td>07/04/2010</td>
                                            <td>BLR - DEL, DEL - BLR</td>
                                            <td>ClearTrip</td>
                                            <td>Domestic</td>
                                        </tr>
                                        <tr>
                                            <td><NavLink to="#" className="btn btn-nothing text-primary">CURIO289919</NavLink></td>
                                            <td>FSL</td>
                                            <td>07/04/2010</td>
                                            <td>Patricia Gilbert</td>
                                            <td>07/04/2010</td>
                                            <td>BLR - DEL, DEL - BLR</td>
                                            <td>ClearTrip</td>
                                            <td>Domestic</td>
                                        </tr>
                                        <tr>
                                            <td><NavLink to="#" className="btn btn-nothing text-primary">CURIO289919</NavLink></td>
                                            <td>FSL</td>
                                            <td>07/04/2010</td>
                                            <td>Patricia Gilbert</td>
                                            <td>07/04/2010</td>
                                            <td>BLR - DEL, DEL - BLR</td>
                                            <td>ClearTrip</td>
                                            <td>Domestic</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-footer">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="page-count">
                                            <div className="text">Items</div>
                                            <div className="dropdown d-inline-block">
                                                <button type="button" className="btn dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    10
                                                </button>
                                                <div className="dropdown-menu">
                                                    <button className="dropdown-item">10</button>
                                                    <button className="dropdown-item">20</button>
                                                    <button className="dropdown-item">30</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <Pagination
                                            activePage={this.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={10}
                                            pageRangeDisplayed={1}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default ServiceWiseReportPage;