import React from "react";
import Pagination from "react-js-pagination";
import DropdownButton from "./components/DropDownButton";
import { NavLink } from "react-router-dom";
import Menu from "../Menu";
import { Select, Input } from "../../../_common/_forms";
const FormsPage = props => {
  const {
    fullFormData,
    loading,
    formNameHandler,
    handleSubmit,
    handlePageChange,
    handleItemChange,
    handleStatusChange,
    handleHeaderButtonClick,
    editFormName,
    validator,
    editValidator,
    updateForm,
    currentModule,
    changeModule,
    listService,
    resetState,
    pageTypeList,
    pageTypeName,
    changePageType
  } = props;
  let activeStyle = ["bg-primary text-white"];
  let isItemSelected = fullFormData.selectedFormHeader && fullFormData.selectedFormHeader.length;
  let removeButtonStyle = ["btn "];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
    removeButtonStyle.push("daisabled");
  }

  let modifyForm =
    fullFormData.forms &&
    fullFormData.forms.map((form, index) => {
      return (
        <>
          <tr
            key={index}
            className={
              fullFormData.selectedForm.some(i => i.id === form.id)
                ? activeStyle
                : null
            }>
            <td>
              {form.formName}
            </td>
            <td>
              <button
                className="btn btn-nothing hover-v"
                onClick={editFormName.bind(this, form)}
                data-target="#editForm"
                data-toggle="modal">
                <i className="material-icons fs-20">edit</i>
                <span className="hover-name right-top">Edit</span>
              </button>
            </td>
            <td>
              <p className="td-padding">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={e => handleStatusChange(form)}
                    checked={form.activeStatus}
                    value={form.activeStatus}
                  />
                  <span className="slider round" />
                </label>{" "}
                
              </p>
            </td>
          </tr>
        </>
      );
    });

  return (
    <div>
      <div className="page-header"><h6 className="heading">Forms and Fields</h6></div>
      <div className="page-content">
        <Menu changeModule={changeModule} currentModule={currentModule} listService={listService} />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Forms</h6>
              <p className="text">These are forms</p>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="p-padding pb-0">
          <div className="form-holder">
            <div className="row">
              <div className="col-4">
                <div className="form-group custom labelup mb-0">
                  <label>Form Type</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-border-basic dropdown-toggle btn-block"
                      data-toggle="dropdown">
                      {fullFormData.selectedFormHeader
                        ? fullFormData.selectedFormHeader
                        : "Select Form Type"}
                    </button>
                    <div className="dropdown-menu">
                      {fullFormData.formHeaderName
                        ? fullFormData.formHeaderName.map((formhead, key) => {
                          return (
                            <DropdownButton
                              handleHeaderButtonClick={handleHeaderButtonClick}
                              key={key}
                              id={formhead.id}
                              name={formhead.formHeaderName}
                            />
                          );
                        })
                        : "No Headers Found"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="p-padding pt-0">
            <div className="admin-tabs">
              <ul className="nav nav-tabs">
                <li>
                  <NavLink
                    className="show active"
                    to="/database-settings/admin-form/forms/list">
                    Forms
                </NavLink>
                </li>
                <li>
                  <NavLink
                    className="show"
                    to="/database-settings/admin-form/forms-fields/list"
                    aria-current="page">
                    Forms Fields
                </NavLink>
                </li>
                <li>
                  <NavLink
                    className="show"
                    to="/database-settings/admin-form/fields/list">
                    Fields
                </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="table-container">
            {fullFormData.selectedFormHeader && (
              <>
                <form>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="btn-group-margin">
                        <button
                          type="button"
                          className="btn "
                          data-target="#newForm"
                          data-toggle="modal"
                          onClick={resetState}>
                          Add Form
                      </button>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="search-custom">
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="Search"
                          disabled={
                            fullFormData.selectedFormHeader ? false : true
                          }
                        />
                        <span>
                          <i className="icon ion-md-search" />
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="table-responsive" display="none">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Form Name</th>
                        <th>Action</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modifyForm ? (
                        modifyForm
                      ) : (
                          <tr>
                            <td colSpan="4">No data found</td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {fullFormData.forms && (
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown">
                            {fullFormData.itemPerPage}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              onClick={handleItemChange.bind(this, 10)}
                              className="dropdown-item">
                              10
                          </button>
                            <button
                              onClick={handleItemChange.bind(this, 20)}
                              className="dropdown-item">
                              20
                          </button>
                            <button
                              onClick={handleItemChange.bind(this, 30)}
                              className="dropdown-item">
                              30
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={fullFormData.activePage}
                      itemsCountPerPage={fullFormData.itemsCountPerPage}
                      totalItemsCount={fullFormData.totalItemsCount}
                      pageRangeDisplayed={1}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* --------------------------ADD FORM MODEL---------------------------------- */}

        <div
          className="modal fade"
          id="newForm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddTemplateModalLabel"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="AddTemplateModalLabel">
                  New Form
              </h5>
                {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> */}
              </div>
              <form>
                <div className="modal-middle pm-30">
                  <Input
                    label="Form Name"
                    type="text"
                    name="formName"
                    className="bg-white"
                    error={validator.message('formName', fullFormData.formName, 'required')}
                    value={fullFormData.formName}
                    controlFunc={e => formNameHandler(e)}
                  />
                  <div className="modal-last btn-group-margin">
                    {(!loading) ?
                      <div>
                        <button
                          type="submit"
                          className="btn btn-border-success lg-padding"
                          onClick={handleSubmit}
                          data-dismiss={
                            fullFormData.createdForm === true ? "modal" : ""
                          }>
                          {" "}
                          Save
                  </button>
                        &nbsp;
                      <button
                          type="button"
                          className="btn btn-border-basic lg-padding"
                          data-dismiss="modal">
                          {" "}
                          Cancel
                  </button>
                      </div>
                      :
                      <button
                        type="button"
                        className="btn btn-border-success lg-padding"
                      >
                        {" "}
                        Please Wait... </button>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* --------------------------EDIT FORM MODEL---------------------------------- */}

        <div
          className="modal fade"
          id="editForm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddTemplateModalLabel"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="AddTemplateModalLabel">
                  Edit Form
              </h5>
                {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> */}
              </div>
              <form>
                <div className="modal-middle pm-30">
                  <Input
                    label="Form Name"
                    type="text"
                    name="formName"
                    className="bg-white"
                    error={editValidator.message('formName', fullFormData.formName, 'required')}
                    value={fullFormData.formName}
                    controlFunc={e => formNameHandler(e)}
                  />
                  <div className="modal-last btn-group-margin">
                    <button
                      type="button"
                      className="btn btn-border-success lg-padding"
                      onClick={updateForm}>
                      {" "}
                      Update
                  </button>
                    <button
                      type="button"
                      className="btn btn-border-basic lg-padding"
                      data-dismiss="modal">
                      {" "}
                      Cancel
                  </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsPage;
