import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { HistoryListPage, HistoryDetailsPage, UserDetailsPage } from './index'

class HistoryPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/history') {
            this.props.history.push('/history/list')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/history') {
            this.props.history.push('/history/list')
        }
    }
    render() {
        return (
            <div className="HistoryPage">
                <Switch>
                    <Route path='/history/list' component={HistoryListPage} />
                    <Route path='/history/details/:id/:type' component={HistoryDetailsPage} />
                    <Route path='/history/userDetails' component={UserDetailsPage} />
                </Switch>
            </div>
        );
    }
}

export default HistoryPage;
