import React, { Component } from 'react';
import { Input, Select, DomainInput } from '../_common/_forms';
import SimpleReactValidator from 'simple-react-validator';
import { masterService, projectService } from '../../_services';
import { toast } from 'react-toastify';
import { alertConstants } from '../../_constants/alert.constants';
// import ErrorPut from '../_common/error';

class CreateProjectsPage extends Component {
    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator();
        this.validatorStep2 = new SimpleReactValidator();
        this.validatorStep3 = new SimpleReactValidator();
        this.validatorStep4 = new SimpleReactValidator();
        if (this.props.match.params.step > 0 && this.props.match.params.step <= 4) {
            this.page = parseInt(this.props.match.params.step)
        } else {
            this.props.history.push('/projects/list');
        }
        this.state = {
            entityCode: '',
            companyName: '',
            companyLogo: '',
            subDomain: '',
            countryId: '',
            contactInfo: {
                profileImg: '',
                name: '',
                designation: '',
                email: '',
                phone: ''
            },
            orderTypeId: '',
            billingCycleId: '',
            orderEffectiveDate: '',
            invoiceGenerationDate: '',
            serviceInfo: []
        }
    }

    componentDidMount() {
        this.getCountry();
        this.getOrderType();
        this.getBillingType();
        this.serviceModule();
        if (this.state.entityCode === '') {
            this.props.history.push('/projects/create/1');
            this.page = 1;
        }
    }
    serviceModules = []
    serviceModule() {
        masterService.getServiceModuleList()
            .then(
                data => {
                    if (data != null) {
                        this.serviceModules = data.result
                        this.forceUpdate()
                    }
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    orderTypes = [];
    orderName = 'Order Type'
    getOrderType() {
        masterService.getOrderType()
            .then(
                data => {
                    if (data != null) {
                        this.orderTypes = data.result
                        this.forceUpdate()
                    }
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    changeOrder = (val, feild) => {
        this.orderName = val.items;
        this.setState({ [feild]: val.id })
        this.forceUpdate();
    }
    billingTypes = [];
    billingName = 'Billing Type'
    getBillingType() {
        masterService.getBillingCycle()
            .then(
                data => {
                    if (data != null) {
                        this.billingTypes = data.result
                        this.forceUpdate()
                    }
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    changeBilling = (val, feild) => {
        this.billingName = val.billingType;
        this.state[feild] = val.id;
        this.forceUpdate();
    }
    nextStep = () => {
        if (this.page < 4) {
            var next = this.page + 1
            this.props.history.push('/projects/create/' + next);
            this.page = next;
            this.forceUpdate()
        }
    }
    prevStep = () => {
        if (this.page > 0) {
            var prev = this.page - 1
            this.props.history.push('/projects/create/' + prev);
            this.page = prev;
            this.forceUpdate()
        }
    }
    countries = [];
    countryName = 'Country Name'
    getCountry() {
        masterService.getCountryList()
            .then(
                data => {
                    if (data != null) {
                        this.countries = data.result;
                        this.forceUpdate()
                    }
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    changeCountry = (val, field) => {
        this.countryName = val.name;
        this.state[field] = val.id;
        this.forceUpdate();
    }
    listService = [
        { name: "Travel", id: 1 },
        { name: "Expense", id: 2 },
        { name: "Cash Advance", id: 3 }
    ]
    choosed = [];
    chooseService(e) {
        const index = this.choosed.indexOf(e);
        if (index === -1) {
            this.choosed.push(e);
        }
        else {
            this.choosed.splice(index, 1);
        }
        this.forceUpdate();
    }
    handleChange = (e) => {
        var value = e.target.value;
        var name = e.target.name;
        this.setState({ [name]: value })
    }
    subHandleChange = (e) => {
        var value = e.target.value;
        var name = e.target.name;
        this.setState(prevState => ({
            contactInfo: {
                ...prevState.contactInfo,
                [name]: value
            }
        }))
    }
    submitForm = (e) => {
        e.preventDefault();
        if (this.page === 1) {
            if (this.validator.allValid()) {
                this.nextStep();
                this.validator.hideMessages();
                this.forceUpdate();
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        } else if (this.page === 2) {
            if (this.validatorStep2.allValid()) {
                this.nextStep();
                this.validatorStep2.hideMessages();
                this.forceUpdate();
            } else {
                this.validatorStep2.showMessages();
                this.forceUpdate();
            }
        } else if (this.page === 3) {
            if (this.validatorStep3.allValid()) {
                this.nextStep();
                this.validatorStep3.hideMessages();
                this.forceUpdate();
            } else {
                this.validatorStep3.showMessages();
                this.forceUpdate();
            }
        } else if (this.page === 4) {
            this.state.serviceInfo = this.choosed;
            if (this.choosed.length === 0) {
                toast.error(alertConstants.ANY_ONE + ' service minimum');
            } else {
                this.createProject();
            }
        }
    }
    createProject() {
        projectService.addProjects(this.state)
            .then(
                data => {
                    toast.success(alertConstants.CREATED_SUCCESSFULLY)
                    this.props.history.push('/projects/list')
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    render() {
        return (
            <div className="CreateProjectsPage">
                <div className="page-header">
                    <h6 className="heading">Create Project</h6>
                </div>
                <div className="page-content">
                    <div className="p-padding">
                        <div className="form-holder">
                            <div className="steps">
                                <div className={(this.page === 1) ? "step active" : "step"}>1</div>
                                <div className={(this.page === 2) ? "step active" : "step"}>2</div>
                                <div className={(this.page === 3) ? "step active" : "step"}>3</div>
                                <div className={(this.page === 4) ? "step active" : "step"}>4</div>
                            </div>
                            {(this.page === 1) ?
                                <div className="Steps1Page">
                                    <h5 className="sub-heading">Company Details</h5>
                                    <form name="createForm" onSubmit={e => this.submitForm(e)} autoComplete="off">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input
                                                    label="Entity Code"
                                                    type="text"
                                                    name="entityCode"
                                                    error={this.validator.message('entityCode', this.state.entityCode, 'required')}
                                                    value={this.state.entityCode}
                                                    controlFunc={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group custom labelup">
                                                    <label>Country</label>
                                                    {this.countries &&
                                                        <Select
                                                            placeholder={this.countryName}
                                                            keyName={'name'}
                                                            options={this.countries}
                                                            controlFunc={e => this.changeCountry(e, 'countryId')}
                                                            error={this.validator.message('countryName', this.state.countryId, 'required')}
                                                        />}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    label="Company Name"
                                                    type="text"
                                                    name="companyName"
                                                    error={this.validator.message('companyName', this.state.companyName, 'required')}
                                                    value={this.state.companyName}
                                                    controlFunc={this.handleChange}

                                                />
                                            </div>
                                            {/* <div className="col-md-6">
                                                <DomainInput
                                                    label="Sub domain Name"
                                                    type="text"
                                                    name="subDomain"
                                                    error={this.validator.message('subDomain', this.state.subDomain, 'required')}
                                                    value={this.state.subDomain}
                                                    controlFunc={this.handleChange}
                                                />
                                            </div> */}
                                            <div className="col-md-12">
                                                <div className="btn-group-margin">
                                                    <button type="submit" className="btn btn-border-success lg-padding">Next</button>
                                                    {/* <button type="button" className="btn btn-border-basic lg-padding">Cancel</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                :
                                (this.page === 2) ?
                                    <div className="Steps2Page">
                                        <h5 className="sub-heading">Contact Person</h5>
                                        <form name="createForm2" onSubmit={e => this.submitForm(e)} autoComplete="off">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Input
                                                        label="name"
                                                        type="text"
                                                        name="name"
                                                        error={this.validatorStep2.message('name', this.state.contactInfo['name'], 'required')}
                                                        value={this.state.contactInfo['name']}
                                                        controlFunc={this.subHandleChange}
                                                    />
                                                    <Input
                                                        label="designation"
                                                        type="text"
                                                        name="designation"
                                                        error={this.validatorStep2.message('designation', this.state.contactInfo['designation'], 'required')}
                                                        value={this.state.contactInfo['designation']}
                                                        controlFunc={this.subHandleChange}
                                                    />
                                                    <Input
                                                        label="Phone"
                                                        type="text"
                                                        name="phone"
                                                        error={this.validatorStep2.message('phone', this.state.contactInfo['phone'], 'required|numeric|min:10|max:12')}
                                                        value={this.state.contactInfo['phone']}
                                                        controlFunc={this.subHandleChange}
                                                    />
                                                    <Input
                                                        label="Email"
                                                        type="email"
                                                        name="email"
                                                        error={this.validatorStep2.message('email', this.state.contactInfo['email'], 'required|email')}
                                                        value={this.state.contactInfo['email']}
                                                        controlFunc={this.subHandleChange}
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="btn-group-margin">
                                                        <button type="submit" className="btn btn-border-success lg-padding">Next</button>
                                                        <button type="button" onClick={this.prevStep} className="btn btn-border-basic lg-padding">Previous</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    :
                                    (this.page === 3) ?
                                        <div className="Steps3Page">
                                            <h5 className="sub-heading">Biling And Development</h5>
                                            <form name="createForm3" onSubmit={e => this.submitForm(e)} autoComplete="off">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group custom labelup">
                                                            <label>Order Type</label>

                                                            {this.orderTypes &&
                                                                <Select
                                                                    placeholder={this.orderName}
                                                                    keyName={'items'}
                                                                    options={this.orderTypes}
                                                                    controlFunc={e => this.changeOrder(e, "orderTypeId")}
                                                                    error={this.validatorStep3.message('orderTypeId', this.state.orderTypeId, 'required')}
                                                                />}
                                                        </div>
                                                        <div className="form-group custom labelup">
                                                            <label>Biling Cycle</label>
                                                            {this.billingTypes &&
                                                                <Select
                                                                    placeholder={this.billingName}
                                                                    keyName={'billingType'}
                                                                    options={this.billingTypes}
                                                                    controlFunc={e => this.changeBilling(e, "billingCycleId")}
                                                                    error={this.validatorStep3.message('billingCycleId', this.state.billingCycleId, 'required')}
                                                                />}
                                                        </div>
                                                        <Input
                                                            label="Order Effective Date"
                                                            type="date"
                                                            min={new Date().toString("yyyy-MM-dd")}
                                                            name="orderEffectiveDate"
                                                            error={this.validatorStep3.message('orderEffectiveDate', this.state.orderEffectiveDate, 'required')}
                                                            value={this.state.orderEffectiveDate}
                                                            controlFunc={this.handleChange}

                                                        />
                                                        <Input
                                                            label="Invoice Generation Date"
                                                            type="date"
                                                            min={new Date().toString("yyyy-MM-dd")}
                                                            name="invoiceGenerationDate"
                                                            error={this.validatorStep3.message('invoiceGenerationDate', this.state.invoiceGenerationDate, 'required')}
                                                            value={this.state.invoiceGenerationDate}
                                                            controlFunc={this.handleChange}

                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="btn-group-margin">
                                                            <button type="submit" className="btn btn-border-success lg-padding">Next</button>
                                                            <button type="button" onClick={this.prevStep} className="btn btn-border-basic lg-padding">Previous</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        :
                                        (this.page === 4) ?
                                            <div className="Steps4Page">
                                                <h5 className="sub-heading">Services And Fees</h5>
                                                <form name="createForm4" onSubmit={e => this.submitForm(e)} autoComplete="off">
                                                    {this.serviceModules &&
                                                        <div className="row">
                                                            {this.serviceModules.map((item, key) => {
                                                                return (
                                                                    <div className="col-md-4" key={key}>
                                                                        <div className={(this.choosed.indexOf(item.id) === -1) ? "clickable-box" : "clickable-box active "} onClick={e => this.chooseService(item.id)}>
                                                                            <div className="left-icon">
                                                                                {(item.serviceModuleName && item.serviceModuleName === "Travel") ?
                                                                                    <i class="material-icons first">flight</i>
                                                                                    : (item.serviceModuleName === "Expense") ?
                                                                                        <i class="material-icons first">graphic_eq</i> : <i class="material-icons first">money</i>
                                                                                }
                                                                                <i class="material-icons second">check</i>
                                                                            </div>
                                                                            <div>
                                                                                <div>
                                                                                    {/* <p className="float">$/user</p> */}
                                                                                    <h6 className="heading">{item.serviceModuleName}</h6>
                                                                                </div>
                                                                                <p>
                                                                                    {(item.serviceModuleName && item.serviceModuleName === 'Travel') ?
                                                                                        "Experience smooth corporate travel booking along with advanced tools to manage company costs." :
                                                                                        (item.serviceModuleName === 'Expense') ?
                                                                                            "Simplify your employee expense reports and claims with controls you need to make it your own" :
                                                                                            "Manage Cash advance process completely from employee request to issuance with ease"
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="col-md-12">
                                                                <div className="btn-group-margin">
                                                                    <button type="submit" className="btn btn-border-success lg-padding">Create</button>
                                                                    <button onClick={this.prevStep} type="button" className="btn btn-border-basic lg-padding">Previous</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </form>
                                            </div>
                                            : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateProjectsPage;
