import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { userService, masterService } from "../_services";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import SortHead from "./_common/table/sort";
import { Select, Input } from "./_common/_forms";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import { alertConstants } from "../_constants";
class UserPage extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator();
    this.validatorUpdate = new SimpleReactValidator();
    this.state = {
      name: "",
      email: "",
      phone: "",
      id: ""
    };
  }
  componentDidMount() {
    this.getUserList();
    // this.getRoleList();
    this.loading = false;
  }
  itemsPerPage = 10;
  activePage = 1;
  userList = [];
  search = "";
  count = 0;
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  getUserList() {
    userService
      .getUserList(this.activePage, this.itemsPerPage, this.search)
      .then(
        data => {
          if (data != null) {
            this.count = data.count;
            this.userList = data.result;
            this.forceUpdate();
          }
        },
        error => {
          if (error.statusCode === 422) {
            let errors = error.message;
            Object.keys(errors).map(key => {
              return toast.error(errors[key]);
            });
          } else {
            toast.error(error.message);
          }
        }
      );
  }
  handleSearch = (e) =>{
    this.search = e.target.value;
    this.getUserList()
  }
  roleName = "Role";
  roleList = [];
  getRoleList() {
    masterService.getRoleAllList().then(
      data => {
        if (data != null) {
          this.roleList = data.result;
          this.forceUpdate();
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  }
  sortBy = e => {
    let arrayCopy = this.userList;
    arrayCopy.sort(this.compareBy(e));
    this.userList = arrayCopy;
    this.forceUpdate();
  };
  column = "";
  isDesc = "";
  compareBy(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    return function(a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    };
  }
  handlePageChange = pageNumber => {
    this.activePage = pageNumber;
    this.getUserList();
  };
  handleItemChange = limit => {
    this.activePage = 1;
    this.itemsPerPage = limit["items"];
    this.getUserList();
  };
  handleStatusChange = (e, item) => {
    let newStatus = !item.activeStatus; 
    userService.changeUserStatus(item, newStatus).then(
      res => { 
        newStatus
          ? toast.info("User Activated")
          : toast.info("User Deactivated");
        this.getUserList();
      }
    );
  };
  createForm = {
    name: "",
    email: "",
    roleId: "",
    phoneNo: "",
    mobileNumber: "",
    password: this.autoGen(6, "#aA")
  };
  updateForm = {
    id: "",
    name: "",
    email: "",
    roleId: "",
    phoneNo: "",
    mobileNumber: ""
  };
  autoGen(length, chars) {
    var mask = "";
    if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
    if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (chars.indexOf("#") > -1) mask += "0123456789";
    if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
    var result = "";
    for (var i = length; i > 0; --i)
      result += mask[Math.floor(Math.random() * mask.length)];
    return result;
  }

  handleCreateChange = (e, feild) => {
    if (feild === "roleId") {
      this.createForm[feild] = e.id;
      this.roleName = e.roleName;
      this.forceUpdate();
    } else {
      this.createForm[e.target.name] = e.target.value;
      this.forceUpdate();
    }
  };
  handleUpdateChangeGet = (e, feild) => {
    this.roleList.forEach(list => {
      if (e.roleId === list.id) {
        this.roleName = list.roleName;
      }
    });
  };
  handleUpdateChange = (e, feild) => {
    if (feild === "roleId") {
      this.updateForm[feild] = e.id;
      this.roleName = e.roleName;
      this.forceUpdate();
    } else {
      this.updateForm[e.target.name] = e.target.value;
      this.setState({
        [e.target.name]: e.target.value
      });
      this.forceUpdate();
    }
  };
  loading = "";
  createUser = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.loading = true;
      var req = {
        email: this.createForm.email,
        mobileNumber: parseInt(this.createForm.mobileNumber),
        name: this.createForm.name,
        password: this.createForm.password,
        phoneNo: parseInt(this.createForm.phoneNo)
      };
      userService.createUser(req).then(
        data => {
          this.loading = false;
          if (!toast.isActive(this.toastId)) {
            this.toastId = toast.success(alertConstants.CREATED_SUCCESSFULLY);
          }
          this.getUserList();
          $(".close").click();
        },
        error => {
          this.loading = false;
          if (error.statusCode === 422) {
            let errors = error.message;
            Object.keys(errors).map(key => {
              return toast.error(errors[key]);
            });
          } else {
            toast.error(error.message);
          }
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  // For editing user details
  updateUser = e => {
    e.preventDefault();
    if (this.validatorUpdate.allValid()) {
      this.loading = true;
      var req = {
        email: this.updateForm.email,
        mobileNumber: parseInt(this.updateForm.mobileNumber),
        name: this.updateForm.name,
        password: this.updateForm.password,
        id: this.updateForm.id,
        phoneNo: parseInt(this.updateForm.phoneNo)
      };
      userService.updateUser(req).then(
        data => {
          this.loading = false;
          if (!toast.isActive(this.toastId)) {
            this.toastId = toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          }
          this.getUserList();
          $(".close").click();
        },
        error => {
          this.loading = false;
          if (error.statusCode === 422) {
            let errors = error.message;
            Object.keys(errors).map(key => {
              return toast.error(errors[key]);
            });
          } else {
            toast.error(error.message);
          }
        }
      );
    } else {
      this.validatorUpdate.showMessages();
      this.forceUpdate();
    }
  };
  getEditDetails(data) {
    this.updateForm["name"] = data.name;
    this.updateForm["email"] = data.email;
    this.updateForm["mobileNumber"] = data.mobileNumber;
    this.updateForm["id"] = data.id;
    // Bug fix - om
    this.setState(
      {
        name: data.name,
        email: data.email,
        mobileNumber: data.mobileNumber,
        id: data.id
      },
      () => {
        this.forceUpdate();
      }
    );

    // End of bug fix

    // this.updateForm['phoneNo'] = data.phoneNo;
    // if (data.userRole.length > 0) {
    //   this.updateForm['roleId'] = data.userRole[0].id
    //   this.handleUpdateChangeGet(data.userRole[0], 'roleId')
    // }
    // console.log(this.updateForm)
    this.forceUpdate();
  }

  render() {
    return (
      <div className="UserPage">
        <div className="page-header">
          <h6 className="heading">Manage Users</h6>
        </div>
        <div className="page-content">
          <div className="py-padding">
            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-md-10">
                    <div className="btn-group-margin">
                      <button
                        type="button"
                        data-target="#CreatUserModal"
                        data-toggle="modal"
                        className="btn "
                      >
                        Add User
                      </button>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="search-custom">
                      <input
                        type="text"
                        name="search"
                        onChange={this.handleSearch}
                        className="form-control"
                        placeholder="Search"
                      />
                      <span>
                        <i className="icon ion-md-search" />
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <SortHead
                        name="Name"
                        feild="name"
                        column={this.column}
                        isDesc={this.isDesc}
                        sortBy={this.sortBy}
                      />
                      <SortHead
                        name="Email"
                        feild="email"
                        column={this.column}
                        isDesc={this.isDesc}
                        sortBy={this.sortBy}
                      />
                      <th>Action</th>
                      <SortHead
                        name="Status"
                        feild="activeStatus"
                        column={this.column}
                        isDesc={this.isDesc}
                        sortBy={this.sortBy}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {!this.userList && (
                      <tr>
                        <td colSpan="4">No records found</td>
                      </tr>
                    )}
                    {this.userList &&
                      this.userList.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>
                            <h6 className="heading">{item.name}</h6>
                              {/* <div className="shape-name">
                                <div className="left">VR</div>
                                <div className="right">
                                  <h6 className="heading">{item.name}</h6>
                                </div>
                              </div> */}
                            </td>
                            <td>
                              <p className="td-padding">{item.email}</p>
                            </td>
                            <td>
                              <button
                                onClick={e => this.getEditDetails(item)}
                                data-toggle="modal"
                                data-target="#EditUserModal"
                                className="btn btn-nothing hover-v"
                              >
                                <i className="material-icons fs-20">edit</i>
                                <span className="hover-name right-top">Edit</span>
                              </button>
                            </td>
                            <td>
                              <p className="td-padding">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    onChange={e =>
                                      this.handleStatusChange(e, item)
                                    }
                                    checked={item.activeStatus}
                                    value={item.activeStatus}
                                  />
                                  <span className="slider round" />
                                </label>
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {this.userList && (
                <div className="table-footer">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="page-count">
                        <div className="text">Items</div>
                        {this.pageItemsList && (
                          <Select
                            placeholder={this.itemsPerPage}
                            keyName={"items"}
                            options={this.pageItemsList}
                            controlFunc={e => this.handleItemChange(e)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        activePage={this.activePage}
                        itemsCountPerPage={this.itemsPerPage}
                        totalItemsCount={this.count}
                        pageRangeDisplayed={1}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="CreatUserModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="CreateUserModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="CreateUserModalLabel">
                  Create User
                </h5>
                {/* <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <form autoComplete="off" onSubmit={this.createUser}>
                <div className="modal-content pm-30">
                  {/* <div className="form-group custom labelup">
                    <label>{this.roleName}</label>
                    {this.roleList &&
                      <Select
                        error={this.validator.message('roleId', this.createForm['roleId'], 'required')}
                        placeholder={this.roleName}
                        keyName={'roleName'}
                        options={this.roleList}
                        controlFunc={e => this.handleCreateChange(e, 'roleId')}
                      />}
                  </div> */}
                  {/* <div className="upload-input mb-3">
                    <input type="file" />
                    <div className="text-center py-2">
                      <p><i className="far fa-file"></i></p>
                      <div>Upload Profile Picture</div>
                    </div>
                  </div>
                  <div className="file-output">
                    <span className="file-close">&times;</span><span>File Name</span>
                  </div> */}
                  <Input
                    label="Name"
                    type="text"
                    name="name"
                    error={this.validator.message(
                      "name",
                      this.createForm["name"],
                      "required"
                    )}
                    value={this.createForm["name"]}
                    controlFunc={this.handleCreateChange}
                  />
                  <Input
                    label="Email"
                    type="text"
                    name="email"
                    error={this.validator.message(
                      "email",
                      this.createForm["email"],
                      "required|email"
                    )}
                    value={this.createForm["email"]}
                    controlFunc={this.handleCreateChange}
                  />
                  {/* <Input
                    label="Phone No"
                    type="text"
                    name="phoneNo"
                    error={this.validator.message('phoneNo', this.createForm['phoneNo'], 'required|integer|min:10|max:12')}
                    value={this.createForm['phoneNo']}
                    controlFunc={this.handleCreateChange}
                  /> */}
                  <Input
                    label="Mobile No"
                    type="text"
                    name="mobileNumber"
                    error={this.validator.message(
                      "mobileNumber",
                      this.createForm["mobileNumber"],
                      "required|integer|min:10|max:12"
                    )}
                    value={this.createForm["mobileNumber"]}
                    controlFunc={this.handleCreateChange}
                  />

                  <div className="modal-last btn-group-margin">
                    {this.loading === false ? (
                      <span>
                        <button
                          type="submit"
                          className="btn btn-border-success lg-padding"
                        >
                          Save {this.loading}
                        </button>
                        <button
                          type="button"
                          className="btn btn-border-basic lg-padding"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </span>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-border-success lg-padding"
                      >
                        Please wait...
                      </button>
                    )}
                  </div>
                </div>                
              </form>
            </div>
          </div>
        </div>

        {/* Edit User Modal */}
        <div
          className="modal fade"
          id="EditUserModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="EditUserModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h5 className="modal-title text-white" id="EditUserModalLabel">
                  Edit User
                </h5>
                {/* <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <form autoComplete="off" onSubmit={this.updateUser}>
                <div className="modal-middle pm-30">
                  {/* <div className="form-group custom labelup">
                    <label>{this.roleName}</label>
                    {this.roleList &&
                      <Select
                        error={this.validator.message('roleId', this.updateForm['roleId'], 'required')}
                        placeholder={this.roleName}
                        keyName={'roleName'}
                        options={this.roleList}
                        controlFunc={e => this.handleUpdateChange(e, 'roleId')}
                      />}
                  </div> */}
                  {/* <div className="upload-input mb-3">
                    <input type="file" />
                    <div className="text-center py-2">
                      <p><i className="far fa-file"></i></p>
                      <div>Upload Profile Picture</div>
                    </div>
                  </div>
                  <div className="file-output">
                    <span className="file-close">&times;</span><span>File Name</span>
                  </div> */}
                  <Input
                    label="Name"
                    type="text"
                    name="name"
                    error={this.validatorUpdate.message(
                      "name",
                      this.state.name,
                      "required"
                    )}
                    value={this.state.name}
                    controlFunc={this.handleUpdateChange}
                  />
                  <Input
                    label="Email"
                    type="text"
                    name="email"
                    error={this.validatorUpdate.message(
                      "email",
                      this.state.email,
                      "required|email"
                    )}
                    value={this.state.email}
                    controlFunc={this.handleUpdateChange}
                  />
                  {/* <Input
                    label="Phone No"
                    type="text"
                    name="phoneNo"
                    error={this.validatorUpdate.message('phoneNo', this.updateForm['phoneNo'], 'required|integer|min:10|max:12')}
                    value={this.updateForm['phoneNo']}
                    controlFunc={this.handleUpdateChange}
                  /> */}
                  <Input
                    label="Mobile No"
                    type="text"
                    name="mobileNumber"
                    error={this.validatorUpdate.message(
                      "mobileNumber",
                      this.state.mobileNumber,
                      "required"
                    )}
                    value={this.state.mobileNumber}
                    controlFunc={this.handleUpdateChange}
                  />

                  <div className="modal-last btn-group-margin">
                    {this.loading === false ? (
                      <span>
                        <button
                          type="submit"
                          className="btn btn-border-success lg-padding"
                        >
                          Update {this.loading}
                        </button>
                        <button
                          type="button"
                          className="btn btn-border-basic lg-padding"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </span>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-border-success lg-padding"
                      >
                        Please wait...
                      </button>
                    )}
                  </div>
                </div>               
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserPage;
