import React from 'react';

const DisplayTypes = props => {
    const {handleDisplayType,displayType, id} = props;
    return(
        <button type="button" onClick={e =>handleDisplayType(id, displayType)} className="dropdown-item">
            {displayType}
        </button>
    )
}

export default DisplayTypes;