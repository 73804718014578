import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";

class ReportPage extends Component {
    render() {
        return (
            <div>
                <div className="page-header">
                    <h6 className="heading">Operations & Profitability Reports</h6>
                </div>
                <div className="page-content">
                    <div className="p-padding">
                        <div className="bg-white py-padding">
                            <div className="center-heading text-center py-padding">
                                <div className="report_logo">
                                    <img src="/assets/images/report_icon.png" alt="" />
                                </div>
                                <h4>Reports</h4>
                                <p className="">What kind of report are you looking for</p>
                            </div>
                            <div className="pb-5">
                                <div className="row">
                                    <div className="col-md-10 offset-1">
                                        <h4 className="pb-2 report__head">Operation Reports</h4>
                                        <div className="row mb-4">
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/standard-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Operations Standard Report</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/operation-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Operations Report</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/service-wise-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Service Wise Report</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/client-wise-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Client Wise Report</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>

                                        <h4 className="pb-2 report__head">Operation Report - Internal</h4>
                                        <div className="row mb-4">
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/client-wise-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Employees Handling Client (Date)</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/employee-list">
                                                        <p className="mb-0">
                                                            <span className="ml-0">List of Client Handling Manager</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/top-performer">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Top 5 Performers</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/supplier-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Supplier Efficiency</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/error-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Error Reported</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/cancellation-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Cancellation Wise</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/error-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Service Wise Error Details</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/reschedule-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Re-Schedule Wise</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/employee-report">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Employee Efficiency</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/profitable-reports/offline-request">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Offline Request</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>

                                        <h4 className="pb-2 report__head">Profitability Report</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="#">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Profitability Report</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="#">
                                                        <p className="mb-0">
                                                            <span className="ml-0">TDS</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="#">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Supplier Report</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="#">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Transaction Fee</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="#">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Curio Report</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="#">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Service Charges</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="#">
                                                        <p className="mb-0">
                                                            <span className="ml-0">Service Tax Report</span>
                                                        </p>
                                                    </NavLink>
                                                </div>
                                            </div>  
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default ReportPage;