import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Forms from "./forms/forms";
class FormsMainPage extends Component {
  componentDidMount(){
    if (this.props.location.pathname === "/database-settings/admin-form/forms") {
      this.props.history.push("/database-settings/admin-form/forms/list");
    }
    // else {
    //   this.props.history.push(this.props.location.pathname);
    // }
  }
  // componentDidUpdate() {
  //     if (this.props.location.pathname === '/database-settings/forms') {
  //         this.props.history.push('/database-settings/forms')
  //     }
  // }
  render() {
    return (
      <div className="FFMainPage">
        <Switch>
          <Route path="/database-settings/admin-form/forms/list" component={Forms} />
        </Switch>
      </div>
    );
  }
}
export default FormsMainPage;
