import React from 'react';

const ApproverPermission = props => {
    const {handleApproverPermission,permission, id} = props;
    return(
        <button type="button" onClick={e =>handleApproverPermission(id,permission)} className="dropdown-item">
            {permission}
        </button>
    )
}

export default ApproverPermission;