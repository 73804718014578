import React, { Component } from 'react';
import { BrowserRouter,Router, Route, Switch, Redirect } from "react-router-dom";
import './_assets/main.css';
import {
  LoginPage,
  PageNotFoundPage,
  HomePage,
} from './_components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { history, PrivateRoute } from './_helpers';

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Router history={history}>
            <div className="routes">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange={false}
                draggable
                pauseOnHover
              />
              <Switch>
                <Route exact path='/login' component={LoginPage} />
                <PrivateRoute path="/" component={HomePage} /> 
                <Route path='/404' component={PageNotFoundPage} />
                <Redirect from='*' to='/404' />
              </Switch>
            </div>
          </Router>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
