import { authHeader } from '../_helpers';
import { toast } from 'react-toastify';

export const http = {
    get,
    post,
    put,
    patch,
    fileUpload,
    delete: _delete
};

function get(url){
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader()}
    };
    return fetch(url, requestOptions).then(handleResponse);
}
function post(url,data){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader()},
        body: JSON.stringify(data)
    };
    return fetch(url, requestOptions).then(handleResponse);
}
function put(url,data){
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader()},
        body: JSON.stringify(data)
    };
    return fetch(url, requestOptions).then(handleResponse);
}
function patch(url,data){
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader()},
        body: JSON.stringify(data)
    };
    return fetch(url, requestOptions).then(handleResponse);
}
function fileUpload(url /*: string */, data /*: mixed */) {
  let user  = JSON.parse(localStorage.user);
  const requestOptions = {
    method: 'POST',
    headers: {
      "x-api-key": "G0OndZH93PuXhhZk",
      Authorization: "Bearer " + user.token
    },
    body: data
};
  return fetch(url, requestOptions).then(handleResponse);
}
function _delete(url){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(url, requestOptions).then(handleResponse);
}
// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if(response.status  === 0){
//                 return "Server down or check your internet connection"
//             }else if(response.status === 500){
//                 return "Internal server error contact API"
//             }else if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 localStorage.removeItem('user');
//                 window.location.reload(true);
//             }
//             const error = (data && data.error) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }
// * Updated error handler
function handleResponse(response) {
  return response.text().then(text => {
    let data = "";
    try{

       data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 0) {
        return toast.error("Server down or check your internet connection");
      } else if (response.status === 500) {
        return toast.error("Internal server error contact API");
      } else if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("user");
        // window.location.reload(true);
        return toast.error("Session Expired");
      } else if (response.status === 422) {
        let errors = data.error;
        if (errors) {
          if (errors.details) {
            errors.details.map(items => {
              toast.error(items.code + " " + items.message);
            });
          } else if (errors.message) {
            if (typeof errors.message === "string") {
              toast.error(errors.message);
            } else {
              Object.keys(errors.message).map(key => {
                return toast.error(errors.message[key]);
              });
            }
          } else {
            toast.error(errors.message);
          }
        }
      } else if (response.status === 404) {
        let errors = data.error;
        toast.error(errors.message);
      } else if (response.status === 400) {
        let errors = data.error;
        toast.error(errors.message);
      }
      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }
  }catch(err){

  }
    return data;
  });
}