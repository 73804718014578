import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";

class OperationReportPage extends Component {
    render() {
        return (
            <div>
                <div className="page-header">
                    <h6 className="heading">Operations Report</h6>
                </div>
                <div className="page-content">
                    <div className="p-padding">
                        <div className="form-holder mb-4">
                            <form>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group mb-0 custom">
                                            <input type="text" className="form-control" placeholder="&nbsp;" />
                                            <label>From</label>
                                            <span className="icon-custom"><i className="material-icons">calendar_today</i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-0 custom">
                                            <input type="text" className="form-control" placeholder="&nbsp;" />
                                            <label>To</label>
                                            <span className="icon-custom"><i className="material-icons">calendar_today</i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn">Search</button>
                                    </div>
                                    <div className="col-md-2 offset-2">
                                        <div className="dropdown">
                                            <button type="button" className="btn dropdown-toggle"
                                                data-toggle="dropdown">
                                                Download
                                            </button>
                                            <div className="dropdown-menu">
                                                <button className="dropdown-item">Excel</button>
                                                <button className="dropdown-item">PDF</button>
                                                <button className="dropdown-item">CSV</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Top 5 Clients</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">Client Name</th>
                                                    <th className="border-0">From</th>
                                                    <th className="border-0">SERVICE</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Top 5 Suppliers Service Wise</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">SUPPLIER NAME</th>
                                                    <th className="border-0">From</th>
                                                    <th className="border-0">SERVICE</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>FSL</td>
                                                    <td>07/04/2010</td>
                                                    <td>Air</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Top 5 Arlines/Arlines</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">AIRLINE COde</th>
                                                    <th className="border-0">Airline name</th>
                                                    <th className="border-0">Sector</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Top 5 Sector</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">Airline code</th>
                                                    <th className="border-0">Airline name</th>
                                                    <th className="border-0">Sector</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Distance Travelled</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">Client Name</th>
                                                    <th className="border-0">From</th>
                                                    <th className="border-0">SERVICE</th>
                                                    <th className="border-0">KM/ML</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td>9000</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td>9000</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td>9000</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td>9000</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td>9000</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Top 5 Sector Flown on a Perticular Carrier</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">Airline code</th>
                                                    <th className="border-0">Airline name</th>
                                                    <th className="border-0">Sector</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>UK</td>
                                                    <td>IndiGo</td>
                                                    <td>BLR - DEL</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Top 5 Hotels</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">Hotel Name</th>
                                                    <th className="border-0">Group</th>
                                                    <th className="border-0">location</th>
                                                    <th className="border-0">duration</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Royal Grand</td>
                                                    <td>Group Name</td>
                                                    <td>Bangalore</td>
                                                    <td>5 Days</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Royal Grand</td>
                                                    <td>Group Name</td>
                                                    <td>Bangalore</td>
                                                    <td>5 Days</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Royal Grand</td>
                                                    <td>Group Name</td>
                                                    <td>Bangalore</td>
                                                    <td>5 Days</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Royal Grand</td>
                                                    <td>Group Name</td>
                                                    <td>Bangalore</td>
                                                    <td>5 Days</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Royal Grand</td>
                                                    <td>Group Name</td>
                                                    <td>Bangalore</td>
                                                    <td>5 Days</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Top 5 Car Suppliers</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">Car Suppliers</th>
                                                    <th className="border-0">Location</th>
                                                    <th className="border-0">From</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>New Brand</td>  
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Top 5 Location Car Used</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">Car Suppliers</th>
                                                    <th className="border-0">Location</th>
                                                    <th className="border-0">From</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white mb-4">
                                    <div className="p-4">
                                        <h5>Top 5 Distance Travelled</h5>
                                    </div>
                                    <div className="table-responsiv">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-0">#</th>
                                                    <th className="border-0">Car Suppliers</th>
                                                    <th className="border-0">Location</th>
                                                    <th className="border-0">From</th>
                                                    <th className="border-0">KM/ML</th>
                                                    <th className="text-right border-0">TOTAL AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td>9000</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td>9000</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td>9000</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td>9000</td>
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>New Brand</td>
                                                    <td>Bangalore</td>
                                                    <td>07/04/2010</td>
                                                    <td>9000</td>   
                                                    <td className="text-right">USD 100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default OperationReportPage;