import {
    config
} from '../env'
import {
    http
} from '../_helpers'




//------------list form headers------------------

const getFormHeader = (serviceModuleId) => {
    return http.get(config.apiUrlCM + `form-headers?serviceModuleId=${serviceModuleId}`)
        .then(data => data)
}

// ----------------------------------------------

// -------list form headers with service module-----

const getFormHeadersbyServices = (id) => {
    return http.get(config.apiUrlCM + `form-headers/?serviceModuleId=${id}`)
        .then(data => data)
}

// -------------------------------------------------


//----------------create form Headers--------------

const createFormHeader = (data) => {
    return http.post(config.apiUrlCM + `forms`, data)
        .then(data => data)
}
// ------------------------------------------------

// ------------list all service module -------------

const getServiceModule = () => {
    return http.get(config.apiUrlCM + `service-modules`)
        .then(data => data)
}

// -------------------------------------------------


//----------------- create service Modules--------------

const createServiceModule = (data) => {
    return http.post(config.apiUrlCMCM + `form-headers`, data)
        .then(data => data)
}

// -----------------------------------------FORMS--------------------------------------

// ------------------------Create Forms--------------------------------

const createForms = (data) => {
    return http.post(config.apiUrlCM + `forms`, data)
        .then(data => data)
}

//  list forms with paginaion ,limit, servicetype

const listFormsPLSF = (page, limit, serviceModuleId, formHeaderId) => {
    return http.get(config.apiUrlCM + `forms?page=${page}&limit=${limit}&serviceModuleId=${serviceModuleId}&formHeaderId=${formHeaderId}`)
        .then(data => data)
}

//  list forms with paginaion ,limit

const listFormsPL = (page, limit) => {
    return http.get(config.apiUrlCM + `forms?page=${page}&limit=${limit}`)
        .then(data => data)
}

//  list forms with paginaion

const listFormsAll = (page) => {
    return http.get(config.apiUrlCM + `forms?page=${page}`)
        .then(data => data)
}


// -----------------------------------------FORMS ENDS--------------------------------------

//  -----------------------------------FORMS and FIELDS ---------------------------------------------

const getFormFields = (data) => {
    return http.post(config.apiUrlCM + `fields/list-all`, data)
        .then(data => data)
}

const getMappedFields = data => {
    return http.post(config.apiUrlCM + `fields/list-all`, data)
        .then(data => data)
}
const mapFieldsToForm = data => {
    return http.post(config.apiUrlCM + `add-form-field`, data)
        .then(data => data)
}

const createFields = data => {
    return http.post(config.apiUrlCM + `fields`, data)
        .then(data => data)
}

const deleteFields = (id, data) => {
    return http.patch(config.apiUrlCM + `fields/${id}/delete`, data)
        .then(data => data)
}

const modifyFields = (id, data) => {
    return http.patch(config.apiUrlCM + `fields/${id}/update`, data)
        .then(data => data)
}
const getFieldsDetails = (id) => {
    return http.get(config.apiUrlCM + `fields/${id}/details`)
        .then(data => data)
}
// change form status
const changeFormStatus = (formId, status) => {
    return http.patch(config.apiUrlCM + `forms/${formId}`, status)
        .then(data => data)
}
// edit form name
const editFormDetail = (formId, data) => {
    return http.patch(config.apiUrlCM + `forms/${formId}`, data)
        .then(data => data)
}
// change field status  
const changeFieldStatus = (formId, status) => {
    return http.patch(config.apiUrlCM + `fields/${formId}/active-status`, status)
        .then(data => data)
}

const getRoles = () => {
    return http.get(config.apiUrlCM + `roles`)
        .then(data => data)
}

const customFieldsAre = (data) => {
    return http.post(config.apiUrlCM + `fields/list-all`, data)
        .then(data => data)
}
// ---------------------------------------------------------------------------------------

// ------------------------- Connected List ---------------------------------

const createConnectedList = (data) => {
    return http.post(config.apiUrlCM + `connected-lists`, data)
        .then(data => data)
}
const getConnectedList = (page, limit, serviceModuleId, formHeaderId) => {
    return http.get(config.apiUrlCM + `connected-lists?page=${page}&limit=${limit}&serviceModuleId=${serviceModuleId}&formHeaderId=${formHeaderId}`).then(data => data)
}

const connectedListStatusChange = (id, newStatus) => {
    return http.patch(config.apiUrlCM + `connected-lists/${id}/activate-status`, {
        activeStatus: newStatus
    })
        .then(data => data)
}

const listManagement = (id) => {
    return http.get(config.apiUrlCM + `get-list-parent?companyId=${id}`)
        .then(data => data)
}

const customFields = (data) => {
    return http.post(config.apiUrlCM + `company-fields/list-all`, data)
        .then(data => data)
}

const getChildrens = (parentId, companyId) => {
    return http.get(config.apiUrlCM + `get-list-parent?id=${parentId}&companyId=${companyId}`)
        .then(data => data)
}
// ---------------------------------------------------------------------------------------

const sapAPI = (data) => {
    return http.post(config.apiUrl + `arcolabsTrip/Sap`, data)
        .then(data => data)
}

export const companyAdmin = {

    createFields,
    getFormHeader,
    createFormHeader,
    getServiceModule,
    createForms,
    listFormsAll,
    listFormsPL,
    listFormsPLSF,
    createServiceModule,
    getFormHeadersbyServices,
    getFormFields,
    changeFormStatus,
    editFormDetail,
    changeFieldStatus,
    deleteFields,
    modifyFields,
    getFieldsDetails,
    getRoles,

    // forms and fields
    getMappedFields,
    mapFieldsToForm,
    customFieldsAre,

    // connected List
    createConnectedList,
    getConnectedList,
    connectedListStatusChange,
    listManagement,
    customFields,
    getChildrens,
    sapAPI
}