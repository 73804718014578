import React, { Component } from 'react';
import { Input, Button } from './_common/_forms';
import SimpleReactValidator from 'simple-react-validator';
import { observer } from "mobx-react"
import authStore from '../_store/auth.store';
class LoginPage extends Component {
    constructor(props) {
        localStorage.clear()
        super(props)
        this.state = {
            email: '',
            password: '',
        }
        this.validator = new SimpleReactValidator(); 
    }
    handleChange = (e) => {
        var value = e.target.value;
        var name = e.target.name;
        this.setState({ [name]: value })
    }
    submitForm = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            const { email, password } = this.state
            authStore.login({ email: email, password: password }) 
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        const { email, password } = this.state
        return (
            <div className="login">
                <div className="container-fluid">
                    <div className="login__half">
                        <div className="d-table d-table__middle">
                            <div>
                                <div className="logo">
                                    <img src="assets/images/logo_login.svg" alt="Logo" />
                                </div>
                                <form name="loginForm" onSubmit={this.submitForm} autoComplete="off">
                                    <Input
                                        label="Email"
                                        type="email"
                                        className=""
                                        name="email"
                                        error={this.validator.message('email', email, 'required|email')}
                                        value={email}
                                        controlFunc={this.handleChange}
                                    />
                                    <Input
                                        label="Password"
                                        type="password"
                                        name="password"
                                        error={this.validator.message('password', password, 'required|min:5|max:12')}
                                        value={password}
                                        controlFunc={this.handleChange}
                                    /> 
                                    {(authStore.inProgress === false) ?
                                        <Button type="submit" name="Login" className="btn  lg-padding" /> :
                                        <Button type="button" name="Please wait..." className="btn  lg-padding" />}
                                </form>
                                <div className="login-abs">
                                    <p className="">Curio Technologies Pvt. Ltd</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login__half">
                        <div className="d-table d-table__middle">
                            <div>
                                <h1>CURIO</h1>
                                <p>Managed corporate travel and expense</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(LoginPage);
