import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
class RescheduleBookingRequestPage extends Component {
  render() {
    let data = this.props;
    return (
      <div>
        <div className="page-header">
          <h6 className="heading">Flight Booking Request</h6>
        </div>
        <div className="page-content">
          <div className="admin-tabs">
            {/* <div className="inner-header">
            <div className="shape-header ml-5">
              <div className="left">F</div>
              <div className="right">
                <h6 className="heading">Flight Booking Request</h6>
                <p className="text">Has been the industry’s standard dummy text ever since the 1500s.</p>
              </div>
            </div>
          </div> */}
            <div className="py-padding">
              <div className="table-container">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="dropdown">
                          <button type="button" className="btn dropdown-toggle bg-light border-0 " data-toggle="dropdown">All Clients
                            </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">Expense Entry Form</button>
                            <button className="dropdown-item">Expense Entry Form</button>
                            <button className="dropdown-item">Expense Entry Form</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 offset-md-6">
                      <div className="search-custom">
                        <input type="text" name="" className="form-control" placeholder="Search" />
                        <span><i className="material-icons align-middle">search</i></span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>CURIO REF NO</th>
                        <th>GDS & PCC</th>
                        <th>TRAVELER NAME</th>
                        <th>PAYMENT TYPE</th>
                        <th>TDATE</th>
                        <th>CANCEL AT</th>
                        <th>COMPANY</th>
                        <th>AIRLINE</th>
                        <th>BOOKING DATE</th>
                        <th>RESCHEDULE DATE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-primary"><NavLink to="/test/mid-office/trip-id">CTFRKY2RAXS2</NavLink></td>
                        <td>QDAFDD<br />6GJ3</td>
                        <td>Marilyn Pierce</td>
                        <td>Float</td>
                        <td>12/01/2019</td>
                        <td>08/03/2019</td>
                        <td>FSL</td>
                        <td>9W 1184, 9W12126></td>
                        <td>08/03/2019</td>
                        <td>08/03/2019</td>
                      </tr>
                      <tr>
                        <td className="text-primary"><NavLink to="/test/mid-office/trip-id">CTFRKY2RAXS2</NavLink></td>
                        <td>QDAFDD<br />6GJ3</td>
                        <td>Marilyn Pierce</td>
                        <td>Float</td>
                        <td>12/01/2019</td>
                        <td>08/03/2019</td>
                        <td>FSL</td>
                        <td>9W 1184, 9W12126></td>
                        <td>08/03/2019</td>
                        <td>08/03/2019</td>
                      </tr>
                      <tr>
                        <td className="text-primary"><NavLink to="/test/mid-office/trip-id">CTFRKY2RAXS2</NavLink></td>
                        <td>QDAFDD<br />6GJ3</td>
                        <td>Marilyn Pierce</td>
                        <td>Float</td>
                        <td>12/01/2019</td>
                        <td>08/03/2019</td>
                        <td>FSL</td>
                        <td>9W 1184, 9W12126></td>
                        <td>08/03/2019</td>
                        <td>08/03/2019</td>
                      </tr>
                      <tr>
                        <td className="text-primary"><NavLink to="/test/mid-office/trip-id">CTFRKY2RAXS2</NavLink></td>
                        <td>QDAFDD<br />6GJ3</td>
                        <td>Marilyn Pierce</td>
                        <td>Float</td>
                        <td>12/01/2019</td>
                        <td>08/03/2019</td>
                        <td>FSL</td>
                        <td>9W 1184, 9W12126></td>
                        <td>08/03/2019</td>
                        <td>08/03/2019</td>
                      </tr>
                      <tr>
                        <td className="text-primary"><NavLink to="/test/mid-office/trip-id">CTFRKY2RAXS2</NavLink></td>
                        <td>QDAFDD<br />6GJ3</td>
                        <td>Marilyn Pierce</td>
                        <td>Float</td>
                        <td>12/01/2019</td>
                        <td>08/03/2019</td>
                        <td>FSL</td>
                        <td>9W 1184, 9W12126></td>
                        <td>08/03/2019</td>
                        <td>08/03/2019</td>
                      </tr>
                      <tr>
                        <td className="text-primary"><NavLink to="/test/mid-office/trip-id">CTFRKY2RAXS2</NavLink></td>
                        <td>QDAFDD<br />6GJ3</td>
                        <td>Marilyn Pierce</td>
                        <td>Float</td>
                        <td>12/01/2019</td>
                        <td>08/03/2019</td>
                        <td>FSL</td>
                        <td>9W 1184, 9W12126></td>
                        <td>08/03/2019</td>
                        <td>08/03/2019</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table-footer">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="page-count">
                        <div className="text">Items</div>
                        <div className="count">
                          <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                              10</button>
                            <div className="dropdown-menu">
                              <button type="button" className="dropdown-item">10</button>
                              <button type="button" className="dropdown-item">20</button>
                              <button type="button" className="dropdown-item">30</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        activePage={data.active}
                        itemsCountPerPage={10}
                        totalItemsCount={10}
                        pageRangeDisplayed={1}
                        onChange={data.handlePageChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RescheduleBookingRequestPage;
