import React from 'react';
import ReactToPrint from 'react-to-print';
import ETicketPage from './e-ticket';
import InvoicePage from './invoice';
import { midOfficeServices } from "../../_services";

class HotelSwitch extends React.Component {
    state = {
        data: [],
        type: ''
    }
    componentDidMount = () => {
        let data = {
            type: this.props.match.params.type,
            tripId: this.props.match.params.tripId,
            bookingType: this.props.match.params.bookingType,
            hotelId: this.props.match.params.hotelId,
        }
        console.log(data);
        this.setState({ type: this.props.match.params.type });
        this.getETicket(data);
    };
    getETicket = async (data) => {
        let res = await midOfficeServices.getETicket(data);
        if (res) {
            console.log(res);
            this.setState({ data: res.result });
        }
    };
    render() {
        console.log(this.state.data);
        return (
            <div>
                <ReactToPrint
                    trigger={() => <div className=""><a href="#" className="btn btn-primary">{(this.state.type === 'eticket') ? "Print E-Voucher" : "Print Invoice"}</a></div>}
                    content={() => this.componentRef}
                />
                {(this.state.type === 'eticket') ?
                    <ETicketPage ref={el => (this.componentRef = el)}
                        eTicketData={this.state.data} />
                    :
                    <InvoicePage ref={el => (this.componentRef = el)}
                        invoiceData={this.state.data} />
                }

            </div>
        );
    }
}
export default HotelSwitch;
















































