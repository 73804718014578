import React, { Component } from "react";
import { masterService } from "../../_services";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from "jquery";
import { toast } from "react-toastify";
import { alertConstants } from "../../_constants";
import SortHead from "../_common/table/sort";
import { Input, Select } from "../_common/_forms";
import SimpleReactValidator from "simple-react-validator";
import SearchInput, { createFilter } from "react-search-input";

const KEYS_TO_FILTERS = ["formHeaderName"];
class ExpenseFormHeaderPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.updateValidator = new SimpleReactValidator();
    this.state = {
      activePage: 1,
      itemPerPage: 10,
      listAll: [],
      formHeaderName: "",
      formHeaderKey: "",
      isGroupRequired: false,
      serviceModuleId: "",
      id: "",
      searchTerm: ""
    };
    this.getService();
  }
  loading = false;
  handleEditChange(field, e) {
    let editFields = this.state.editFields;
    editFields[field] = e.target.value;
    this.setState({ editFields });
    this.setState({ editErrors: "" });
  }
  serviceName = "Choose Service";
  changeService = e => {
    this.serviceName = e.serviceModuleName;
    this.setState({
      serviceModuleId: e.id
    });
    this.forceUpdate();
  };
  addSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.loading = true;
      var resp = await masterService.createExpenseFormHeader({
        formHeaderName: this.state.formHeaderName,
        serviceModuleId: this.state.serviceModuleId,
        isGroupRequired: this.state.isGroupRequired,
        formHeaderKey: this.state.formHeaderKey
      });
      this.loading = false;
      toast.success(resp.message);
      this.getList();
      this.forceUpdate()
      $(".close").click();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  listService = [];
  getService = async () => {
    var resp = await masterService.getServiceModuleListFl();
    if (resp) {
      this.listService = resp.result;
      this.forceUpdate();
    }
  };

  editSubmit = async e => {
    e.preventDefault();
    if (this.updateValidator.allValid()) {
      var resp = await masterService.updateExpenseFormHeader(this.state.id, {
        formHeaderName: this.state.formHeaderName,
        serviceModuleId: this.state.serviceModuleId,
        isGroupRequired: this.state.isGroupRequired,
        formHeaderKey: this.state.formHeaderKey
      });
      toast.success(resp.message);
      this.getList();
      $(".close").click();
    } else {
      this.updateValidator.showMessages();
      this.forceUpdate();
    }
  };

  cancelAdd() {
    // this.setState({addErrors:'',addFields:''})
    // document.getElementById("create-form").();
    $("#create-form input").val("");
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  componentDidMount() {
    this.getList();
  }
  loader = false;
  count = 0;
  getList() {
    this.setState({ listAll: [] });
    this.loader = true;
    masterService
      .getExpenseFormHeader()
      .then(data => {
        if (data !== null && data !== "") {
          this.count = data.count;
          this.setState({ listAll: data.result });
        }
      })
      .finally(() => {
        this.loader = false;
        this.forceUpdate();
      });
  }

  changeStatus(id, status) {
    masterService
      .activeInactiveBillingCycle(id, { activeStatus: status })
      .then(
        data => {
          if (!toast.isActive(this.toastId)) {
            this.toastId = toast.success(data.message);
          }
          this.getList();
        },
        error => {
          this.loading = false;
          if (error.statusCode === 422) {
            let errors = error.message;
            Object.keys(errors).map(key => {
              return toast.error(errors[key]);
            });
          } else {
            toast.error(error.message);
          }
        }
      )
      .finally(() => {
        this.loading = false;
        this.forceUpdate();
      });
  }

  doSearch(queryText) {
    console.log(queryText);
    //get query result
    var queryResult = [];
    this.props.data.forEach(function(person) {
      if (person.name.toLowerCase().indexOf(queryText) !== -1)
        queryResult.push(person);
    });

    this.setState({
      query: queryText,
      filteredData: queryResult
    });
  }

  editData(data) {
    // let editFields = this.state.editFields;
    this.serviceName =
      data.serviceModuleId && data.serviceModuleId.serviceModuleName;
    this.setState({
      formHeaderName: "",
      formHeaderKey: "",
      isGroupRequired: false,
      serviceModuleId: "",
      id: ""
    });
    this.isChecked = data.isGroupRequired;
    this.setState({
      formHeaderName: data.formHeaderName,
      formHeaderKey: data.formHeaderKey,
      isGroupRequired: data.isGroupRequired,
      serviceModuleId: data.serviceModuleId && data.serviceModuleId.id,
      id: data.id
    }); 
    this.forceUpdate();
  }
  searchUpdated = term => {
    this.setState({ searchTerm: term });
  };
  isChecked = null;
  getFunctModule = () => {
    this.isChecked = !this.isChecked;
    this.setState({
        isGroupRequired: this.isChecked
    },()=>{
        this.forceUpdate() 
    });
  };
  render() {
    const { itemPerPage } = this.state;
    const listAll = this.state.listAll.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );
    return (
      <div className="master">
        <div className="page-header">
          <h6 className="heading">Manage Form header</h6>
        </div>
        <div className="page-content">
          <div className="py-padding">
            {/* <NavLink to="" data-toggle="modal" data-target="#CreatUserModal"
                            className="add-button text-white">+</NavLink> */}
            <div className="table-container">
              <form autoComplete="off">
                <div className="row">
                  <div className="col-md-2">
                    <div className="btn-group-margin">
                      {/* <button
                        type="button"
                        data-toggle="modal"
                        data-target="#CreatUserModal"
                        className="btn "
                      >
                        Add Form Header
                      </button> */}
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <SortHead
                        name="Header Name"
                        feild="formHeaderName"
                        column={this.column}
                        isDesc={this.isDesc}
                        sortBy={this.sortBy}
                      />
                      <th>Service Module Name</th>
                      <th>Key Name</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.loader === true && (
                      <tr>
                        <td colSpan="3">Loading</td>
                      </tr>
                    )}
                    {!listAll && this.loader === false && (
                      <tr>
                        <td colSpan="3">No records found</td>
                      </tr>
                    )}
                    {listAll &&
                      listAll.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.formHeaderName}</td>
                            <td>{item.serviceModuleId.serviceModuleName}</td>
                            <td>{item.formHeaderKey}</td>
                            {/* <td>
                              <button
                                data-toggle="modal"
                                onClick={e => this.editData(item)}
                                data-target="#EditUserModal"
                                className="btn btn-nothing hover-v"
                              >
                              <i className="material-icons fs-20">edit</i>
                                <span className="hover-name right-top">Edit</span>
                              </button>
                            </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="table-footer">
                <div className="row">
                  <div className="offset-9 col-md-3">
                    {/* <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={this.state.listAll.length}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                        /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="CreatUserModal"
          tabIndex="-2"
          role="dialog"
          aria-labelledby="CreateUserModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form
                autoComplete="off"
                id="create-form"
                onSubmit={this.addSubmit.bind(this)}
              >
                <div className="modal-head">
                  <h5 className="modal-title" id="CreateUserModalLabel">
                    Create Header Name
                  </h5>
                  {/* <button
                    type="button"
                    onClick={e => this.cancelAdd(e)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                </div>
                <div className="modal-middle pm-30">
                  <Input
                    label="Header Name"
                    type="text"
                    name="formHeaderName"
                    className="bg-white"
                    error={this.validator.message(
                      "HeaderName",
                      this.state.formHeaderName,
                      "required"
                    )}
                    value={this.state.formHeaderName}
                    controlFunc={e => this.handleChange(e)}
                  />
                  <Input
                    label="Header Key"
                    type="text"
                    name="formHeaderKey"
                    className="bg-white"
                    error={this.validator.message(
                      "HeaderKey",
                      this.state.formHeaderKey,
                      "required"
                    )}
                    value={this.state.formHeaderKey}
                    controlFunc={e => this.handleChange(e)}
                  />
                  {this.listService && (
                    <Select
                      placeholder={this.serviceName}
                      keyName={"serviceModuleName"}
                      options={this.listService}
                      controlFunc={e =>
                        this.changeService(e, "serviceModuleId")
                      }
                      error={this.validator.message(
                        "ServiceModule",
                        this.state.serviceModuleId,
                        "required"
                      )}
                    />
                  )}
                  <div className="form-group mt-4">
                    <label className="switch">
                      <input
                        onClick={e => this.getFunctModule()}
                        type="checkbox"
                        id="services"
                        defaultChecked={this.isChecked}
                        name="services"
                      />
                      <span className="slider round" />
                    </label>
                    <span>Is Group Access</span>
                  </div>
                {/* </div> */}
                {this.loading === false ? (
                  <div className="modal-last btn-group-margin">
                    <button
                      type="submit"
                      className="btn btn-border-success lg-padding"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={this.cancelAdd()}
                      className="btn btn-border-basic lg-padding"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="modal-last btn-group-margin">
                    <button
                      type="button"
                      className="btn btn-border-success lg-padding"
                    >
                      Please Wait...
                    </button>
                  </div>
                )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="EditUserModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="EditUserModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form
                autoComplete="off"
                id="edit-form"
                onSubmit={this.editSubmit.bind(this)}
              >
                <div className="modal-head">
                  <h5 className="modal-title" id="EditUserModalLabel">
                    Edit Header Name
                  </h5>
                  {/* <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                </div>
                <div className="modal-middle pm-30">
                  <Input
                    label="Header Name"
                    type="text"
                    name="formHeaderName"
                    className="bg-white"
                    error={this.updateValidator.message(
                      "HeaderName",
                      this.state.formHeaderName,
                      "required"
                    )}
                    value={this.state.formHeaderName}
                    controlFunc={e => this.handleChange(e)}
                  />
                  <Input
                    label="Header Key"
                    type="text"
                    name="formHeaderKey"
                    className="bg-white"
                    error={this.updateValidator.message(
                      "HeaderName",
                      this.state.formHeaderKey,
                      "required"
                    )}
                    value={this.state.formHeaderKey}
                    controlFunc={e => this.handleChange(e)}
                  />
                  {this.listService && (
                    <Select
                      placeholder={this.serviceName}
                      keyName={"serviceModuleName"}
                      options={this.listService}
                      controlFunc={e =>
                        this.changeService(e, "serviceModuleId")
                      }
                      error={this.validator.message(
                        "ServiceModule",
                        this.state.serviceModuleId,
                        "required"
                      )}
                    />
                  )}
                  <div className="form-group mt-4">
                    <label className="switch">
                      <input
                        onClick={e => this.getFunctModule()}
                        type="checkbox"
                        id="services"
                        defaultChecked={this.isChecked}
                        name="services"
                      />
                      <span className="slider round" />
                    </label>
                    <span>Is Group Access</span>
                  </div>
                {/* </div> */}
                {this.loading === false ? (
                  <div className="modal-last btn-group-margin">
                    <button
                      type="submit"
                      className="btn btn-border-success lg-padding"
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      onClick={this.cancelAdd()}
                      className="btn btn-border-basic lg-padding"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="modal-last btn-group-margin">
                    <button
                      type="button"
                      className="btn btn-border-success lg-padding"
                    >
                      Please Wait...
                    </button>
                  </div>
                )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ExpenseFormHeaderPage;
