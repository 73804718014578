import { http } from "../_helpers";
import { config } from "../env";

const getRoleAllList = () => {
  return http.get(config.apiUrl + "roles").then(data => {
    return data;
  });
};

const getServiceModuleList = () => {
  return http
    .get(config.apiUrlCM + "service-module?isCompany=true")
    .then(data => {
      return data;
    });
};
const getServiceModuleListFl = () => {
  return http.get(config.apiUrlCM + "service-module").then(data => {
    return data;
  });
};

const getFormPageType = (serviceModuleId, headerId) => {
  return http
    .get(
      config.apiUrlCM +
      `form-page-type?serviceModuleId=${serviceModuleId}&formHeaderId=${headerId}`
    )
    .then(data => {
      return data;
    });
};

const addServiceModule = data => {
  return http.post(config.apiUrlCM + "service-module", data).then(data => {
    return data;
  });
};
// travel rule category
const getTravelRuleCategoryList = () => {
  return http
    .get(config.apiUrlSup + "travel/travel-category")
    .then(data => {
      return data;
    });
};

const createTravelRuleCategoryList = (data) => {
  return http
    .post(config.apiUrlSup + "travel/travel-category", data)
    .then(data => {
      return data;
    });
};

// Billing cycle api

const getBillingCycle = () => {
  return http.get(config.apiUrl + "billingcycles").then(data => {
    return data;
  });
};

const addBillingCycle = data => {
  return http.post(config.apiUrl + "billingcycles", data).then(data => {
    return data;
  });
};

const updateBillingCycle = data => {
  return http
    .patch(config.apiUrl + "billingcycles/update/" + data.id, data)
    .then(data => {
      return data;
    });
};

const activeInactiveBillingCycle = (id, data) => {
  return http
    .patch(config.apiUrl + "billingcycles/active-status/" + id, data)
    .then(data => {
      return data;
    });
};

const deleteBillingCycle = data => {
  return http.post(config.apiUrl + "billingcycles", data).then(data => {
    return data;
  });
};

// order type api starts here

const getOrderType = () => {
  return http.get(config.apiUrl + "list-order-type").then(data => {
    return data;
  });
};

const addOrderType = data => {
  return http.post(config.apiUrl + "create-order-type", data).then(data => {
    return data;
  });
};

const updateOrderType = data => {
  return http
    .patch(config.apiUrl + "update-order-type" + data.id, data)
    .then(data => {
      return data;
    });
};

const activeInactiveOrderType = data => {
  return http
    .patch(config.apiUrl + "active-inactive-order-type", data)
    .then(data => {
      return data;
    });
};

const deleteOrderType = data => {
  return http.post(config.apiUrl + "delete-order-type", data).then(data => {
    return data;
  });
};

const getCountryList = () => {
  // hotfix dinesh node
  return http
    .get(config.apiUrl + "countries")
    .then(data => {
      return data;
    });
};

const createTravelRule = data => {
  return http.post(config.apiUrlSup + "travel/travel-category-rule", data).then(data => {
    return data;
  });
};

const getContitions = () => {
  return http
    .get(config.apiUrlSup + "rule-condition")
    .then(data => {
      return data;
    });
};

const getListContitions = (id, page, limit) => {
  return http.get(config.apiUrlSup + "travel/travel-category-rule?travelCategoryId=" + id + "&page=" + page + "&limit=" + limit).then(data => {
    return data;
  });
};

const policyList = (id) => {
  return http.get(config.apiUrlSup + "travel/travel-policy-key?travelCategoryId=" + id).then(data => {
    return data;
  });
};

const getListContitionsId = id => {
  return http.get(config.apiUrlSup + "travel/travel-category-rule?id=" + id).then(data => {
    return data;
  });
};

const getRules = () => {
  return http.get(config.apiUrlSup + "master/condition/").then(data => {
    return data;
  });
};

const updateTravelRule = (data, id) => {
  return http
    .patch(config.apiUrlSup + "travel/travel-category-rule/" + data.id, data)
    .then(data => {
      return data;
    });
};

const getMenuList = () => {
  return http.get(config.apiUrlCM + "menus").then(data => {
    return data;
  });
};

const createMenuList = data => {
  return http.post(config.apiUrlCM + "menus", data).then(data => {
    return data;
  });
};

const updateMenuList = (id, data) => {
  return http.patch(config.apiUrlCM + "menus/" + id, data).then(data => {
    return data;
  });
};

// create service control

const getService = () => {
  return http.get(config.apiUrl + "list-service").then(data => {
    return data;
  });
};

const getPlanList = () => {
  return http.get(config.apiUrl + "subscription-plan").then(data => {
    return data;
  });
};

const getExpenseFormHeader = () => {
  return http.get(config.apiUrlCM + "form-headers").then(data => {
    return data;
  });
};

const createExpenseFormHeader = data => {
  return http.post(config.apiUrlCM + "form-headers", data).then(data => {
    return data;
  });
};

const updateExpenseFormHeader = (id, data) => {
  return http.patch(config.apiUrlCM + "form-headers/" + id, data).then(data => {
    return data;
  });
};

const updateProject = (id, data) => {
  return http
    .patch(config.apiUrl + "projects/" + id, data)
    .then(data => {
      return data;
    });
};

const updateP2P = (id, data) => {
  return http
    .patch(config.apiUrl + "projects/" + id + "/service", data)
    .then(data => {
      return data;
    });
};

const createClient = id => {
  return http
    .get(config.apiUrl + `/projects/${id}/status`)
    .then(data => {
      return data;
    });
};

const moveTestToLive = (id) =>{
  return http
  .post(config.apiUrl + `/projects/test-to-live`,{companyId:id})
  .then(data => {
    return data;
  });
}

//Fair policy api
const getFairPolicyType = () => {
  return http.get(config.apiUrl + "list-order-type").then(data => {
    return data;
  });
};

const getPaymentType = () => {
  return http.get(config.apiUrl + "list-order-type").then(data => {
    return data;
  });
};
const getReasonType = () => {
  return http.get(config.apiTestMidOffice + "cancelation-reasons/get-all").then(data => {
    return data;
  });
};

const getRulesList = () => {
  return http.get(config.apiUrl + "list-order-type").then(data => {
    return data;
  });
};

const getFareCurrencyType = () => {
  return http.get(config.apiUrlCM + "list-management?itemCode=CUR001").then(data => {
    console.log(data);
    return data;
  });
};

// const getPaymentForUpdateType = () => {
//   return http.get(config.apiUrl + "list-order-type").then(data => {
//     return data;
//   })
// }

//----------------- Country -----------------
const postCountryData = data => {
  return http.post(config.apiUrl + `countries`, data).then(data => {
    return data;
  });
};

const getCountryData = () => {
  return http.get(config.apiUrl + `countries`).then(data => data);
};

//----------------- State -----------------

const postStateData = data => {
  return http.post(config.apiUrl + `states`, data).then(data => data);
};

const getStateData = (id) => {
  return http.get(config.apiUrl + `states/?isDependecyRequired=true&countryId=${id}`)
};

// ---------------- City ---------------------
const postCityData = data => {
  return http.post(config.apiUrl + `cities`, data).then(data => data);
};
const getCityData = (countryId, stateId) => {
  return http.get(config.apiUrl + `cities?countryId=${countryId}&stateId=${stateId}&isDependecyRequired=true`);
};

export const masterService = {
  updateP2P,
  getRoleAllList,
  getServiceModuleList,
  addServiceModule,
  getCountryList,
  getBillingCycle,
  addBillingCycle,
  updateBillingCycle,
  deleteBillingCycle,
  activeInactiveBillingCycle,
  getOrderType,
  addOrderType,
  updateOrderType,
  deleteOrderType,
  activeInactiveOrderType,
  getContitions,
  createTravelRule,
  getListContitions,
  getListContitionsId,
  updateTravelRule,
  getMenuList,
  createMenuList,
  updateMenuList,
  getService,
  getPlanList,
  getServiceModuleListFl,
  getExpenseFormHeader,
  createExpenseFormHeader,
  updateExpenseFormHeader,
  getFormPageType,
  updateProject,
  createClient,
  getFairPolicyType,
  getReasonType,
  getTravelRuleCategoryList,
  createTravelRuleCategoryList,
  getRulesList,

  getFareCurrencyType,

  policyList,
  getPaymentType,

  getPaymentType,

  postCountryData,
  getCountryData,
  postStateData,
  getStateData,
  postCityData,
  getCityData,
  moveTestToLive
};
