import React, { Component } from "react";
import AddEditTemplatePage from "./add-edit-template-html";
import { companyEmailServices }from "../../../../_services";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
// import { companyEmailTravelServices } from "../../../../_services";
import {withRouter} from 'react-router-dom';


class AddEditTemplate extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      companyId : localStorage["440a28"],
      notificationBoxContent : "",
      serviceModuleId : localStorage["serviceModuleId"],
      name: "",
      subject: "",
      copyToEmail: "",
      content: "",
      isEditable: false,
      loading: false,
      serviceModuleName : "",
      changeSaveToLink : false,

    }
  }



  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (this.props.match.params.id !== undefined) {
      this.getListAll();
      this.setState({ isEditable: true });
    }
  }

  getListAll = async () => {
    var res = await companyEmailServices.getDetailEmailTemplate(
      this.props.match.params.id,
      this.state.companyId,
      this.state.serviceModuleId
    );
    if (res) {
      this.setState({
        name: res.result.name,
        subject: res.result.subject,
        copyToEmail: res.result.sendTo.join(","),
        content: res.result.content,
        activeStatus: res.result.activeStatus,
        notificationBoxContent: res.result.notificationContent ? res.result.notificationContent : ""
      });
    }
  };

  addEditTemplate = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    let data = {
      serviceModuleId: this.state.serviceModuleId,
      name: this.state.name,
      subject: this.state.subject,
      sendTo: this.state.copyToEmail,
      content: this.state.content,
      activeStatus: true,
      notificationContent : this.state.notificationBoxContent
    };
    if (this.validator.allValid()) {
      this.setState({changeSaveToLink:true})
      if (this.state.isEditable) {
        var res = await companyEmailServices.updateDetailEmailTemplate(
          this.props.match.params.id,
          data
        ).catch(e => {
          this.setState({ loading: false });
        });
        if (res) {
          toast.success(res.message);
          this.setState({ loading: false});
          this.props.history.push(`/database-settings/email-administration/${this.state.serviceModuleId}`)
        }
      } else {
        const resp = await companyEmailServices.postDetailEmailTemplate(data).catch(e => {
          this.setState({ loading: false });
        });
        if (resp) {
          this.setState({ loading: false });
          toast.success(resp.message);
          this.props.history.push(`/database-settings/email-administration/${this.state.serviceModuleId}`)
        }
      }
    } else {
      this.validator.showMessages();
      this.setState({ loading: false });
      this.forceUpdate();
    }
  };

  inputChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  emailContentHandler = data => {
    this.setState({ content: data });
  };

  notificationBoxContentHandler = data => {
    this.setState({
      notificationBoxContent : data.target.value
    })

  }

  render() {
    return (
      <div>
        <AddEditTemplatePage
          {...this.state}
          listUser={this.userList}
          inputChangeHandler={this.inputChangeHandler}
          submitHandler={this.addEditTemplate}
          emailContentHandler={this.emailContentHandler}
          validator={this.validator}
          notificationBoxContentHandler={this.notificationBoxContentHandler}
        />
      </div>
    );
  }
}
export default withRouter(AddEditTemplate);
