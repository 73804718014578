import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { ClientListPage, ViewClientPage } from './index';
class ClientPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/client') {
            this.props.history.push('/client/list')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/client') {
            this.props.history.push('/client/list')
        }
    }
    render() {
        return (
            <div className="ClientPage">
                <div className="InsideName">
                    <Switch>
                        <Route path='/client/list' component={ClientListPage} /> 
                        <Route path='/client/view/:id' component={ViewClientPage} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default ClientPage;
