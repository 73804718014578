import React from "react";
import { NavLink } from "react-router-dom";
import DataType from "./components/Datatype";
import DisplayTypes from "./components/DisplayType";

const ModifyFormsFieldsPage2 = props => {
  let {
    modifyFields,
    formNameHandler,
    handleSubmit,
    redirect,
    validator,
    onisNotPii,
    onisPiiEncrypt,
    onChangeHandler,
    onisPiiNotEncrypt,
    handleDataType,
    handleDisplayType,
    onisMandatory,
    oncustomField,
    onisFormField
  } = props;
  // console.log("serviceModuleId", modifyFields.serviceModuleId);

  return (
    <div>
      <div className="page-header go-back">
        <h6 className="heading">
          {/* <NavLink
            className="page-back"
            to={
              modifyFields.redirectionPath === "fields"
                ? "/database-settings/admin-form/fields/list"
                : "/database-settings/admin-form/forms-fields/list"
            }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
          </NavLink> */}
          Back
        </h6>
      </div>
      <div className="page-content">
        <div className="p-padding">
          <div className="form-holder">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-5">
                  <h6 className="sub-heading">
                    <b>Modify Fields</b>
                  </h6>
                  <label>Field Name</label>
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      name="fieldName"
                      onChange={formNameHandler}
                      value={modifyFields.fieldName || ""}
                    />
                    {validator.message(
                      "Field Name",
                      modifyFields.fieldName,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      name="labelName"
                      onChange={formNameHandler}
                      value={modifyFields.labelName || ""}
                    />
                    {validator.message(
                      "Field Label",
                      modifyFields.labelName,
                      "required"
                    )}
                    <label>Field Label</label>
                  </div>

                  <div className="form-group custom">
                    <input
                      className="form-control"
                      type="text"
                      name="columnName"
                      placeholder="&nbsp;"
                      onChange={formNameHandler}
                      value={modifyFields.columnName}
                      style={{ textTransform: "uppercase" }}
                    />
                    {validator.message(
                      "Column Name",
                      modifyFields.columnName,
                      "required|alpha_num_dash_space"
                    )}
                    <label>Column Name</label>
                  </div>

                  <div className="form-group custom">
                    <input
                      className="form-control"
                      type="text"
                      name="tableName"
                      placeholder="&nbsp;"
                      onChange={formNameHandler}
                      value={modifyFields.tableName}
                      style={{ textTransform: "uppercase" }}
                    />
                    {validator.message(
                      "Table Name",
                      modifyFields.tableName,
                      "required|alpha_num_dash_space"
                    )}
                    <label>Table Name</label>
                  </div>

                  <div className="form-group custom labelup">
                    <label>Data Type</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown">
                        {modifyFields.dataType
                          ? modifyFields.dataType
                          : "Select Data type"}
                      </button>
                      <div className="dropdown-menu">
                        {modifyFields.dataTypes
                          ? modifyFields.dataTypes.map(dataType => {
                            return (
                              <DataType
                                handleDataType={handleDataType}
                                key={dataType.id}
                                id={dataType.id}
                                dataType={dataType.dataType}
                              />
                            );
                          })
                          : "No Data Type found"}
                      </div>
                      {validator.message(
                        "Column Name",
                        modifyFields.dataType,
                        "required"
                      )}
                    </div>
                  </div>

                  <div className="form-group custom labelup">
                    <label>Display Type</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown">
                        {modifyFields.displayType
                          ? modifyFields.displayType
                          : "Select Display type"}
                      </button>
                      <div className="dropdown-menu">
                        {modifyFields.displayTypes
                          ? modifyFields.displayTypes.map(displayType => {
                            return (
                              <DisplayTypes
                                handleDisplayType={handleDisplayType}
                                key={displayType.id}
                                id={displayType.id}
                                displayType={displayType.displayType}
                              />
                            );
                          })
                          : "No Display Type found"}
                      </div>
                    </div>
                  </div>
                  {modifyFields.dataType &&
                    modifyFields.dataType !== "connected_list" ? (
                      <div className="form-group custom">
                        <input
                          className="form-control"
                          type="text"
                          name="maxLength"
                          placeholder="&nbsp;"
                          onChange={formNameHandler}
                          value={modifyFields.maxLength}
                        />
                        {validator.message(
                          "Max length Name",
                          modifyFields.maxLength,
                          "required|numeric"
                        )}
                        <label>MaxLength</label>
                      </div>
                    ) : null}

                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={onisMandatory}
                        checked={modifyFields.isMandatory ? "checked" : null}
                        name="isMandatory"
                      />
                      <span className="slider round" />
                    </label>
                    <span>Mandatory</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={oncustomField}
                        checked={modifyFields.customField ? "checked" : null}
                        name="customField"
                      />
                      <span className="slider round" />
                    </label>
                    <span>Custom Field</span>
                  </div>

                  <div className="add-border">
                    <h6 className="caption-text">Personal information input?</h6>
                    <div className="form-group">
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={onisPiiEncrypt}
                          checked={modifyFields.isPiiEncrpt ? "checked" : null}
                          name="isPiiEncrpt"
                        />
                        <span className="slider round" />
                      </label>
                      <span>Yes It's PII</span>
                    </div>
                    <div className="form-group">
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={onisPiiNotEncrypt}
                          checked={
                            modifyFields.isPiiNotEncrypt ? "checked" : null
                          }
                          name="isPiiNotEncrypt"
                        />
                        <span className="slider round" />
                      </label>
                      <span>
                        Yes It's PII, but not sensitive, Store It without
                        encryption.
                    </span>
                    </div>
                    <div className="form-group">
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={onisNotPii}
                          checked={modifyFields.isNotPii ? "checked" : null}
                          name="isNotPii"
                        />
                        <span className="slider round" />
                      </label>
                      <span>No it's not PII</span>
                    </div>
                  </div>
                  <div className="btn-group-margin">
                    <button className="btn btn-border-success lg-padding">
                      Save
                  </button>
                    <button
                      type="button"
                      className="btn btn-border-basic lg-padding"
                      onClick={redirect}>
                      Cancel
                  </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModifyFormsFieldsPage2;
