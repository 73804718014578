import React from 'react';

const SortHead = (props) => (
    <th onClick={e => props.sortBy(props.feild)}>
        <div className="table-h">
            {props.name } 
            {(props.column !== props.feild) ?
                <span className="table-sort">
                    <i className="icon ion-md-arrow-dropdown"></i>
                    <i className="icon ion-md-arrow-dropup"></i>
                </span> : (props.column === props.feild && !props.isDesc) ? <span className="table-sort"><i className="icon ion-md-arrow-dropdown"></i></span> : <span className="table-sort"><i className="icon ion-md-arrow-dropup"></i></span>
            }
        </div>
    </th>
);

export default SortHead;  