import React from "react";
import PropTypes from "prop-types";

const ReminderType = props => {
  switch (props.serviceModuleId) {
    case "5c74ed2177addf3b07acb011":
      return (
        <div className="form-group custom labelup">
          <label>Reminder Type</label>
          <div className="dropdown">
            <button
              type="button"
              className={"btn dropdown-toggle"}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.displayType
                ? props.displayType
                : props.type
                ? props.type
                : "Reminder Type"}
            </button>
            <div className="help-block">
              {props.validator.message("Reminder Type", props.type, "required")}
            </div>
            <div className="dropdown-menu">
              <button
                type="button"
                className="dropdown-item"
                onClick={e =>
                  props.onSelectCurrencyHandler(
                    "Travel Awaiting approval for greater than X hrs/days",
                    "TRIP_PENDING_APPROVAL"
                  )
                }
              >
                Travel Awaiting approval for greater than X hrs/days
              </button>
              {/* <button
                type="button"
                className="dropdown-item"
                onClick={e =>
                  props.onSelectCurrencyHandler(
                    "Travel Pending for greater than X hrs/days",
                    "TRIP_PENDING_EXPENSE"
                  )
                }
              >
                Travel Pending for greater than X hrs/days
              </button> */}

            </div>
          </div>
        </div>
      );
    case "5c74ed2977addf3b07acb012":
      return (
        <div className="form-group custom labelup">
          <label>Reminder Type</label>
          <div className="dropdown">
            <button
              type="button"
              className={"btn dropdown-toggle"}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.displayType
                ? props.displayType
                : props.type
                ? props.type
                : "Reminder Type"}
            </button>
            <div className="help-block">
              {props.validator.message("Reminder Type", props.type, "required")}
            </div>
            <div className="dropdown-menu">
              <button
                type="button"
                className="dropdown-item"
                onClick={e =>
                  props.onSelectCurrencyHandler(
                    "Unsubmitted Expense greater than X hrs/days",
                    "EXPENSE_PENDING_SUBMISSION"
                  )
                }
              >
                Unsubmitted Expense greater than X hrs/days
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={e =>
                  props.onSelectCurrencyHandler(
                    "Expense awaiting approval for greater than X hrs/days",
                    "EXPENSE_PENDING_APPROVAL"
                  )
                }
              >
                Expense awaiting approval for greater than X hrs/days
              </button>
            </div>
          </div>
        </div>
      );
    case "5c74ed3677addf3b07acb013":
      return (
        <div className="form-group custom labelup">
          <label>Reminder Type</label>
          <div className="dropdown">
            <button
              type="button"
              className={"btn dropdown-toggle"}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.displayType
                ? props.displayType
                : props.type
                ? props.type
                : "Reminder Type"}
            </button>
            <div className="help-block">
              {props.validator.message("Reminder Type", props.type, "required")}
            </div>
            <div className="dropdown-menu">
              <button
                type="button"
                className="dropdown-item"
                onClick={e =>
                  props.onSelectCurrencyHandler("Cash advance awaiting approval for greater than X hrs/days", "CASH_ADVANCE_PENDING_APPROVAL")
                }
              >
                Cash advance awaiting approval for greater than X hrs/days
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={e =>
                  props.onSelectCurrencyHandler("Cash advance where Unassigned Amount is greater than zero for X hrs/days", "CASH_ADVANCE_UNASSIGNED")
                }
              >
                cash advance where Unassigned Amount (Balance) is greater than zero for greater than X hrs/days
              </button>
            </div>
          </div>
        </div>
      );
    case "5c74ed3d77addf3b07acb014":
      return (
        <div className="form-group custom labelup">
          <label>Reminder Type</label>
          <div className="dropdown">
            <button
              type="button"
              className={"btn dropdown-toggle"}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.displayType
                ? props.displayType
                : props.type
                ? props.type
                : "Reminder Type"}
            </button>
            <div className="help-block">
              {props.validator.message("Reminder Type", props.type, "required")}
            </div>
            <div className="dropdown-menu">
              <button
                type="button"
                className="dropdown-item"
                onClick={e =>
                  props.onSelectCurrencyHandler(
                    "Passport Expiry Earlier than X Days",
                    "PASSPORT_EXPIRY"
                  )
                }
              >
                Passport Expiry Earlier than X Days
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={e =>
                  props.onSelectCurrencyHandler("Visa Expiry Earlier than X Days", "VISA_EXPIRY")
                }
              >
                Visa Expiry Earlier than X Days
              </button>
            </div>
          </div>
        </div>
      );
    default:
  }
};

ReminderType.propTypes = {
  onSelectCurrencyHandler: PropTypes.func,
  type: PropTypes.string,
  displayType: PropTypes.string,
  serviceModuleId: PropTypes.string
};
export default ReminderType;
