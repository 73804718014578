import React, { Component } from 'react';
class TermsPrivacyPage extends Component {
  render() {
    return (
      <div className="TermsPrivacyPage">
        <div className="page-header">
          <h6 className="heading">Terms And Privacy</h6>
        </div>
        <div className="page-content">
          <div className="p-padding">
          Terms and Privacy Page works
          </div>
        </div>
      </div>
    );
  }
}

export default TermsPrivacyPage;
