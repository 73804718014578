import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import EmailReminderPage from "./travel-html";
import { companyEmailReminderServices } from "../../../../_services";

class EmailReminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailReminderData: [],
      serviceModuleData: [],
      serviceModuleId: "",
      isLoading: false,
      search: "",
      itemsPerPage: 10,
      currentPage: 1,
      countTotalItems: ""
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getServiceModule();
  }

  // get all services
  getServiceModule = async () => {
    const res = await companyEmailReminderServices.getServiceModuleId();
    this.setState({
      serviceModuleData: res ? res.result : [],
      serviceModuleId: res.result.find(o => o.serviceModuleName === "Travel").id
    });
    localStorage.setItem("serviceModuleId", this.state.serviceModuleId);
    this.getAllEmailReminder(
      this.state.serviceModuleId,
      this.state.itemsPerPage,
      this.state.currentPage
    );
  };

  // getting all email reminder
  getAllEmailReminder = async (id, itemsPerPage, currentPage) => {
    this.setState({ isLoading: true });
    const res = await companyEmailReminderServices.getAllEmailReminders(
      id ? id : this.state.serviceModuleId,
      itemsPerPage,
      currentPage
    );
    this.setState({
      emailReminderData: res ? res.result : [],
      isLoading: false,
      countTotalItems: res ? res.count : ""
    });
  };

  // set the service module id
  getServiceModuleId = async id => {
    await this.setState({
      serviceModuleId: id,
    });
    localStorage.setItem("serviceModuleId", this.state.serviceModuleId);
    this.getAllEmailReminder(
      id,
      this.state.itemsPerPage,
      this.state.currentPage
    );
  };

  // search input handler
  onInputHandler = e => {
    this.setState({
      search: e.target.value
    });
    this.getFilteredTemplate();
  };

  getFilteredTemplate = () => {
    let timeOut;
    clearTimeout(timeOut);
    timeOut = setTimeout(async () => {
      this.setState({ isLoading: true });
      const response = await companyEmailReminderServices.getFilterEmailTemplates(
        this.state.serviceModuleId,
        this.state.itemsPerPage,
        this.state.currentPage,
        this.state.search
      );
      this.setState({
        emailReminderData: response ? response.result : [],
        isLoading: false,
        countTotalItems: response ? response.count : ""
      });
    }, 250);
  };
  // Pagination items per page limit
  OnPageLimitHandler = async itemsPerPage => {
    if (itemsPerPage !== this.state.itemsPerPage) {
      itemsPerPage = itemsPerPage ? itemsPerPage : 10;
      if (!this.state.search) {
        await this.setState({
          itemsPerPage: itemsPerPage
        });
        this.getAllEmailReminder(
          this.state.serviceModuleId,
          itemsPerPage,
          this.state.currentPage
        );
      } else {
        await this.setState(
          {
            itemsPerPage: itemsPerPage
          },
          async () => {
            this.getFilteredTemplate();
          }
        );
      }
    }
  };

  // pagination current page handler
  OnPageChangeHandler = async pageNumber => {
    if (!this.state.search) {
      pageNumber = pageNumber ? pageNumber : 1;
      this.setState({
        currentPage: pageNumber
      });
      this.getAllEmailReminder(
        this.state.serviceModuleId,
        this.state.itemsPerPage,
        pageNumber
      );
    } else {
      pageNumber = pageNumber ? pageNumber : 1;
      this.setState(
        {
          currentPage: pageNumber
        },
        async () => {
          this.getFilteredTemplate();
        }
      );
    }
  };

  // email active or emnactive status handler
  OnStatusHandler = async status => {
    const payload = {
      isSet: !status.isSet
    };
    let res = await companyEmailReminderServices.activeInactiveStatus(
      status.id,
      payload
    );
    if (res) {
      toast.success(res.message);
      let emailReminderData = [...this.state.emailReminderData];
      emailReminderData[
        emailReminderData.findIndex(item => item.id === status.id)
      ].isSet = !status.isSet;
      this.setState({ emailReminderData });
    }
  };

  render() {
    let navBar = (
      <div className="admin-tabs">
        <ul className="nav nav-tabs px-0">
          {this.state.serviceModuleData &&
            this.state.serviceModuleData.map((value, key) => (
              <li key={value.id}>
                <NavLink
                  className={
                    this.state.serviceModuleId === value.id
                      ? "show active"
                      : "show"
                  }
                  onClick={() => this.getServiceModuleId(value.id)}
                  to={`#`}
                >
                  {value.serviceModuleName}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    );
    return (
      <div>
        <EmailReminderPage
          {...this.state}
          navBar={navBar}
          onInputHandler={this.onInputHandler}
          OnPageLimitHandler={this.OnPageLimitHandler}
          OnPageChangeHandler={this.OnPageChangeHandler}
          OnStatusHandler={this.OnStatusHandler}
        />
      </div>
    );
  }
}
export default EmailReminder;
