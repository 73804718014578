import { observable, action, reaction,decorate } from 'mobx'; 

class CommonStore {

  appName = 'Curio';
  token = JSON.parse(localStorage.getItem('token'));
  appLoaded = false;

  errors = undefined;
  constructor() {
    reaction(
      () => this.token,
      token => {
        if (token) {
          localStorage.setItem('token', token);
        } else {
          localStorage.removeItem('token');
        }
      }
    );
  }

  setToken(token) {
    this.token = token;
  }

  setAppLoaded() {
    this.appLoaded = true;
  }

}
decorate(CommonStore, {
  appName: observable,
  token: observable,
  appLoaded: observable,
  errors:observable,
  setToken:action,
  setAppLoaded:action
})

export default new CommonStore();
