import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SortHead from '../_common/table/sort';
import { masterService } from '../../_services';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import { Select} from '../_common/_forms';




class TravelRulePage extends Component {
    componentDidMount() {  
        this.getListCategory()
    }  
    state = {
        listAll:[],
        selectedCategoryId:null,
        listOfRules:[],
        page:1,
        limit:10,
        count:0
    }
    pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
    getListCategory() {
        this.setState({ listAll: [],count:0 }) 
        this.loader = true;
        masterService.getTravelRuleCategoryList()
            .then(
                data => {
                    if (data != null) {    
                        this.setState({ listAll: data.result})
                        this.setState({
                            selectedCategoryId:this.state.listAll[0] && this.state.listAll[0].id
                        },() =>{
                            this.getList()
                        })
                    }
                }
            )

    }
    handlePageChange = (pageNumber) => {
        this.setState({
            page:pageNumber, 
        })
        this.getList()
    }
    handleItemChange = (limit) => { 
        this.setState({
            page:1,
            limit:limit['items']
        })
        this.getList();
    }
    chooseCategoryId(id){
        this.setState({
            selectedCategoryId:id
        },() =>{
            this.getList()
        })
    } 
    getList() {
        this.setState({
            listOfRules:[]
        })
        masterService.getListContitions(this.state.selectedCategoryId,this.state.page,this.state.limit)
            .then(
                data => { 
                    if (data !== null && data !== "") {
                        this.setState({
                            listOfRules:data.result,
                            count:data.count
                        }) 
                    }   
                } 
            )
    }
    sortBy = (e) => {
        let arrayCopy = this.state.listOfRules;
        arrayCopy.sort(this.compareBy(e));
        this.state.listOfRules = arrayCopy;
        this.forceUpdate()
    }
    column = ''
    isDesc = ''
    compareBy(property) {
        this.isDesc = !this.isDesc; //change the direction   
        this.column = property;
        let direction = this.isDesc ? 1 : -1;
        return function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        };
    }
    render() {
        return (
            <div>
                <div className="page-header">
                    <h6 className="heading">Travel Rule Builder</h6>
                </div>
                
                <div className="page-content">
                <div className="p-padding pb-0">
                                <div className="admin-tabs">
                                        <ul className="nav nav-tabs px-0">
                                        {this.state.listAll && this.state.listAll.map((item,key) => {
                                            return(
                                             <li key={key}>
                                                <a onClick={e => this.chooseCategoryId(item.id)} className={(this.state.selectedCategoryId === item.id)?"show active":"show"}>
                                                    {item.travelCategoryName}
                                                </a>
                                             </li> 
                                            )
                                        })}
                                        </ul>
                                </div>
                            </div>
                    <div className="py-padding">
                    
                        <div className="table-container">
                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="btn-group-margin">
                                            <NavLink to="/database-settings/create-template" className="btn ">New</NavLink>
                                            {/* <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    Action
                                            </button>
                                                <div className="dropdown-menu">
                                                    <button className="dropdown-item">Make Inactive</button>
                                                    <button className="dropdown-item">Delete</button>
                                                    <button className="dropdown-item">Edit</button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        {/* <div className="btn-group-margin">
                                            <button className="btn btn-upload"><i
                                                className="icon ion-md-cloud-upload"></i></button>
                                            <button className="btn btn-upload"><i
                                                className="icon ion-md-cloud-download"></i></button>
                                        </div> */}
                                    </div>
                                    <div className="col-md-2">
                                        {/* <div className="search-custom">
                                            <input type="text" name="" className="form-control" placeholder="Search" />
                                            <span><i className="material-icons align-middle">search</i></span>
                                        </div> */}
                                    </div>
                                </div>
                            </form> 
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <SortHead
                                                name="Template Category"
                                                feild="templateCategory"
                                                column={this.column}
                                                isDesc={this.isDesc}
                                                sortBy={this.sortBy}
                                            />
                                            <SortHead
                                                name="Template"
                                                feild="conditionName"
                                                column={this.column}
                                                isDesc={this.isDesc}
                                                sortBy={this.sortBy}
                                            />
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {this.listOfRules.length} */}
                                    {this.state.listOfRules && this.state.listOfRules.length === 0 &&
                                        <tr>
                                            <td colSpan="3">No records found!</td>
                                        </tr>
                                    }
                                        {this.state.listOfRules && this.state.listOfRules.map((item, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        {this.state.listAll.map((list,index) =>{
                                                            if(list.id === item.travelCategoryId){
                                                                return(  
                                                                    <span key={index}>{list.travelCategoryName}</span>
                                                                )
                                                            }
                                                        })}
                                                    </td>
                                                    <td>{item.travelCategoryRule}</td>
                                                    <td>
                                                        <NavLink to={"/database-settings/update-template/" + item.id}>
                                                            <button className="btn btn-nothing hover-v"> <i className="material-icons fs-20">edit</i>
                                                            <span className="hover-name right-top">Edit</span></button>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-footer">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="page-count">
                                            <div className="text">Items</div>
                                            {this.pageItemsList &&
                                                <Select
                                                    placeholder={this.state.limit}
                                                    keyName={'items'}
                                                    options={this.pageItemsList}
                                                    controlFunc={e => this.handleItemChange(e)}
                                                />}
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                    <Pagination
                                        activePage={this.state.page}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.count}
                                        pageRangeDisplayed={3}
                                        onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TravelRulePage;