import React from 'react';

const Childrens = props => {
    const {
        handleChild,
        child
    } = props;
    return(
        <button type="button" onClick={e =>handleChild(child)} className="dropdown-item">
            {child}
        </button>
    )
}

export default Childrens;