import React, { Component } from "react";
import CustomReportPageHtml from "./custom-report-html";
//import Pagination from "react-js-pagination";
//import { NavLink } from "react-router-dom";
//import { Select } from "../_common/_forms";
import { reportService } from "../../_services";
import uuid from "uuid";
import moment from "moment";
//import DefaultField from "../_common/DefaultField";
import _ from "lodash";
import { toast } from "react-toastify";
//import { alertConstants } from "../../_constants/alert.constants";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
class CustomReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: "5d209b2369ce5f7e50ab3587",
      serviceModule: ["5c74ed3d77addf3b07acb014"],
      reportType: this.props.match.params.expenseType,
      listOfForms: [],
      employeeFormList: [],
      //expense
      reportHeaderList: [],
      reportEntryList: [],
      reportItemizationList: [],
      reportAllocationList: [],
      reportAttendeeList: [],
      //travel
      requestHeaderList: [],
      requestAllocationList: [],
      requestCashAdvanceList: [],
      requestEntryList: [],
      //cash advance
      cashAdvanceList: [],
      //end
      dataForFilter: [],
      UiFilterData: [],
      selectedCheckBoxArray: [],
      reportName: "",
      reportDescription: "",
      selectedDropDown: {},
      inputValue: "",
      startDate: "",
      endDate: "",
      postDataList: [],
      // * array to track remaining
      remainingDataForFilter: [],
      selectedDataForFilter: []
    };
    this.state.serviceModule.push(this.props.match.params.serviceModule);
  }
  validator = new SimpleReactValidator();
  componentDidMount() {
    this.getFormHeaders();
    this.getFormHeadersValue();
    this.employeeForm();
  }
  cancelAll = () => {
    this.setState({
      reportName: "",
      reportDescription: "",
      selectedDropDown: {},
      inputValue: "",
      startDate: "",
      endDate: ""
    });
  };
  storeResp = [];
  getFormHeaders = async () => {
    const { serviceModule, listOfForms } = this.state;
    await serviceModule.forEach(async list => {
      let result = await reportService.getFormHeader(list);
      if (result) {
        if (this.storeResp.length === 0) {
          this.storeResp = result;
        } else {
          this.storeResp.concat(result);
        }
      }
    });
    console.log(this.storeResp);
  };

  getFormHeadersValue = async () => {
    if (this.state.reportType === "EXPENSE") {
      let reportHeader = await reportService.getFormHeadersValue({
        formHeaderKey: ["report-header"],
        companyId: this.state.companyId
      });
      if (reportHeader) {
        this.setState({
          reportHeaderList: reportHeader.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
      let reportEntry = await reportService.getFormHeadersValue({
        formHeaderKey: ["report-entry"],
        companyId: this.state.companyId
      });
      if (reportEntry) {
        this.setState({
          reportEntryList: reportEntry.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
      let reportItemization = await reportService.getFormHeadersValue({
        formHeaderKey: ["report-itemization"],
        companyId: this.state.companyId
      });
      if (reportItemization) {
        this.setState({
          reportItemizationList: reportItemization.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
      let reportAllocation = await reportService.getFormHeadersValue({
        formHeaderKey: ["report-allocation"],
        companyId: this.state.companyId
      });
      if (reportAllocation) {
        this.setState({
          reportAllocationList: reportAllocation.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
      let reportAttendee = await reportService.getFormHeadersValue({
        formHeaderKey: ["report-attendee"],
        companyId: this.state.companyId
      });
      if (reportAttendee) {
        this.setState({
          reportAttendeeList: reportAttendee.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
    } else if (this.state.reportType === "TRAVEL") {
      let requestHeader = await reportService.getFormHeadersValue({
        formHeaderKey: ["request-header"],
        companyId: this.state.companyId
      });
      if (requestHeader) {
        this.setState({
          requestHeaderList: requestHeader.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
      let requestAllocation = await reportService.getFormHeadersValue({
        formHeaderKey: ["request-allocation"],
        companyId: this.state.companyId
      });
      if (requestAllocation) {
        this.setState({
          requestAllocationList: requestAllocation.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
      let requestcashAdvance = await reportService.getFormHeadersValue({
        formHeaderKey: ["request-cashAdvance"],
        companyId: this.state.companyId
      });
      if (requestcashAdvance) {
        this.setState({
          requestCashAdvanceList: requestcashAdvance.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
      let requestEntry = await reportService.getFormHeadersValue({
        formHeaderKey: ["request-entry"],
        companyId: this.state.companyId
      });
      if (requestEntry) {
        this.setState({
          requestEntryList: requestEntry.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
    }
    if (this.state.reportType === "CASH_ADVANCE") {
      let cashAdvance = await reportService.getFormHeadersValue({
        formHeaderKey: ["cash-advance"],
        companyId: this.state.companyId
      });
      if (cashAdvance) {
        this.setState({
          cashAdvanceList: cashAdvance.result.filter(
            item =>
              item.dataType !== "connected_list" &&
              item.dataType !== "different_list"
          )
        });
      }
    }
  };

  employeeForm = async () => {
    let EMPForm = await reportService.getFormHeadersValue({
      formHeaderKey: ["employee-form"],
      companyId: this.state.companyId
    });

    if (EMPForm) {
      this.setState({
        employeeFormList: EMPForm.result.filter(
          item =>
            item.dataType !== "connected_list" &&
            item.dataType !== "different_list"
        )
      });
    }
  };

  checkboxHandler = (e, headerKey, dataType) => {
    let postDataList = [...this.state.postDataList];
    let selectedCheckBoxArray = [...this.state.selectedCheckBoxArray];
    let columnName = e.target.value;
    if (e.currentTarget.checked) {
      postDataList.push({
        [columnName + headerKey]: {
          columnName: columnName,
          headerKey: headerKey,
          dataType: dataType
        }
      });
      selectedCheckBoxArray.push(columnName + headerKey);
      this.setState({
        postDataList: postDataList,
        selectedCheckBoxArray: selectedCheckBoxArray
      });
    } else {
      let myArr = postDataList.filter(item => !item[columnName + headerKey]);
      let myDeselect = selectedCheckBoxArray.filter(
        item => item !== columnName + headerKey
      );
      this.setState({
        postDataList: myArr,
        selectedCheckBoxArray: myDeselect
      });
    }
  };

  // * When user clicks on continue this function gets executed
  filterData = e => {
    e.preventDefault();
    let dataForFilter = [];
    let postDataList = [...this.state.postDataList];
    postDataList.forEach(item => {
      let abc = Object.values(item);
      dataForFilter.push(abc[0]);
    });
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({
      dataForFilter: dataForFilter,
      remainingDataForFilter: dataForFilter,
      startDate: firstDay,
      endDate: lastDay
    });
  };

  // * When user clicks on Option, this function gets executed
  selectFilterHandler = (e, id) => {
    // let remainingDataForFilter = [...this.state.remainingDataForFilter];
    // remainingDataForFilter = this.state.remainingDataForFilter.filter(item => item.columnName !== e.columnName);
    let selectedDataForFilter = [...this.state.selectedDataForFilter];
    let itemPresentInUiFilteredData = this.state.UiFilterData.find(
      item => item.id === id
    );
    if (itemPresentInUiFilteredData != null) {
      selectedDataForFilter = selectedDataForFilter.filter(
        item => item.columnName !== itemPresentInUiFilteredData.columnName
      );
    }
    selectedDataForFilter.push(e);

    let remainingDataForFilter = _.differenceWith(
      this.state.dataForFilter,
      selectedDataForFilter,
      _.isEqual
    );

    let UiFilterData = [...this.state.UiFilterData];
    let matchedItem = UiFilterData.find(item => item.id === id);
    matchedItem.columnName = e.columnName;
    matchedItem.headerKey = e.headerKey;
    matchedItem.dataType = e.dataType;
    matchedItem.match.eq = "";
    this.setState({
      UiFilterData,
      remainingDataForFilter,
      selectedDataForFilter
    });
  };

  getInputValue = (e, id) => {
    let UiFilterData = [...this.state.UiFilterData];
    let matchedItem = UiFilterData.find(item => item.id === id);
    matchedItem.match.eq = [e.target.value];
    this.setState({ UiFilterData });
  };

  startDateHandler = e => {
    this.setState({
      startDate: e
    });
  };

  endDateHandler = e => {
    this.setState({
      endDate: e
    });
  };

  addFilterUI = e => {
    e.preventDefault();
    let UiFilterData = [...this.state.UiFilterData];
    let selectedDropDown = { ...this.state.selectedDropDown };
    let inputValue = this.state.inputValue;
    let filter = {
      id: uuid.v4(),
      columnName: selectedDropDown.columnName,
      headerKey: selectedDropDown.headerKey,
      match: {
        eq: [inputValue]
      }
    };
    UiFilterData.push(filter);
    this.setState({
      UiFilterData: UiFilterData,
      selectedDropDown: {},
      inputValue: ""
    });
  };

  removeFilter = (e, item) => {
    e.preventDefault();
    let UiFilterData = [...this.state.UiFilterData];
    let selectedDataForFilter = [...this.state.selectedDataForFilter];
    selectedDataForFilter = selectedDataForFilter.filter(
      ele => ele.columnName !== item.columnName
    );
    let remainingDataForFilter = _.differenceWith(
      this.state.dataForFilter,
      selectedDataForFilter,
      _.isEqual
    );
    let matchedItem = UiFilterData.find(ele => ele.id === item.id);

    let matchedIndex = UiFilterData.indexOf(matchedItem);
    UiFilterData.splice(matchedIndex, 1);
    this.setState({
      UiFilterData,
      selectedDataForFilter,
      remainingDataForFilter
    });
  };
  reportNameSet = e => {
    this.setState({
      reportName: e.currentTarget.value
    });
  };

  reportDescriptionSet = e => {
    this.setState({
      reportDescription: e.currentTarget.value
    });
  };

  defaultValueChangeHandler = (e, id, dataType) => {
    let UiFilterData = [...this.state.UiFilterData];

    if (
      dataType === "text" ||
      dataType === "integer" ||
      dataType === "amount"
    ) {
      let matchedItem = UiFilterData.find(item => item.id === id);
      matchedItem.match.eq = [e.target.value];
      this.setState({ UiFilterData });
    } else if (dataType === "date") {
      let matchedItem = UiFilterData.find(item => item.id === id);
      matchedItem.match.eq = [moment(e).format("DD MMM YYYY")];
      this.setState({ UiFilterData });
    }
    if (dataType === "boolean") {
      e.target.value = e.target.checked;
      let matchedItem = UiFilterData.find(item => item.id === id);
      matchedItem.match.eq = [e.target.checked];
      this.setState({ UiFilterData });
    }
    if (dataType === "search") {
      let matchedItem = UiFilterData.find(item => item.id === id);
      matchedItem.match.eq = [e.target.value];
      this.setState({ UiFilterData });
    }
  };

  defaultSelectHandler = (e, id) => {
    let UiFilterData = [...this.state.UiFilterData];
    let matchedItem = UiFilterData.find(item => item.id === id);
    matchedItem.match.eq = [e.target.value];
    this.setState({ UiFilterData });
  };

  checkAllHandler = e => {
    if (e.target.checked) {
      this.selectAllCheckBoxes();
    } else {
      this.setState({
        postDataList: [],
        selectedCheckBoxArray: []
      });
    }
  };

  selectAllCheckBoxes = () => {
    let postDataList = [];

    let reportHeaderList = [...this.state.reportHeaderList];
    let reportEntryList = [...this.state.reportEntryList];
    let reportItemizationList = [...this.state.reportItemizationList];
    let reportAllocationList = [...this.state.reportAllocationList];
    let reportAttendeeList = [...this.state.reportAttendeeList];

    let requestHeaderList = [...this.state.requestHeaderList];
    let requestAllocationList = [...this.state.requestAllocationList];
    let requestCashAdvanceList = [...this.state.requestCashAdvanceList];
    let requestEntryList = [...this.state.requestEntryList];
    //cash advance
    let cashAdvanceList = [...this.state.cashAdvanceList];

    let employeeFormList = [...this.state.employeeFormList];
    let selectedCheckBoxArray = [...this.state.selectedCheckBoxArray];

    //travel
    requestHeaderList.map(item => {
      return postDataList.push({
        [item.columnName + "request-header"]: {
          columnName: item.columnName,
          headerKey: "request-header",
          dataType: item.dataType
        }
      });
    });
    requestHeaderList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "request-header");
    });

    requestAllocationList.map(item => {
      return postDataList.push({
        [item.columnName + "request-allocation"]: {
          columnName: item.columnName,
          headerKey: "request-allocation",
          dataType: item.dataType
        }
      });
    });
    requestAllocationList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "request-allocation");
    });

    requestCashAdvanceList.map(item => {
      return postDataList.push({
        [item.columnName + "request-cashAdvance"]: {
          columnName: item.columnName,
          headerKey: "request-cashAdvance",
          dataType: item.dataType
        }
      });
    });
    requestCashAdvanceList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "request-cashAdvance");
    });

    requestEntryList.map(item => {
      return postDataList.push({
        [item.columnName + "request-entry"]: {
          columnName: item.columnName,
          headerKey: "request-entry",
          dataType: item.dataType
        }
      });
    });
    requestEntryList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "request-entry");
    });

    //cash advance

    cashAdvanceList.map(item => {
      return postDataList.push({
        [item.columnName + "cash-advance"]: {
          columnName: item.columnName,
          headerKey: "cash-advance",
          dataType: item.dataType
        }
      });
    });
    cashAdvanceList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "cash-advance");
    });

    //expense
    reportHeaderList.map(item => {
      return postDataList.push({
        [item.columnName + "report-header"]: {
          columnName: item.columnName,
          headerKey: "report-header",
          dataType: item.dataType
        }
      });
    });
    reportHeaderList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "report-header");
    });
    reportEntryList.map(item => {
      return postDataList.push({
        [item.columnName + "report-entry"]: {
          columnName: item.columnName,
          headerKey: "report-entry",
          dataType: item.dataType
        }
      });
    });

    reportEntryList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "report-entry");
    });
    reportItemizationList.map(item => {
      return postDataList.push({
        [item.columnName + "report-itemization"]: {
          columnName: item.columnName,
          headerKey: "report-itemization",
          dataType: item.dataType
        }
      });
    });

    reportItemizationList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "report-itemization");
    });

    reportAllocationList.map(item => {
      return postDataList.push({
        [item.columnName + "report-allocation"]: {
          columnName: item.columnName,
          headerKey: "report-allocation",
          dataType: item.dataType
        }
      });
    });

    reportAllocationList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "report-allocation");
    });

    reportAttendeeList.map(item => {
      return postDataList.push({
        [item.columnName + "report-attendee"]: {
          columnName: item.columnName,
          headerKey: "report-attendee",
          dataType: item.dataType
        }
      });
    });
    reportAttendeeList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "report-attendee");
    });

    employeeFormList.map(item => {
      return postDataList.push({
        [item.columnName + "employee-form"]: {
          columnName: item.columnName,
          headerKey: "employee-form",
          dataType: item.dataType
        }
      });
    });

    employeeFormList.forEach(item => {
      selectedCheckBoxArray.push(item.columnName + "employee-form");
    });

    this.setState({ postDataList, selectedCheckBoxArray });
  };

  postData = async e => {
    e.preventDefault();
    let myArr = [];
    let UiFilterData = [...this.state.UiFilterData];
    let filterData = UiFilterData.map(item => {
      return {
        columnName: item.columnName,
        headerKey: item.headerKey,
        match: item.match
      };
    });
    let postDataList = [...this.state.postDataList];
    postDataList.forEach(item => {
      let abc = Object.values(item);
      myArr.push({
        columnName: abc[0].columnName,
        headerKey: abc[0].headerKey
      });
    });

    let payLoad = {};
    if (this.state.startDate === "" || this.state.endDate === "") {
      payLoad = {
        reportName: this.state.reportName,
        reportType: this.props.match.params.expenseType,
        export: myArr,
        filter: filterData,
        description: this.state.reportDescription
      };
    } else {
      payLoad = {
        reportName: this.state.reportName,
        reportType: this.props.match.params.expenseType,
        export: myArr,
        filter: filterData,
        description: this.state.reportDescription,
        fromDate: moment(this.state.startDate).format("YYYY-MM-DD"),
        toDate: moment(this.state.endDate).format("YYYY-MM-DD")
      };
    }

    if (this.validator.allValid()) {
      let resp = await reportService.getExpenseReport(payLoad);
      if (resp) {
        $(".close").click();
        toast.success("Report Created Successfully");
        this.props.history.push("/reporting/report");
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <>
        <CustomReportPageHtml
          state={this.state}
          checkboxHandler={this.checkboxHandler}
          cancelAll={this.cancelAll}
          postData={this.postData}
          filterData={this.filterData}
          selectFilterHandler={this.selectFilterHandler}
          getInputValue={this.getInputValue}
          addFilterUI={this.addFilterUI}
          removeFilter={this.removeFilter}
          startDateHandler={this.startDateHandler}
          endDateHandler={this.endDateHandler}
          handleCloseModalDefault={this.handleCloseModalDefault}
          reportNameSet={this.reportNameSet}
          reportDescriptionSet={this.reportDescriptionSet}
          defaultValueChangeHandler={this.defaultValueChangeHandler}
          defaultSelectHandler={this.defaultSelectHandler}
          validator={this.validator}
          checkAllHandler={this.checkAllHandler}
        />
      </>
    );
  }
}

export default CustomReportPage;
