import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import HomePage from './home-html';
import { midOfficeServices } from "../../_services"


class DashboardHomePage extends Component {
    state = {
        flight: {},
        car: {},
        hotel: {},
        bus: {},
        train: {},
        isLoading: true,
        completedTrips: {},
        canceledTrips: {}
    }

    componentDidMount() {
        this.getflight();
        // this.getCancelledTripsData();
        // this.getIssuedCreditsData();
    }

    getflight = async () => {
        let res = await midOfficeServices.getDetailflight();
        if (res) {
            this.setState({ isLoading: false })
            this.setState({
                flight: res.result.flight,
                hotel: res.result.hotel,
                car: res.result.car,
                bus: res.result.bus,
                train: res.result.train
            })
        }
    }
    // getCancelledTripsData = async () => {
    //     let res = await midOfficeServices.getCancelledTripsData();
    //     if (res) {
    //         this.setState({ canceledTrips: res.result.canceledTrips });
    //     }
    // }
    // getIssuedCreditsData = async () => {
    //     let res = await midOfficeServices.getIssuedCreditsData();
    //     if (res) {
    //         this.setState({ completedTrips: res.result.completedTrips });
    //     }
    // }

    render() {
        return (<div>
            <HomePage
                {...this.state}
                state={this.state}
            />
        </div>)
    }
}

export default DashboardHomePage;
