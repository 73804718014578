import React, { Component } from 'react';
import _ from 'lodash';
import Moment from "react-moment";
import NewWindow from 'react-new-window'

class UserDetailsPage extends Component {
    render() {
        const { userDetails, savedPassVisa } = this.props;
        const basicDetails = [];

        (userDetails && userDetails.userFields.forEach((user, i) => {
            if (user.columnName === 'GROUP_NAME' || user.columnName === 'ORG_UNIT_1' || user.columnName === 'ORG_UNIT_2' || user.columnName === 'ORG_UNIT_3') {
                basicDetails.push({ fieldName: user.fieldName, fieldValue: user.connectedFieldValue })
            }
            else if (user.columnName === 'EMPLOYEE_FIRST_NAME' || user.columnName === 'MIDDLE_NAME' || user.columnName === 'EMPLOYEE_LAST_NAME' || user.columnName === 'EMPLOYEE_ID' || user.columnName === 'EMP_ID' || user.columnName === 'ADDRESS' || user.columnName == 'GENDER' || user.columnName === 'CUSTOM_24') {
                basicDetails.push({ fieldName: user.fieldName, fieldValue: user.fieldValue })
            }
            else if (user.columnName === 'REIMBURSEMENT_CURRENCY' || user.columnName === 'CTRY_CODE') {
                basicDetails.push({ fieldName: user.fieldName, fieldValue: user.differentListValue })
            }
            else if (user.columnName === 'LEDGER_KEY') {
                basicDetails.push({ fieldName: user.fieldName, fieldValue: '--' })
            }
            else {
                basicDetails.push({ fieldName: user.fieldName, fieldValue: '--' })
            }
        }));
        return (
            this.props.popUp ?
                <NewWindow>
                    <div>
                        <div className={this.props.popUp != undefined && this.props.popUp ? "fixed-pop white-card fade-in" : "d-none"}
                            style={{ width: "100%", height: "100vh", overflowY: "scroll" }}
                        >
                            <div className="inner-div">
                                <h5 className="hotel_info_head text-white left mb-0">User Details
                                <span className="right px-2" onClick={(e) => this.props.closePopUp(e)}>
                                        &times;
                                </span>
                                </h5>
                                <div className="clearfix" />
                                <div class="accordion" id="accordionExample">
                                    <div class="card border-0">
                                        <div class="card-header border-0" id="userHeadingOne">
                                            <div className="cursor-pointer" data-toggle="collapse" data-target="#userDetailOne" aria-expanded="true" aria-controls="userDetailOne">
                                                <div className="float-left pt-2">
                                                    <h6 className="sub-heading mb-0">1. User Information</h6>
                                                </div>
                                                <div className="float-right">
                                                    <span>
                                                        <i className="material-icons">
                                                            keyboard_arrow_down</i>
                                                    </span>
                                                </div>
                                                <div className="clearfix" />
                                            </div>
                                        </div>
                                        <div id="userDetailOne" class="collapse show" aria-labelledby="userHeadingOne" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <ul>
                                                    {userDetails ? basicDetails.length > 0 && basicDetails.map((user, i) => {
                                                        return (
                                                            <li className="pl-2 d-inline-block mb-3 mr-3 w-30">
                                                                <p className="sub-heading mb-0">{user.fieldName}</p>
                                                                <p className="text-black">{user.fieldValue ? user.fieldValue : '--'}</p>
                                                            </li>
                                                        )
                                                    }) : 'No Data Found'}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div class="card-header border-0" id="userHeadingTwo">
                                            <div className="cursor-pointer" data-toggle="collapse" data-target="#userDetailTwo" aria-expanded="true" aria-controls="userDetailTwo">
                                                <div className="float-left pt-2">
                                                    <h6 className="sub-heading mb-0">2. Passport</h6>
                                                </div>
                                                <div className="float-right">
                                                    <span>
                                                        <i className="material-icons">
                                                            keyboard_arrow_down
                                                        </i>
                                                    </span>
                                                </div>
                                                <div className="clearfix" />
                                            </div>
                                        </div>
                                        <div id="userDetailTwo" class="collapse show" aria-labelledby="userHeadingTwo" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <table className="table mb-0" style={{ fontSize: "14px" }}>
                                                    <thead className="border-0">
                                                        <tr className="border-0">
                                                            <th className="sub-heading border-0">Passport Number</th>
                                                            <th className="sub-heading border-0">Country</th>
                                                            <th className="sub-heading border-0">Expiry</th>
                                                            <th className="sub-heading border-0">Download</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userDetails && userDetails.passport ? userDetails.passport.map((user, i) => {
                                                            return (
                                                                <tr className={savedPassVisa.passportNumber === user.passportNumber ? "bg-light border" : ''}>
                                                                    <td className="border-top-0">
                                                                        <p className="text-black">{user.passportNumber}</p>
                                                                    </td>
                                                                    <td className="border-top-0">
                                                                        <p className="text-black">{user.countryOfIssue}</p>
                                                                    </td>
                                                                    <td className="border-top-0">
                                                                        <p className="text-black"><Moment format="DD-MMM-YYYY">{user.expiryDate}</Moment></p>
                                                                    </td>
                                                                    <td className="border-top-0" >
                                                                        <button type="button" className="btn btn-nothing"
                                                                            title="Download photocopy of Passport">
                                                                            <a href={user.photoCopy[0]} download><i className="material-icons text-black">cloud_download</i>
                                                                            </a>
                                                                        </button>
                                                                    </td>
                                                                </tr>

                                                            )
                                                        })
                                                            :
                                                            <tr><td colSpan="4" className="text-center">No Passport details are added</td></tr>
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div class="card-header border-0" id="userHeadingThree">
                                            <div className="cursor-pointer" data-toggle="collapse" data-target="#userDetailThree" aria-expanded="true" aria-controls="userDetailThree">
                                                <div className="float-left pt-2">
                                                    <h6 className="sub-heading mb-0">3. Visa</h6>
                                                </div>
                                                <div className="float-right">
                                                    <span>
                                                        <i className="material-icons">
                                                            keyboard_arrow_down
                                                        </i>
                                                    </span>
                                                </div>
                                                <div className="clearfix" />
                                            </div>
                                        </div>
                                        <div id="userDetailThree" class="collapse show" aria-labelledby="userHeadingThree" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <table className="table mb-0" style={{ fontSize: "14px" }}>
                                                    <thead>
                                                        <tr className="border-0">
                                                            <th className="sub-heading border-0">Visa Number</th>
                                                            <th className="sub-heading border-0">Country</th>
                                                            <th className="sub-heading border-0">Expiry</th>
                                                            <th className="sub-heading border-0">Download</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userDetails && userDetails.visa ? userDetails.visa.map((user) => {
                                                            return (
                                                                <tr className={savedPassVisa.visaNumber === user.visaNumber ? "bg-light border " : ''}>
                                                                    <td className="border-top-0">
                                                                        <p className="text-black">{user.visaNumber}</p>
                                                                    </td>
                                                                    <td className="border-top-0">
                                                                        <p className="text-black">{user.countryOfIssue}</p>
                                                                    </td>
                                                                    <td className="border-top-0">
                                                                        <p className="text-black"><Moment format="DD-MMM-YYYY">{user.expiryDate}</Moment></p>
                                                                    </td>
                                                                    <td className="border-top-0">
                                                                        <button type="button" className="btn btn-nothing"
                                                                            title="Download photocopy of Visa">
                                                                            <a href={user.photoCopy[0]} download><i className="material-icons text-black">cloud_download</i>
                                                                            </a>
                                                                        </button>
                                                                    </td>
                                                                </tr>)
                                                        }) : <tr><td colSpan="4" className="text-center">No Visa details are added</td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div class="card-header border-0" id="userHeadingFour">
                                            <div className="cursor-pointer" data-toggle="collapse" data-target="#userDetailFour" aria-expanded="true" aria-controls="userDetailThree">
                                                <div className="float-left pt-2">
                                                    <h6 className="sub-heading mb-0">4. Frequent Flyer Number</h6>
                                                </div>
                                                <div className="float-right">
                                                    <span>
                                                        <i className="material-icons">
                                                            keyboard_arrow_down</i>
                                                    </span>
                                                </div>
                                                <div className="clearfix" />
                                            </div>
                                        </div>
                                        <div id="userDetailFour" class="collapse show" aria-labelledby="userHeadingFour" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <table className="table mb-0" style={{ fontSize: "14px" }}>
                                                    <thead className="border-0">
                                                        <tr className="border-0">
                                                            <th className="sub-heading border-0">Name</th>
                                                            <th className="sub-heading border-0">Type</th>
                                                            <th className="sub-heading border-0">Program</th>
                                                            <th className="sub-heading border-0">Frequent Flyer Number</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userDetails && userDetails.frequentFlyerNumbers ? userDetails.frequentFlyerNumbers.map((user, i) => {
                                                            return (
                                                                <tr >
                                                                    <td className="border-top-0">
                                                                        <p className="text-black">{user.travellerName}</p>
                                                                    </td>
                                                                    <td className="border-top-0">
                                                                        <p className="text-black">{user.brand.name}</p>
                                                                    </td>
                                                                    <td className="border-top-0">
                                                                        <p className="text-black">{user.programmeName}</p>
                                                                    </td>
                                                                    <td className="border-top-0">
                                                                        <p className="text-black">{user.frequentFlyerNumber}</p>
                                                                    </td>
                                                                </tr>)
                                                        }) : <tr><td colSpan="4" className="text-center">Frequent Flyers number is not available</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </NewWindow>
                : ''

        )
    }
}
export default UserDetailsPage;