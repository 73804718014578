import React, { Component } from "react";

class IntegrationPage extends Component {
    render() {
        return (
            <div>
                <div className="page-header">
                    <h6 className="heading">Integration</h6>
                </div>
                <div className="page-content">
                    <div className="p-padding">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="bg-white text-center py-3">
                                    <div className="company_img">
                                        <img src="/assets/images/sap-image.png" alt="" />
                                    </div>
                                    <p className="text-black font-weight-bold">Sap Integration</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="bg-white text-center py-3">
                                    <div className="company_img">
                                        <img src="/assets/images/sap-image.png" alt="" />
                                    </div>
                                    <p className="text-black font-weight-bold">Oracle Integration</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="bg-white text-center py-3">
                                    <div className="company_img">
                                        <img src="/assets/images/sap-image.png" alt="" />
                                    </div>
                                    <p className="text-black font-weight-bold">Dynamics Integration</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default IntegrationPage;