import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
class HomePage extends Component {
    render() {
        //added
        const { state, isLoading, completedTrips, canceledTrips } = this.props;
        return (
            <div className="UserPage">
                <div className="page-header">
                    <div className="resp-toggle-menu"><i className="material-icons">menu</i></div>
                    <div className="row">
                        <div className="col-md-5">
                            <h6 className="heading">Mid Office - Dashboard</h6>
                        </div>
                        <div className="col-md-7">
                            <ul className="right-items">
                                <li>
                                    <div className="logo-holder">
                                        <img height="32" src="https://expense-receipt-images.s3.ap-south-1.amazonaws.com/1555921288527-logo.png" alt="Client" />
                                    </div>
                                </li>
                                <li>
                                    <div className="help-image">
                                        <i className="material-icons">help_outline</i>
                                    </div>
                                    <span className="hover-name bottom">Help</span>
                                    <div className="help-msg d-none">
                                        <ul>
                                            <li>
                                                <NavLink to="#">
                                                    <i className="material-icons align-middle">help_outline</i>
                                                    Help
                                                </NavLink>
                                            </li>
                                            <li data-toggle="modal" data-target="#sendFeedbackModal">
                                                <NavLink to="#">
                                                    <i className="material-icons align-middle">
                                                        feedback
                                                    </i> Send Feedback
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className="user-image" onClick={this.handleclick}>
                                        <i className="material-icons">account_circle</i>
                                    </div>
                                    <span className="hover-name bottom more">Account</span>

                                    <div className="delegates-popup d-none">
                                        <div className="user-info">
                                            <div className="shape-name">
                                                <div className="left img" style={{ backgroundImage: "url(&quot;https://curio-user-document.s3.amazonaws.com/1554307677993-tvs-apache-rr-310-racing-red.png&quot;)" }} />
                                                <div className="right">
                                                    <h6 className="heading">
                                                        <span>Ravi </span>
                                                        <span>Gangadhar</span>
                                                    </h6>
                                                    <p className="text">support@curio.com</p>
                                                </div>
                                                <div className="clearfix" />
                                            </div>
                                        </div>
                                        <div className="btns">
                                            <button className="float-right btn">LOGOUT</button>
                                            <NavLink to="#" className="btn">PROFILE SETTINGS</NavLink>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="booking-bg ">
                        <div className="text-right btn-group-margin p-padding pb-0">
                            <div className="bg-white py-3 px-4">
                                <NavLink to="/mid-office/home" className="btn">Live</NavLink>
                                <NavLink to="/test/mid-office/home" className="btn">Test</NavLink>
                            </div>
                        </div>
                        <div className="p-padding">
                            <div className="bg-white">
                                <div className="table-responsive">
                                    {(this.props.isLoading) ?
                                        <div className="mx-auto text-center">
                                            <img
                                                width="200"
                                                className="img-fluid "
                                                alt="no flight"
                                                src="/assets/images/loader.gif"
                                            />
                                            <p>Loading please wait...</p>
                                        </div>
                                        :
                                        <table className="table table-bordered table-forbooking mb-0">
                                            <thead>
                                                <tr className="text-center booking-options">
                                                    <th className="border-bottom-0"></th>
                                                    <th className="text-uppercase" colSpan="2">Flight</th>
                                                    <th className="text-uppercase" colSpan="2">Hotel</th>
                                                    <th className="text-uppercase" colSpan="2">Car</th>
                                                    <th className="text-uppercase" colSpan="2">Bus</th>
                                                    <th className="text-uppercase" colSpan="2">Train</th>
                                                    <th className="text-uppercase" colSpan="2">Visa</th>
                                                </tr>
                                                <tr className="text-center booking-type">
                                                    <th className="border-top-0 border-bottom-0 text-uppercase text-left pl-4">Type</th>
                                                    <th className="text-uppercase">DOM</th>
                                                    <th className="text-uppercase">INTL</th>
                                                    <th className="text-uppercase">DOM</th>
                                                    <th className="text-uppercase">INTL</th>
                                                    <th className="text-uppercase">DOM</th>
                                                    <th className="text-uppercase">INTL</th>
                                                    <th className="text-uppercase">DOM</th>
                                                    <th className="text-uppercase">INTL</th>
                                                    <th className="text-uppercase">DOM</th>
                                                    <th className="text-uppercase">INTL</th>
                                                    <th className="text-uppercase last-text">processing</th>
                                                    <th className="text-uppercase last-text">processed</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-center">
                                                <tr className="quality--set-pad">
                                                    <td className="bg-white text-left"><span className="full-pad">Non-API Booking</span></td>
                                                    <td><NavLink to={`flight-booking-request/Non_Api_Booking/FLIGHT/false`} className="full-pad">
                                                        {state.flight.Non_Api_Booking ? state.flight.Non_Api_Booking.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`flight-booking-request/Non_Api_Booking/FLIGHT/true`} className="full-pad">
                                                        {state.flight.Non_Api_Booking ? state.flight.Non_Api_Booking.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Non_Api_Booking/HOTEL/false`} className="full-pad">
                                                        {state.hotel.Non_Api_Booking ? state.hotel.Non_Api_Booking.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Non_Api_Booking/HOTEL/true`} className="full-pad">
                                                        {state.hotel.Non_Api_Booking ? state.hotel.Non_Api_Booking.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`car-booking-request/Non_Api_Booking/CAR/false`} className="full-pad">{state.car.Non_Api_Booking ? state.car.Non_Api_Booking.DOM : ""}</NavLink>
                                                    </td>
                                                    <td><NavLink to={`car-booking-request/Non_Api_Booking/CAR/true`} className="full-pad">
                                                        {state.car.Non_Api_Booking ? state.car.Non_Api_Booking.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Non_Api_Booking/BUS/false`} className="full-pad">
                                                        {state.bus.Non_Api_Booking ? state.bus.Non_Api_Booking.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Non_Api_Booking/BUS/true`} className="full-pad">
                                                        {state.bus.Non_Api_Booking ? state.bus.Non_Api_Booking.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Non_Api_Booking/TRAIN/false`} className="full-pad">
                                                        {state.train.Non_Api_Booking ? state.train.Non_Api_Booking.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Non_Api_Booking/TRAIN/true`} className="full-pad">
                                                        {state.train.Non_Api_Booking ? state.train.Non_Api_Booking.INTL : ""}
                                                    </NavLink></td>
                                                    <td><span className="full-pad">0</span></td>
                                                    <td><span className="full-pad">0</span></td>
                                                </tr>

                                                {/* <tr className="quality--set-pad">
                                                    <td className="bg-white text-left"><span className="full-pad">Queue Processing</span></td>
                                                    <td><NavLink to={`flight-booking-request/Queue_Processing/FLIGHT/false`} className="full-pad">
                                                        {state.flight.Queue_Processing ? state.flight.Queue_Processing.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`flight-booking-request/Queue_Processing/FLIGHT/true`} className="full-pad">
                                                        {state.flight.Queue_Processing ? state.flight.Queue_Processing.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Queue_Processing/HOTEL/false`} className="full-pad">
                                                        {state.hotel.Queue_Processing ? state.hotel.Queue_Processing.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Queue_Processing/HOTEL/true`} className="full-pad">
                                                        {state.hotel.Queue_Processing ? state.hotel.Queue_Processing.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`car-booking-request/Queue_Processing/CAR/false`} className="full-pad">
                                                        {state.car.Queue_Processing ? state.car.Queue_Processing.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`car-booking-request/Queue_Processing/CAR/true`} className="full-pad">
                                                        {state.car.Queue_Processing ? state.car.Queue_Processing.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Queue_Processing/BUS/false`} className="full-pad">
                                                        {state.bus.Queue_Processing ? state.bus.Queue_Processing.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Queue_Processing/BUS/true`} className="full-pad">
                                                        {state.bus.Queue_Processing ? state.bus.Queue_Processing.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Queue_Processing/TRAIN/false`} className="full-pad">
                                                        {state.train.Queue_Processing ? state.train.Queue_Processing.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Queue_Processing/TRAIN/true`} className="full-pad">
                                                        {state.train.Queue_Processing ? state.train.Queue_Processing.INTL : ""}
                                                    </NavLink></td>
                                                    <td><span className="full-pad">0</span></td>
                                                    <td><span className="full-pad">0</span></td>
                                                </tr>
                                                <tr className="quality--set-pad">
                                                    <td className="bg-white text-left"><span className="full-pad">Vendor Queue</span></td>
                                                    <td><NavLink to={`flight-booking-request/Vendor_Queue/FLIGHT/false`} className="full-pad">
                                                        {state.flight.Vendor_Queue ? state.flight.Vendor_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`flight-booking-request/Vendor_Queue/FLIGHT/true`} className="full-pad">
                                                        {state.flight.Vendor_Queue ? state.flight.Vendor_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Vendor_Queue/HOTEL/false`} className="full-pad">
                                                        {state.hotel.Vendor_Queue ? state.hotel.Vendor_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Vendor_Queue/HOTEL/true`} className="full-pad">
                                                        {state.hotel.Vendor_Queue ? state.hotel.Vendor_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`car-booking-request/Vendor_Queue/CAR/false`} className="full-pad">
                                                        {state.car.Vendor_Queue ? state.car.Vendor_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`car-booking-request/Vendor_Queue/CAR/true`} className="full-pad">
                                                        {state.car.Vendor_Queue ? state.car.Vendor_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Vendor_Queue/BUS/false`} className="full-pad">
                                                        {state.bus.Vendor_Queue ? state.bus.Vendor_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Vendor_Queue/BUS/true`} className="full-pad">
                                                        {state.bus.Vendor_Queue ? state.bus.Vendor_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Vendor_Queue/TRAIN/false`} className="full-pad">
                                                        {state.train.Vendor_Queue ? state.train.Vendor_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Vendor_Queue/TRAIN/true`} className="full-pad">
                                                        {state.train.Vendor_Queue ? state.train.Vendor_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><span className="full-pad">0</span></td>
                                                    <td><span className="full-pad">0</span></td>
                                                </tr> */}



                                                <tr className="cancel quality--set-pad">
                                                    <td className="bg-white text-left full-pad"><span className="full-pad">Cancellation Requests</span></td>
                                                    <td><NavLink to={`flight-booking-request/Cancellation_Queue/FLIGHT/false`} className="full-pad text-danger">
                                                        {state.flight.Cancellation_Queue ? state.flight.Cancellation_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`flight-booking-request/Cancellation_Queue/FLIGHT/true`} className="full-pad text-danger">
                                                        {state.flight.Cancellation_Queue ? state.flight.Cancellation_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Cancellation_Queue/HOTEL/false`} className="full-pad text-danger">
                                                        {state.hotel.Cancellation_Queue ? state.hotel.Cancellation_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Cancellation_Queue/HOTEL/true`} className="full-pad text-danger">
                                                        {state.hotel.Cancellation_Queue ? state.hotel.Cancellation_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`car-booking-request/Cancellation_Queue/CAR/false`} className="full-pad text-danger">
                                                        {state.car.Cancellation_Queue ? state.car.Cancellation_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`car-booking-request/Cancellation_Queue/CAR/true`} className="full-pad text-danger">
                                                        {state.car.Cancellation_Queue ? state.car.Cancellation_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Cancellation_Queue/BUS/false`} className="full-pad text-danger">
                                                        {state.bus.Cancellation_Queue ? state.bus.Cancellation_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Cancellation_Queue/BUS/true`} className="full-pad text-danger">
                                                        {state.bus.Cancellation_Queue ? state.bus.Cancellation_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Cancellation_Queue/TRAIN/false`} className="full-pad text-danger">
                                                        {state.train.Cancellation_Queue ? state.train.Cancellation_Queue.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Cancellation_Queue/TRAIN/true`} className="full-pad text-danger">
                                                        {state.train.Cancellation_Queue ? state.train.Cancellation_Queue.INTL : ""}
                                                    </NavLink></td>
                                                    <td><span className="full-pad">0</span></td>
                                                    <td><span className="full-pad">0</span></td>
                                                </tr>
                                                {/* <tr className=" reschedule quality--set-pad">
                                                    <td className="bg-white text-left full-pad"><span className="full-pad">Reschedule Requests</span></td>
                                                    <td><NavLink to={`flight-booking-request/Reschedule_Requests/FLIGHT/false`} className="full-pad">
                                                        {state.flight.Reschedule_Requests ? state.flight.Reschedule_Requests.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`flight-booking-request/Reschedule_Requests/FLIGHT/true`} className="full-pad">
                                                        {state.flight.Reschedule_Requests ? state.flight.Reschedule_Requests.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Reschedule_Requests/HOTEL/false`} className="full-pad">
                                                        {state.hotel.Reschedule_Requests ? state.hotel.Reschedule_Requests.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`hotel-booking-request/Reschedule_Requests/HOTEL/true`} className="full-pad">
                                                        {state.hotel.Reschedule_Requests ? state.hotel.Reschedule_Requests.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`car-booking-request/Reschedule_Requests/CAR/false`} className="full-pad">
                                                        {state.car.Reschedule_Requests ? state.car.Reschedule_Requests.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`car-booking-request/Reschedule_Requests/CAR/true`} className="full-pad">
                                                        {state.car.Reschedule_Requests ? state.car.Reschedule_Requests.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Reschedule_Requests/BUS/false`} className="full-pad">
                                                        {state.bus.Reschedule_Requests ? state.bus.Reschedule_Requests.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`bus-booking-request/Reschedule_Requests/BUS/true`} className="full-pad">
                                                        {state.bus.Reschedule_Requests ? state.bus.Reschedule_Requests.INTL : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Reschedule_Requests/TRAIN/false`} className="full-pad">
                                                        {state.train.Reschedule_Requests ? state.train.Reschedule_Requests.DOM : ""}
                                                    </NavLink></td>
                                                    <td><NavLink to={`train-booking-request/Reschedule_Requests/TRAIn/true`} className="full-pad">
                                                        {state.train.Reschedule_Requests ? state.train.Reschedule_Requests.INTL : ""}
                                                    </NavLink></td>
                                                    <td><span className="full-pad">0</span></td>
                                                    <td><span className="full-pad">0</span></td>
                                                </tr> */}

                                            </tbody>
                                        </table>

                                    }</div>


                            </div>

                            <div className="py-padding">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="my-card border">
                                            <div className="my-padding">
                                                <div className="heading">
                                                    <i className="icon ion-md-globe"></i><h5 className="d-inline-block">Pending Credit Note</h5>
                                                </div>
                                            </div>
                                            <div className="quality">
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-airplane"></i></div>
                                                    <h6>Flight</h6>
                                                    <h5 className="text-primary">{canceledTrips ? canceledTrips.flights : ''}</h5>
                                                </div>
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-bed"></i></div>
                                                    <h6>Hotel</h6>
                                                    <h5 className="text-primary">{canceledTrips ? canceledTrips.hotels : ''}</h5>
                                                </div>
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-bus"></i></div>
                                                    <h6>Bus</h6>
                                                    <h5 className="text-primary">{canceledTrips ? canceledTrips.buses : ''}</h5>
                                                </div>
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-train"></i></div>
                                                    <h6>Train</h6>
                                                    <h5 className="text-primary">{canceledTrips ? canceledTrips.trains : ''}</h5>
                                                </div>
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-car"></i></div>
                                                    <h6>Car</h6>
                                                    <h5 className="text-primary">{canceledTrips ? canceledTrips.cars : ''}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-6">
                                        <div className="my-card border">
                                            <div className="my-padding">
                                                <div className="heading">
                                                    <i className="icon ion-md-globe"></i><h5 className="d-inline-block">Issued Credit Note</h5>
                                                </div>
                                            </div>
                                            <div className="quality">
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-airplane"></i></div>
                                                    <h6>Flight</h6>
                                                    <h5 className="text-primary">{completedTrips ? completedTrips.flights : ''}</h5>
                                                </div>
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-bed"></i></div>
                                                    <h6>Hotel</h6>
                                                    <h5 className="text-primary">{completedTrips ? completedTrips.hotels : ''}</h5>
                                                </div>
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-bus"></i></div>
                                                    <h6>Bus</h6>
                                                    <h5 className="text-primary">{completedTrips ? completedTrips.buses : ''}</h5>
                                                </div>
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-train"></i></div>
                                                    <h6>Train</h6>
                                                    <h5 className="text-primary">{completedTrips ? completedTrips.trains : ''}</h5>
                                                </div>
                                                <div className="transport-booking">
                                                    <div><i className="icon ion-md-car"></i></div>
                                                    <h6>Car</h6>
                                                    <h5 className="text-primary">{completedTrips ? completedTrips.cars : ''}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="venderQueueModal" tabIndex="-1" role="dialog" aria-labelledby="venderQueueLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-head text-white">
                                <h6 className="modal-subtitle">View</h6>
                                <h5 className="modal-title text-white" id="venderQueueLabel"> Vendor Queue</h5>
                            </div>
                            <div className="modal-middle pm-30">
                                <div className="table-responsive vender-table">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td className="border-0"><b>RIYA</b></td>
                                                <td className="border-0"><b>DOM</b></td>
                                                <td className="border-0"><b>INT</b></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="text-primary">10</td>
                                                <td className="text-primary">20</td>
                                            </tr>
                                            <tr>
                                                <td className="border-0"><b>FAST CAR</b></td>
                                                <td className="border-0"><b>DOM</b></td>
                                                <td className="border-0"><b>INT</b></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="text-primary">10</td>
                                                <td className="text-primary">20</td>
                                            </tr>
                                            <tr>
                                                <td className="border-0"><b>EZEEGO</b></td>
                                                <td className="border-0"><b>DOM</b></td>
                                                <td className="border-0"><b>INT</b></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="text-primary">10</td>
                                                <td className="text-primary">20</td>
                                            </tr>
                                            <tr>
                                                <td className="border-0"><b>TATA AIG</b></td>
                                                <td className="border-0"><b>DOM</b></td>
                                                <td className="border-0"><b>INT</b></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="text-primary">10</td>
                                                <td className="text-primary">20</td>
                                            </tr>
                                            <tr>
                                                <td className="border-0"><b>INDIGO </b></td>
                                                <td className="border-0"><b>DOM</b></td>
                                                <td className="border-0"><b>INT</b></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="text-primary">10</td>
                                                <td className="text-primary">20</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-last btn-group-margin pm-30 pt-0">
                                    <button type="button" className="btn" data-dismiss="modal">Okay</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        );
    }
}
export default HomePage