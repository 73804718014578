import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import TableRow from "./Table-row";
import { Select } from "../../../_common/_forms";

const TravelPage = props => {
  const { navBar } = props;
  return (
    <div>
      <div className="page-header">
        <div className="row">
          <div className="col-md-5">
            <h6 className="heading">Email Administration</h6>
          </div>
          <div className="col-md-7">
            <ul className="right-items">
              <li>
                <div className="user-image">
                  <i className="material-icons">account_circle</i>
                </div>
                <span className="hover-name bottom more">Account</span>
              </li>
            </ul>
            <div className="clearfix" />
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="admin-tabs">
          <div className="inner-header">
            <div className="shape-header">
              <div className="left">E</div>
              <div className="right">
                <h6 className="heading">Manage Email Templates</h6>
                <p className="text">
                  You can use templates to replace with your own content and
                  customise to your requirement.
                </p>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
        <div className="p-padding pb-0">{navBar}</div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-9">
                  <div className="btn-group-margin">
                    <NavLink
                      to={`/database-settings/add-edit-template`}
                      className="btn btn-primary"
                    >
                      Add Template
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="search-custom">
                    <input
                      type="text"
                      name="search"
                      onChange={e => props.inputChangeHandler(e)}
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <i className="material-icons">search</i>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Sent To</th>
                    <th>Editable By</th>
                    <th>Default</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <TableRow {...props} />
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  {props.totalCount && (
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <Select
                            placeholder={props.dataPerPage}
                            keyName={"items"}
                            options={props.pageItemsList}
                            controlFunc={e => props.handleItemChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-9">
                  {props.totalCount && (
                    <Pagination
                      activePage={props.selectedPage}
                      itemsCountPerPage={props.dataPerPage}
                      totalItemsCount={props.totalCount}
                      pageRangeDisplayed={3}
                      onChange={props.pageClickHandler}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TravelPage;
