import { http } from "../_helpers";
import { config } from "../env";

const getServiceModuleId = () => {
  return http.get(config.apiUrlCM + `service-module`).then(data => {
    return data;
  });
};

const getAllEmailReminders = (serviceModuleId, limit, page) => {
  return http
    .get(
      config.apiUrlCM +
        `email-remainder/list-all?serviceModuleId=${serviceModuleId}&limit=${limit}&page=${page}`
    )
    .then(data => {
      return data;
    });
};

const createEmailReminder = data => {
  return http.post(config.apiUrlCM + `email-remainder`, data).then(data => {
    return data;
  });
};

const getEmailReminderDeatilsById = emailtemplateId => {
  return http
    .get(config.apiUrlCM + `email-remainder/${emailtemplateId}`)
    .then(data => {
      return data;
    });
};

const updateEmailReminder = (emailtemplateId, data) => {
  return http
    .patch(config.apiUrlCM + `email-remainder/${emailtemplateId}`, data)
    .then(data => {
      return data;
    });
};

const getFilterEmailTemplates = (serviceModuleId, limit, page, search) => {
    return http
    .get(config.apiUrlCM + `email-remainder/list-all?serviceModuleId=${serviceModuleId}&limit=${limit}&page=${page}&search=${search}`)
    .then(data => {
      return data;
    });
}
const activeInactiveStatus = (emailtemplateId, data) => {
    return http
    .patch(config.apiUrlCM + `email-remainder/${emailtemplateId}`, data)
    .then(data => {
      return data;
    });
}
export const companyEmailReminderServices = {
  getServiceModuleId,
  getAllEmailReminders,
  createEmailReminder,
  getEmailReminderDeatilsById,
  updateEmailReminder,
  getFilterEmailTemplates,
  activeInactiveStatus
};
