// export const config = {
    // apiUrl: 'https://prod-bf0854569a80.curiotechnologies.com:5000/',
    // //Live
    // apiUrlCMLive: 'https://prod-bf0854569a80.curiotechnologies.com:7010/',
    // apiUrlSupLive: 'https://prod-bf0854569a80.curiotechnologies.com:7020/',
    // apiUrlExLive: 'https://prod-bf0854569a80.curiotechnologies.com:7030/',
    // apiMidOfficeLive: "https://prod-bf0854569a80.curiotechnologies.com:7060/",
    // //test
    // apiUrlCM: 'https://prod-bf0854569a80.curiotechnologies.com:5010/',
    // apiUrlSup: 'https://prod-bf0854569a80.curiotechnologies.com:5020/',
    // apiUrlEx: 'https://prod-bf0854569a80.curiotechnologies.com:5030/',
    // apiTestMidOffice: "https://prod-bf0854569a80.curiotechnologies.com:5060/",

    // apiUrl: 'http://13.234.165.138:5000/',
    //Live
    // apiUrlCMLive: 'http://13.234.165.138:7010/',
    // apiUrlSupLive: 'http://13.234.165.138:7020/',
    // apiUrlExLive: 'http://13.234.165.138:7030/',
    // apiMidOfficeLive: "http://13.234.165.138:7060/",
    //test
    // apiUrlCM: 'http://13.234.165.138:5010/',
    // apiUrlSup: 'http://13.234.165.138:5020/',
    // apiUrlEx: 'http://13.234.165.138:5030/',
    // apiTestMidOffice: "http://13.234.165.138:5060/",
  // };

  
  export const config = {
    apiUrl: 'https://prod-bf0854569a80.curiotechnologies.com:5000/',
    // Live
    apiUrlCMLive: 'https://prod-bf0854569a80.curiotechnologies.com:7010/',
    apiUrlSupLive: 'https://prod-bf0854569a80.curiotechnologies.com:7020/',
    apiUrlExLive: 'https://prod-bf0854569a80.curiotechnologies.com:7030/',
    apiMidOfficeLive: "https://prod-bf0854569a80.curiotechnologies.com:7060/",
    //test
    apiUrlCM: 'https://prod-bf0854569a80.curiotechnologies.com:5010/',
    apiUrlSup: 'https://prod-bf0854569a80.curiotechnologies.com:5020/',
    apiUrlEx: 'https://prod-bf0854569a80.curiotechnologies.com:5030/',
    apiTestMidOffice: "https://prod-bf0854569a80.curiotechnologies.com:5060/",
  };
  