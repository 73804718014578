import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import DropdownButton from "./components/DropDownButton";
import Menu from "../Menu";

const FormsFieldsPage = props => {
  const {
    fullFormData,
    closeModal,
    flushNow,
    closeToggleHandler,
    editToggleHandler,
    formName,
    formNameHandler,
    handleSubmit,
    handlePageChange,
    handleItemChange,
    handleStatusChange,
    handleHeaderButtonClick,
    toggleFormSelect,
    toggleFormFieldSelect,
    getMappedFields,
    handleMappedStatusChange,
    handleMappedMandatoryChange,
    toggleFieldSelect,
    removeFields,
    mapFieldsToForm,
    resetSeletedField,
    onlyCustomFields,
    changeModule,
    loading,
    listService,
    currentModule
  } = props;

  // select form field
  let activeStyle = ["bg-primary text-white"];
  let noStyle = [""];
  let isItemSelected = fullFormData.selectedFormField.length;
  let removeButtonStyle = ["btn "];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
  }

  // select field

  let fieldSelected = fullFormData.selectedField.length;
  let removeFieldStyle = ["btn "];
  if (fieldSelected) {
    removeFieldStyle.push("disabled");
  }

  const closeBtn = (
    <button className="close" onClick={closeToggleHandler}>
      &times;
    </button>
  );
  let getCustomFields =
    fullFormData.customFields &&
    fullFormData.customFields.map(field => {
      return (
        <tr
          key={field.id}
          className={
            fullFormData.selectedField.includes(field.id) ? activeStyle : null
          }>
          <td onClick={toggleFieldSelect.bind(this, field)}>
            {field.fieldName}
          </td>
          <td onClick={toggleFieldSelect.bind(this, field)}>
            {field.columnName}
          </td>
          <td onClick={toggleFieldSelect.bind(this, field)}>
            {JSON.stringify(field.isMandatory)}
          </td>
        </tr>
      );
    });

  let mappedFieldsAre =
    fullFormData.mappedFields &&
    fullFormData.mappedFields.map(mappedField => {
      return (
        <tr
          key={mappedField.id}
          className={
            fullFormData.selectedFormField.some(i => i.id === mappedField.id)
              ? activeStyle
              : null
          }>
          <td onClick={toggleFormFieldSelect.bind(this, mappedField)}>
            {mappedField.labelName}
          </td>
          <td onClick={toggleFormFieldSelect.bind(this, mappedField)}>
            {mappedField.columnName}
          </td>
          <td>
            <p className="td-padding">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={e => handleMappedMandatoryChange(mappedField)}
                  checked={mappedField.isMandatory}
                  value={mappedField.isMandatory}
                />
                <span className="slider round" />
              </label>{" "}
              <span>{mappedField.isMandatory ? "Active" : "Inactive"}</span>
            </p>
          </td>
          <td>
            {" "}
            <p className="td-padding">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={e => handleMappedStatusChange(mappedField)}
                  checked={mappedField.activeStatus}
                  value={mappedField.activeStatus}
                />
                <span className="slider round" />
              </label>{" "}
              <span>{mappedField.activeStatus ? "Active" : "Inactive"}</span>
            </p>
          </td>
        </tr>
      );
    });

  let modifyForm =
    fullFormData.forms &&
    fullFormData.forms.map(form => {
      return (
        <>
          <tr
            key={form.id}
            className={
              fullFormData.selectedForm.some(i => i.id === form.id)
                ? noStyle
                : null
            }>
            <td onClick={toggleFormSelect.bind(this, form)}>
              <span className="add-minus" />
              {form.formName}
            </td>{" "}
            <td>{form.formAccess}</td>
            <td>
              <button
                className="btn  sm-padding"
                data-toggle="collapse"
                data-target={"#demo" + form.id}
                aria-expanded="true"
                aria-controls={"demo" + form.id}
                onClick={getMappedFields.bind(this, form.id)}>
                Edit
              </button>
            </td>
            <td>
              <p className="td-padding">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={e => handleStatusChange(form)}
                    checked={form.activeStatus}
                    value={form.activeStatus}
                  />
                  <span className="slider round" />
                </label>{" "}

              </p>
            </td>
          </tr>
          <tr className="collapse" id={"demo" + form.id}
            aria-labelledby={"heading" + form.id}
            data-parent="#accordion"
          >
            <td colSpan="4">
              <div className="table-container">
                <form className="bg-white">
                  <div className="btn-group-margin mb-2">
                    <NavLink
                      to={
                        fullFormData.selectedFormField.length
                          ? `/database-settings/admin-form/forms-fields/modify-form-fields/${
                          fullFormData.selectedFormField[0].id
                          }`
                          : `#`
                      }>
                      <button
                        className="btn "
                        disabled={isItemSelected ? false : true}>
                        Modify
                      </button>{" "}
                    </NavLink>

                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#"
                      className="btn  sm-padding"
                      onClick={removeFields}
                      disabled={isItemSelected ? false : true}>
                      Remove Form Field
                    </button>
                    <button
                      type="button"
                      onClick={onlyCustomFields}
                      data-toggle="modal"
                      data-target="#insertField"
                      className="btn  sm-padding">
                      Insert Field
                    </button>
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#fieldPreview"
                      className="btn "
                      onClick={flushNow}
                      disabled={isItemSelected ? false : true}>
                      Preview Form
                    </button>
                  </div>
                </form>

                <div className="table-responsive">
                  <table className="table table-striped table-movable">
                    <thead>
                      <tr>
                        <th>Form Field Name</th>
                        <th>Column Name</th>
                        <th>Mandatory</th>
                        {/* <th>Sequence</th> */}
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>{mappedFieldsAre ? mappedFieldsAre : <tr><td>No data found</td></tr>}</tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
        </>
      );
    });

  return (
    <div>
      <div className="page-header">
        <h6 className="heading">Forms and Fields</h6>
      </div>
      <div className="page-content">
        <Menu changeModule={changeModule} currentModule={currentModule} listService={listService} />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Forms Fields</h6>
              <p className="text">These are form Fields</p>
            </div>
          </div>
        </div>
        <div className="p-padding pb-0">
          <div className="row">
            <div className="col-4">
              <div className="form-group custom labelup mb-0">
                <label>Form Type</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown">
                    {fullFormData.selectedFormHeader
                      ? fullFormData.selectedFormHeader
                      : "Select Form Type"}
                  </button>
                  <div className="dropdown-menu">
                    {fullFormData.formHeaderName
                      ? fullFormData.formHeaderName.map(formhead => {
                        return (
                          <DropdownButton
                            handleHeaderButtonClick={handleHeaderButtonClick}
                            key={formhead.id}
                            id={formhead.id}
                            name={formhead.formHeaderName}
                          />
                        );
                      })
                      : "No Headers Found"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="p-padding pt-0">
            <div className="admin-tabs">
              <ul className="nav nav-tabs">
                <li>
                  <NavLink
                    className="show"
                    to="/database-settings/admin-form/forms/list">
                    Forms
            </NavLink>
                </li>
                <li>
                  <NavLink
                    className="show active"
                    to="/database-settings/admin-form/forms-fields/list"
                    aria-current="page">
                    Forms Fields
            </NavLink>
                </li>
                <li>
                  <NavLink
                    className="show"
                    to="/database-settings/admin-form/fields/list">
                    Fields
            </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="table-container" id="accordion">
            {fullFormData.selectedFormHeader && (
              <>
                <form>
                  <div className="row">
                    <div className="col-md-10" />
                    <div className="col-md-2">
                      <div className="search-custom">
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="Search"
                          disabled={
                            fullFormData.selectedFormHeader ? false : true
                          }
                        />
                        <span>
                          <i className="icon ion-md-search" />
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="table-responsive" display="none">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Form Name</th>
                        <th>Access</th>
                        <th>Action</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modifyForm ? (
                        modifyForm
                      ) : (
                          <tr>
                            <td colSpan="4">No data found</td>
                          </tr>
                        )}
                      {/* {formDatas} */}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {fullFormData.forms && (
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown">
                            {fullFormData.itemPerPage}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              onClick={handleItemChange.bind(this, 10)}
                              className="dropdown-item">
                              10
                          </button>
                            <button
                              onClick={handleItemChange.bind(this, 20)}
                              className="dropdown-item">
                              20
                          </button>
                            <button
                              onClick={handleItemChange.bind(this, 30)}
                              className="dropdown-item">
                              30
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={fullFormData.activePage}
                      itemsCountPerPage={fullFormData.itemsCountPerPage}
                      totalItemsCount={fullFormData.totalItemsCount}
                      pageRangeDisplayed={1}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

      </div>
      {/* Insert field */}
      <div
        className="modal fade"
        id="insertField"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="insertFieldLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="insertFieldLabel">
                Add File to Form
              </h5>
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            {/* <div className="search-custom">
                <input
                  type="text"
                  name=""
                  className="form-control"
                  placeholder="Search Permissions"
                />
                <span>
                  <i className="icon ion-md-search" />
                </span>
              </div> */}
            <div className="modal-middle pm-30">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-striped table-movable">
                    <thead>
                      <tr>
                        <th>Form Field Name</th>
                        <th>Column Name</th>
                        <th>Mandatory</th>
                      </tr>
                    </thead>
                    <tbody>{getCustomFields}</tbody>
                  </table>
                </div>
              </div>
              {/* </div> */}
              <div className="modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn btn-border-success lg-padding"
                  disabled={fieldSelected ? false : true}
                  onClick={mapFieldsToForm}>
                  Assign
              </button>
                <button
                  type="button"
                  className="btn btn-border-basic lg-padding"
                  data-dismiss="modal"
                  onClick={resetSeletedField}>
                  Close
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Preview Field Details */}
      <div
        className="modal fade"
        id="fieldPreview"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="fieldPreviewModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="fieldPreviewModalLabel">
                Preview Form
              </h5>
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <div className="modal-middle pm-30">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].fieldName
                      }
                    />
                    <label>Field Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].labelName
                      }
                    />
                    <label>Label Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].columnName
                      }
                    />
                    <label>Column Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].tableName
                      }
                    />
                    <label>Table Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      className="form-control bg-white"
                      placeholder="&nbsp;"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].maxLength
                      }
                    />
                    <label>Max Length</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].tableName
                      }
                    />
                    <label>Table Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        JSON.stringify(
                          fullFormData.selectedFormField[0].isMandatory
                        )
                      }
                    />
                    <label>Is Mandatory</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        JSON.stringify(
                          fullFormData.selectedFormField[0].activeStatus
                        )
                      }
                    />
                    <label>Active status</label>
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn btn-border-basic lg-padding"
                  data-dismiss="modal">
                  Close
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormsFieldsPage;
