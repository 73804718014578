import React from 'react';
import uuid from 'uuid';
const Select = (props) => (

    // <div className={"count"} key={uuid.v4()}>
        <div className={"dropdown" + ' ' + props.myClass}  key={uuid.v4()}>
            <button type="button" className={"btn dropdown-toggle" + ' ' + props.myClass}
                data-toggle="dropdown">
                {props.placeholder}
            </button>
            <div className="dropdown-menu ">
                {props.options.map((opt, index) => {
                    return (
                        <button
                            type="button"
                            onClick={e => props.controlFunc(opt)}
                            className="dropdown-item "
                            key={index}>
                            {opt[props.keyName]}</button>
                    );
                })}
            </div>
            <div className="help-block">{props.error}</div>
        </div>
        
    // </div>
);

export default Select; 