import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/styles';
import Moment from "react-moment";
import NumberFormat from 'react-number-format';

const container = {
    width: '95%',
    padding: '35px',
    margin: '20px auto',
    backgroundColor: '#fff',
    fontSize: '14px'
};
const ul = {
    width: '100%',
    borderBottom: '2px solid rgba(0, 0, 0, .2)',
    padding: '20px 0px'
};
const li = {
    listStyle: 'none',
    display: 'inline-block',
    width: '33%',
    textAlign: 'left'
};
const invoice = {
    fontWeight: 500,
    fontSize: '28px'
};
const logoimg = {
    height: '60px',
    width: 'auto'
};
const fieldset = {
    border: '1px solid rgba(0, 0, 0, .2)',
    position: 'relative',
    width: '100%'
};
const legend = {
    position: 'absolute',
    top: '-16px',
    fontSize: '16px',
    fontWeight: 500,
    left: '10px',
    backgroundColor: '#fff',
    width: 'auto',
    padding: '0px 5px'
};
const h5 = {
    padding: '15px 15px 10px 15px',
    fontSize: '18px',
    fontWeight: 500
};
const tables = {
    width: '100%'
};
const table = {
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'text-top'
};
const th = {
    padding: '10px',
    textTransform: 'capitalize',
    margin: '0px 20px',
    fontSize: '14px'
};
const td = {
    fontWeight: 500,
    fontSize: '14px'
};
const address = {
    opacity: 0.5
};
const itineraryTable = {
    border: '1px solid rgba(0, 0, 0, .2)',
    width: '100%'
};
const itineraryDetails = {
    marginTop: '30px'
};
const tableHead = {
    fontWeight: 'bold',
    fontSize: '16px'
};
const itineraryTh = {
    textTransform: 'uppercase',
    fontWeight: 500,
    padding: '20px 10px',
    backgroundColor: '#f1f1f2',
    border: '1px solid rgba(0, 0, 0, .2)',
    fontSize: '14px'
};
const itineraryTd = {
    padding: '10px',
    border: '1px solid rgba(0, 0, 0, .2)',
    fontSize: '14px'
};


const travelHeading = {
    paddingLeft: "10px",
    fontWeight: "600",
    paddingTop: "20px"
}
const row = {
    width: "100%",
}
const col = {
    width: "45%",
    marginLeft: "15px",
    marginRight: "15px",
    display: "inline-block"
}

class ETicketPage extends React.Component {
    getTime = (time) => {
        let type = 'AM'
        let hours = parseInt(time / 60);
        let minutes = parseInt(time) % 60;
        if (hours > 12) {
            hours = hours - 12;
            type = "PM";
        }
        minutes = (minutes === 0) ? "00" : minutes;
        return hours + ' : ' + minutes + ' ' + type;
    }
    render() {
        const { eTicketData } = this.props;
        console.log(eTicketData);
        return (
            <div style={container}>
                <ul style={ul}>
                    <li style={li}>
                        <img src="/assets/images/curio-clear-logo.png" style={logoimg} alt="logo" />
                    </li>
                    <li style={li}>
                        <p style={invoice}>E-Voucher</p>
                    </li>
                    <li style={li}>
                        <fieldset style={fieldset}>
                            <legend style={legend}>Your E-Voucher Number</legend>
                            <h5 style={h5}>--</h5>
                        </fieldset>
                    </li>
                </ul>
                <div style={tables}>
                    <div style={row}>
                        <div style={col}>
                            <h5 style={travelHeading}>Hotel Information</h5>
                            <table style={table}>
                                <tr>
                                    <th style={th}>Booking Ref#</th>
                                    <td style={{ ...td, "fontWeight": "bold" }}>{eTicketData && eTicketData.pnrData ? eTicketData.pnrData.bookingReferenceNumber : '--'}</td>
                                </tr>
                                <tr>
                                    <th style={th}>Hotel PinCode </th>
                                    <td style={td}>{eTicketData && eTicketData.pnrData ? eTicketData.pnrData.hotelPinCode : '--'}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={th}>Hotel Phone</th>
                                    <td style={td}>{eTicketData && eTicketData.pnrData ? eTicketData.pnrData.hotelPhone : '--'}</td>
                                </tr>
                                <tr>
                                    <th style={th}>Contact Person</th>
                                    <td style={td}>{eTicketData && eTicketData.pnrData ? eTicketData.pnrData.contactPerson : '--'}</td>
                                </tr>
                            </table>

                        </div>
                        <div style={col}>
                            <h5 style={travelHeading}>Traveller Information</h5>
                            <table style={table}>
                                <tr>
                                    <th style={th}>Guest Name </th>
                                    <td style={td}>{eTicketData.travellerName}</td>
                                </tr>
                                <tr>
                                    <th style={th}>BOOKING DATE </th>
                                    <td style={td}>{eTicketData.bookingDate}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={th}>BOOKING REFERENCE NUMBER </th>
                                    <td style={td}>{eTicketData.pnrData ? eTicketData.pnrData.bookingReferenceNumber : '--'}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={th}>STATUS</th>
                                    <td style={{ ...td, "fontWeight": "bold" }}>{eTicketData.pnrStatus}</td>
                                </tr>
                                <tr>
                                    <th style={th}>EMAIL</th>
                                    <td style={td}>{eTicketData.email}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div style={itineraryDetails}>
                    <h6 style={tableHead}>Hotel Information</h6>

                    <table style={itineraryTable}>

                        <tr>
                            <th style={itineraryTh}>Hotel</th>
                            <th style={itineraryTh}>Check-In</th>
                            <th style={{ ...itineraryTh, "color": "green" }}>DURATION</th>
                            <th style={itineraryTh}>Check-Out</th>
                        </tr>

                        <tr>
                            <td style={itineraryTd} rowSpan="3">
                                <span style={{ "fontWeight": "700" }}>{eTicketData.data ? eTicketData.data.hotel_name : '--'}</span><br />
                                {eTicketData.data ? eTicketData.data.hotel_address + ', ' + eTicketData.data.hotel_city : '--'}
                            </td>
                            <td style={itineraryTd}>{eTicketData.data ? this.getTime(eTicketData.data.checkin_from) : '--'}</td>
                            <td rowspan="2" style={{ ...itineraryTd, "fontWeight": "800" }}><span style={{ color: "green" }}>{eTicketData.data ? eTicketData.data.trip_duration : '--'}</span><br /> Day(s)
                    </td>
                            <td style={itineraryTd}>{eTicketData.data ? this.getTime(eTicketData.data.checkout_to) : '--'}</td>
                        </tr>
                        <tr>
                            <td style={{ ...itineraryTd, "fontWeight": "700" }}>{eTicketData.data ? eTicketData.data.hotel_from : '--'}</td>
                            <td style={{ ...itineraryTd, "fontWeight": "700" }}>{eTicketData.data ? eTicketData.data.hotel_to : '--'}</td>
                        </tr>
                    </table>
                </div>
                <div style={itineraryDetails}>
                    <h6 style={tableHead}>Additional Information</h6>
                    <table style={itineraryTable}>
                        <tr>
                            <th style={itineraryTh}>Breakfast</th>
                            <th style={itineraryTh}>Lunch</th>
                            <th style={itineraryTh}>Pre-Payment</th>
                            <th style={itineraryTh}>Dinner</th>
                            <th style={itineraryTh}>Refundability</th>
                            <th style={itineraryTh}>cancellation</th>
                            <th style={itineraryTh}>Room Type</th>
                            <th style={itineraryTh}>Guests</th>
                            <th style={itineraryTh}>Per Night</th>
                        </tr>
                        <tr>
                            <td style={itineraryTd}>{(eTicketData.data && eTicketData.data.hotel_room_info.hotel_room_breakfast !== false) ? "Included" : "Not Included"}</td>
                            <td style={itineraryTd}>{(eTicketData.data && eTicketData.data.hotel_room_info.hotel_room_lunch !== false) ? "Included" : "Not Included"}</td>
                            <td>--</td>
                            <td style={itineraryTd}>{(eTicketData.data && eTicketData.data.hotel_room_info.hotel_room_dinner_include !== false) ? "Included" : "Not Included"}</td>
                            <td style={itineraryTd}>{eTicketData.data && eTicketData.data.hotel_room_info.hotel_room_refundable ? "YES" : "NO"}</td>
                            <td style={itineraryTd}><Moment format="DD-MMM-YYYY">{eTicketData.data ? eTicketData.data.hotel_room_info.hotel_refudable_untill : '--'}</Moment></td>
                            <td>--</td>
                            <td style={itineraryTd}>{eTicketData.data ? eTicketData.data.hotel_room_info.hotel_room_max_occupancy : '--'}</td>
                            <td>--</td>
                        </tr>
                    </table>
                </div>
                <div style={itineraryDetails}>
                    <h6 style={tableHead}>Tax Breakup</h6>
                    <table style={itineraryTable}>
                        <tr>
                            <th style={itineraryTh}>Base fare / Day</th>
                            <th style={itineraryTh}>Hotel Tax & surcharges</th>
                            <th style={itineraryTh}>Total fare</th>
                        </tr>
                        <tr>
                            <td style={itineraryTd}>
                                <NumberFormat
                                    value={(eTicketData.data != undefined) ? (Math.floor(eTicketData.data.hotel_room_info.hotel_room_price * 1000) / 1000) : ''}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={eTicketData.pnrData ? eTicketData.data.hotel_currency_code + ' ' : '--'} />

                            </td>
                            <td style={itineraryTd}>
                                <NumberFormat
                                    value={eTicketData.pnrData ?
                                        (Math.floor(eTicketData.pnrData.totalTax * 1000) / 1000) + ' ' + eTicketData.data.hotel_currency_code : '--'}
                                    displayType={'text'} thousandSeparator={true}
                                    prefix={eTicketData.pnrData ? eTicketData.data.hotel_currency_code + ' ' : '--'} />
                            </td>
                            <td style={itineraryTd}>
                                <NumberFormat
                                    value={eTicketData.pnrData ? (Math.floor(eTicketData.pnrData.totalFare * 1000) / 1000) : ''}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={eTicketData.pnrData ? eTicketData.data.hotel_currency_code + ' ' : '--'} />


                            </td>
                        </tr>
                    </table>
                </div>


                {/* <div style={itineraryDetails}>
                    <h6 style={tableHead}>Rules & Conditions</h6>
                    {(eTicketData.data && eTicketData.data.hotel_policies.map((policy, i) => {
                        return (
                            <p key={i} style={pFont}>- {policy.content}</p>
                        )
                    }))}

                </div> */}
                {/* <div style={signature}>
                    <div style={floatLeft}>
                        <p style={fontWeightMd}>For Curio Technologies(P) Ltd.</p>
                        <p style={{ "opacity": "0.5" }}>MM Square, #5M - 671,1st & 2nd Floor, 5th Main,<br /> OMBR Layout,B-Channasandra,
                    Bangalore(KA)-560043</p>
                    </div>
                    <div style={floatRight}>
                        <p style={{ ...fontWeightMd, "textAlign": "right" }}>Support: +91 10011 91888</p>
                        <p>www.curiotechnologies.com</p>
                    </div>
                    <div style={{ "clear": "both" }}></div>
                </div> */}
                <p style={{ fontWeight: "700", marginTop: "30px" }}>Cancellation Policy</p>
                <p>--</p>
            </div>
        )
    }
}

ETicketPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (ETicketPage);
