import React, { Component } from 'react';
import DatabaseMenu from '../_common/database-menu';
import { Route, Redirect } from "react-router-dom";
import TravelRulePage from './travel-rule';
import CreateTemplatePage from './create-template';
import ManageMenuItemsPage from './manage-menu-items';
import ServiceModulePage from './service-module';
import PageNotFoundPage from '../page-not-found';
import BillingCyclePage from './billing-cycle';
import OrderTypePage from './order-type';
import CountryPage from './country';
import StatePage from './state';
import CityPage from './city';
import TravelContitionPage from './travel-contition';
import ExpenseFormHeaderPage from './expense-form-header';
import ListManagement from './list-management/list-management';
import SystemList from './system-list/system-list';
import FFMainPage from './forms/manage';
import Travel from './email-administration/travel/travel';
import EmailReminder from './email-reminder/travel/travel';
import AddEditTemplate from './email-administration/add-edit-template/add-edit-template';

import AddEmailReminder from './email-reminder/add-edit-reminder/add-edit-reminder';


import CountriesPage from './countries-page/countries';

import TravelRuleCategoryPage from './travel-rule-category';


class DatabaseSettingsPage extends Component {
  constructor(props) {
    super(props)
    if (props.location.pathname === '/database-settings') {
      this.props.history.push('/database-settings/travel-category')
    } else {
      this.props.history.push(props.location.pathname)
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === '/database-settings') {
      this.props.history.push('/database-settings/travel-category')
    }
  }
  render() {
    return (
      <div className="DatabaseSettingsPage">
        <DatabaseMenu />
        <div className="inner-wrapper database">
          <Route path='/database-settings/travel-rule-builder' component={TravelRulePage} />
          <Route path='/database-settings/create-template' component={CreateTemplatePage} />
          <Route path='/database-settings/update-template/:id' component={CreateTemplatePage} />
          <Route path='/database-settings/service-module' component={ServiceModulePage} />
          <Route path='/database-settings/manage-menu-items' component={ManageMenuItemsPage} />
          <Route path='/database-settings/billing-cycle' component={BillingCyclePage} />
          <Route path='/database-settings/order-type' component={OrderTypePage} />
          <Route path='/database-settings/countries' component={CountryPage} />
          <Route path='/database-settings/state' component={StatePage} />
          <Route path='/database-settings/city' component={CityPage} />
          <Route path='/database-settings/travel-contition' component={TravelContitionPage} />
          <Route path='/database-settings/expense-form-header' component={ExpenseFormHeaderPage} />
          <Route path='/database-settings/list-management' component={ListManagement} />
          <Route path='/database-settings/system-list' component={SystemList} />
          <Route path='/database-settings/admin-form' component={FFMainPage} />
          <Route path='/database-settings/email-administration/:id' component={Travel} />
          <Route path='/database-settings/email-reminders' component={EmailReminder} />
          <Route path='/database-settings/add-edit-reminder/:id' exact component={AddEmailReminder} />
          <Route path='/database-settings/add-edit-reminder/' exact component={AddEmailReminder} />
          {/* <Route path='/database-settings/email-administration/Cash_Advance' component={CashAdvance} />
          <Route path='/database-settings/email-administration/Expense' component={Expense} />
          <Route path='/database-settings/email-administration/Company' component={System} /> */}
          <Route path='/database-settings/add-edit-template' exact component={AddEditTemplate} />
          <Route path='/database-settings/add-edit-template/:id' component={AddEditTemplate} />
          <Route path='/database-settings/countries-page' component={CountriesPage} />
          <Route path='/database-settings/travel-category' component={TravelRuleCategoryPage} />
          {/* <Route path="**" component={PageNotFoundPage} />  */}
        </div>
      </div>
    );
  }
}

export default DatabaseSettingsPage;
