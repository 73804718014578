import { http } from "../_helpers";
import { config } from "../env";


const getCommonData = (data) => {
  console.log(data);
  return http.get(config.apiTestMidOffice + `trips/history?bookingType=${data.bookingType}&search=${data.search}&page=${data.page}&limit=${data.limit}&companyId=${data.companyId ? data.companyId : ''}`)
    .then(data => {
      return data ? data : "";
    });
};

const getTripData = (id) => {
  let url = `${config.apiTestMidOffice}bookings/get-one-trip/${id}`;
  return http.get(url)
    .then(res => res);
}

const getDownloadHistory = (data) => {
  let url = `${config.apiTestMidOffice}mid-office/hystery-excel?fromDate=${new Date(data.fromDate)}&toDate=${new Date(data.toDate)}&bookingType=${data.bookingType}`;
  console.log(url);
  return http.get(url)
    .then(res => res);
}

export const TestHistoryServices = {
  getCommonData,
  getTripData,
  getDownloadHistory
};
