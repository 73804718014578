import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class CancelRequestPage extends Component {
    render() {
        return (
            <div>
                <div className="page-header">
                    <h6 className="heading">Cancellation Request -CY2WXXTRWSD</h6>
                </div>
                <div className="page-content">
                    <div className="admin-tabs">
                        {/* <div className="inner-header">
                            <div className="shape-header ml-5">
                                <div className="left">F</div>
                                <div className="right">
                                    <h6 className="heading">Cancellation Request -CY2WXXTRWSD</h6>
                                    <p className="text">Has been the industry’s standard dummy text ever since the 1500s.</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="booking-bg">
                            <div className="p-padding">
                                <div className="row">
                                    <div className="col-md-10 offset-1">
                                        <div className="traveller-block">
                                            <div className="pb-3">
                                                <div className="float-left">
                                                    <h6 className="mt-2"><b>Request Details</b></h6>
                                                </div>
                                                <div className="float-right">
                                                    <button type="button" className="btn " data-toggle="modal" data-target="#eTicketModal"><i className="icon ion-md-document mr-1"></i>View E-Ticket</button>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="traveller-details">
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <td className="border-0">CANCELLATION LOG <br /><b>BLR- DEL</b></td>
                                                                <td className="border-0">BASE FARE<br /><b>4286 INR</b></td>
                                                                <td className="border-0">TOTAL TAX <br /><b>1728.00 INR</b></td>
                                                                <td className="border-0">SERVICE TAX <br /><b>0.00 INR</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border-0">CANCELLATION LOG <br /><b>BLR- DEL</b></td>
                                                                <td className="border-0">BASE FARE<br /><b>4286 INR</b></td>
                                                                <td className="border-0">TOTAL TAX <br /><b>1728.00 INR</b></td>
                                                                <td className="border-0">SERVICE TAX <br /><b>0.00 INR</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="px-15 py-padding pb-0">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group custom labelup mb-0">
                                                            <label>Fare Type</label>
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle " data-toggle="dropdown">Refundable
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="travel_alert rounded">
                                                            <div className="d-inline-block warn-icon">
                                                                <span><i className="icon ion-md-alert "></i></span>
                                                            </div>
                                                            <div className="d-inline-block">
                                                                <span>Kindly select fare type "Non-Refundable" incase ticket is non refundable or airline doesn't give refund amount.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-15 py-padding">
                                                <h6 className="pb-3"><b>REFUND DETAILS</b></h6>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group custom labelup">
                                                            <label>Airline Cancellation Charge</label>
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle " data-toggle="dropdown">1000
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group custom labelup">
                                                            <label>Base Fare</label>
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle " data-toggle="dropdown">1271.0
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group custom labelup">
                                                            <label>Service Charge</label>
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle " data-toggle="dropdown">5,558.00
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group custom labelup">
                                                            <label>Refund to client</label>
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle " data-toggle="dropdown">5,558.00
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group custom textarea labelup mb-0 bg-light">
                                                            <textarea rows="4" className="form-control" placeholder="Meeting date postponed"></textarea>
                                                            <label>Reason Provided by customer</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group custom labelup">
                                                            <label>Commission form Vendor</label>
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle " data-toggle="dropdown">1000
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group custom labelup">
                                                            <label>Total Tax</label>
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle " data-toggle="dropdown">1271.0
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group custom labelup">
                                                            <label>Total Refund from Vendor</label>
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle " data-toggle="dropdown">5,558.00
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group custom labelup">
                                                            <label>Supplier Ref Number</label>
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle " data-toggle="dropdown">5,558.00
                                                            </button>
                                                                <div className="dropdown-menu">
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                    <button className="dropdown-item">Expense Entry Form</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group custom textarea mb-0">
                                                            <textarea rows="4" className="form-control" placeholder="&nbsp;"></textarea>
                                                            <label>Message</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-group-margin text-right mt-4">
                                            <NavLink to="flight-booking-cancel" className="btn">Cancel</NavLink>
                                            <NavLink to="flight-booking-cancel" className="btn  mr-0">Save<i className="icon ion-md-arrow-dropright ml-2"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="eTicketModal" tabIndex="-1" role="dialog" aria-labelledby="eTicketLable" aria-hidden="true">
                    <div className="modal-dialog  modal-xl modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-head">
                                <h6 className="modal-subtitle">View</h6>
                                <h5 className="modal-title text-capitalize text-white" id="eTicketLable">E-Ticket</h5>
                            </div>
                            <div className="modal-middle pm-30">
                                <div className="border pm-15 mb-3">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="bg-light text-uppercase">
                                                <th>Flight <br /> Number</th>
                                                <th>Carrier</th>
                                                <th colspan="2">Departing</th>
                                                <th className="text-success">Duration</th>
                                                <th colspan="2">Arrival</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td rowspan="2" className="text-primary">I5-2383 </td>
                                                <td rowspan="2">
                                                    <img src="/assets/images/lufthansa.png" alt="logo" />
                                                    <p className="mt-1">Air asia</p>
                                                </td>
                                                <td colspan="2" className="lighter-p">
                                                    7:30 - Sat June 2019
                                                </td>
                                                <td rowspan="2" >
                                                    <p className="text-success">1Hr</p>
                                                    <p>Non Stop</p></td>
                                                <td colspan="2" className="">8:30 - Sat June 2019</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="lighter-p">
                                                    7:30 - Sat June 2019
                                                </td>
                                                <td colspan="2" className="">8:30 - Sat June 2019</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6 className="font-weight-bold mb-3">Additional Information</h6>
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr className="bg-light text-uppercase">
                                                <th>Meal</th>
                                                <th>Baggage</th>
                                                <th>Hand Baggage</th>
                                                <th>Flight Equipment</th>
                                                <th>class</th>
                                                <th>seat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Dinner</td>
                                                <td>15 kg</td>
                                                <td>5 kg</td>
                                                <td>Boeing 737-800</td>
                                                <td>Economy,<br />Coach (s)</td>
                                                <td>C4</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className="font-weight-bold mb-3">Fair Details</h6>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="bg-light text-uppercase">
                                            <th>Air Fare</th>
                                            <th>Airline tax & surcharges</th>
                                            <th>Total Fare</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>3300.00 INR</td>
                                            <td>632.00 INR</td>
                                            <td>3932.00 INR</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className="font-weight-bold mb-3">Tax Breakup</h6>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="text-uppercase">
                                            <td className="font-weight-bold bg-light">AIRLINE - MSC : <br />187.00 INR</td>
                                            <td>cgst:87.00 INR</td>
                                            <td>psf:153.00 INR</td>
                                            <td>sgst:87.00 INR</td>
                                            <td>udf:118.00 INR</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className="font-weight-bold mb-3">Fare Rules and Cancellation Policy</h6>
                                <p>- Use your airline PNR for all your communication for this booking with the airline</p>
                                <p>- All the times indicated are the local time at the relevant airport and we recommend you check-In 2 hours prior to departure</p>
                                <p>- All the passangers including Children and infants, must present valid photo identification at check-in</p>
                                <p>- Carry a printout of this e-ticket and present it to the airline counter at the time of check-In</p>
                                <p>- Kindly check airline website for baggage information</p>
                                <div className="modal-last btn-group-margin mt-4">
                                    <NavLink to="/mid-office/e-ticket" target="_blank" className="btn">okay</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default CancelRequestPage;
