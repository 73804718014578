import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { midOfficeServices } from "../../_services";
class BusBookingRequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalCount: "",
      search: "",
      limit: 10,
      page: 1,
    }
  }
  componentDidMount() {
    let data = {
      bookingType: this.props.match.params.bookingType,
      statusType: this.props.match.params.statusType,
      isInternational: this.props.match.params.find,
      search: this.state.search,
      limit: this.state.limit,
      page: this.state.page
    }
    this.getBusBookingRequest(data)
  }

  getBusBookingRequest = async (data) => {
    var res = await midOfficeServices.getBusBookingRequest(data)
    if (res) {
      console.log(res.result);
      this.setState({
        data: res.result,
        totalCount: res.count,
        search: "",
        limit: 10,
        page: 1,
      })
    } else {
      this.setState({ data: [], totalCount: "" })
    }
  }
  searchHandler = (e) => {
    this.setState({ search: e.target.value })
    let data = {
      bookingType: this.props.match.params.statusType,
      statusType: this.props.match.params.bookingType,
      isInternational: this.props.match.params.isInternational === "true" ? true : false,
      search: e.target.value,
      limit: this.state.limit,
      page: this.state.page
    }
    this.getBusBookingRequest(data)
  }

  handleItemChange = limit => {
    this.setState({ limit: limit.items, page: 1 });
    let data = {
      bookingType: this.props.match.params.statusType,
      statusType: this.props.match.params.bookingType,
      isInternational: this.props.match.params.isInternational === "true" ? true : false,
      search: this.state.search,
      limit: limit.items,
      page: this.state.page
    }
    this.getBusBookingRequest(data);
  };

  pageClickHandler = page => {
    this.setState({ page: page });
    let data = {
      bookingType: this.props.match.params.statusType,
      statusType: this.props.match.params.bookingType,
      isInternational: this.props.match.params.isInternational === "true" ? true : false,
      search: this.state.search,
      limit: this.state.limit,
      page: page
    }
    this.getBusBookingRequest(data);
  };

  render() {
    let data = this.props;
    return (
      <div>
        <div className="page-header">
          <h6 className="heading">Bus Booking Request</h6>
        </div>
        <div className="page-content">
          <div className="admin-tabs">
            {/* <div className="inner-header">
            <div className="shape-header ml-5">
              <div className="left">F</div>
              <div className="right">
                <h6 className="heading">Bus Booking Request</h6>
                <p className="text">Has been the industry’s standard dummy text ever since the 1500s.</p>
              </div>
            </div>
          </div> */}
            <div className="py-padding">
              <div className="table-container">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group ">
                        <div className="dropdown">
                          <button type="button" className="btn dropdown-toggle bg-light border-0 " data-toggle="dropdown">All Clients
                            </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">Expense Entry Form</button>
                            <button className="dropdown-item">Expense Entry Form</button>
                            <button className="dropdown-item">Expense Entry Form</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 offset-md-5 col-lg-2 offset-lg-6">
                      <div className="search-custom">
                        <input type="text" name="" className="form-control" placeholder="Search" />
                        <span><i className="material-icons align-middle">search</i></span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>CURIO REF NO</th>
                        <th>PNR</th>
                        <th>TRAVELER NAME</th>
                        <th>BOOKING REF</th>
                        <th>BOOKING DATE</th>
                        <th>VENDOR(BUS)</th>
                        <th>SECTOR</th>
                        <th>TRAVEL DATE</th>
                        <th>COMPANY</th>
                        <th>FARE TYPE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-primary" data-toggle="modal" data-target="#sendToVender3Modal">CTFRKY2RAXS2</td>
                        <td>34536753247</td>
                        <td>Vishwa</td>
                        <td>QDGFF</td>
                        <td>08/03/2019</td>
                        <td>RED BUS</td>
                        <td>BLR - DEL</td>
                        <td>8/8/2088</td>
                        <td>FSL</td>
                        <td>Corporate fair</td>
                      </tr>
                      <tr>
                        <td className="text-primary">CTFRKY2RAXS2</td>
                        <td>34536753247</td>
                        <td>Vishwa</td>
                        <td>QDGFF</td>
                        <td>08/03/2019</td>
                        <td>RED BUS</td>
                        <td>BLR - DEL</td>
                        <td>8/8/2088</td>
                        <td>FSL</td>
                        <td>Corporate fair</td>
                      </tr>
                      <tr>
                        <td className="text-primary">CTFRKY2RAXS2</td>
                        <td>34536753247</td>
                        <td>Vishwa</td>
                        <td>QDGFF</td>
                        <td>08/03/2019</td>
                        <td>RED BUS</td>
                        <td>BLR - DEL</td>
                        <td>8/8/2088</td>
                        <td>FSL</td>
                        <td>Corporate fair</td>
                      </tr>
                      <tr>
                        <td className="text-primary">CTFRKY2RAXS2</td>
                        <td>34536753247</td>
                        <td>Vishwa</td>
                        <td>QDGFF</td>
                        <td>08/03/2019</td>
                        <td>RED BUS</td>
                        <td>BLR - DEL</td>
                        <td>8/8/2088</td>
                        <td>FSL</td>
                        <td>Corporate fair</td>
                      </tr>
                      <tr>
                        <td className="text-primary">CTFRKY2RAXS2</td>
                        <td>34536753247</td>
                        <td>Vishwa</td>
                        <td>QDGFF</td>
                        <td>08/03/2019</td>
                        <td>RED BUS</td>
                        <td>BLR - DEL</td>
                        <td>8/8/2088</td>
                        <td>FSL</td>
                        <td>Corporate fair</td>
                      </tr>
                      <tr>
                        <td className="text-primary">CTFRKY2RAXS2</td>
                        <td>34536753247</td>
                        <td>Vishwa</td>
                        <td>QDGFF</td>
                        <td>08/03/2019</td>
                        <td>RED BUS</td>
                        <td>BLR - DEL</td>
                        <td>8/8/2088</td>
                        <td>FSL</td>
                        <td>Corporate fair</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table-footer">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="page-count">
                        <div className="text">Items</div>
                        <div className="count">
                          <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                              10</button>
                            <div className="dropdown-menu">
                              <button type="button" className="dropdown-item">10</button>
                              <button type="button" className="dropdown-item">20</button>
                              <button type="button" className="dropdown-item">30</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        activePage={data.active}
                        itemsCountPerPage={10}
                        totalItemsCount={10}
                        pageRangeDisplayed={1}
                        onChange={data.handlePageChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="sendToVender3Modal" tabIndex="-1" role="dialog" aria-labelledby="sendToVender3Lable" aria-hidden="true">
          <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">Action</h6>
                <h5 className="modal-title text-uppercase text-white" id="sendToVender3Lable"> ksdjksjaksn-2</h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="mb-2 white-card info-box pm-15 py-3">
                  <div className="row">
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Traveller name</p>
                      <p className="text-capitalize font-weight-bold mb-3 ">marilyn pierce</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Date of birth</p>
                      <p className="text-capitalize font-weight-bold mb-3">26-03-1974</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Mobile</p>
                      <p className="text-capitalize font-weight-bold mb-3 ">+918888888888</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Booked by</p>
                      <p className="text-capitalize font-weight-bold">Nicolas Richards</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Email Id</p>
                      <p className="text-capitalize font-weight-bold">NicolasRichards@gmail.com</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Mobile Number</p>
                      <p className="text-capitalize font-weight-bold">+918885555888</p>
                    </div>
                  </div>
                </div>
                <div className="white-card info-box pm-15 py-3 mt-2">
                  <div className="row">
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Base fair</p>
                      <p className="text-capitalize font-weight-bold">2,190.00</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Total tax</p>
                      <p className="text-capitalize font-weight-bold">2,200.00</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Total fair</p>
                      <p className="text-capitalize font-weight-bold">4,390.00</p>
                    </div>
                  </div>
                </div>
                <div className="white-card">
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="text-uppercase font-weight-bold mb-3">Bangalore - Chennai</h6>
                      <div className="info-box pm-15">
                        <p className="lighter-p text-capitalize">Boarding Point</p>
                        <p className="text-capitalize font-weight-bold mb-3">Service Road, Bhoopsandra, Sanjaynagar, Bengaluru, Karnataka 560024</p>

                        <p className="lighter-p text-capitalize">Dropping Point</p>
                        <p className="text-capitalize font-weight-bold mb-3">Chennai</p>

                        <p className="lighter-p text-capitalize">Seat</p>
                        <p className="font-weight-bold">1R</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h6 className="text-uppercase font-weight-bold mb-3">Chennai - Bangalore</h6>
                      <div className="info-box pm-15">
                        <p className="lighter-p text-capitalize">Boarding Point</p>
                        <p className="text-capitalize font-weight-bold mb-3">Jawaharlal Neharu Salai, Chennai, Tamil Nadu 560024</p>

                        <p className="lighter-p text-capitalize">Dropping Point</p>
                        <p className="text-capitalize font-weight-bold mb-3">Hebbal</p>

                        <p className="lighter-p text-capitalize">Seat</p>
                        <p className="font-weight-bold">2L</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group custom labelup mt-2">
                      <label>Fair Currency</label>
                      <div className="dropdown">
                        <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                          INR
                                            </button>
                        <div className="dropdown-menu">
                          <button className="dropdown-item">INR</button>
                          <button className="dropdown-item">INR</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group custom labelup mt-2">
                      <label>Payment Type</label>
                      <div className="dropdown">
                        <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                          FLOAD
                                            </button>
                        <div className="dropdown-menu">
                          <button className="dropdown-item">FLOAD</button>
                          <button className="dropdown-item">FLOAD</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
                <div className="modal-last btn-group-margin">
                  <button type="button" className="btn mb-0" data-dismiss="modal">Cancel</button>
                  <NavLink to="/mid-office/invoice" target="_blank" className="btn  mb-0">Save
                            <i className="icon ion-md-arrow-dropright icon-margin"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default BusBookingRequestPage;
