import React, { Component } from 'react';
import { Input, Select } from "./_common/_forms";
import SimpleReactValidator from "simple-react-validator";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { companyAdmin } from '../_services/companyAdmin';
import { toast } from 'react-toastify';
import { getValue } from './_common/loadash';
import moment from 'moment';


export default class SAP extends Component {
    constructor(props, context) {
        super(props, context);
        this.validator = new SimpleReactValidator();
        this.state = {
            isLoading: false,
            headerField: {
                DocumentType: 'HT',
                ReferenceNo: '',
                DocumentDate: '',
                PostingDate: '',
                CompanyCode: '',
                UserName: '',
                VoucherType: 'HT',
            },
            creditField: {
                EmployeeOrVendor: '',
                EmployeeType: '',
                CreditDocumentCurrency: [],
                CreditLocalCurrency: [],
                CreditAmountInDocumentCurrency: '',
                CreditAmountInLocalCurrency: '',
                NarrationText: ''
            },
            entryField: [
                {
                    DebitGLCode: '',
                    DebitDocumentCurrency: '',
                    DebitLocalCurrency: '',
                    CostCentre: '',
                    DebitAmountInDocumentCurrency: '',
                    DebitAmountInLocalCurrency: '',
                    NarrationText: ''
                }
            ]

        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            headerField: {
                ...prevState.headerField,
                [name]: value
            }
        }))
    }
    handleChange1 = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            creditField: {
                ...prevState.creditField,
                [name]: value
            }
        }))
    }
    handleChange2 = (e, index) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            entryField: prevState.entryField.map(
                (el, key) => key === index ? { ...el, [name]: value } : el
            )
        }))
    }

    handleChangeDate = (e, name) => {
        // const {name,value} = e.target;
        this.setState((prevState) => ({
            headerField: {
                ...prevState.headerField,
                [name]: e
            }
        }))
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.validator.allValid()) {
                this.setState({ isLoading: true })
                let req = {
                    headerField: this.state.headerField,
                    creditField: this.state.creditField,
                    entryField: this.state.entryField
                }
                console.log(this.state)
                this.state.headerField.DocumentDate = moment(this.state.headerField.DocumentDate).format("DD-MM-YYYY")
                this.state.headerField.PostingDate = moment(this.state.headerField.PostingDate).format("DD-MM-YYYY")
                this.state.creditField.CreditDocumentCurrency = [this.state.creditField.CreditDocumentCurrency]
                this.state.creditField.CreditLocalCurrency = [this.state.creditField.CreditLocalCurrency]

                let resp = await companyAdmin.sapAPI(req);
                if (resp) {
                    console.log(resp)
                    this.setState({ isLoading: false })
                    toast.success("Added successfully")
                    this.reset()
                } else {
                    this.setState({ isLoading: false })
                }
            } else {
                this.validator.showMessages();
                this.setState({ isLoading: false })
                this.forceUpdate();
            }
        } catch (error) {
            toast.error(getValue(error, `response.data.error`, 'Something went wrong,Please try after some time'))
            this.setState({ isLoading: false })
        }
    }

    reset = () => {
        this.setState((prevState) => ({
            headerField: {
                ...prevState.headerField,
                DocumentType: 'HT',
                ReferenceNo: '',
                DocumentDate: '',
                PostingDate: '',
                CompanyCode: '',
                UserName: '',
                VoucherType: 'HT',
            }
        }))
        this.setState((prevState) => ({
            creditField: {
                ...prevState.creditField,
                EmployeeOrVendor: '',
                EmployeeType: '',
                CreditDocumentCurrency: [],
                CreditLocalCurrency: [],
                CreditAmountInDocumentCurrency: '',
                CreditAmountInLocalCurrency: '',
                NarrationText: ''
            }
        }))
        this.setState(prevState => ({
            entryField: prevState.entryField.map(
                (el, key) => key === 0 ? {
                    ...el,
                    DebitGLCode: '',
                    DebitDocumentCurrency: '',
                    DebitLocalCurrency: '',
                    CostCentre: '',
                    DebitAmountInDocumentCurrency: '',
                    DebitAmountInLocalCurrency: '',
                    NarrationText: ''
                } : el
            )
        }))
    }

    addEntryField = () => {
        this.state.entryField.push({
            DebitGLCode: '',
            DebitDocumentCurrency: '',
            DebitLocalCurrency: '',
            CostCentre: '',
            DebitAmountInDocumentCurrency: '',
            DebitAmountInLocalCurrency: '',
            NarrationText: ''
        })
        this.setState({ ...this.state })
    }

    removeEntryField = (key) => {
        let filteredFields = this.state.entryField.filter((_item, index) => index !== key)
        this.setState({
            entryField: filteredFields
        })
    }
    render() {
        console.log(this.state)
        return (
            <div className="ProjectPage">
                <div className="page-header">
                    <h6 className="heading">SAP</h6>
                </div>
                <div className="page-content">
                    <div className="py-padding">
                        <div className="table-container">
                            <div className="profile-card ">
                                <div className="details">
                                    <h5>Header Field</h5>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="DocumentType"
                                                value={this.state.headerField.DocumentType}
                                                controlFunc={this.handleChange}
                                                label="Document Type"
                                                error={this.validator.message(
                                                    "Document Type",
                                                    this.state.headerField.DocumentType,
                                                    "required"
                                                )}
                                                disabled={true}
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group custom labelup">
                                                <label>
                                                    Document Date (approver’s date) <span className="text-danger">*</span>
                                                </label>
                                                <DayPickerInput
                                                    format="DD MMM YYYY"
                                                    name="DocumentDate"
                                                    onDayChange={e => this.handleChangeDate(e, "DocumentDate")}
                                                    value={this.state.headerField.DocumentDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group custom labelup">
                                                <label>
                                                    Posted Date (verified date)<span className="text-danger">*</span>
                                                </label>
                                                <DayPickerInput
                                                    format="DD MMM YYYY"
                                                    name="PostingDate"
                                                    onDayChange={e => this.handleChangeDate(e, "PostingDate")}
                                                    value={this.state.headerField.PostingDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="ReferenceNo"
                                                value={this.state.headerField.ReferenceNo}
                                                controlFunc={this.handleChange}
                                                label="Reference No (reportId)"
                                                error={this.validator.message(
                                                    "Reference No",
                                                    this.state.headerField.ReferenceNo,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="CompanyCode"
                                                value={this.state.headerField.CompanyCode}
                                                controlFunc={this.handleChange}
                                                label="Company Code"
                                                error={this.validator.message(
                                                    "Company Code",
                                                    this.state.headerField.CompanyCode,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="UserName"
                                                value={this.state.headerField.UserName}
                                                controlFunc={this.handleChange}
                                                label="User Name (verified by email)"
                                                // className="col-md-3"
                                                error={this.validator.message(
                                                    "Reference No",
                                                    this.state.headerField.UserName,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="VoucherType"
                                                value={this.state.headerField.VoucherType}
                                                controlFunc={this.handleChange}
                                                label="Voucher Type"
                                                error={this.validator.message(
                                                    "Voucher Type",
                                                    this.state.headerField.VoucherType,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-card ">
                                <div className="details">
                                    <h5>Credit Field</h5>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="EmployeeOrVendor"
                                                value={this.state.creditField.EmployeeOrVendor}
                                                controlFunc={this.handleChange1}
                                                label="Employee or Vendor (EMP_ID)"
                                                error={this.validator.message(
                                                    "Employee or Vendor",
                                                    this.state.creditField.EmployeeOrVendor,
                                                    "required"
                                                )}
                                                disabled
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="EmployeeType"
                                                value={this.state.creditField.EmployeeType}
                                                controlFunc={this.handleChange1}
                                                label="Employee Type"
                                                error={this.validator.message(
                                                    "Employee Type",
                                                    this.state.creditField.EmployeeType,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="CreditDocumentCurrency"
                                                value={this.state.creditField.CreditDocumentCurrency}
                                                controlFunc={this.handleChange1}
                                                label="Credit Document Currency"
                                                error={this.validator.message(
                                                    "Credit Document Currency",
                                                    this.state.creditField.CreditDocumentCurrency,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="CreditLocalCurrency"
                                                value={this.state.creditField.CreditLocalCurrency}
                                                controlFunc={this.handleChange1}
                                                label="Credit Local Currency"
                                                // className="col-md-3"
                                                error={this.validator.message(
                                                    "Credit Local Currency",
                                                    this.state.creditField.CreditLocalCurrency,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="CreditAmountInDocumentCurrency"
                                                value={this.state.creditField.CreditAmountInDocumentCurrency}
                                                controlFunc={this.handleChange1}
                                                label="CreditAmount In Document Currency"
                                                error={this.validator.message(
                                                    "CreditAmount In Document Currency",
                                                    this.state.creditField.CreditAmountInDocumentCurrency,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="CreditAmountInLocalCurrency"
                                                value={this.state.creditField.CreditAmountInLocalCurrency}
                                                controlFunc={this.handleChange1}
                                                label="CreditAmount In Local Currency"
                                                error={this.validator.message(
                                                    "CreditAmount In Local Currency",
                                                    this.state.creditField.CreditAmountInLocalCurrency,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                name="NarrationText"
                                                value={this.state.creditField.NarrationText}
                                                controlFunc={this.handleChange1}
                                                label="Narration Text"
                                                error={this.validator.message(
                                                    "Narration Text",
                                                    this.state.creditField.NarrationText,
                                                    "required"
                                                )}
                                            ></Input>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="profile-card ">
                                <div className="details">
                                    <h5>Entry Field</h5>
                                    <br></br>
                                    {
                                        this.state.entryField.length !== 0 ? this.state.entryField.map((field, index) => {
                                            return (
                                                <>
                                                    <hr />
                                                    <button className="btn" disabled={index === 0 ? true : false} onClick={() => this.removeEntryField(index)}>Remove Entry Field</button>
                                                    <br />
                                                    <div key={index} className="row">
                                                        <div className="col-md-3">
                                                            <Input
                                                                type="text"
                                                                name="DebitGLCode"
                                                                value={field.DebitGLCode}
                                                                controlFunc={(e) => this.handleChange2(e, index)}
                                                                label="Debit GLCode (GL code)"
                                                                error={this.validator.message(
                                                                    "Debit GLCode",
                                                                    field.DebitGLCode,
                                                                    "required"
                                                                )}
                                                                disabled
                                                            ></Input>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Input
                                                                type="text"
                                                                name="DebitDocumentCurrency"
                                                                value={field.DebitDocumentCurrency}
                                                                controlFunc={(e) => this.handleChange2(e, index)}
                                                                label="Debit Document Currency (entry currency)"
                                                                error={this.validator.message(
                                                                    "Debit Document Currency",
                                                                    field.DebitDocumentCurrency,
                                                                    "required"
                                                                )}
                                                            ></Input>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Input
                                                                type="text"
                                                                name="DebitLocalCurrency"
                                                                value={field.DebitLocalCurrency}
                                                                controlFunc={(e) => this.handleChange2(e, index)}
                                                                label="Debit Local Currency (entry base currency)"
                                                                error={this.validator.message(
                                                                    "Debit Local Currency",
                                                                    field.DebitLocalCurrency,
                                                                    "required"
                                                                )}
                                                            ></Input>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Input
                                                                type="text"
                                                                name="CostCentre"
                                                                value={field.CostCentre}
                                                                controlFunc={(e) => this.handleChange2(e, index)}
                                                                label="Cost Centre"
                                                                error={this.validator.message(
                                                                    "Cost Centre",
                                                                    field.CostCentre,
                                                                    "required"
                                                                )}
                                                            ></Input>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Input
                                                                type="text"
                                                                name="DebitAmountInDocumentCurrency"
                                                                value={field.DebitAmountInDocumentCurrency}
                                                                controlFunc={(e) => this.handleChange2(e, index)}
                                                                label="Debit Amount InDocument Currency"
                                                                error={this.validator.message(
                                                                    "Debit Amount InDocument Currency",
                                                                    field.DebitAmountInDocumentCurrency,
                                                                    "required"
                                                                )}
                                                            ></Input>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Input
                                                                type="text"
                                                                name="DebitAmountInLocalCurrency"
                                                                value={field.DebitAmountInLocalCurrency}
                                                                controlFunc={(e) => this.handleChange2(e, index)}
                                                                label="Debit Amount In Local Currency"
                                                                error={this.validator.message(
                                                                    "Debit Amount In Local Currency",
                                                                    field.DebitAmountInLocalCurrency,
                                                                    "required"
                                                                )}
                                                            ></Input>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Input
                                                                type="text"
                                                                name="NarrationText"
                                                                value={field.NarrationText}
                                                                controlFunc={(e) => this.handleChange2(e, index)}
                                                                label="Narration Text(Report Name)"
                                                                error={this.validator.message(
                                                                    "Narration Text",
                                                                    field.NarrationText,
                                                                    "required"
                                                                )}
                                                            ></Input>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }) : ""
                                    }
                                    <button className="btn" onClick={this.addEntryField}>Add Entry Field</button> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <button className="btn" onClick={this.handleSubmit}>{!this.state.isLoading ? "Submit" : "Please wait.."}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}