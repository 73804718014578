import React, { Component } from "react";
import TravelPage from "./travel-html";
import { companyEmailTravelServices } from "../../../../_services";
import { companyEmailServices } from "../../../../_services";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

class Travel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage["440a28"],
      serviceModule : [],
      dataList : [],
      serviceModuleId : "",
      loading : true,
      totalCount : '',
      pageItemsList : [{ items: 10 }, { items: 20 }, { items: 30 }],
      selectedPage : 1,
      dataPerPage : 10
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getServiceId();
  }

  getServiceId = async () => {
    const res = await companyEmailServices.getServiceModuleId();
      this.setState({
        serviceModule : res ? res.result : [],
        serviceModuleId : res.result.find(o => o.serviceModuleName === "Travel").id
      })
      localStorage.setItem("serviceModuleId", this.state.serviceModuleId);
      this.getTemplateLists();
    }

    getTemplateLists = async (id) => {
      if (this.state.search) {
        let data = {
          companyId : this.state.companyId,
          serviceModuleId : id ? id : this.state.serviceModuleId,
          limit: this.state.dataPerPage,
          page: this.state.selectedPage,
          search : this.state.search
        }
        const res = await companyEmailTravelServices.getFilterEmailTemplate(data);
        this.setState({
          dataList : res ? res.result : [],
          totalCount : res ? res.count : '',
          loading : false
        })
    } else {
        let data = {
          companyId : this.state.companyId,
          serviceModuleId : id ? id : this.state.serviceModuleId,
          limit: this.state.dataPerPage,
          page: this.state.selectedPage
        }
        const res = await companyEmailTravelServices.getEmailTemplate(data);
        this.setState({
          dataList : res ? res.result : [],
          totalCount : res ? res.count : '',
          loading : false
        })
      }
    }

  inputChangeHandler = e => {
    this.setState({
      search : e.target.value
    }, () => {this.getTemplateLists();})

  };

  handleItemChange = limit => {
    this.setState({
      selectedPage : 1,
      dataPerPage : limit["items"]
    }, () => {this.getTemplateLists();})

  };

  pageClickHandler = page => {
    this.setState({
      selectedPage : page
    }, () => {this.getTemplateLists();})
  };

  serviceModuleHandler= (serviceModuleId) =>{
    this.setState({
      serviceModuleId : serviceModuleId
    }, () => {
      localStorage.setItem("serviceModuleId", this.state.serviceModuleId);
    })
    this.getTemplateLists(serviceModuleId);
  }

  statusHandler = async (template) => {
    let data = {
      companyId: this.companyId,
      serviceModuleId: this.serviceModuleId,
      activeStatus: template.activeStatus
    };
    var res = await companyEmailServices.updateStatusEmailTemplate(
      template.id,
      data
    );
    if (res) {
      toast.success(res.message);
      let dataList = [...this.state.dataList];
      dataList[
        dataList.findIndex(item => item.id === template.id)
      ].activeStatus = !template.activeStatus;
      this.setState({ dataList });
    }
  }

  render() {
    let navBar = <div className="admin-tabs">
      <ul className="nav nav-tabs px-0" >
        {this.state.serviceModule && this.state.serviceModule.map((item)=> (
          <li key={item.id}>
            <NavLink
              className={this.state.serviceModuleId === item.id ? "show active" : "show"}
              to={`#`}
              onClick={() => this.serviceModuleHandler(item.id)}
            >{item.serviceModuleName}
            </NavLink>
          </li>
          ))}
      </ul>
    </div>


    return (
      <div>
        <TravelPage
          {...this.state}
          navBar = {navBar}
          inputChangeHandler={this.inputChangeHandler}
          handleItemChange={this.handleItemChange}
          pageClickHandler={this.pageClickHandler}
          statusHandler={this.statusHandler}
        />
      </div>
    );
  }
}
export default Travel;
