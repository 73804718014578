import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { midOfficeServices } from "../../_services";
import { Select } from "../_common/_forms";

class FlightBookingRequestPage extends Component {

  state = {
    data: [],
    totalCount: null,
    search: "",
    limit: 10,
    page: 1,
    pageItemsList: [{ items: 10 }, { items: 20 }, { items: 30 }, { items: 40 }],
    isLoading: true,
    clientList: [],
    clientName: 'Filter items by company',
    clientType: ''
  }

  componentDidMount() {
    let data = {
      bookingType: this.props.match.params.bookingType,
      statusType: this.props.match.params.statusType,
      isInternational: this.props.match.params.find,
      search: this.state.search,
      limit: this.state.limit,
      page: this.state.page
    }
    this.getFlightBookingRequest(data);
    this.getClientList();
  }

  getFlightBookingRequest = async (data) => {
    var res = await midOfficeServices.getFlightBookingRequest(data)
    if (res) {
      this.setState({ isLoading: false });
      this.setState({
        data: res.result,
        totalCount: res.count,
        search: this.state.search,
        limit: this.state.limit,
        page: this.state.page,

      })
    } else {
      this.setState({ isLoading: false });
      this.setState({ data: [], totalCount: null })
    }
  }
  getClientList = async () => {
    let res = await midOfficeServices.getClientList();
    if (res) {
      console.log(res);
      this.setState({ clientList: res.result });
    }
  }
  changeClient = (val, field) => {
    this.setState({ clientName: val.companyName, [field]: val.id });
    let data = {
      bookingType: this.props.match.params.bookingType,
      statusType: this.props.match.params.statusType,
      isInternational: this.props.match.params.isInternational === "true" ? true : false,
      search: this.state.search,
      limit: this.state.limit,
      page: this.state.page,
      companyId: val.id,
    }
    console.log(data);
    this.getFlightBookingRequest(data)

  }

  searchHandler = (e) => {
    if (e.target.value !== '') {
      let data = {
        bookingType: this.props.match.params.bookingType,
        statusType: this.props.match.params.statusType,
        isInternational: this.props.match.params.isInternational === "true" ? true : false,
        search: e.target.value,
        limit: this.state.limit,
        page: this.state.page
      }
      console.log(data);
      this.getFlightBookingRequest(data)
    }
    else {
      let data = {
        bookingType: this.props.match.params.bookingType,
        statusType: this.props.match.params.statusType,
        isInternational: this.props.match.params.find,
        search: this.state.search,
        limit: this.state.limit,
        page: this.state.page
      }
      this.getFlightBookingRequest(data)
    }
  }

  handleItemChange = limit => {
    this.setState({ limit: limit.items });
    let data = {
      bookingType: this.props.match.params.statusType,
      statusType: this.props.match.params.bookingType,
      isInternational: this.props.match.params.isInternational === "true" ? true : false,
      search: this.state.search,
      limit: limit.items,
      page: this.state.page
    }
    this.getFlightBookingRequest(data);
  };
  handlePageChange = (pageNumber) => {
    let data = {
      bookingType: this.props.match.params.bookingType,
      statusType: this.props.match.params.statusType,
      isInternational: this.props.match.params.isInternational === "true" ? true : false,
      search: this.state.search,
      limit: this.state.limit,
      page: pageNumber
    }
    this.setState({ page: pageNumber });
    console.log(pageNumber);
    this.getFlightBookingRequest(data);
  }


  render() {
    console.log(this.state.page);
    console.log(this.state.clientList);
    let list = this.state.data.length > 0 ? this.state.data.map((data, index) => {
      console.log(data.id);
      return <tr key={index}>
        <td className="text-primary"><NavLink to={`/mid-office/trip-id/${data.id}`}>{data.tripId}</NavLink></td>
        <td>{data.travellerName}</td>
        <td>{data.travellingDate}</td>
        <td className="text-primary"><NavLink to={`/mid-office/trip-id/${data.id}`}>
          {data.rbd.map((rbd, i) => (i === 0) ? '' + rbd : ', ' + rbd)}
        </NavLink></td>
        <td> {data.airLine.map((airline, i) => (i === 0) ? '' + airline : ', ' + airline)}</td>
        <td>{(data.flightData !== undefined) ? data.flightData.flights.map((flight, i) => <p key={i}>{flight.flight_from + " - " + flight.flight_to}</p>
        ) : ""}</td>
        <td>{data.paymentType}</td>
        <td>{data.company}</td>
        <td>{data.fareType}</td>
      </tr>
    }) : <tr><td colSpan="9" className="text-center">No data found</td></tr>
    return (
      <div>
        <div className="page-header">
          <div class="resp-toggle-menu">
            <i className="material-icons">menu</i>
          </div>
          <h6 className="heading">Flight Booking Request</h6>
        </div>
        <div className="page-content">
          <div className="admin-tabs">
            <div className="py-padding">
              <div className="table-container">
                <form className="">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group ">
                        {this.state.clientList && (
                          <Select
                            placeholder={this.state.clientName}
                            keyName={"companyName"}
                            options={this.state.clientList}
                            controlFunc={e =>
                              this.changeClient(e, "clientType")
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-md-3 offset-5">
                      <div className="search-custom">
                        <input type="text" className="form-control" placeholder="Search"
                          onChange={(e) => this.searchHandler(e)} />
                        <span><i className="material-icons align-middle">search</i></span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  {
                    (this.state.isLoading) ?
                      <div className="mx-auto text-center">
                        <img
                          width="200"
                          className="img-fluid "
                          alt="no flight"
                          src="/assets/images/loader.gif"
                        />
                        <p>Loading please wait...</p>
                      </div>
                      :
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>CURIO REF NO</th>
                            <th>TRAVELER NAME</th>
                            <th>DATE</th>
                            <th>RBD</th>
                            <th>AIRLINE</th>
                            <th>SECTOR</th>
                            <th>PAYMENT TYPE</th>
                            <th>COMPANY</th>
                            <th>FARE TYPE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list}
                        </tbody>
                      </table>
                  }
                </div>
                <div className="table-footer">
                  <div className="row">
                    <div className="col-md-3">
                      {this.state.totalCount && (
                        <div className="page-count">
                          <div className="text">Items</div>
                          <div className="count">
                            <div className="dropdown">
                              <Select
                                placeholder={this.state.limit}
                                keyName={"items"}
                                options={this.state.pageItemsList}
                                controlFunc={e => this.handleItemChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-9 text-right">
                      {this.state.totalCount &&
                        <Pagination
                          activePage={this.state.page}
                          itemsCountPerPage={this.state.limit}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={3}
                          onChange={(e) => this.handlePageChange(e)} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FlightBookingRequestPage;