import React, { Component } from 'react';
import { TestHistoryServices, testMidOfficeServices } from "../../../_services";
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import UserDetailsPage from './userDetails';
//import FlightDetails from '../mid-office/flightDetails';
import FlightDetails from '../../test/mid-office/flightDetails';
// import HotelDetails from '../mid-office/hotelDetails';
import HotelDetails from '../../test/mid-office/hotelDetails';
class HistoryDetailsPage extends Component {
  state = {
    data: [],
    type: '',
    popUp: false,
    isLoading: true,
    pnrDetails: [],
    savedPassVisa: {}
  }
  componentDidMount = () => {
    let data = {
      id: this.props.match.params.id,
      type: this.props.match.params.type
    }
    this.setState({ type: data.type });
    this.getTripData(data.id);
    if (data.type === 'FLIGHT') {
      this.getPnrData(data.id);
    }
  }
  getTripData = async (id) => {
    this.setState({ isLoading: true });
    let res = await TestHistoryServices.getTripData(id);
    if (res) {
      this.setState({ data: res.result, isLoading: false, userId: res.result.userId })
      this.getUserDetails();
      this.getChosenPassportVisa();
    }
  }
  enablePopUp = () => {
    console.log("Hi");
    this.setState({ popUp: true })
  }
  closePopUp = () => {
    this.setState({ popUp: false })
  }
  getUserDetails = async () => {
    let res = await testMidOfficeServices.getUserDetails(this.state.userId);
    if (res) {
      this.setState({ userDetails: res.result })
    }
  }
  getPnrData = async (id) => {
    var res = await testMidOfficeServices.getPnrdata(id)
    if (res) {
      console.log(res.result);
      this.setState({ pnrDetails: res.result });
    }
  }
  getChosenPassportVisa = async () => {
    let res = await testMidOfficeServices.getChosenPassportVisa(this.state.data.tripId, this.state.data.userId);
    if (res) {
      this.setState({ savedPassVisa: res.result });
    }
  }

  render() {
    const { data, userDetails, pnrData } = this.state;
    console.log(data);
    console.log(this.state.savedPassVisa);

    var totalBaseFare = 0;
    data.flightData && data.flightData.flights.forEach((flight, i) => {
      totalBaseFare += flight.fareContent.fare.flight_base_price
    });
    var totalTaxOfAll = 0;
    data.flightData && data.flightData.flights.forEach((flight, i) => {
      totalTaxOfAll += flight.fareContent.fare.flight_total_tax
    });
    var totalPrice = 0;
    data.flightData && data.flightData.flights.forEach((flight, i) => {
      totalPrice += flight.fareContent.fare.flight_total_price
    });
    return (
      <div className="ProjectPage">
        <div className="page-header">
          <h6 className="heading">Trip Details</h6>
        </div>
        <div className="page-content">
          <div className="p-padding">
            {this.state.type === 'FLIGHT' &&
              <div className="traveller-details">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <td>TRAVELER NAME <br /><b>{data.travellerName}</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right">
                          <button className="btn" onClick={(e) => this.enablePopUp(e)} >User Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td>BOOKED BY <br /><b>{data.travellerName}</b></td>
                        <td>EMAIL ID <br /><b>{data.email}</b></td>
                        <td>MOBILE NUMBER <br /><b>--</b></td>
                        <td>SEAT PREFERENCE <br /><b>{data.seatPreference ? data.seatPreference : '--'}</b></td>
                        <td>MEAL PREFERENCE <br /><b>{data.mealRequest}</b></td>
                      </tr>
                      {(data.flightData != undefined && Array.isArray(data.flightData.flights[0].fareContent)) ?
                        <tr>
                          <td>BASE FARE <br />
                            <b>
                              <NumberFormat
                                value={(Math.floor(data.flightData.flights[0].fare.flight_base_price * 1000) / 1000)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={data.baseCurrency + ' '} />
                            </b>
                          </td>
                          <td>TOTAL TAX <br />
                            <b>
                              <NumberFormat
                                value={(Math.floor(data.flightData.flights[0].fare.flight_total_tax * 1000) / 1000)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={data.baseCurrency + ' '}
                              />
                            </b>
                          </td>
                          <td>TOTAL FARE <br />
                            <b>
                              <NumberFormat
                                value={(Math.floor(data.flightData.flights[0].fare.flight_total_price * 1000) / 1000)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={data.baseCurrency + ' '} />
                            </b>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        :
                        <tr>
                          <td>BASE FARE <br />
                            <b>
                              <NumberFormat
                                value={(Math.floor(totalBaseFare * 1000) / 1000)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={data.baseCurrency + ''} />
                            </b>
                          </td>
                          <td>TOTAL TAX <br />
                            <b>
                              <NumberFormat
                                value={(Math.floor(totalTaxOfAll * 1000) / 1000)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={data.baseCurrency + ' '} />
                            </b>
                          </td>
                          <td>TOTAL FARE <br />
                            <b>
                              <NumberFormat
                                value={(Math.floor(totalPrice * 1000) / 1000)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={data.baseCurrency + ' '} />
                            </b>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            }
            {this.state.type === 'HOTEL' &&
              <div className="traveller-details">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <td>TRAVELER NAME <br /><b>{data.travellerName}</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right">
                          <button className="btn" onClick={(e) => this.enablePopUp(e)} >User Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td>BOOKED BY <br /><b>{data.travellerName}</b></td>
                        <td>EMAIL ID <br /><b>{data.email}</b></td>
                        <td>MOBILE NUMBER <br /><b>--</b></td>
                        <td></td>
                        <td>MEAL PREFERENCE <br /><b>{data.mealRequest ? data.mealRequest : '--'}</b></td>
                      </tr>
                      <tr>
                        <td>AMOUNT <br />
                          <b>
                            <NumberFormat
                              value={(data.hotelData != undefined) ? (Math.floor(data.hotelData.hotel_room_info.hotel_room_price * 1000) / 1000) : ""} displayType={'text'} thousandSeparator={true} prefix={data.hotelData ? data.hotelData.hotel_room_info.hotel_room_rack_rate.currency + ' ' : ''} />
                          </b>
                        </td>
                        <td>TOTAL TAX <br />
                          <b>
                            <NumberFormat value={(data.hotelData && data.hotelData.hotel_room_info.hotel_taxes != undefined) ? (Math.floor(data.hotelData.hotel_room_info.hotel_taxes * 1000) / 1000) : "0"} displayType={'text'} thousandSeparator={true} prefix={data.hotelData ? data.hotelData.hotel_room_info.hotel_room_rack_rate.currency + ' ' : ''} />
                          </b>
                        </td>
                        <td>TOTAL AMOUNT <br />
                          <b>
                            <NumberFormat value={(data.hotelData != undefined) ? (Math.floor(data.hotelData.hotel_room_info.hotel_total_price * 1000) / 1000) + ' ' + (data.hotelData.hotel_currency_code ? data.hotelData.hotel_currency_code : data.hotelData.hotel_room_info.hotel_currency_code) : ""} displayType={'text'} thousandSeparator={true} prefix={data.hotelData ? data.hotelData.hotel_room_info.hotel_room_rack_rate.currency + ' ' : ''} />
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            }
            <div className="py-padding">
              <div className="accordion" id="accordionExample">
                {this.state.type === 'FLIGHT' && (this.state.isLoading ?
                  <div className="mx-auto text-center">
                    <img
                      width="200"
                      className="img-fluid "
                      alt="no flight"
                      src="/assets/images/loader.gif"
                    />
                    <p>Loading please wait...</p>
                  </div>
                  : <FlightDetails
                    data={data}
                    eTicket={true}
                    invoice={true}
                    pnrDetails={this.state.pnrDetails} />)}
              </div>
            </div>
            <div className="">
              <div className="accordion" id="accordionExample">
                {(this.state.isLoading) && this.state.type === 'HOTEL' ?
                  <div className="mx-auto text-center">
                    <img
                      width="200"
                      className="img-fluid "
                      alt="no flight"
                      src="/assets/images/loader.gif"
                    />
                    <p>Loading please wait...</p>
                  </div>
                  :
                  (this.state.type === 'HOTEL') &&
                  <HotelDetails
                    data={data}
                    eTicket={true}
                    invoice={true} />
                }
              </div>
            </div>
          </div>
        </div>
        <UserDetailsPage
          popUp={this.state.popUp}
          userDetails={userDetails}
          closePopUp={this.closePopUp}
          savedPassVisa={this.state.savedPassVisa}
        />
      </div>
    );
  }
}

export default HistoryDetailsPage;
