import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from './routes'
import SideMenu from './_common/side-menu';
class HomePage extends Component {
    render() {
        return (
            <div className="holder">
                <Router>
                    <div className="container-fluid p-0">
                        <SideMenu />
                        <div className="wrapper">
                            <Switch>
                                {routes.map((route, key) => {
                                    return route.component ? (
                                        <Route key={key} path={route.path}
                                            exact={route.exact} name={route.name}
                                            children={route.children}
                                            render={props => (
                                                <route.component {...props}
                                                />
                                            )}
                                        />) : (null);
                                },
                                )}
                            </Switch>
                        </div>
                    </div>
                </Router>
            </div>
        );
    }
}

export default HomePage;
