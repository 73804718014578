import React, { Component } from 'react';
import _ from 'lodash';
import Moment from "react-moment";

class CheckListPage extends Component {
    state = {
        checkedArray: [],
    }
    checkListArray = [
        { id: 1, value: "Title and Name of the Traveller" },
        { id: 2, value: "Date of Travel" },
        { id: 3, value: "Ticket Number of Booking Confirmation" },
        { id: 4, value: "E-mail Id updated in the PNR" },
        { id: 5, value: "Fare Validation" },
        { id: 6, value: "Invoice generated correctly with additional services" },
        { id: 7, value: "Mobile Number updated in PNR" },
        { id: 8, value: "GST details updated in booking" },
        { id: 9, value: "Seat Updated" },
        { id: 10, value: "Meal Updated" },
        { id: 11, value: "Date of Birth" },
        { id: 12, value: "Excess Baggage" },
        { id: 13, value: "Mode of payment from Curio" },
        { id: 14, value: "Payment Information from traveller" },
        { id: 15, value: "Other ancillary services requested by traveller and update" },
        { id: 16, value: "Ticket and Invoice generation" },
        { id: 17, value: "Nationality" },
    ];

    checkedItem = []
    handleCheckChange = (item) => {
        const indexCheck = this.checkedItem.indexOf(item);
        console.log(indexCheck);
        if (indexCheck === -1) {
            this.checkedItem.push(item)
        } else {
            this.checkedItem.splice(indexCheck, 1)
        }
        this.setState({ checkedArray: this.checkedItem })
    }

    render() {
        const { checkList, id } = this.props;

        return (
            <div>
                <div className={checkList ? "fixed-pop white-card bg-white fade-in fade-out w-30" : "d-none"}>
                    <div className="inner-div">
                        <h5 className="hotel_info_head bg-white left mb-0 border-bottom"><b>Check List
                            <span className="right px-2" onClick={(e) => this.props.closeCheckList(e)}>
                                &times;</span>
                        </b>
                        </h5>
                        <div className="check-list-opts">
                            <ul>
                                {this.checkListArray.length > 0 ? this.checkListArray.map((item, i) => {
                                    return (
                                        <li key={item.id}>
                                            <div className="form-group mb-1">
                                                <label>
                                                    <input type="checkbox"
                                                        onChange={(e) => this.handleCheckChange(item)}
                                                    />
                                                    <span>&nbsp;{item.value}</span>
                                                </label>
                                            </div>
                                        </li>
                                    )
                                }) : ''}

                            </ul>
                        </div>
                        
                        <div className="clearfix" />

                    </div>
                    <div className="fixed__bottom">
                            <button type="button"
                                disabled={(this.state.checkedArray.length === this.checkListArray.length || this.state.checkAll) ? false : true}
                                className="btn  btn-block"
                                data-toggle="modal" data-target="#markCompleteModal"
                            >Complete</button>
                        </div>
                </div>

                <div className="modal fade" id="markCompleteModal" tabIndex="-1" role="dialog" aria-labelledby="markCompleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-head">
                                <h6 className="modal-subtitle">ACTION</h6>
                                <h5 className="modal-title text-capitalize text-white" id="cancelBookingLabel"> Mark Complete</h5>
                            </div>
                            <div className="modal-middle pm-30">

                                <div className="d-inline-block alert-detail">
                                    <h5>Are you sure you are updated PNR status and</h5>
                                    <h5>want to mark it as complete?</h5>
                                </div>

                                <div className="modal-last btn-group-margin pt-4">
                                    <button type="button" className="btn" data-dismiss="modal">No</button>

                                    <button type="button" className="btn  mb-0" onClick={(e) => this.props.handleMarkComplete(id)} data-dismiss="modal">YES
                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}
export default CheckListPage;