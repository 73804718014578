import React, { Component } from "react";
import FormsPage from "./forms-html";
import { companyAdmin } from "../../../../_services/companyAdmin";
import { masterService } from "../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../_constants";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import { toJS } from "mobx";

class Forms extends Component {
  state = {
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    // Add form header
    formName: "",
    formId: "",
    formHeaderId: null,
    formHeaderName: [],
    selectedFormHeader: "",
    serviceModuleId: localStorage.getItem("serviceModuleId"),
    pageType: "",
    formAccess: "Global",
    selectedForm: [],
    forms: [],
    selectedItem: null,
    formType: "",
    createdForm: null
  };

  validator = new SimpleReactValidator();
  editValidator = new SimpleReactValidator();

  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  // --------------reset state---------------------'
  resetState = () => {
    this.setState({
      formName: "",
      pageType: ""
    }); 
  };
  // ---------------- creating forms --------------------------
  loading = false;
  handleSubmit = event => {
    event.preventDefault();
    console.log(this.state);
    if (this.validator.allValid()) {
      let data = {
        formName: this.state.formName,
        serviceModuleId: this.state.serviceModuleId,
        formHeaderId: this.state.formHeaderId, 
      };
      this.loading = true;
      this.forceUpdate();
      companyAdmin
        .createForms(data)
        .then(res => {
          toast.success(alertConstants.SUCCESS);
          $(".close").click();
          this.setState({
            formName: "",
            pageType: ""
          });
          this.loading = false;
          this.getForms();
        })
        .catch(e => { this.loading = false; this.forceUpdate(); });
    } else {
      this.validator.showMessages();
    }
    this.forceUpdate();
  };

  // ---------------- creating forms --------------------------

  editFormName = form => {
    this.setState({
      formId: form.id,
      formName: form.formName,
      pageType: form.pageType
    });
    this.pageTypeName = form.pageType
  };

  updateForm = () => {
    if (this.editValidator.allValid()) {
      let data = {
        formName: this.state.formName
      };
      companyAdmin
        .editFormDetail(this.state.formId, data)
        .then(res => {
          this.getForms();
          $(".close").click();
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        })
        .catch(e => toast.error(e, alertConstants.ERROR));
    } else {
      this.editValidator.showMessages();
      this.forceUpdate();
    }
  };


  // ---------------- get forms ------------------------------
  getForms = (id = null) => {
    companyAdmin
      .listFormsPLSF(
        this.state.activePage,
        this.state.itemPerPage,
        this.state.serviceModuleId,
        this.state.formHeaderId
      )
      .then(res => {
        this.setState(
          {
            ...this.state,
            forms: res.result,
            totalItemsCount: res.count
          },
          () => { }
        );
      })
      .catch(e => toast.error(e, alertConstants.ERROR));
  };


  // -------------------change status ---------------------------

  handleStatusChange = form => {
    let newStatus = !form.activeStatus;
    let status = {
      activeStatus: JSON.stringify(newStatus)
    };

    companyAdmin.changeFormStatus(form.id, status).then(
      res => {
        newStatus
          ? toast.success(alertConstants.ACTIVATED_SUCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);
        let newItems = this.state.forms.map(forms => {
          if (forms.id === form.id) {
            forms.activeStatus = newStatus;
          }
          return forms;
        });
        this.setState({
          forms: newItems
        });
      },
      err => {
        toast.error(err, alertConstants.ERROR);
      }
    );
  };


  // ---------------- get forms headers -------------------
  getFormHeader = () => {
    companyAdmin.getFormHeader(this.state.serviceModuleId).then(res => {
      this.setState(
        {
          formHeaderName: res.result && res.result,
          selectedFormHeader: res.result && res.result[0].formHeaderName,
          formHeaderId: res.result && res.result[0].id
        },
        () => {
          if (res) { 
            this.getForms();
          }
        }
      );
    });
  };

  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, () => {
      this.getForms();
    });
  };
  handleItemChange = limit => {
    this.setState({ itemPerPage: limit }, () => {
      this.getForms();
    });
  };


  // ---------------- Create form headers -------------------

  handleHeaderButtonClick = (id, name) => {
    if (this.state.formHeaderId !== id) {
      this.setState(
        {
          selectedFormHeader: name,
          formHeaderId: id
        },
        () => {
          this.getForms();
        }
      );
    }
  };

  // ---------------- ------------------- -------------------

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getService()
  } 
  getService = async () => {
    var resp = await masterService.getServiceModuleListFl()
    if (resp) {
      this.listService = resp.result;
      if (localStorage.serviceModule != undefined) {
        this.setState({
          serviceModuleId: localStorage.serviceModule
        })
        this.currentModule = localStorage.serviceModule
      } else {
        this.setState({
          serviceModuleId: resp.result && resp.result[0].id
        })
        this.currentModule = resp.result && resp.result[0].id;
      }
      this.forceUpdate()
      this.getFormHeader();
    }
  }
  currentModule = ''
  changeModule = type => {
    this.setState({
      serviceModuleId: type
    }, () => {
      this.currentModule = type;
      localStorage.setItem('serviceModule', type)
      this.getFormHeader();
    })
  };
  pageTypeName = "Choose Page Type"
  changePageType = (e) => {
    this.pageTypeName = e.formPageTypeName
    this.setState({
      pageType: e.formPageTypeName
    })
    this.forceUpdate()
  }

  render() {
    return (
      <div>
        <FormsPage
          // state
          fullFormData={this.state}
          validator={this.validator}
          editValidator={this.editValidator}
          updateForm={this.updateForm}
          resetState={this.resetState}
          // event handlers
          handlePageChange={this.handlePageChange}
          handleStatusChange={this.handleStatusChange}
          editFormName={this.editFormName}
          handleItemChange={this.handleItemChange}
          pageRangeDisplayed={1}
          formNameHandler={this.formNameHandler}
          handleSubmit={this.handleSubmit}
          setFormType={this.setFormType}
          handleHeaderButtonClick={this.handleHeaderButtonClick}
          toggleFormSelect={this.toggleFormSelect}
          changeModule={this.changeModule}
          loading={this.loading}
          listService={this.listService}
          changePageType={this.changePageType}
          pageTypeList={this.pageTypeList}
          pageTypeName={this.pageTypeName}
          currentModule={this.currentModule}
        />
      </div>
    );
  }
}

export default Forms;
