import ConnectedListsPage from "./connected-lists-html";
import React, { Component } from "react";
import { companyAdmin } from "../../../../_services/companyAdmin";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { alertConstants } from "../../../../_constants";

class ConnectedLists extends Component {
  state = {
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    sort: "asc",
    serviceModuleId: "5c74ed3d77addf3b07acb014",
    companyId: sessionStorage.getItem("companyId"),
    formHeaderId: null,
    formHeaderName: [],
    selectedFormHeader: "",
    isAdmin: true,
    isFormField: false,
    dataTypes: [
      { id: 1, dataType: "integer" },
      { id: 2, dataType: "text" },
      { id: 3, dataType: "connected list" },
      { id: 4, dataType: "dropdown" }
    ],
    permissionCodes: [
      { id: 1, permission: "Read Only" },
      { id: 2, permission: "Write" },
      { id: 3, permission: "Modify" },
      { id: 4, permission: "Full Access" }
    ],

    connectedLists: [],
    listManagements: [],
    customFields: [],
    selectedConnectedList: [],

    selectedList: [],
    connectedListName: null,
    selectedCustomField: [],
    selectedCustomFieldId: [],

    fieldName: null,
    labelName: null,
    dataType: null,
    employeeRole: null,
    employeeAdminRole: null,

    selectedChild: "",
    selectedForm: [],

    parentId: null,
    childrens: [],
    forms: [],
    displayConnectedList: 0
  };

  validator = new SimpleReactValidator();

  handleSubmit = event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let data = {
        fieldName: this.state.fieldName,
        labelName: this.state.labelName,
        isMandatory: this.state.isMandatory,
        dataType: this.state.dataType,
        maxLength: this.state.maxLength
      };
      if (this.props.match.params.id) {
        companyAdmin
          .modifyFields(this.props.match.params.id, data)
          .then(res => {
            toast.success(alertConstants.UPDATED_SUCCESSFULLY);
            this.redirect();
          })
          .catch(e => console.log("error", e));
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  validateFields = () => {
    if (this.validator.allValid()) {
      this.handleDisplayChangeincrement();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleDisplayChangeincrement = () => {
    this.setState(prevState => {
      return {
        displayConnectedList: prevState.displayConnectedList + 1
      };
    });
  };
  handleDisplayChangeDecrement = () => {
    this.setState(prevState => {
      return {
        displayConnectedList: prevState.displayConnectedList - 1
      };
    });
  };

  handleResetDiplay = () => {
    this.setState(prevState => {
      return {
        displayConnectedList: (prevState.displayConnectedList = 0),
        selectedList: [],
        connectedListName: "",
        selectedCustomField: [],
        selectedConnectedList: [],
        fieldName: null,
        labelName: null,
        dataType: null,
        employeeRole: null,
        employeeAdminRole: null,
        selectedChild: "",
        selectedForm: []
      };
    });
    this.connectedListsAre();
  };
  // ------------------Handle Form -------------------------------------------

  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  // ------------------------------------------------------------------
  // ---------------- select datatype -------------------

  handleDataType = dataType => {
    if (this.state.dataType !== dataType) {
      this.setState({
        dataType: dataType
      });
    } else {
      toast.error(alertConstants.ERROR);
    }
  };

  // ---------------- ------------------- -------------------
  // ---------------- select Child -------------------

  handleChild = child => {
    if (this.state.selectedChild !== child) {
      this.setState({
        selectedChild: child
      });
    } else {
      toast.error(alertConstants.ERROR);
    }
  };

  // ---------------- ------------------- -------------------

  // ---------------- select employee permissions -------------------

  handleEmployeePermission = (id, permission) => {
    if (this.state.permissionName !== permission) {
      this.setState({
        ...this.state,
        employeeRole: permission
      });
    } else {
      toast.error(alertConstants.ERROR);
    }
  };

  // ---------------- ------------------- -------------------
  // ---------------- select approver permissions -------------------

  handleApproverPermission = (id, permission) => {
    if (this.state.permissionName !== permission) {
      this.setState({
        ...this.state,
        employeeAdminRole: permission
      });
    } else {
      toast.error(alertConstants.ERROR);
    }
  };

  // ---------------- ------------------- -------------------

  // ---------------- select display -------------------

  handleDisplayType = (id, displayType) => {
    if (this.state.displayType !== displayType) {
      this.setState({
        ...this.state,
        displayType
      });
    } else {
      toast.error(alertConstants.ERROR);
    }
  };

  // ---------------- ------------------- -------------------

  // -------------------------------------------list management-------------------------------------------------

  toggleListManagement = listManagement => {
    let id = listManagement.id;
    let itemSelected = [...this.state.selectedConnectedList];
    let indexSelected = itemSelected.indexOf(listManagement);
    if (indexSelected === -1) {
      itemSelected = [listManagement];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedConnectedList: itemSelected });
  };

  // -------------------change status ---------------------------

  handleConnectedListStatus = connectedList => {
    let newStatus = !connectedList.activeStatus;

    companyAdmin.connectedListStatusChange(connectedList.id, newStatus).then(
      res => {
        newStatus
          ? toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);

        let newItems = this.state.connectedLists.map(connectedLists => {
          if (connectedLists.id === connectedList.id) {
            connectedLists.activeStatus = newStatus;
          }
          return connectedLists;
        });
        this.setState({
          connectedLists: newItems
        });
      },
      err => {
        toast.error(alertConstants.ERROR, err);
      }
    );
  };
  // --------------selected status change handler----------------------------
  handleConnectedListStatusSelected = connectedList => {
    let newStatus = !connectedList[0].activeStatus;
    companyAdmin.connectedListStatusChange(connectedList[0].id, newStatus).then(
      res => {
        newStatus
          ? toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);

        let newItems = this.state.connectedLists.map(connectedLists => {
          if (connectedLists.id === connectedList[0].id) {
            connectedLists.activeStatus = newStatus;
          }
          return connectedLists;
        });
        this.setState({
          connectedLists: newItems
        });
      },
      err => {
        toast.error(alertConstants.ERROR, err);
      }
    );
  };
  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, () => {
      this.connectedListsAre();
    });
  };
  handleItemChange = limit => {
    this.setState({ itemPerPage: limit }, () => {
      this.connectedListsAre();
    });
  };

  // ------------------------------------------------ END OF LIST MANAGEMENT -----------------------------------------------------------

  // -----------------selectedList-----------------------

  toggleListSelect = list => {
    let id = list.id;
    let itemSelected = [...this.state.selectedList];
    let indexSelected = itemSelected.indexOf(list);
    if (indexSelected === -1) {
      itemSelected = [list];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedList: itemSelected, parentId: id }, () => {
      companyAdmin.getChildrens(id, this.state.companyId).then(res => {
        this.setState({
          childrens: res.result
        });
      });
    });
  };

  // -----------------------------------------------------------------

  // -----------------Select selectedCustomField-----------------------

  toggleCustomFieldSelect = field => {
    let id = field.id;
    let itemSelected = [...this.state.selectedCustomField];
    let indexSelected = itemSelected.indexOf(field);
    if (indexSelected === -1) {
      itemSelected = [field];
    } else {
      itemSelected = [];
    }
    this.setState(
      {
        selectedCustomField: itemSelected,
        selectedCustomFieldId: id
      },
      () => {
        companyAdmin
          .getFieldsDetails(this.state.selectedCustomFieldId)
          .then(res => {
            this.setState({
              fieldName: res.result.fieldName,
              labelName: res.result.labelName,
              dataType: res.result.dataType,
              employeeRole: res.result.fieldRolePermissions[0].permissionId,
              employeeAdminRole: res.result.fieldRolePermissions[1].permissionId
            });
          })
          .catch(err => console.log("err", err));
      }
    );
  };

  // -----------------------------------------------------------------
  // -----------------selectedForm------------------------------------

  toggleSelectedForm = form => {
    let id = form.id;
    let itemSelected = [...this.state.selectedForm];
    let indexSelected = itemSelected.indexOf(form);
    if (indexSelected === -1) {
      itemSelected = [form];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedForm: itemSelected });
  };

  // -----------------------------------------------------------------
  // ---------------- get forms headers -------------------
  getFormHeader = () => {
    companyAdmin.getFormHeader(this.state.serviceModuleId).then(res => {
      this.setState(
        {
          formHeaderName: res.result,
          selectedFormHeader: res.result[0].formHeaderName,
          formHeaderId: res.result[0].id
        },
        () => {
          this.connectedListsAre();
          this.getCustomFields();
        }
      );
    });
  };

  // ---------------- Create form headers -------------------

  handleHeaderButtonClick = (id, name) => {
    this.setState(
      {
        selectedFormHeader: name,
        formHeaderId: id
      },
      () => this.connectedListsAre()
    );
  };

  // ---------------- ------------------- -------------------

  // -------------- list  management Lists - p1----------------
  getListManagement = () => {
    companyAdmin.listManagement(this.state.companyId).then(res => {
      this.setState({
        listManagements: res.result
      });
    });
  };
  // -------------- custom fields - p2----------------
  getCustomFields = () => {
    let data = {
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      companyId: this.state.companyId,
      customField: true,
      isAdmin: true,
      isFormField: false,
      page: this.state.activePage,
      limit: this.state.itemPerPage,
      sort: "asc"
    };
    companyAdmin
      .customFields(data)
      .then(res => {
        this.setState({
          customFields: res.result
        });
      })
      .catch(err => console.log("error", err));
  };

  // -------------- select childrens - page 3----------------
  selectChildren = () => {
    companyAdmin
      .getChildrens(this.state.parentId, this.state.companyId)
      .then(res => {
        this.setState({
          childrens: res.result
        });
      });
  };
  // -------------- select forms- page 4----------------

  getForms = () => {
    companyAdmin
      .listFormsPLSF(
        this.state.activePage,
        this.state.itemPerPage,
        this.state.serviceModuleId,
        this.state.formHeaderId
      )
      .then(res => {
        this.setState({
          forms: res.result
        });
      });
  };
  // ------------------------ Create connected list ----------------------------------

  createConnectedList = () => {
    let data = {
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      listManagementId:
        this.state.selectedList.length && this.state.selectedList[0].id,
      fieldId:
        this.state.selectedCustomField.length &&
        this.state.selectedCustomField[0].id,
      listName: this.state.connectedListName,
      labelName: this.state.fieldLabelName,
      fieldName: this.state.fieldName,
      level: this.state.selectedList.length && this.state.selectedList[0].level,
      rolePermission: [
        {
          fieldRolePermissionId: "5c75375940a28c50664f2a72",
          permissionId: "2"
        },
        {
          fieldRolePermissionId: "5c75375940a28c50664f2a74",
          permissionId: "2"
        }
      ]
    };

    companyAdmin
      .createConnectedList(data)
      .then(res => {
        this.setState(
          {
            connectedLists: res.result
          },
          () => this.connectedListsAre()
        );
      })
      .catch(err => console.log("errror", err));
  };

  finishAndReset = () => {
    this.createConnectedList();
    this.handleResetDiplay();
    this.connectedListsAre();
  };

  submitAndContinue = () => {
    this.createConnectedList();
    this.handleDisplayChangeincrement();
    this.handleResetDiplay();
  };

  // ------------------------ List connected list ----------------------------------
  connectedListsAre = () => {
    companyAdmin
      .getConnectedList(
        this.state.activePage,
        this.state.itemPerPage,
        this.state.serviceModuleId,
        this.state.formHeaderId
      )
      .then(res =>
        this.setState({
          connectedLists: res.result,
          totalItemsCount: res.count
        })
      );
  };
  // -------------------------------------
  componentDidMount() {
    this.getFormHeader();
  }

  render() {
    return (
      <ConnectedListsPage
        connectedListData={this.state}
        connectedListsAre={this.connectedListsAre}
        createConnectedList={this.createConnectedList}
        getListManagement={this.getListManagement}
        handleConnectedListStatus={this.handleConnectedListStatus}
        handleConnectedListStatusSelected={
          this.handleConnectedListStatusSelected
        }
        getCustomFields={this.getCustomFields}
        formNameHandler={this.formNameHandler}
        handleDataType={this.handleDataType}
        handleEmployeePermission={this.handleEmployeePermission}
        handleApproverPermission={this.handleApproverPermission}
        handleDisplayType={this.handleDisplayType}
        toggleListManagement={this.toggleListManagement}
        toggleListSelect={this.toggleListSelect}
        toggleCustomFieldSelect={this.toggleCustomFieldSelect}
        toggleSelectedForm={this.toggleSelectedForm}
        handleChild={this.handleChild}
        handlePageChange={this.handlePageChange}
        handleItemChange={this.handleItemChange}
        handleDisplayChange={this.handleDisplayChange}
        handleDisplayChangeincrement={this.handleDisplayChangeincrement}
        handleDisplayChangeDecrement={this.handleDisplayChangeDecrement}
        handleResetDiplay={this.handleResetDiplay}
        validateFields={this.validateFields}
        finishAndReset={this.finishAndReset}
        submitAndContinue={this.submitAndContinue}
        selectChildren={this.selectChildren}
        handleHeaderButtonClick={this.handleHeaderButtonClick}
        validator={this.validator}
      />
    );
  }
}
export default ConnectedLists;
