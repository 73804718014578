import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Input, Select } from '../_common/_forms';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { masterService } from '../../_services';
import { alertConstants } from '../../_constants/alert.constants';

class CreateTemplatePage extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator();
    this.state = {
      id: "",
      travelCategoryRule: "",
      isLLF:false, 
      travelCategoryId: "",
      ruleCondition: [{ name: "",targetKey:null,targetContition:false,targetPolicy:"Choose policy", type: null, dataType: null, dataTypeValue: null, listName: "Type",listNameValue: "Choose item", checkContition:false,listOfItems:[] }]
    };
  }
  policyList = []
  isEdit = false
  componentDidMount() { 
    this.getContitions()
    this.getRules() 
  }
  policy(type){
    this.policyList = []
    masterService.policyList(this.state.travelCategoryId)
    .then(
      data=>{
        if(data.result){
          this.policyList = data.result;
          if(type === 1){ 
            this.state.ruleCondition.map(items =>{ 
              this.policyList.forEach(item =>{
                if(items.targetKey === item.id){  
                  items.targetPolicy = item.travelPolicyKey 
                }
              }) 
            }) 
            this.setState({...this.state})
          } 
          
        }  
      }
    )
  }
  getRules = async () =>{
    var resp = await masterService.getTravelRuleCategoryList()
    if(resp){
      this.travelCategoryIdList = resp.result
    }
    this.forceUpdate()
  }
  detailList = ''
  getContitionalDetails(id) {
    masterService.getListContitionsId(id)
      .then(
        data => {
          if (data !== null) {
            this.detailList = data.result && data.result[0];
            this.updateData(this.detailList)
          }
        }
      )
  }
  updateData = async (data) => {
    let count = data.ruleCondition.length - 1
    for (var i = 0; i < count; i++) {
      this.handleAddShareholder()
    } 
    data.ruleCondition.forEach(async item => {   
        await this.listOfContition.forEach(list => { 
          if (list.id === item.dataType) {  
            item.listName = list.conditionListName 
            if(list.travelPolicyKey === 0){
              item.targetContition = true;
            }
            if(list.conditionType === 0 && list.conditionList.length !== 0){
              item.checkContition = true;
              item.listOfItems = list.conditionList
              list.conditionList.forEach(e=>{ 
                if(e._id === item.dataTypeValue || e.id === item.dataTypeValue){ 
                  if(e._id){
                    item.listNameValue = e.condition  
                  }else{
                    item.listNameValue = e.itemName  
                  }
                }
              })
            }
          }
        })  
    }) 
    this.travelCategoryIdList.forEach(list=>{ 
      if(list.id === data.travelCategoryId){
        this.categoryName = list.travelCategoryName
      }
    })
    this.setState({
      travelCategoryRule: data.travelCategoryRule,
      travelCategoryId: data.travelCategoryId,
      travelPolicyKey:data.travelPolicyKey,
      isLLF:data.isLLF, 
      id: data.id,
      ruleCondition: data.ruleCondition
    })   
    this.policy(1)
     
  }
  listOfContition = []
  getContitions() {
    masterService.getContitions()
      .then(
        data => {
          if (data != null) {
            this.listOfContition = data.result;
            if (this.props.match.params.id !== undefined) {
              this.isEdit = true;
              this.getContitionalDetails(this.props.match.params.id)
            } 
            this.forceUpdate()
          }
        } 
      )
  }
  handleNameChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleShareholderNameChange = idx => evt => {
    const newShareholders = this.state.ruleCondition.map((ruleCondition, sidx) => {
      if (idx !== sidx) return ruleCondition;
      return { ...ruleCondition, [evt.target.name]: evt.target.value };
    });
    this.setState({ ruleCondition: newShareholders });
  };
  loading = false;
  handleSubmit = evt => {
    evt.preventDefault()
    if (this.validator.allValid()) {
      this.loading = true;
      this.validator.hideMessages();
      this.forceUpdate();
      masterService.createTravelRule(this.state)
        .then(
          data => {
            this.loading = true;
            this.props.history.push('/database-settings/travel-rule-builder');
            toast.success(alertConstants.CREATED_SUCCESSFULLY)
          } 
        )
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  travelCategoryIdList = []
  cancelAdd = () => {
    this.props.history.push('/database-settings/travel-rule-builder')
  }
  typeOfInput = [
    { type: 'select', value: 'Select' },
    { type: 'text', value: 'Text' },
    { type: 'number', value: 'Number' },
    { type: 'checkbox', value: 'Checkbox' }
  ]
  handleAddShareholder = () => {
    if (this.state.ruleCondition.length > 6) {
      toast.error('You reached the max limit');
    } else {
      this.setState({
        ruleCondition: this.state.ruleCondition.concat([{ name: "",targetKey:null,targetContition:false,targetPolicy:"Choose policy", type: null, dataType: null, dataTypeValue: null, listName: "Type",listNameValue: "Choose item",checkContition:false,listOfItems:[] }])
      });
    }
  };

  handleRemoveShareholder = idx => () => {
    this.setState({
      ruleCondition: this.state.ruleCondition.filter((s, sidx) => idx !== sidx)
    });
  };
  types = 'Type';
  changeInput = (e, idx) => { 
    var newShareholders = [] 
    if(e.conditionType === 0 && e.conditionList.length !== 0){
      newShareholders = this.state.ruleCondition.map((ruleCondition, sidx) => {
        if (idx !== sidx) return ruleCondition;
        return { ...ruleCondition, listName: e.conditionListName,dataType:e.id,checkContition:true,listOfItems:e.conditionList };
      });
    }else{
      newShareholders = this.state.ruleCondition.map((ruleCondition, sidx) => {
        if (idx !== sidx) return ruleCondition;
        return { ...ruleCondition, listName: e.conditionListName,dataType:e.id,checkContition:false };
      });
    } 
    this.setState({ ruleCondition: newShareholders },() =>{
      var newTempShareholders = []  
      if(e.travelPolicyKey === 0){
        newTempShareholders = this.state.ruleCondition.map((ruleCondition, sidx) => {
          if (idx !== sidx) return ruleCondition;
          return { ...ruleCondition, targetContition:true, };
        });
      }else{
        newTempShareholders = this.state.ruleCondition.map((ruleCondition, sidx) => {
          if (idx !== sidx) return ruleCondition;return { ...ruleCondition, targetContition:false, };
        });
      } 
      this.setState({ ruleCondition: newTempShareholders });
    });  
  }
  listName = 'Choose List';
  changeListId = (e, idx) => { 
    const newShareholders = this.state.ruleCondition.map((ruleCondition, sidx) => {
      if (idx !== sidx) return ruleCondition;
      return { ...ruleCondition, dataTypeValue: e._id, listNameValue: e.condition };
    });
    this.setState({ ruleCondition: newShareholders }); 
  }
  changeListIdManage = (e, idx) => { 
    const newShareholders = this.state.ruleCondition.map((ruleCondition, sidx) => {
      if (idx !== sidx) return ruleCondition;
      return { ...ruleCondition, dataTypeValue: e.id, listNameValue: e.itemName };
    });
    this.setState({ ruleCondition: newShareholders });  
  }
  categoryName = "Category"
  changeTitle(e) {
    this.categoryName = e.travelCategoryName
    this.setState({
      travelCategoryId: e.id
    },()=>{
      this.policy()
    }) 
  }
  policyName = "Choose here"
  changePolicy = (e, idx) => { 
    this.policyName = e.travelPolicyKey
    this.setState({
      targetKey:e.id
    })
    const newShareholders = this.state.ruleCondition.map((ruleCondition, sidx) => {
      if (idx !== sidx) return ruleCondition;
      return { ...ruleCondition, targetKey: e.id, targetPolicy: e.travelPolicyKey };
    });
    this.setState({ ruleCondition: newShareholders });  
  }
  updateForm = evt => {
    evt.preventDefault()
    if (this.validator.allValid()) {
      this.loading = true;
      this.validator.hideMessages();
      this.forceUpdate();
      masterService.updateTravelRule(this.state)
        .then(
          data => {
            this.loading = true;
            this.props.history.push('/database-settings/travel-rule-builder');
            toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          } 
        )
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  getLLF  () {
    this.setState( {isLLF:!this.state.isLLF}) 
  }
  render() {
    return (
      <div>
        <div className="page-header">
          <h6 className="heading">
            <NavLink className="page-back" to="/database-settings/travel-rule-builder"></NavLink>  
              {(this.isEdit === false) ?
              "Add Company Location " : "Edit Company Location"
            }
          </h6>
        </div>
        <div className="page-content">
            
          <div className="p-padding">
          <div> 
              <button className="btn mb-3"
                onClick={e =>this.getLLF(e)}  
              >
              {(this.state.isLLF)?
                <i className="material-icons align-middle mr-2">
                check_circle
                </i>
                :""}
                LLF
              </button> 
            </div>
            <div className="form-holder">
              <form onSubmit={(this.isEdit === false) ? this.handleSubmit : this.updateForm} autoComplete="off">
                {/* <form onSubmit={this.handleSubmit} autoComplete="off"> */}
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="form-group custom labelup mb-3">
                      <label>Rule Category</label>
                      <Select
                        placeholder={this.categoryName}
                        keyName={'travelCategoryName'}
                        options={this.travelCategoryIdList}
                        controlFunc={e => this.changeTitle(e, "travelCategoryId")}
                        error={this.validator.message('travelCategoryId', this.state.travelCategoryId, 'required')}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <Input
                      label="Rule Template Name"
                      type="text"
                      name="travelCategoryRule"
                      className="mb-3"
                      error={this.validator.message('ruleTemplateName', this.state.travelCategoryRule, 'required')}
                      value={this.state.travelCategoryRule}
                      controlFunc={this.handleNameChange}
                    />
                    
                  </div> 

                </div>
                {(this.policyList.length !== 0) && 
                <div>
                  <div className="profile-card grey">
                    {this.state.ruleCondition.map((shareholder, idx) => ( 
                      <div key={idx}>
                        <div className="select-div" key={idx}>
                          {(this.state.ruleCondition.length > 1) &&
                            <div className="abs cursor-pointer" onClick={this.handleRemoveShareholder(idx)}>
                              <span className="minus"><i className="icon ion-md-remove-circle"></i></span>
                            </div>
                          }
                          <div className="row">
                            <div className="col-md-3">
                              <Input
                                label="Feild name"
                                type="text"
                                name="name"
                                className="mb-0"
                                error={this.validator.message('name', shareholder.name, 'required')}
                                value={shareholder.name}
                                controlFunc={this.handleShareholderNameChange(idx)}
                              />
                            </div> 
                            <div className="col-md-3">

                              <div className="form-group custom bg-white mb-0">
                                <Select
                                  placeholder={(shareholder.dataType === '') ? this.types : shareholder.listName}
                                  keyName={'conditionListName'}
                                  options={this.listOfContition}
                                  controlFunc={e => this.changeInput(e, idx)}
                                  error={this.validator.message('type', shareholder.dataType, 'required')}
                                />
                              </div>
                            </div>
                            {(shareholder.checkContition) &&
                              <div className="col-md-3"> 
                                {(shareholder.listName === "List Management")?
                                <div className="form-group custom bg-white mb-0">
                                  <Select
                                    placeholder={(shareholder.dataTypeValue === '') ? this.listName : shareholder.listNameValue}
                                    keyName={'itemName'}
                                    options={shareholder.listOfItems}
                                    controlFunc={e => this.changeListIdManage(e, idx)}
                                    error={this.validator.message('Choose Item', shareholder.dataTypeValue, 'required')}
                                  /> 
                                </div>
                                :
                                <div className="form-group custom bg-white mb-0">
                                  <Select
                                    placeholder={(shareholder.dataTypeValue === '') ? this.listName : shareholder.listNameValue}
                                    keyName={'condition'}
                                    options={shareholder.listOfItems}
                                    controlFunc={e => this.changeListId(e, idx)}
                                    error={this.validator.message('Choose Item', shareholder.dataTypeValue, 'required')}
                                  />
                                </div>
                                }
                              </div>
                            }
                            {(shareholder.targetContition) && this.policyList.length !== 0 &&  
                              <div className="col-md-3">
                                <div className="form-group custom labelup mb-3">
                                  <label>Travel Policy Key</label>
                                  <Select
                                    placeholder={shareholder.targetPolicy}
                                    keyName={'travelPolicyKey'}
                                    options={this.policyList}
                                    controlFunc={e => this.changePolicy(e, idx)}
                                    error={this.validator.message('targetKey', shareholder.targetKey, 'required')}
                                  /> 
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        <hr className="m-0" />
                      </div>
                    ))}
                    {(this.state.ruleCondition.length <= 6) &&
                      <div className="select-div footer">
                          <button type="button" onClick={this.handleAddShareholder} className="btn">Insert Field</button>
                      </div>
                    }
                  </div>
                  {(this.loading === false) ?
                    <div className="btn-group-margin mt-4">
                      {(this.isEdit === false) ?
                        <button type="submit" className="btn btn-border-success lg-padding">Save</button> :
                        <button type="submit" className="btn btn-border-success lg-padding">Update</button>
                      }
                      <button type="button" onClick={this.cancelAdd} className="btn">Cancel</button>
                    </div> :
                    <div className="btn-group-margin">
                      <button type="button" className="btn">Please wait...</button>
                    </div>
                  }
                </div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateTemplatePage;
