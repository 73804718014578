const dynamicFields = {
   DATA_TYPE_TEXT: "text",
   DATA_TYPE_TEXTAREA:"textarea",
   DATA_TYPE_INTEGER: "integer",
   DATA_TYPE_EMAIL: "email",
   DATA_TYPE_CONNECTED_LIST: "connected_list",
   DATA_TYPE_FILE: "file",
   DATA_TYPE_AMOUNT: "amount",
   DATA_TYPE_BOOLEAN: "boolean",
   DATA_TYPE_CHECKBOX: "checkbox",
   DATA_TYPE_DATE: "date",
   DATA_TYPE_SYSTEM_LIST: "different_list",
   DATA_TYPE_SEARCH: "search"
}

export default dynamicFields;