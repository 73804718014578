import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { HistoryListPage, HistoryDetailsPage, UserDetailsPage } from './index'

class TestHistoryPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/test-history/history') {
            this.props.history.push('/test-history/history/list')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/test-history/history') {
            this.props.history.push('/test-history/history/list')
        }
    }
    render() {
        return (
            <div className="HistoryPage">
                <Switch>
                    <Route path='/test-history/history/list' component={HistoryListPage} />
                    <Route path='/test-history/history/details/:id/:type' component={HistoryDetailsPage} />
                    <Route path='/test-history/history/userDetails' component={UserDetailsPage} />
                </Switch>
            </div>
        );
    }
}

export default TestHistoryPage;
