import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
import { historyServices, midOfficeServices } from "../../_services";
import { Select } from "../_common/_forms";
import NumberFormat from 'react-number-format';

class HistoryListPage extends Component {
  state = {
    page: 1,
    search: '',
    limit: 10,
    totalCount: null,
    flightData: [],
    bookingType: '',
    totalCount: null,
    pageItemsList: [{ items: 10 }, { items: 20 }, { items: 30 }, { items: 40 }],
    isLoading: true,
    clientList: [],
    clientName: 'Filter by Company',
    btnActive: false,
    typeName: 'FLIGHT',
  }
  componentDidMount = () => {
    this.getCommonData("FLIGHT");
    this.getClientList();
  }
  getCommonData = (type) => {
    this.setState({ allTypeData: [], bookingType: type });
    let data = {
      bookingType: type,
      search: this.state.search,
      page: this.state.page,
      limit: this.state.limit
    }
    this.getData(data);
  }
  getData = async (data) => {
    this.setState({ isLoading: true });
    let res = await historyServices.getCommonData(data);
    if (res) {
      this.setState({ allTypeData: res.result, totalCount: res.count, isLoading: false });
    }
    else {
      this.setState({ allTypeData: [], totalCount: 0, isLoading: false })
    }
  }
  searchHandler = (e) => {
    if (e.target.value !== '') {
      let data = {
        bookingType: this.state.bookingType,
        search: e.target.value,
        page: this.state.page,
        limit: this.state.limit
      }
      this.getData(data);
    }
    else {
      let data = {
        bookingType: this.state.bookingType,
        search: this.state.search,
        page: this.state.page,
        limit: this.state.limit
      }
      this.getData(data);
    }
  }
  handleItemChange = limit => {
    this.setState({ limit: limit.items, page: 1 });
    let data = {
      bookingType: this.state.bookingType,
      search: this.state.search,
      page: this.state.page,
      limit: limit.items
    }
    this.getData(data);
  };
  handlePageChange = (pageNumber) => {
    this.setState({
      page: pageNumber,
    })
    let data = {
      bookingType: this.state.bookingType,
      search: this.state.search,
      page: pageNumber,
      limit: this.state.limit
    }
    this.getData(data);
  }
  getClientList = async () => {
    let res = await midOfficeServices.getClientList();
    if (res) {
      console.log(res);
      this.setState({ clientList: res.result });
    }
  }
  changeClient = (val, field) => {
    this.setState({ clientName: val.companyName, [field]: val.id });
    let data = {
      bookingType: this.state.bookingType,
      search: this.state.search,
      page: this.state.page,
      limit: this.state.limit,
      companyId: val.id
    }
    console.log(data);
    this.getData(data);
  }
  typeList = [{ type: 'FLIGHT' }, { type: 'HOTEL' }, { type: 'CAR' }, { type: 'BUS' }, { type: 'TRAIN' }];
  changeType = (val) => {
    this.setState({ typeName: val.type });
    this.getCommonData(val.type);
  }
  render() {
    const { allTypeData } = this.state;
    return (
      <div className="ProjectPage">
        <div className="page-header">
          <h6 className="heading">Trip History</h6>
        </div>
        <div className="page-content">
          <div className="py-padding">
            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-md-2">
                    <div className="btn-group-margin w-100">
                      {this.typeList && (
                        <Select
                          placeholder={this.state.typeName}
                          keyName={"type"}
                          options={this.typeList}
                          myClass={"w-100"}
                          controlFunc={e =>
                            this.changeType(e)
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="btn-group-margin">
                      {this.state.clientList && (
                        <Select
                          placeholder={this.state.clientName}
                          keyName={"companyName"}
                          options={this.state.clientList}
                          controlFunc={e =>
                            this.changeClient(e, "clientType")
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="search-custom">
                      <input type="text" className="form-control" placeholder="Search"
                        onChange={(e) => this.searchHandler(e)} />
                      <span><i className="material-icons align-middle">search</i></span>
                    </div>
                  </div>
                </div>
              </form>

              <div className="table-responsive">
                {
                  (this.state.isLoading) ?
                    <div className="mx-auto text-center">
                      <img
                        width="200"
                        className="img-fluid "
                        alt="no flight"
                        src="/assets/images/loader.gif"
                      />
                      <p>Loading please wait...</p>
                    </div>
                    :
                    <table className="table table-striped">
                      {(this.state.bookingType === 'FLIGHT') ?
                        (<thead>
                          <tr>
                            <th>CURIO REF NO</th>
                            <th>TRAVELER NAME</th>
                            <th>DATE</th>
                            <th>RBD</th>
                            <th>AIRLINE</th>
                            <th>SECTOR</th>
                            <th>PAYMENT TYPE</th>
                            <th>COMPANY</th>
                            <th>FARE TYPE</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        ) : (this.state.bookingType === 'HOTEL' ? (
                          <thead>
                            <tr>
                              <th>CURIO REF NO</th>
                              <th>NAME</th>
                              <th>HOTEL NAME</th>
                              <th>ROOM CATEGORY</th>
                              <th>TOTAL AMOUNT</th>
                              <th>BOOKING DATE</th>
                              <th>COMPANY</th>
                              <th>LOCATION</th>
                            </tr>
                          </thead>

                        ) : '')}

                      <tbody>
                        {
                          (this.state.bookingType === 'FLIGHT') &&
                          allTypeData && allTypeData.length > 0 && allTypeData.map((data, index) => {
                            return <tr key={index}>
                              <td className="text-primary"><NavLink to={`/history/details/${data.id}/${this.state.bookingType}`}>{data.tripId}</NavLink></td>
                              <td>{data.travellerName}</td>
                              <td>{data.travellingDate}</td>
                              <td className="text-primary"><NavLink to={`/history/details/${data.id}/${this.state.bookingType}`}>
                                {data.rbd.map((rbd, i) =>
                                  (i === 0) ? '' + rbd : ', ' + rbd
                                )}
                              </NavLink></td>
                              <td> {data.airLine.map((airline, i) => (i === 0) ? '' + airline : ', ' + airline)}</td>
                              <td>{(data.flightData != undefined) ? data.flightData.flights.map((flight, i) => <p key={i}>{flight.flight_from + " - " + flight.flight_to + ', '}</p>
                              ) : ""}</td>
                              <td>{data.paymentType}</td>
                              <td>{data.company}</td>
                              <td>{data.fareType}</td>
                              <td>{data.statusType}</td>
                            </tr>
                          })
                        }
                      </tbody>
                      <tbody>
                        {
                          (this.state.bookingType === 'HOTEL') &&
                          allTypeData && allTypeData.length > 0 && allTypeData.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-primary"><NavLink to={`/history/details/${item.id}/${this.state.bookingType}`}>{item.tripId}
                                </NavLink></td>
                                <td>{item.travellerName}</td>
                                <td>{item.hotelData.hotel_name}</td>
                                <td>{item.hotelData.hotel_room_info.hotel_room_name}</td>
                                <td>
                                  <NumberFormat
                                    value={(Math.floor(item.hotelData.hotel_room_info.hotel_total_price * 100) / 100)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={item.hotelData.hotel_room_info.hotel_currency_code + ' '} />
                                </td>
                                <td>{item.bookingDate}</td>
                                <td>{item.company}</td>
                                <td>{item.hotelData.hotel_city}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>

                    </table>

                }
              </div>
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    {this.state.totalCount && (
                      <div className="page-count">
                        <div className="text">Items</div>
                        <div className="count">
                          <div className="dropdown">
                            <Select
                              placeholder={this.state.limit}
                              keyName={"items"}
                              options={this.state.pageItemsList}
                              controlFunc={e => this.handleItemChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-9 text-right">
                    {this.state.totalCount && <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={3}
                      onChange={this.handlePageChange} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HistoryListPage;
