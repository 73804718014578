import React from 'react';

const Button = (props) => (
    <div className="form-group">
        <button
            type={props.type}
            className={props.className}>
            {props.name}</button>
    </div>
);

export default Button;  