import React from "react";
import { Input } from "../../../_common/_forms";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReminderType from "./add-reminder-type-html";

const AddEmailReminderPage = props => {
  return (
    <div>
      <div className="page-header">
        <h6 className="heading">Email Reminder</h6>
      </div>
      <div className="page-content">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <h6 className="heading">Edit Reminder</h6>
              <p className="text">
                You can edit email reminders and replace with your own content.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="row">
            <div className="col-md-7">
              <form id="form">
                <Input
                  label={"Name"}
                  name="name"
                  type="text"
                  value={props.name}
                  controlFunc={e => props.onInputChangeHandler(e)}
                  error={props.validator.message(
                    "name",
                    props.name,
                    "required"
                  )}
                />
                <ReminderType {...props} />
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      label={"Older Than"}
                      type="text"
                      name="olderThan"
                      value={props.olderThan}
                      controlFunc={e => props.onInputChangeHandler(e)}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="form-group custom labelup">
                      <label>Value</label>
                      <div className="dropdown">
                        <button
                          type="button"
                          className={"btn dropdown-toggle"}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {props.olderThanType
                            ? props.olderThanType
                            : "Select..."}
                        </button>
                        <div className="dropdown-menu">
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={e => props.onTimeSelectHandler("DAYS")}
                          >
                            DAYS
                          </button>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={e => props.onTimeSelectHandler("HOURS")}
                          >
                            HOURS
                          </button>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={e => props.onTimeSelectHandler("MINUTES")}
                          >
                            MINUTES
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Frequency</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className={"btn dropdown-toggle"}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {props.displayfrequency
                        ? props.displayfrequency
                        : props.frequency
                        ? props.frequency
                        : "Select..."}
                    </button>
                    <div className="dropdown-menu">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={e =>
                          props.onFrequencySelectHandler(
                            "DAILY (WEEKDAYS ONLY)",
                            "DAILY_WEEKDAY_ONLY"
                          )
                        }
                      >
                        DAILY (WEEKDAYS ONLY)
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={e =>
                          props.onFrequencySelectHandler("DAILY", "DAILY")
                        }
                      >
                        DAILY
                      </button>
                      {/* <button
                        type="button"
                        className="dropdown-item"
                        onClick={e =>
                          props.onFrequencySelectHandler(
                            "EVERY X DAYS",
                            "EVERY_X_DAYS"
                          )
                        }
                      >
                        EVERY X DAYS
                      </button> */}
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={e =>
                          props.onFrequencySelectHandler(
                            "SPECIFIC DAY OF MONTH",
                            "SPECIFIC_DAY_OF_MONTH"
                          )
                        }
                      >
                        SPECIFIC DAY OF MONTH
                      </button>
                    </div>
                  </div>
                </div>
                {/* {props.frequency === "EVERY_X_DAYS" ? (
                  <Input
                    label={"Day"}
                    type="text"
                    name="frequencyValue"
                    value={props.frequencyValue}
                    controlFunc={e => props.onInputChangeHandler(e)}
                  />
                ) : null} */}
                {props.frequency === "SPECIFIC_DAY_OF_MONTH" ? (
                  <div>
                  <label>Date</label>                  
                  <div className="form-group custom">
                    <DatePicker 
                      selected={props.startDate&&props.startDate}
                      onChange={props.handleChange}
                      dateFormat="dd MMM yyyy"
                      placeholderText="Click to select a date"
                    />
                  </div>
                  </div>
                ) : null}
                <Input
                  label={"Email Subject"}
                  type="text"
                  name="subject"
                  value={props.subject}
                  controlFunc={e => props.onInputChangeHandler(e)}
                  error={props.validator.message(
                    "Subject",
                    props.subject,
                    "required"
                  )}
                />
                <h6 className="heading mt-4">Content</h6>
                <CKEditor
                  editor={ClassicEditor}
                  data={props.content ? props.content : ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    props.emailContentHandler(data);
                  }}
                />
                <div className="text-danger fs-12">
                  {props.validator.message(
                    "Content",
                    props.content,
                    "required"
                  )}
                </div>
                {props.type === "PASSPORT_EXPIRY" ||
                props.type === "VISA_EXPIRY" ? (
                  <div>
                    <h6 className="heading mt-4">After Expiry</h6>
                    <CKEditor
                      editor={ClassicEditor}
                      data={
                        props.contentAfterExpiry ? props.contentAfterExpiry : ""
                      }
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        props.emailAfterExpiryContentHandler(data);
                      }}
                    />
                    <div className="text-danger fs-12">
                      {props.validator.message(
                        "after expiry",
                        props.contentAfterExpiry,
                        "required"
                      )}
                    </div>
                  </div>
                ) : null}
                <div className="form-group custom textarea mt-4">
                  <textarea
                    className="form-control"
                    name="notificationBoxContent"
                    value={props.notificationBoxContent}
                    onChange={e => props.notificationContentHandler(e)}
                    placeholder="&nbsp;"
                  />
                  <label>Notification</label>
                </div>
                {props.type === "PASSPORT_EXPIRY" ||
                props.type === "VISA_EXPIRY" ? (
                  <div className="form-group custom textarea mt-4">
                    <textarea
                      className="form-control"
                      name="notificationBoxAfterExpiry"
                      value={props.notificationBoxAfterExpiry}
                      onChange={e =>
                        props.notificationAterExpiryContentHandler(e)
                      }
                      placeholder="&nbsp;"
                    />
                    <label>After Expiry Notification</label>
                  </div>
                ) : null}

                <div className="form-group custom labelup">
                  <label>Copy to Approval</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className={"btn dropdown-toggle"}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {props.displayApproval
                        ? props.displayApproval
                        : props.copyToApproval
                        ? props.copyToApproval.toString()
                        : "Select..."}
                    </button>
                    <div className="dropdown-menu">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={e =>
                          props.isApproverSelecthandler("true", true)
                        }
                      >
                        true
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={e =>
                          props.isApproverSelecthandler("false", false)
                        }
                      >
                        false
                      </button>
                    </div>
                  </div>
                </div>
                <div className="btn-group-margin mt-4">
                  <button
                    type="submit"
                    className="btn"
                    onClick={
                      props.isEditable
                        ? e => props.onUpdatehandler(e)
                        : e => props.onSubmit(e)
                    }
                  >
                    {props.loading === true
                      ? "Please Wait"
                      : props.isEditable
                      ? "UPDATE"
                      : "SAVE"}
                  </button>
                  <NavLink
                    to="/database-settings/email-reminders"
                    className="btn"
                  >
                    CANCEL
                  </NavLink>
                </div>
              </form>
            </div>
            <div className="col-md-5">
              <h6 className="sub-heading">
                <i className="material-icons align-middle">find_in_page</i>{" "}
                Preview
              </h6>
              <div className="template-output">
                <div dangerouslySetInnerHTML={{ __html: props.content }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddEmailReminderPage.propTypes = {
  onSelectCurrencyHandler: PropTypes.func,
  onFrequencySelectHandler: PropTypes.func,
  onInputChangeHandler: PropTypes.func,
  onUpdatehandler: PropTypes.func,
  onSubmit: PropTypes.func,
  emailContentHandler: PropTypes.func,
  isEditable: PropTypes.bool,
  displayfrequency: PropTypes.string,
  frequency: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string
};
export default AddEmailReminderPage;
