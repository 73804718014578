import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
// import DashboardFlightPage from './flight';
import DashboardHotelPage from './hotel';
import FlightBookingRequestPage from './flight-booking-request';
import FlightBookingCancellationRequestPage from './flight-booking-cancel';
import HotelBookingRequestPage from './hotel-booking-request';
import FlightTripIdPage from './trip-id';
import BookedHotelDetailsPage from './booked-hotel-details';
import DashboardHomePage from './home';
import CancelRequestPage from './cancellation-request';
import TrainBookingRequestPage from './train-booking-request';
import BusBookingRequestPage from './bus-booking-request';
import RescheduleBookingRequestPage from './reschedule-booking-request';
import ETicketPage from './e-ticket';
import InvoicePage from './invoice';
import HotelSwitch from './hotelSwitch';
import FlightDetails from './flightDetails';
import HotelDetails from './hotelDetails';
import FlightETicketPage from './flightETicket';
import FlightInvoicePage from './flightInvoice';

import FlightSwitch from './flightSwitch';
import CheckListPage from './check-list';

class MidOfficeTestMainPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/test/mid-office' || props.location.pathname === '/test/mid-office/' || props.location.pathname === '/') {
            this.props.history.push('/test/mid-office/home')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/test/mid-office' || this.props.location.pathname === '/test/mid-office/' || this.props.location.pathname === '/') {
            this.props.history.push('/test/mid-office/home')
        }
    }
    render() {
        return (
            <div>
                <Switch>
                    {/* <Route path="/test/mid-office/flight" component={DashboardFlightPage} />  */}
                    <Route path="/test/mid-office/hotel" component={DashboardHotelPage} />
                    <Route path="/test/mid-office/flight-booking-request/:statusType/:bookingType/:find" component={FlightBookingRequestPage} />
                    <Route path="/test/mid-office/trip-id/:id" component={FlightTripIdPage} />
                    <Route path="/test/mid-office/flight-booking-cancel" component={FlightBookingCancellationRequestPage} />
                    <Route path="/test/mid-office/hotel-booking-request/:statusType/:bookingType/:find" component={HotelBookingRequestPage} />
                    <Route path="/test/mid-office/home" component={DashboardHomePage} />
                    <Route path="/test/mid-office/cancellation-request" component={CancelRequestPage} />
                    <Route path="/test/mid-office/train-booking-request/:statusType/:bookingType/:find" component={TrainBookingRequestPage} />
                    <Route path="/test/mid-office/bus-booking-request/:statusType/:bookingType/:find" component={BusBookingRequestPage} />
                    <Route path="/test/mid-office/reschedule-booking-request" component={RescheduleBookingRequestPage} />
                    <Route path="/test/mid-office/e-ticket" component={ETicketPage} />
                    <Route path="/test/mid-office/invoice" component={InvoicePage} />
                    <Route path="/test/mid-office/hotelSwitch/:tripId/:bookingType/:hotelId/:type" component={HotelSwitch} />
                    <Route path="/test/mid-office/booked-hotel-details/:id" component={BookedHotelDetailsPage} />
                    <Route path="/test/mid-office/flightDetails" component={FlightDetails} />
                    <Route path="/test/mid-office/hotelDetails" component={HotelDetails} />
                    <Route path="/test/mid-office/flightETicket/:tripId/:journeyId/:type" component={FlightETicketPage} />
                    <Route path="/test/mid-office/flightInvoice/:tripId/:journeyId/:type" component={FlightInvoicePage} />
                    <Route path="/test/mid-office/hotelSwitch/:tripId/:bookingType/:hotelId/:type" component={HotelSwitch} />
                    <Route path="/test/mid-office/flightSwitch/:type1/:tripId/:journeyId/:type" component={FlightSwitch} />
                    <Route path="/test/mid-office/check-list" component={CheckListPage} />
                </Switch>
            </div >
        )
    }
}
export default MidOfficeTestMainPage;
