import React, { Component } from 'react';
import Arrow from './Arrow';



export default class TreeNode extends Component {

    constructor(props) {
        super(props);
        this.state = { collapsed: false }; 
    }
    onClick() {

        this.setState({
            collapsed: !this.state.collapsed
        });

    }

    render() {
        const { getParent } = this.props
        let subtree = null;
        if (this.props.data.children) {
            subtree = this.props.data.children.map(function (child) {
                return <TreeNode selected={this.props.selected} editPermission={this.props.editPermission} updateParent={this.props.updateParent} getParent={e => this.props.getParent(e)} editPanel={e => this.props.editPanel(e)} key={child.id} data={child} />;
            }.bind(this));
        }

        var arrowClassName = 'btn btn-nothing ';
        var containerClassName = 'tree-node-children ml-4 mt-2';
        if (this.state.collapsed) {
            arrowClassName = 'btn btn-nothing collapsed';
            containerClassName += ' tree-node-children-collapsed';
        }

        if (subtree) {
            return (
                <li>
                    <div className={(this.props.selected == this.props.data.id) ? "table-movable selected" : (this.props.data.menuType === 1) ? "table-movable selectedFun" : (this.props.data.menuType === 2) ? "table-movable selectedFun": (this.props.data.menuType === 3) ? "table-movable selectedPage" : "table-movable"}>
                        <h6 className="heading" onClick={e => this.props.updateParent(this.props.data)} >{this.props.data.menuName}</h6>
                        {(this.props.data.id === 0) ? "" :
                            <div className="right-icon">
                                <div className="dropdown more" >
                                    <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                        <i className="icon ion-md-more"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                        <button className="dropdown-item" onClick={e => this.props.editPanel(this.props.data)}>Edit Menu Item</button>
                                        <button className="dropdown-item" onClick={e => this.props.editPermission(this.props.data)}>Edit Permissions</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <ul className={containerClassName}>
                        <li>
                            {subtree}
                        </li>
                    </ul> 
                        <button onClick={e => getParent(this.props.data)} className="btn btn-border-success">Add Menu Item</button> 
                </li>

            );
        }
        else {
            return (
                <div>
                    <div className={(this.props.selected == this.props.data.id) ? "table-movable selected" : (this.props.data.menuType === 1) ? "table-movable selectedFun" : (this.props.data.menuType === 2) ? "table-movable selectedFun": (this.props.data.menuType === 3) ? "table-movable selectedPage" : "table-movable"}>
                        {/* <div className={(this.props.selected == this.props.data.id) ? "table-movable selected" : "table-movable"}> */}
                        <h6 className="heading" onClick={e => this.props.updateParent(this.props.data)}>  {this.props.data.menuName}</h6>
                        {(this.props.data.id === 0) ? "" :
                            <div className="right-icon">
                                <div className="dropdown more" onClick={e => this.props.updateParent(this.props.data)}>
                                    <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                        <i className="icon ion-md-more"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                        <button className="dropdown-item" onClick={e => this.props.editPanel(this.props.data)}>Edit Menu Item</button>
                                        <button className="dropdown-item" onClick={e => this.props.editPermission(this.props.data)}>Edit Permissions</button>
                                        {/* <button className="dropdown-item">Delete Menu Item</button> */}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {(this.props.data.menuType === 0) ?
                        <button onClick={e => getParent(this.props.data)} className="btn btn-border-success">Add Menu Item</button>
                        : ""
                    }
                </div>
            );
        }


    }
};
