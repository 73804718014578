import { observable, action,decorate } from 'mobx'; 

class UserStore {

  currentUser;  

  pullUser(data) {
    this.loadingUser = true;
    this.currentUser = data; 
  }

}
decorate(UserStore, {
  currentUser: observable, 
  pullUser:action
})

export default new UserStore();
