import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { history } from '../../_helpers';

class SideMenu extends Component {
    openCloseMenu() {
        var sidebar = document.getElementById("sidebar");
        sidebar.classList.toggle("is--extended");
    }
    clear() {
        localStorage.clear();
        history.push('/login');
    }
    render() {
        return (
            <div id="sidebar" className="sidebar">
                <div className="logo">
                    <img
                        className="logo-img1"
                        src="/assets/images/logo_white.svg"
                        alt="Curio"
                    />
                    <img className="logo-img" src="/assets/images/logo.svg" alt="Curio Logo" />
                    <img className="hidden" src="/assets/images/logo-text-black.png" alt="" />
                    <div className="bars" onClick={e => this.openCloseMenu()}>
                        <i className="material-icons menu">menu</i>
                        <span className="hover-name">Menu</span>
                        {/* <i className="icon ion-md-close hidden"></i> */}
                    </div>
                </div>
                <div className="navigation">
                    <ul>
                        <li>
                            <NavLink to="/mid-office/home">
                                <div>
                                    <i className="material-icons">
                                        gps_fixed
                                    </i>
                                    <span className="hover-name">Dashboard</span>
                                </div>
                                <span className="hidden">Dashboard</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/users">
                                <div>
                                    <i className="material-icons">
                                        person
                                    </i>
                                    <span className="hover-name">Users</span>
                                </div>
                                <span className="hidden">Users</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/terms-privacy">
                                <div>
                                    <i className="icon ion-md-lock"></i>
                                    <span className="hidden">Terms and Privacy</span>
                                </div>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/projects">
                                <div>
                                    {/* <i className="icon ion-md-briefcase"></i> */}
                                    <i className="material-icons">
                                        work
                                    </i>
                                    <span className="hover-name">Projects</span>
                                </div>
                                <span className="hidden">Projects</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/client">
                                <div>
                                    {/* <i className="icon ion-md-contacts"></i> */}
                                    <i className="material-icons">
                                        supervisor_account
                                    </i>
                                    <span className="hover-name">Clients</span>
                                </div>
                                <span className="hidden">Clients</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/database-settings">
                                <div>
                                    {/* <i className="icon ion-md-analytics"></i> */}
                                    <i className="material-icons">
                                        timeline
                                    </i>
                                    <span className="hover-name">Database Settings</span>
                                </div>
                                <span className="hidden">Database Settings</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/reporting">
                                <div>
                                    <i className="material-icons">
                                        assignment
                                    </i>
                                    <span className="hover-name">Reporting</span>
                                </div>
                                <span className="hidden">Reporting</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/profitable-reports">
                                <div>
                                    <i className="material-icons">
                                        bar_chart
                                    </i>
                                    <span className="hover-name">Profitable Reports</span>
                                </div>
                                <span className="hidden">Profitable Reports</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/integration">
                                <div>
                                    <i className="material-icons">
                                        merge_type
                                    </i>
                                    <span className="hover-name">Integration</span>
                                </div>
                                <span className="hidden">Integration</span>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/history">
                                <div>
                                    <i className="material-icons">
                                        timer
                                    </i>
                                    <span className="hover-name">History</span>
                                </div>
                                <span className="hidden">History</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/test/mid-office/home">
                                <div>
                                    <i className="material-icons">
                                        dashboard
                                    </i>
                                    <span className="hover-name">Test Dashboard</span>
                                </div>
                                <span className="hidden">Test Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/test-history/history">
                                <div>
                                    <i className="material-icons">
                                        change_history
                                    </i>
                                    <span className="hover-name">Test History</span>
                                </div>
                                <span className="hidden">Test History</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/sap">
                                <div>
                                    <i className="material-icons">
                                    change_history
                                    </i>
                                    <span className="hover-name">SAP</span>
                                </div>
                                <span className="hidden">SAP</span>
                            </NavLink>
                        </li>
                        <li>
                            <button className="btn-nothing" onClick={e => this.clear()}>
                                <div>
                                    {/* <i className="icon ion-md-log-out"></i> */}
                                    <i className="material-icons">
                                        exit_to_app
                                    </i>
                                    <span className="hover-name">Logout</span>
                                </div>
                                <span className="hidden">Logout</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
export default SideMenu
