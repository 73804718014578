import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { projectService, masterService } from "../../_services";
import { Input, Select } from "../_common/_forms";
import SimpleReactValidator from "simple-react-validator";
import $ from 'jquery';
class ViewProjectsPage extends Component {
  projectDetail = "";
  projectId = "";
  constructor(props) {
    super(props);
    this.state = {
      logUrl: '',
      contactInfo: {
        name: "",
        designation: "",
        email: "",
        phone: ""
      },
      serviceList: [],
      subDomain: "",
      orderTypeId: "",
      billingCycleId: "",
      orderEffectiveDate: "",
      invoiceGenerationDate: "",
      companyName: "",
      logoImg: '',
      loadingLogo: false
    };

    let user = JSON.parse(localStorage.user)
    this.token = user.token
    this.validator = new SimpleReactValidator();
    this.validatorUpdate = new SimpleReactValidator();
  }
  componentDidMount() {
    console.log('----', window.location);
    if (window.location.host === 'localhost:3000' || window.location.host === 'csa.curiotechnologies.in') {
      this.setState({ logUrl: `${window.location.protocol}//dev.curiotechnologies.in` })
    } else if (window.location.host == 'uatcsa.curiotechnologies.in') {
      this.setState({ logUrl: `${window.location.protocol}//uat.curiotechnologies.in` })
    } else if (window.location.host == 's-admin.curiotechnologies.com') {
      this.setState({ logUrl: `${window.location.protocol}//sandbox.curiotechnologies.com` })
    }
    this.getOrderType();
    this.getBillingType();
    if (this.props.match.params.id === undefined) {
      this.props.history.push("/projects/list");
    }
    this.projectId = this.props.match.params.id;
    this.getList();
    this.getCompanyLogo()
  }
  logoImg = ''
  getCompanyLogo() {
    projectService.getLogo(this.projectId).then(
      data => {
        this.setState({
          logoImg: data.result,
          loadingLogo: false
        })
      }
    )
  }
  getList() {
    projectService.getOneProjectDetail(this.projectId).then(
      data => {
        this.loading = false;
        if (data != null) {
          this.count = data.count;
          this.projectDetail = data.result;
          this.getServiceModule()
          this.setState({ ...this.state })
        }
      }
    );
  }
  listServiceModule = [];
  choosedService = [];
  getServiceModule = async () => {
    this.choosedService = [];
    var resp = await masterService.getServiceModuleList();
    if (resp) {
      this.projectDetail &&
        this.projectDetail.projectService.forEach((item, key) => {
          if (item.activeStatus === true) {
            this.choosedService.push(item.serviceId);
          }
        });
      this.listServiceModule = resp.result;
      this.forceUpdate();
    }
  };
  editPanel = false;
  editViewPanel = () => {
    this.editPanel = true;
    this.setState({
      contactInfo: {
        name: this.projectDetail.contactInfo["name"],
        phone: this.projectDetail.contactInfo["phone"],
        email: this.projectDetail.contactInfo["email"],
        designation: this.projectDetail.contactInfo["designation"]
      }
    });
    this.forceUpdate();
  };
  panleProject = false;
  editPanelProject = () => {
    this.panleProject = true;
    this.setState({
      subDomain: this.projectDetail.subDomain,
      orderEffectiveDate: this.projectDetail.orderEffectiveDate,
      invoiceGenerationDate: this.projectDetail.invoiceGenerationDate,
      companyName: this.projectDetail.companyName,
      orderTypeId: this.projectDetail.orderTypeId._id,
      billingCycleId: this.projectDetail.billingCycleId._id
    });
    this.orderName = this.projectDetail.orderTypeId.items;
    this.billingName = this.projectDetail.billingCycleId.billingType;
    this.forceUpdate();
  };
  orderTypes = [];
  orderName = "Order Type";
  getOrderType() {
    masterService.getOrderType().then(
      data => {
        if (data != null) {
          this.orderTypes = data.result;
          this.forceUpdate();
        }
      }
    );
  }
  changeOrder = (val, feild) => {
    this.orderName = val.items;
    this.setState({ [feild]: val.id });
    this.forceUpdate();
  };
  billingTypes = [];
  billingName = "Billing Type";
  getBillingType() {
    masterService.getBillingCycle().then(
      data => {
        if (data != null) {
          this.billingTypes = data.result;
          this.forceUpdate();
        }
      },
    );
  }
  changeBilling = (val, feild) => {
    this.billingName = val.billingType;
    this.state[feild] = val.id;
    this.forceUpdate();
  };
  handlePersonalInfo = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      var req = {
        contactInfo: { ...this.state.contactInfo }
      };
      var resp = await masterService.updateProject(this.projectId, req);
      if (resp) {
        this.getList();
        this.reset();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleChange = e => {
    this.state.contactInfo[e.target.name] = e.target.value;
    this.setState({
      ...this.state
    });
  };
  handleProfileChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleProfileInfo = async e => {
    e.preventDefault();
    if (this.validatorUpdate.allValid()) {
      var req = {
        subDomain: this.state.subDomain,
        orderTypeId: this.state.orderTypeId,
        billingCycleId: this.state.billingCycleId,
        orderEffectiveDate: this.state.orderEffectiveDate,
        invoiceGenerationDate: this.state.invoiceGenerationDate,
        companyName: this.state.companyName
      };
      var resp = await masterService.updateProject(this.projectId, req);
      if (resp) {
        this.getList();
        this.reset();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  reset = e => {
    this.editPanel = false;
    this.panleProject = false;
    this.forceUpdate();
  };
  file = '';
  loadingLogo = false;
  getImage = async (input) => {
    if (input.target.files && input.target.files[0]) {
      this.setState({ loadingLogo: true })
      var resp = await projectService.uploadLogo(this.projectId, input.target.files[0]).catch(e => {
        this.setState({ loadingLogo: false })
      })
      if (resp) {
        this.getCompanyLogo()
      }
    }
  }
  changeMode = async e => {
    const index = this.choosedService.indexOf(e);
    if (index === -1) {
      var req = {
        activeStatus: true,
        serviceId: e
      };
      this.choosedService.push(e);
    } else {
      var req = {
        activeStatus: false,
        serviceId: e
      };
      this.choosedService.splice(index, 1);
    }
    var resp = await masterService.updateP2P(this.props.match.params.id, req);
    if (resp) {
      this.getList();
      this.forceUpdate();
    }
  };
  assignClient = async () => {
    var resp = await masterService.moveTestToLive(this.projectId);
    if (resp) {
      this.getList();
      toast.info("Moved Live successfully")
      $(".close").click();
      this.props.history.push('/projects/list')
    }
  };

  handleLogoRemove = async (e) => {
    e.preventDefault();
    let response = await projectService.removeLogo(this.projectId);
    if(response){
      toast.success('Logo Deleted Successfully.');
      this.getCompanyLogo();
    }
  }

  render() {
    return (
      <div className="ViewProjectsPage">
        <div>
          <div className="page-header">
            <div className="resp-toggle-menu">
              <i className="material-icons">menu</i>
            </div>
            <h6 className="heading">
              Projects - {this.projectDetail && this.projectDetail.companyName}
            </h6>
          </div>
          <div className="page-content">
            <div className="p-padding">
              <div className="row">
                <div className="col-md-4 col-lg-3">
                  <div className="profile-card">
                    {!this.panleProject && (
                      <div className="details1">
                        <p className="label">Project Name</p>
                        <h6 className="value border-b">
                          {this.projectDetail && this.projectDetail.companyName}
                        </h6>
                        <p className="label">Invoice Date</p>
                        <h6 className="value border-b">
                          {this.projectDetail &&
                            this.projectDetail.invoiceGenerationDate}
                        </h6>
                        <p className="label">Billing Cycle</p>
                        <h6 className="value border-b">
                          {this.projectDetail &&
                            this.projectDetail.billingCycleId &&
                            this.projectDetail.billingCycleId.billingType}
                        </h6>
                        <p className="label">Order Type</p>
                        <h6 className="value border-b">
                          {this.projectDetail &&
                            this.projectDetail.orderTypeId &&
                            this.projectDetail.orderTypeId.items}
                        </h6>
                      </div>
                    )}
                    {!this.panleProject && (
                      <button
                        onClick={e => this.editPanelProject()}
                        className="edit-details"
                      >
                        Edit
                      </button>
                    )}
                  </div>
                  <div className="profile-card ">
                    {this.panleProject && (
                      <div className="details">
                        <Input
                          label="Company Name"
                          className=""
                          name="companyName"
                          type="text"
                          value={this.state.companyName}
                          controlFunc={this.handleProfileChange}
                          error={this.validatorUpdate.message(
                            "name",
                            this.state.companyName,
                            "required"
                          )}
                        />
                        {/* <Input
                          label="Sub Domain"
                          className=""
                          name="subDomain"
                          type="text"
                          value={this.state.subDomain}
                          controlFunc={this.handleProfileChange}
                          error={this.validatorUpdate.message(
                            "name",
                            this.state.subDomain,
                            "required"
                          )}
                        /> */}
                        <Input
                          label="Invoice Generation"
                          className=""
                          name="invoiceGenerationDate"
                          type="date"
                          value={this.state.invoiceGenerationDate}
                          controlFunc={this.handleProfileChange}
                          error={this.validatorUpdate.message(
                            "name",
                            this.state.invoiceGenerationDate,
                            "required"
                          )}
                        />
                        <div className="form-group custom labelup">
                          <label>Order Type</label>

                          {this.orderTypes && (
                            <Select
                              placeholder={this.orderName}
                              keyName={"items"}
                              options={this.orderTypes}
                              controlFunc={e =>
                                this.changeOrder(e, "orderTypeId")
                              }
                              error={this.validatorUpdate.message(
                                "orderTypeId",
                                this.state.orderTypeId,
                                "required"
                              )}
                            />
                          )}
                        </div>
                        <div className="form-group custom labelup">
                          <label>Biling Cycle</label>
                          {this.billingTypes && (
                            <Select
                              placeholder={this.billingName}
                              keyName={"billingType"}
                              options={this.billingTypes}
                              controlFunc={e =>
                                this.changeBilling(e, "billingCycleId")
                              }
                              error={this.validatorUpdate.message(
                                "billingCycleId",
                                this.state.billingCycleId,
                                "required"
                              )}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {this.panleProject && (
                      <div className="container">
                        <div className="row">
                          <button
                            className="col-6 edit-details"
                            onClick={e => this.handleProfileInfo(e)}
                          >
                            {" "}
                            Update{" "}
                          </button>
                          <button
                            onClick={e => this.reset()}
                            className="col-6 edit-details"
                          >
                            Cancel
                        </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="profile-card">
                    {!this.editPanel && (
                      <div className="details">
                        <p className="label">Name</p>
                        <h6 className="value">
                          {this.projectDetail &&
                            this.projectDetail.contactInfo["name"]}
                        </h6>
                        <p className="label">Phone</p>
                        <h6 className="value">
                          {this.projectDetail &&
                            this.projectDetail.contactInfo["phone"]}
                        </h6>
                        <p className="label">Email</p>
                        <h6 className="value">
                          {this.projectDetail &&
                            this.projectDetail.contactInfo["email"]}
                        </h6>
                        <p className="label">Designation</p>
                        <h6 className="value">
                          {this.projectDetail &&
                            this.projectDetail.contactInfo["designation"]}
                        </h6>
                      </div>
                    )}
                    {!this.editPanel && (
                      <button
                        onClick={e => this.editViewPanel()}
                        className="col edit-details"
                      >
                        Edit
                      </button>
                    )}
                    {this.editPanel && (
                      <div className="details">
                        <Input
                          label="Name"
                          className=""
                          name="name"
                          type="text"
                          value={this.state.contactInfo.name}
                          controlFunc={this.handleChange}
                          error={this.validator.message(
                            "name",
                            this.state.contactInfo.name,
                            "required"
                          )}
                        />
                        <Input
                          label="Phone Number"
                          className=""
                          name="phone"
                          type="text"
                          value={this.state.contactInfo.phone}
                          controlFunc={this.handleChange}
                          error={this.validator.message(
                            "phone",
                            this.state.contactInfo.phone,
                            "required|max:10|integer"
                          )}
                        />
                        <Input
                          label="Email"
                          className=""
                          name="email"
                          type="email"
                          value={this.state.contactInfo.email}
                          controlFunc={this.handleChange}
                          error={this.validator.message(
                            "email",
                            this.state.contactInfo.email,
                            "required|email"
                          )}
                        />
                        <Input
                          label="Designation"
                          className=""
                          name="designation"
                          type="type"
                          value={this.state.contactInfo.designation}
                          controlFunc={this.handleChange}
                          error={this.validator.message(
                            "designation",
                            this.state.contactInfo.designation,
                            "required"
                          )}
                        />
                      </div>
                    )}
                    {this.editPanel && (
                      <div className="container">
                        <div className="row">
                          <button
                            className="col-6 edit-details"
                            onClick={e => this.handlePersonalInfo(e)}
                          >
                            {" "}
                            Update{" "}
                          </button>
                          <button
                            onClick={e => this.reset()}
                            className="col-6 edit-details"
                          >
                            Cancel
                        </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="profile-card">
                    <div className="details">
                      <h6 className="sub-heading">Update Implementation Status</h6>
                      <button className="btn" data-toggle="modal" data-target="#CreatUserModal">Update Status</button>
                      {/* {this.projectDetail.projectStatus === 0 && (
                        <div className="right-icon">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={e => this.assignClient()}
                            />
                            <span className="slider round" />
                          </label>
                          <span>{this.projectDetail.projectStatus === 0
                            ? "Inprogress"
                            : "Completed"}</span>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-lg-9">
                  <div className="text-right">
                    <a className="btn" target="_blank" href={this.state.logUrl + "/super-admin/" + this.projectId + "/" + this.token} >Go to Console</a>
                  </div>
                  <h5 className="sub-heading">Services to Deploy</h5>
                  {this.listServiceModule &&
                    this.listServiceModule.map((item, key) => {
                      return (
                        <div key={key}>
                          <div className="box1">
                            <div className="left-icon">
                              {(item.serviceModuleName && item.serviceModuleName === "Travel") ?
                                <i class="material-icons">flight</i>
                                : (item.serviceModuleName === "Expense") ?
                                  <i class="material-icons">graphic_eq</i> : <i class="material-icons">money</i>
                              }
                            </div>
                            <div>
                              <div>
                                <h6 className="heading">
                                  {item.serviceModuleName}{" "}
                                  {/* <span>$299/user</span> */}
                                </h6>
                              </div>
                              <p className="m-0">
                                {(item.serviceModuleName && item.serviceModuleName === "Travel") ?
                                  "Experience smooth corporate travel booking along with advanced tools to manage company costs."
                                  : (item.serviceModuleName === "Expense") ?
                                    "Simplify your employee expense reports and claims with controls you need to make it your own" :
                                    "Manage Cash advance process completely from employee request to issuance with ease"
                                }
                              </p>
                            </div>
                            <div className="right-icon">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onClick={e => this.changeMode(item.id)}
                                  defaultChecked={
                                    this.choosedService.indexOf(item.id) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {/* file upload in admin logo */}
                  <div className="box1 pl-3">
                    <h5 className="mb-3 font-weight-bold"> Upload Client Logo</h5>
                    <form>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="upload-input">
                              <div className="text-center">
                                <i className="material-icons">cloud_upload</i>
                                <p>Drag and drop to upload</p>
                              </div>
                              <input type="file" accept="image/gif, image/jpeg, image/png" onChange={e => this.getImage(e)} name="logo" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            {this.state.loadingLogo &&
                              <img height="70" src="http://www.broadwaybalancesamerica.com/images/ajax-loader.gif" className="img-fluid" alt="logo" />
                            }
                            {this.state.logoImg &&
                              <div>
                                <img height="70" src={this.state.logoImg} />
                              </div>}
                              {
                                this.state.logoImg &&
                             <button className="btn btn-danger" onClick={this.handleLogoRemove} >Delete</button>
                              }
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="bg-white">
                    <div class="mb-3 auth__details" id="accordionExample">
                      <div class="" id="headingOne">
                        <h6 class="heading font-weight-bold mb-0 cursor-pointer p-3  collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          <span className="float-left mt-2">SAML Authentication Details</span>
                          <span className="float-right"><i class="material-icons">keyboard_arrow_down</i></span>
                          <span className="clearfix"></span>
                        </h6>
                      </div><div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body px-3 py-4">
                          <div class="row">
                            <div className="col-md-6">
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>Name*</label>
                              </div>
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>Login URL*</label>
                              </div>
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>Logout URL*</label>
                              </div>
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>Public Key*</label>
                              </div>
                              <button className="btn btn-nothing text-primary mb-4">Getkey from file</button><br />
                              <button className="btn">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white">
                    <div class="mb-3 auth__details" id="accordionExample">
                      <div class="" id="headingTwo">
                        <h6 class="heading font-weight-bold mb-0 cursor-pointer p-3  collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          <span className="float-left mt-2">PCC Details</span>
                          <span className="float-right"><i class="material-icons">keyboard_arrow_down</i></span>
                          <span className="clearfix"></span>
                        </h6>
                      </div><div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div class="card-body px-3 py-4">
                          <div class="row">
                            <div className="col-md-6">
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>GDS Type</label>
                              </div>
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>Urgent Contact Email</label>
                              </div>
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>Booking PCC/SID/OfficeID</label>
                              </div>
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>Profile PCC/SID/OfficeID</label>
                              </div>
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>ARC/IATA Agency Number (Booking Source)</label>
                              </div>
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>Ticketing Time Deadline</label>
                              </div>
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>PCC Time Zone</label>
                              </div>
                              <button className="btn">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white">
                    <div class="mb-3 auth__details" id="accordionExample">
                      <div class="" id="headingThree">
                        <h6 class="heading font-weight-bold mb-0 cursor-pointer p-3  collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          <span className="float-left mt-2">GDS/Profile Syncronization</span>
                          <span className="float-right"><i class="material-icons">keyboard_arrow_down</i></span>
                          <span className="clearfix"></span>
                        </h6>
                      </div>
                      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div class="card-body px-3 py-4">
                          <div class="row">
                            <div className="col-md-6">
                              <div class="form-group custom">
                                <input className="form-control" placeholder="&nbsp;" />
                                <label>Select a default value for XML Sync ID</label>
                              </div>
                              <div className="form-group">
                                <div className="float-left">
                                  <label class="switch"><input type="checkbox" checked="" />
                                    <span class="slider round">
                                    </span></label>
                                </div>
                                <div className="right">
                                  <h6 className="border-0"><b>Save Profile to GDS</b></h6>
                                  <p>If checked , Curio will  save the user's profile to the GDS when user's Curio profile is saved</p>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              <div className="form-group">
                                <div className="float-left">
                                  <label class="switch"><input type="checkbox" checked="" />
                                    <span class="slider round">
                                    </span></label>
                                </div>
                                <div className="right">
                                  <h6 className="border-0"><b>Save Profile to GDS</b></h6>
                                  <p>If checked , Curio will  save the user's profile to the GDS when user's Curio profile is saved</p>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              <div className="form-group">
                                <div className="float-left">
                                  <label class="switch"><input type="checkbox" checked="" />
                                    <span class="slider round">
                                    </span></label>
                                </div>
                                <div className="right">
                                  <h6 className="border-0"><b>Save Profile to GDS</b></h6>
                                  <p>If checked , Curio will  save the user's profile to the GDS when user's Curio profile is saved</p>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              <div className="form-group">
                                <div className="right">
                                  <h6 className="border-0"><b>GDS PNR Options</b></h6>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              <div className="form-group">
                                <div className="float-left">
                                  <label class="switch"><input type="checkbox" checked="" />
                                    <span class="slider round">
                                    </span></label>
                                </div>
                                <div className="right">
                                  <h6 className="border-0"><b>Create a GDS PNR for every trip booked on Line through DT</b></h6>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              <div className="form-group">
                                <div className="float-left">
                                  <label class="switch"><input type="checkbox" checked="" />
                                    <span class="slider round">
                                    </span></label>
                                </div>
                                <div className="right">
                                  <h6 className="border-0">
                                    <b>Write passive segments for  non-GDS content If I have not specified a default for the Specific Vendor in the table below</b></h6>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              <button className="btn">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white">
                    <div class="mb-3 auth__details" id="accordionExample">
                      <div class="" id="headingFour">
                        <h6 class="heading font-weight-bold mb-0 cursor-pointer p-3  collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          <span className="float-left mt-2">SAP Configuration</span>
                          <span className="float-right"><i class="material-icons">keyboard_arrow_down</i></span>
                          <span className="clearfix"></span>
                        </h6>
                      </div><div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                        <div class="card-body px-0 py-4">
                          <div class="btn-group-margin px-3 mb-3">
                            <button className="btn">Add Config</button>
                            <button className="btn disabled">Remove</button>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="border-0">ID</th>
                                  <th className="border-0">NAME</th>
                                  <th className="border-0">CONFIG</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>21232</td>
                                  <td>HGS Custom Config</td>
                                  <td><span className="text-primary">Config 1</span></td>
                                </tr>
                                <tr>
                                  <td>21232</td>
                                  <td>HGS Custom Config 2</td>
                                  <td><span className="text-primary">Config 2</span></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="CreatUserModal" tabIndex="-1" role="dialog"
          aria-labelledby="CreateUserModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-middle pm-30">
                <button type="button" className="close d-none" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="text-center pt-5">
                  <img src="/assets/images/confirmation_icon.svg" alt="" />
                  <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                  <p>Are you sure you want to move this project to live. Moving to live status will take information from from test site to live site. This movement is irreversible</p>
                </div>
                <div className="modal-last btn-group-margin text-center">
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                  >
                    No
                </button>
                  <button
                    className="btn"
                    onClick={e => this.assignClient()}
                  >
                    Yes
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewProjectsPage;
