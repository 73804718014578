import React, { Component } from "react";
import CreateFormsFieldsPage from "./create-form-fields-html";
import { companyAdmin } from "../../../../_services/companyAdmin";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { alertConstants } from "../../../../_constants";

class CreateFormsFields extends Component {
  state = {
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    serviceModuleId: this.props.match.params.id,
    formHeaderId: null,
    sort: "asc",
    fields: [],
    selectedItemId: [],
    selectedField: [],
    dataTypes: [
      { id: 1, dataType: "text" },
      { id: 2, dataType: "integer" },
      { id: 3, dataType: "connected_list" },
      { id: 4, dataType: "file" },
      { id: 5, dataType: "amount" },
      { id: 6, dataType: "boolean" },
      { id: 7, dataType: "checkbox" },
      { id: 8, dataType: "date" },
      { id: 9, dataType: "email" },
      { id: 10, dataType: "search" },
      { id: 11, dataType: "different_list" }
    ],
    displayTypes: [
      { id: 1, displayType: "text" },
      { id: 2, displayType: "number" },
      { id: 3, displayType: "email" },
      { id: 4, displayType: "fileUpload" },
      { id: 5, displayType: "profileUpload" },
      { id: 6, displayType: "select" },
      { id: 7, displayType: "checkbox" },
      { id: 8, displayType: "amount" },
      { id: 9, displayType: "boolean" },
      { id: 10, displayType: "date" },
      { id: 11, displayType: "search" },
      { id: 12, displayType: "different_list" }
    ],
    permissionCodes: [
      { id: 1, permission: "Read Only" },
      { id: 2, permission: "Write" },
      { id: 3, permission: "Modify" },
      { id: 4, permission: "Full Access" }
    ],

    //-------- create fields--------

    fieldName: "",
    labelName: "",
    columnName: "",
    tableName: "",
    dataType: "",
    displayType: "",
    maxLength: "",
    isAdmin: true,
    isMandatory: false,
    customField: false,

    isPiiEncrpt: false, //is pi encrypt and ispinoencryt is not same, ie, if it is true, other is false,
    isPiiNotEncrypt: false,
    isNotPii: true // also is this will be false if either of the above is true
  };
  validator = new SimpleReactValidator();

  // ------------------Handle Form -------------------------------------------

  onChangeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  // ------------------------------------------------------------------

  // ------------------Handle checked -------------------------------------------

  onisMandatory = () =>
    this.setState({ ...this.state, isMandatory: !this.state.isMandatory });

  // piValidation = () => {
  //   if (this.state.isPiiEncrpt) {
  //     this.setState({
  //       isPiiNotEncrypt: false,
  //       isNotPii: false
  //     });
  //   } else if (this.state.isPiiNotEncrypt) {
  //     this.setState({
  //       isPiiEncrpt: false,
  //       isNotPii: false
  //     });
  //   } else if (this.state.isNotPii) {
  //     this.setState({
  //       isPiiEncrpt: false,
  //       isPiiNotEncrypt: false
  //     });
  //   }
  // };
  redirect = () => {
    this.props.history.push("/database-settings/admin-form/fields/list");
  };
  onisPiiEncrpt = () => {
    this.setState({
      isPiiEncrpt: !this.state.isPiiEncrpt,
      isNotPii: false
    });
  };
  onisPiiNotEncrypt = () => {
    this.setState({
      ...this.state,
      isPiiNotEncrypt: !this.state.isPiiNotEncrypt
    });
  };
  onisNotPii = () => {
    this.setState({ 
      isNotPii: !this.state.isNotPii,
      isPiiNotEncrypt: false,
      isPiiEncrpt: false
    });
  };

  // onisNotPii = () => this.setState({...this.state, isNotPii:!this.state.isNotPii})
  oncustomField = () => this.setState({ customField: !this.state.customField });
  onisFormField = () =>
    this.setState({ ...this.state, isFormField: !this.state.isFormField });

  // ------------------------------------------------------------------

  // -----------------create fields-----------------------------

  createFieldsHandler = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let createData = {
        serviceModuleId: this.state.serviceModuleId,
        formHeaderId: this.state.formHeaderId,
        fieldName: this.state.fieldName,
        labelName: this.state.labelName,
        columnName: this.state.columnName,
        tableName: this.state.tableName,
        dataType: this.state.dataType,
        displayType: this.state.displayType,
        maxLength: this.state.maxLength,
        isMandatory: this.state.isMandatory,
        customField: this.state.customField,
        isPiiEncrpt: this.state.isPiiEncrpt,
        isPiiNotEncrypt: this.state.isPiiNotEncrypt,
        isNotPii: this.state.isNotPii
      };
      companyAdmin
        .createFields(createData)
        .then(res => {
          console.log("createData", res);
          toast.success(res.message);
          this.setState({
            fieldName: "",
            labelName: "",
            dataType: "",
            columnName: "",
            tableName: "",
            maxLength: "",
            displayType: "",
            isPiiEncrpt: "",
            isPiiNotEncrypt: "",
            isNotPii: "",
            customField: "",
            isMandatory: ""
          });
          this.redirect();
        })
        .catch(error => {
          if (error.statusCode === 422) {
            let errors = error.message;
            Object.keys(errors).map(key => {
              return toast.error(errors[key]);
            });
          } else {
            toast.error(error.message);
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // ---------------------------------------------------------

  // ---------------- select datatype -------------------

  handleDataType = dataType => {
    if (this.state.dataType !== dataType) {
      this.setState({
        dataType: dataType
      });
    }
  };

  // ---------------- ------------------- -------------------

  // ---------------- select employee permissions -------------------

  handleEmployeePermission = (id, permission) => {
    if (this.state.permissionName !== permission) {
      this.setState({
        ...this.state,
        employeePermissionName: permission,
        employeePermissionId: id
      });
    } else {
      toast.error(alertConstants.ERROR);
    }
  };

  // ---------------- ------------------- -------------------
  // ---------------- select approver permissions -------------------

  handleApproverPermission = (id, permission) => {
    if (this.state.permissionName !== permission) {
      this.setState({
        ...this.state,
        approverPermissionName: permission,
        approverPermissionId: id
      });
    } else {
      toast.error(alertConstants.ERROR);
    }
  };

  // ---------------- ------------------- -------------------

  // ---------------- select display -------------------

  handleDisplayType = (id, displayType) => {
    if (this.state.displayType !== displayType) {
      this.setState({
        ...this.state,
        displayType
      });
    }
  };

  // ---------------- ------------------- -------------------

  // ---------------------------------------------------------
  getRoles = () => {
    companyAdmin.getRoles().then(res => {
      // console.log(res);
    });
  };

  // ---------------------------------------------------------
  componentDidMount() {
    this.getRoles();
    this.setState({
      formHeaderId: sessionStorage.getItem("fieldsFormHeaderId"),
      dataType: this.state.dataTypes[0].dataType,
      displayType: this.state.displayTypes[0].displayType
    });
  }

  render() {
    return (
      <div>
        <CreateFormsFieldsPage
          fields={this.state}
          onChangeHandler={this.onChangeHandler}
          createFieldsHandler={this.createFieldsHandler}
          onisMandatory={this.onisMandatory}
          oncustomField={this.oncustomField}
          onisFormField={this.onisFormField}
          onisPiiEncrpt={this.onisPiiEncrpt}
          onisPiiNotEncrypt={this.onisPiiNotEncrypt}
          onisNotPii={this.onisNotPii}
          handleDataType={this.handleDataType}
          handleDisplayType={this.handleDisplayType}
          validator={this.validator}
          redirect={this.redirect}
          piValidation={this.piValidation}
        />
      </div>
    );
  }
}
export default CreateFormsFields;
