import { http } from "../_helpers";
import { config } from "../env";

const getFormHeader = id => {
  return http
    .get(config.apiUrlCM + `form-headers?serviceModuleId=${id}`)
    .then(data => {
      return data.result;
    });
};

const getFormHeadersValue = data => {
  return http
    .post(config.apiUrlCM + `company-fields/get-header-fields`, data)
    .then(data => data);
};

const getExpenseReport = data => {
  return http.post(config.apiUrlEx + `reports/create`, data).then(data => data);
};

const getAllReports = () => {
  return http.get(config.apiUrlEx + `reports`).then(data => data);
};
const getSingleExpenseReport = id => {
  return http
    .get(config.apiUrlEx + `expense/reports/${id}/download`)
    .then(data => data);
};

const getSingleTravelReport = id => {
  return http
    .get(config.apiUrlEx + `travel/reports/${id}/download`)
    .then(data => data);
};

const getSingleCashAdvanceReport = id => {
  return http
    .get(config.apiUrlEx + `cash-advance/reports/${id}/download`)
    .then(data => data);
};

const scheduleReport = (reportId, data) => {
  return http
    .patch(config.apiUrlEx + `reports/${reportId}/schedule`, data)
    .then(data => data);
};

const getEmployeeForm = data => {
  return http
    .post(config.apiUrlCM + `company-fields/list-all`, data)
    .then(data => data);
};

export const reportService = {
  getFormHeader,
  getFormHeadersValue,
  getExpenseReport,
  getAllReports,
  getSingleExpenseReport,
  getSingleTravelReport,
  getSingleCashAdvanceReport,
  scheduleReport,
  getEmployeeForm
};
