import { observable, action,decorate,reaction } from 'mobx';  
import { userService } from '../_services';
import CommonStore from './common.store'; 
import { toast } from 'react-toastify';
import { history } from '../_helpers';  
// import ErrorPut from '../_components/_common/error';
class AuthStore {
  inProgress = false; 
  currentUser = JSON.parse(localStorage.getItem('user'))
  constructor() {
    reaction(
      () => this.currentUser,
      currentUser => {
        if (currentUser) {
          localStorage.setItem('user', JSON.stringify(currentUser));
        } else {
          localStorage.removeItem('user');
        }
      }
    );
  }
  login(data) {
    this.inProgress = true;
    CommonStore.errors = []; 
    userService.login(data)
    .then(
        data => { 
            CommonStore.setToken(data.token)
            this.pullUser(data.result) 
            history.push('/mid-office/home'); 
        }, error => {  
            CommonStore.errors = error;  
        }
    ).finally(action(() => { this.inProgress = false; }));
  } 

  logout() {
    // commonStore.setToken(undefined);
    this.forgetUser();
    return Promise.resolve();
  }
 

  pullUser(data) {
    this.loadingUser = true;
    this.currentUser = data; 
  }

  forgetUser() {
    this.currentUser = undefined;
  }
}
decorate(AuthStore, {
  inProgress: observable,
  currentUser: observable,
  login: action,
  logout:action,
  pullUser:action,
  forgetUser:action
})

export default new AuthStore();
