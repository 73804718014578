import React from "react";
import Pagination from "react-js-pagination";
import DropdownButton from "./components/DropDownButton";
import EmployeePermission from "./components/EmployeePermission";
import ApproverPermission from "./components/ApproverPermission";
import Childrens from "./components/Childrens";
import { NavLink } from "react-router-dom";


const ConnectedListsPage = props => {
  const {
    connectedListData,
    getListManagement,
    handleConnectedListStatus,
    handleConnectedListStatusSelected,
    validator,
    handleChild,
    handleEmployeePermission,
    handleApproverPermission,
    handlePageChange,
    handleItemChange,
    formNameHandler,
    toggleListManagement,
    toggleListSelect,
    toggleCustomFieldSelect,
    toggleSelectedForm,
    handleDisplayChangeincrement,
    handleDisplayChangeDecrement,
    validateFields,
    handleResetDiplay,
    finishAndReset,
    submitAndContinue,
    handleHeaderButtonClick
  } = props;

  console.log("childrens", connectedListData.childrens);
  console.log("Parent", connectedListData.parentId);

  let activeStyle = ["bg-primary text-white"];
  let isItemSelected = connectedListData.selectedConnectedList.length;
  let removeButtonStyle = ["btn "];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
  }
  // -------------------connected list ------------------------------
  let connectedList =
    connectedListData.connectedLists &&
    connectedListData.connectedLists.map(connectedList => {
      return (
        <tr
          key={connectedList.id}
          className={
            connectedListData.selectedConnectedList.some(
              i => i.id === connectedList.id
            )
              ? activeStyle
              : null
          }>
          <td onClick={toggleListManagement.bind(this, connectedList)}>
            {connectedList.listName}
          </td>
          <td onClick={toggleListManagement.bind(this, connectedList)}>
            {connectedList.level}
          </td>
          <td>
            <p className="td-padding">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={e => handleConnectedListStatus(connectedList)}
                  checked={connectedList.activeStatus}
                  value={connectedList.activeStatus}
                />
                <span className="slider round" />
              </label>{" "}
              <span>{connectedList.activeStatus ? "Active" : "Inactive"}</span>
            </p>
          </td>
        </tr>
      );
    });
  // --------------------List management - page 1--------------------
  let listManagement =
    connectedListData.listManagements &&
    connectedListData.listManagements.slice(0, 10).map(list => {
      return (
        <tr
          key={list.id}
          className={
            connectedListData.selectedList.some(i => i.id === list.id)
              ? activeStyle
              : null
          }>
          <td onClick={toggleListSelect.bind(this, list)}>{list.itemName}</td>
          <td onClick={toggleListSelect.bind(this, list)}>{list.level}</td>
        </tr>
      );
    });

  // ------------ Custom Fields - page 2-----------------------------------

  let customFields =
    connectedListData.customFields &&
    connectedListData.customFields.slice(0, 8).map(field => {
      return (
        <tr
          key={field.id}
          className={
            connectedListData.selectedCustomField.some(i => i.id === field.id)
              ? activeStyle
              : null
          }>
          <td onClick={toggleCustomFieldSelect.bind(this, field)}>
            {field.fieldName}
          </td>
        </tr>
      );
    });

  // ------------ select forms - last page------------------------

  let forms =
    connectedListData.forms &&
    connectedListData.forms.slice(0, 8).map(form => {
      return (
        <tr
          key={form.id}
          className={
            connectedListData.selectedForm.some(i => i.id === form.id)
              ? activeStyle
              : null
          }>
          <td onClick={toggleSelectedForm.bind(this, form)}>{form.formName}</td>
        </tr>
      );
    });

  // ---------------------------------------------------------
  return (
    <div>
      <div className="admin-tabs">
        <ul className="nav nav-tabs">
          <li>
            <NavLink
              className="show"
              to="/database-settings/admin-form/forms/list">
              Forms
              </NavLink>
          </li>
          <li>
            <NavLink
              className="show"
              to="/database-settings/admin-form/forms-fields/list"
              aria-current="page">
              Forms Fields
              </NavLink>
          </li>
          <li>
            <NavLink
              className="show"
              to="/database-settings/admin-form/fields/list">
              Fields
              </NavLink>
          </li>
          <li>
            <NavLink
              className="show active"
              to="/database-settings/admin-form/connected-lists/lists">
              Connected Lists
              </NavLink>
          </li>
        </ul>
      </div>
      <div className="admin-tabs">
        {/* <Menu /> */}
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Connected Lists</h6>
              <p className="text">These are connectedList</p>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="px-padding">
            <div className="row">
              <div className="col-4">
                <div className="form-group custom labelup">
                  <label>Form Type</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-border-basic dropdown-toggle btn-block"
                      data-toggle="dropdown">
                      {connectedListData.selectedFormHeader
                        ? connectedListData.selectedFormHeader
                        : "Select Form Type"}
                    </button>
                    <div className="dropdown-menu">
                      {connectedListData.formHeaderName
                        ? connectedListData.formHeaderName.map(formhead => {
                            return (
                              <DropdownButton
                                handleHeaderButtonClick={
                                  handleHeaderButtonClick
                                }
                                key={formhead.id}
                                id={formhead.id}
                                name={formhead.formHeaderName}
                              />
                            );
                          })
                        : "No Headers Found"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-10">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      className="btn "
                      data-toggle="modal"
                      data-target="#AddFormModaltype"
                      onClick={getListManagement}>
                      New Connected List
                    </button>
                    {/* <button
                      className="btn "
                      type="button"
                      onClick={e =>
                        handleConnectedListStatusSelected(
                          connectedListData.selectedConnectedList
                        )
                      }
                      disabled={isItemSelected ? false : true}>
                      Activate / Deactivate
                    </button> */}
                    {/* <button
                      type="button"
                      className="btn "
                      disabled={isItemSelected ? false : true}
                      data-toggle="modal"
                      data-target="#viewConnectedList">
                      View Details
                    </button> */}
                  </div>
                </div>
                {/* <div className="col-md-2">
                  <div className="search-custom">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <i className="icon ion-md-search" />
                    </span>
                  </div>
                </div> */}
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Connected List Name</th>
                    <th>Levels</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {connectedList && connectedList ? (
                    connectedList
                  ) : (
                    <tr>
                      <td colSpan="3">No Data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {connectedListData.connectedLists && (
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown">
                            {connectedListData.itemPerPage}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              onClick={handleItemChange.bind(this, 10)}
                              className="dropdown-item">
                              10
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 20)}
                              className="dropdown-item">
                              20
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 30)}
                              className="dropdown-item">
                              30
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={connectedListData.activePage}
                      itemsCountPerPage={connectedListData.itemPerPage}
                      totalItemsCount={connectedListData.totalItemsCount}
                      pageRangeDisplayed={1}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddFormModaltype"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EditFormModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="EditFormModalLabel">
                create connected list{" "}
              </h5>
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleResetDiplay}>
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>

            <div className="admin-tabs">{/* <Menu /> */}</div>
            <div className="modal-middle pm-30">
              <div className="step-1">
                {connectedListData.displayConnectedList === 0 ? (
                  <>
                    <div className="row">
                      <div className="table-responsive">
                        <table className="table table-striped table-movable">
                          <thead>
                            <tr>
                              <th>List Name</th>
                              <th>Level</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listManagement ? (
                              listManagement
                            ) : (
                              <tr>
                                <td colSpan={2}>No data found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="connect-list-name">
                      <h6>Connected List Name</h6>
                      <div className="form-group custom">
                        <input
                          type="text"
                          placeholder="Enter connected list Name"
                          className="form-control"
                          onChange={formNameHandler}
                          name="connectedListName"
                          value={connectedListData.connectedListName || ""}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="step-2">
                {connectedListData.displayConnectedList === 1 ? (
                  <>
                    <div className="row">
                      <div className="table-responsive">
                        <table className="table table-striped table-movable">
                          <thead>
                            <tr>
                              <th>Custom Field Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customFields ? (
                              customFields
                            ) : (
                              <tr>
                                <td colSpan={2}>No data found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="step-3">
                {connectedListData.displayConnectedList === 2 ? (
                  <>
                    <div className="px-padding">
                      <div className="table-container">
                        <div className="row">
                          <div className="col-md-9">
                            <h6 className="sub-heading">
                              <b>Select Level Properties</b>
                            </h6>
                            <div className="form-group custom">
                              <input
                                type="text"
                                placeholder="&nbsp;"
                                className="form-control"
                                name="fieldName"
                                onChange={formNameHandler}
                                value={connectedListData.fieldName}
                              />
                              {validator.message(
                                "Field Name",
                                connectedListData.fieldName,
                                "required|alpha"
                              )}
                              <label>Field Name</label>
                            </div>
                            {/* <h6>Field Label</h6> */}
                            <div className="form-group custom">
                              <input
                                type="text"
                                placeholder="&nbsp;"
                                className="form-control"
                                onChange={formNameHandler}
                                name="labelName"
                                value={connectedListData.labelName}
                              />
                              {validator.message(
                                "labelName",
                                connectedListData.labelName,
                                "required|alpha"
                              )}
                              <label>Field Label</label>
                            </div>
                            <div className="form-group custom labelup">
                              <label>Data Type</label>
                              <input
                                type="text"
                                placeholder="&nbsp;"
                                className="form-control"
                                value={connectedListData.dataType}
                                disabled
                              />
                            </div>
                            <h6 className="sub-heading">
                              <b>Access Rights</b>
                            </h6>
                            <div className="form-group custom labelup">
                              <label>Employee Role</label>
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle"
                                  data-toggle="dropdown">
                                  {connectedListData.employeeRole
                                    ? connectedListData.employeeRole
                                    : "Select the permission"}
                                </button>
                                <div className="dropdown-menu">
                                  {connectedListData.permissionCodes
                                    ? connectedListData.permissionCodes.map(
                                        permission => {
                                          return (
                                            <EmployeePermission
                                              handleEmployeePermission={
                                                handleEmployeePermission
                                              }
                                              key={permission.id}
                                              id={permission.id}
                                              permission={permission.permission}
                                            />
                                          );
                                        }
                                      )
                                    : "No permissions found"}
                                </div>
                                {validator.message(
                                  "employeeRole",
                                  connectedListData.employeeRole,
                                  "required|alpha"
                                )}
                              </div>
                            </div>
                            <div className="form-group custom labelup">
                              <label>Employee Admin Role</label>
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle"
                                  data-toggle="dropdown">
                                  {connectedListData.employeeAdminRole
                                    ? connectedListData.employeeAdminRole
                                    : "Select the permission"}
                                </button>
                                <div className="dropdown-menu">
                                  {connectedListData.permissionCodes
                                    ? connectedListData.permissionCodes.map(
                                        permission => {
                                          return (
                                            <ApproverPermission
                                              handleApproverPermission={
                                                handleApproverPermission
                                              }
                                              key={permission.id}
                                              id={permission.id}
                                              permission={permission.permission}
                                            />
                                          );
                                        }
                                      )
                                    : "No permissions found"}
                                </div>
                                {validator.message(
                                  "employeeAdminRole",
                                  connectedListData.employeeAdminRole,
                                  "required"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="step-4">
                {connectedListData.displayConnectedList === 3 ? (
                  <>
                    <div className="px-padding">
                      <div className="table-container">
                        <div className="row">
                          <div className="col-md-6 offset-3">
                            <div className="py-padding">
                              <div className="form-group custom labelup">
                                <label>
                                  {connectedListData.selectedList.length !== 0
                                    ? connectedListData.selectedList[0].itemName
                                    : "select list management"}
                                </label>
                                <div className="dropdown">
                                  <button
                                    type="button"
                                    className="btn dropdown-toggle"
                                    data-toggle="dropdown">
                                    {connectedListData.selectedChild
                                      ? connectedListData.selectedChild
                                      : "Select children type"}
                                  </button>
                                  <div className="dropdown-menu">
                                    {connectedListData.childrens
                                      ? connectedListData.childrens.map(
                                          child => {
                                            return (
                                              <Childrens
                                                handleChild={handleChild}
                                                key={child.id}
                                                id={child.id}
                                                child={child.itemName}
                                              />
                                            );
                                          }
                                        )
                                      : "No Data Type found"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="offset-3" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="step-5">
                {connectedListData.displayConnectedList === 4 ? (
                  <>
                    <div className="row">
                      <div className="table-responsive">
                        <table className="table table-striped table-movable">
                          <thead>
                            <tr>
                              <th>Forms</th>
                            </tr>
                          </thead>
                          <tbody>
                            {forms ? (
                              forms
                            ) : (
                              <tr>
                                <td colSpan={2}>No data found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <div className="modal-last btn-group-margin">
              <button
                type="button"
                className="btn btn-border-basic lg-padding mr-auto"
                onClick={handleDisplayChangeDecrement}
                style={{
                  display:
                    connectedListData.displayConnectedList === 0 ? "none" : ""
                }}>
                Back
              </button>

              {connectedListData.displayConnectedList === 0 ? (
                <button
                  type="button"
                  className="btn btn-border-success lg-padding"
                  onClick={handleDisplayChangeincrement}
                  disabled={
                    connectedListData.selectedList.length === 0
                      ? true
                      : connectedListData.connectedListName === null
                      ? true
                      : false
                  }>
                  Next
                </button>
              ) : connectedListData.displayConnectedList === 1 ? (
                <button
                  type="button"
                  className="btn btn-border-success lg-padding"
                  onClick={handleDisplayChangeincrement}
                  disabled={
                    connectedListData.selectedCustomField.length === 0
                      ? true
                      : false
                  }>
                  Next
                </button>
              ) : connectedListData.displayConnectedList === 2 ? (
                <button
                  type="button"
                  className="btn btn-border-success lg-padding"
                  onClick={validateFields}
                  disabled={
                    connectedListData.fieldName === null &&
                    connectedListData.fieldLabelName === null &&
                    connectedListData.dataType === null &&
                    connectedListData.employeeRole === null &&
                    connectedListData.employeeAdminRole === null
                      ? true
                      : false
                  }>
                  Next
                </button>
              ) : connectedListData.displayConnectedList === 3 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-border-success lg-padding"
                    data-dismiss="modal"
                    onClick={finishAndReset}>
                    Finish
                  </button>
                  <button
                    type="button"
                    className="btn btn-border-success lg-padding"
                    onClick={submitAndContinue}>
                    Save and Continue
                  </button>
                </>
              ) : null}
              <button
                type="button"
                className="btn btn-border-basic lg-padding"
                data-dismiss="modal"
                onClick={handleResetDiplay}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* View details */}
      <div
        className="modal fade"
        id="viewConnectedList"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="viewConnectedList"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="viewConnectedList">
                Field Details
              </h5>
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>

            <div className="modal-middle pm-30">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-striped table-movable">
                    <thead>
                      <tr>
                        <th>Form Name</th>
                        <th>Column Name</th>
                        <th>Mandatory</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {connectedListData.selectedConnectedList.length &&
                            connectedListData.selectedConnectedList[0].listName}
                        </td>
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            {/* </div> */}
            <div className="modal-last btn-group-margin">
              <button
                type="button"
                className="btn btn-border-basic lg-padding"
                data-dismiss="modal">
                Close
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConnectedListsPage;
