import React, { Component } from 'react';
class DashboardMainPage extends Component {
    render() {
        return (
            <div className="TermsPrivacyPage">
                <div className="page-header">
                    <h6 className="heading">Dashboard</h6>
                </div>
                <div className="page-content">
                    <div className="p-padding">
                        Dashboard works
          </div>
                </div>
            </div>
        );
    }
}

export default DashboardMainPage;
