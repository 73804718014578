import React, { Component } from 'react';
import { history } from '../../../_helpers';
import {Input,Select } from "../../_common/_forms";
import { testMidOfficeServices, masterService } from "../../../_services";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import { toast } from "react-toastify";
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import UserDetailsPage from '../../history/userDetails';
import HotelDetails from './hotelDetails';
import ReactSelect from 'react-select';

class BookedHotelDetailsPage extends Component {
    constructor() {
        super();
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.validator = new SimpleReactValidator();
        this.validatorUpdate2 = new SimpleReactValidator();
        this.validateForm = new SimpleReactValidator();
        this.validateForm2 = new SimpleReactValidator();
        this.validateForm3 = new SimpleReactValidator();
        this.state = {
            data: {},
            statusType: "",
            paymentType: "",
            farePolicy: "",
            hotelId: '',
            hotelFareData: {},
            totalFare: "",
            totalTax: "",
            cancellation: "",
            paymentName: 'Payment Type',
            hotelFareId: '',
            reasonName: "Reason",
            currencyName: '',
            fareCurrency: '',
            hotelETicketData: {},
            vendorTypes: [],
            vendorName: 'Select',
            isLoading: true,
            userDetails: {},
            userId: '',
            queueName: '',
            savedPassVisa: {}
        }
    }
    queueTypes = [
        { "items": "Move to Non-API booking", "id": "Non_Api_Booking" },
        //  { "items": "Move to Queue Processing", "id": "Queue_Processing" },
        //  { "items": "Move to Vendor Queue", "id": "Vendor_Queue" },
        { "items": "Move to Cancellation Queue", "id": "Cancellation_Queue" }];
    componentDidMount() {
        let data = {
            id: this.props.match.params.id
        }
        this.getHotelDetail(data);
        this.getPaymentType();
        this.getFarePolicyType();
        this.getReasonType();
        this.getVendorsDetails();

    }
    getHotelDetail = async (data) => {
        this.setState({ isLoading: true })
        var res = await testMidOfficeServices.getTripDetail(data)
        if (res) {
            this.setState({ data: res.result });
            this.setState({
                hotelId: res.result.id,
                userId: res.result.userId,
                isLoading: false,
                vendorName: res.result.vendorId ? res.result.vendorId.vendorName : 'Select',
                queueName: res.result.statusType ? res.result.statusType : 'Select',
                statusType: res.result.statusType ? res.result.statusType : 'Select'
            });
            this.getValidateFareData();
            this.getBasicData();
            this.getHotelETicket();
            this.getChosenPassportVisa();
        }
    }
    getChosenPassportVisa = async () => {
        let res = await testMidOfficeServices.getChosenPassportVisa(this.state.data.tripId, this.state.data.userId);
        if (res) {
            this.setState({ savedPassVisa: res.result });
        }
    }

    updateQueueType = async (e) => {
        e.preventDefault();
        let data = {
            statusType: this.state.statusType
        }
        if (this.validatorUpdate2.allValid()) {
            var resp = await testMidOfficeServices.updateQueueType(this.state.data.id, data);
            if (resp) {
                toast.success(resp.message);
                $(".close").click();
                this.props.history.push(
                    "/test/mid-office/home"
                );
            }
        } else {
            this.validatorUpdate2.showMessages();
            this.forceUpdate();
        }

    };
    changeQueue = (val, feild) => {
        this.setState({ queueName: val.items, [feild]: val.id });
    };
    paymentTypes = [];
    getPaymentType() {
        testMidOfficeServices.getPaymentType().then(
            data => {
                if (data != null) {
                    this.paymentTypes = data.result;
                }
            },
            error => {
                if (error.statusCode === 422) {
                    let errors = error.message;
                    Object.keys(errors).map(key => {
                        return toast.error(errors[key]);
                    });
                } else {
                    toast.error(error.message);
                }
            }
        );
    }
    changePaymentType = (val, field) => {
        this.setState({ paymentName: val.paymentType, [field]: val.paymentType });
    };
    fareCurrencyTypes = [];
    getFarePolicyType() {
        masterService.getFareCurrencyType().then(
            data => {
                if (data != null) {

                    data.result.forEach((item, i) => {
                        this.fareCurrencyTypes.push({ label: item.itemName, value: item.itemCode })
                    })
                }
            },
            error => {
                if (error.statusCode === 422) {
                    let errors = error.message;
                    Object.keys(errors).map(key => {
                        return toast.error(errors[key]);
                    });
                } else {
                    toast.error(error.message);
                }
            }
        );
    }
    changeFareCurrencyType2 = (val, feild) => {
        this.setState({ currencyName: val.label, [feild]: val.value });
    };
    getValidateFareData = async () => {
        let res = await testMidOfficeServices.getValidateFareData(this.state.hotelId);
        if (res) {
            this.setState({
                hotelFareData: res.result,
                totalFare: (res.result.totalFare).toFixed(2),
                totalTax: (res.result.totalTax).toFixed(2),
                cancellation: res.result.cancellation,
                fareCurrency: res.result.fareCurrency,
                currencyName: res.result.fareCurrency ? res.result.fareCurrency : 'Select',
                paymentType: res.result.paymentType,
                paymentName: res.result.paymentType ? res.result.paymentType : 'Select',
                hotelFareId: res.result.id
            })
        }
    }
    handleFareChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    UpdateHotelFaredata = async (e) => {
        let payLoad = {
            totalFare: this.state.totalFare,
            totalTax: this.state.totalTax,
            cancellation: this.state.cancellation,
            paymentType: this.state.paymentType,
            fareCurrency: this.state.fareCurrency
        };
        if (this.validateForm2.allValid()) {
            let res = await testMidOfficeServices.UpdateHotelFaredata(this.state.hotelFareId, payLoad);
            if (res) {
                toast.success(res.message);
                $(".close").click();
                let data = {
                    id: this.props.match.params.id
                }
                this.getHotelDetail(data);
            }
        } else {
            this.validateForm2.showMessages();
            this.forceUpdate();
        }
    }
    getBasicData = async () => {
        let res = await testMidOfficeServices.getValidateFareData(this.state.hotelId);
        if (res) {
            this.setState({
                bookingReferenceNumber: res.result.bookingReferenceNumber,
                hotelPinCode: res.result.hotelPinCode,
                hotelPhone: res.result.hotelPhone,
                fareCurrency: res.result.fareCurrency,
                contactPerson: res.result.contactPerson,
            })
        }
    }
    handleUpdateChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    UpdateBasicData = async (e) => {
        let payLoad = {
            bookingReferenceNumber: this.state.bookingReferenceNumber,
            hotelPinCode: this.state.hotelPinCode,
            hotelPhone: this.state.hotelPhone,
            contactPerson: this.state.contactPerson,
            pnrStatus: "Confirmed"
        };

        if (this.validateForm.allValid()) {
            let res = await testMidOfficeServices.UpdateBasicData(this.state.hotelFareId, payLoad);
            if (res) {
                toast.success(res.message);
                $(".close").click();
            }
        } else {
            this.validateForm.showMessages();
            this.forceUpdate();
        }

    }
    reasonTypes = [];
    getReasonType() {
        masterService.getReasonType().then(
            data => {
                if (data != null) {
                    this.reasonTypes = data.result;
                }
            },
            error => {
                if (error.statusCode === 422) {
                    let errors = error.message;
                    Object.keys(errors).map(key => {
                        return toast.error(errors[key]);
                    });
                } else {
                    toast.error(error.message);
                }
            }
        );
    }
    changeReason = (val, feild) => {
        this.setState({ reasonName: val.reason, [feild]: val.reason });
    };
    handleChangeComment = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    updateCancelBookingData = async () => {
        let data = {
            reasonType: this.state.reasonType,
            comments: this.state.comments
        }
        if (this.validateForm3.allValid()) {
            var resp = await testMidOfficeServices.updateBookingCancelData(this.state.data.id, data);
            if (resp) {
                toast.success(resp.message);
                $(".close").click();
                this.props.history.push(
                    "/test/mid-office/home"
                );
            }

        } else {
            this.validateForm3.showMessages();
            this.forceUpdate();
        }
    }
    handleMarkComplete = async () => {
        let res = await testMidOfficeServices.UpdateAsMarkComplete(this.state.data.id);
        if (res) {
            toast.success(res.message);
            this.props.history.push(
                "/test/mid-office/home"
            );
        }
    }
    getHotelETicket = async () => {
        let res = await testMidOfficeServices.getETicketData(this.state.data.tripId, "HOTEL", this.state.data.hotelData.hotel_id);
        if (res) {
            this.setState({ hotelETicketData: res.result });
        }
    }
    getVendorsDetails = async () => {
        let res = await testMidOfficeServices.getVendorsDetails();
        if (res) {
            this.setState({ vendorTypes: res.result });
        }
    }
    changeVendor = async (val, feild) => {
        let data = {
            vendorId: val.id
        }
        let res = await testMidOfficeServices.changeVendorType(this.props.match.params.id, data);
        if (res) {
            toast.success(res.message);
        }
        this.setState({ vendorName: val.vendorName, vendorType: val.vendorName });
    };


    enablePopUp = () => {
        this.setState({ popUp: true })
        this.getUserDetails();
    }
    closePopUp = () => {
        this.setState({ popUp: false })
    }
    getUserDetails = async () => {
        let res = await testMidOfficeServices.getUserDetails(this.state.userId);
        if (res) {
            this.setState({ userDetails: res.result })
        }
    }

    render() {
        const { data, hotelETicketData, userDetails } = this.state;
        return (
            <div>
                <div className="admin-tabs">
                    <div className="inner-header">
                        <div className="shape-header">
                            <div className="left">F</div>
                            <div className="right">
                                <h6 className="heading">{data.tripId}<br />{data.travellerName}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="booking-bg">
                        <div className="p-padding">
                            <div className="traveller-details">
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td>TRAVELER NAME <br /><b>{data.travellerName}</b></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="text-right">
                                                    <button className="btn" onClick={(e) => this.enablePopUp(e)} >User Details</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>BOOKED BY <br /><b>{data.travellerName}</b></td>
                                                <td>EMAIL ID <br /><b>{data.email}</b></td>
                                                <td>MOBILE NUMBER <br /><b>--</b></td>
                                                <td></td>
                                                <td>MEAL PREFERENCE <br /><b>{data.mealRequest ? data.mealRequest : '--'}</b></td>
                                            </tr>
                                            <tr>
                                                <td>AMOUNT <br />
                                                    <b>
                                                        <NumberFormat
                                                            value={(data.hotelData != undefined) ? (Math.floor(data.hotelData.hotel_room_info.hotel_room_price * 1000) / 1000) : ""}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={data.baseCurrency + ' '}
                                                        />
                                                    </b>
                                                </td>
                                                <td>TOTAL TAX <br />
                                                    <b>
                                                        <NumberFormat
                                                            value={(data.hotelData && data.hotelData.hotel_room_info.hotel_taxes != undefined) ? (Math.floor(data.hotelData.hotel_room_info.hotel_taxes * 1000) / 1000) : "0"} displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={data.baseCurrency + ' '} />
                                                    </b>
                                                </td>
                                                <td>TOTAL AMOUNT <br />
                                                    <b>
                                                        <NumberFormat
                                                            value={(data.hotelData != undefined) ? (Math.floor((data.hotelData.hotel_room_info.hotel_total_price) * 1000) / 1000) : ''} displayType={'text'} thousandSeparator={true}
                                                            prefix={data.baseCurrency + ' '}
                                                        />

                                                    </b>
                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="p-padding pt-0">
                            <div className="accordion" id="accordionExample">
                                {
                                    (this.state.isLoading) ?
                                        <div className="mx-auto text-center">
                                            <img
                                                width="200"
                                                className="img-fluid "
                                                alt="no flight"
                                                src="/assets/images/loader.gif"
                                            />
                                            <p>Loading please wait...</p>
                                        </div>
                                        :
                                        <HotelDetails
                                            data={data}
                                        />

                                }
                            </div>
                        </div>
                        <div className="p-padding pt-0">
                            <div className="px-15 py-padding bg-white">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group custom labelup mb-0">
                                            <label>Where was this ticket booked</label>
                                            {this.state.vendorTypes && (
                                                <Select
                                                    placeholder={this.state.vendorName}
                                                    keyName={"vendorName"}
                                                    options={this.state.vendorTypes}
                                                    controlFunc={e =>
                                                        this.changeVendor(e, "vendorType")
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 offset-4">
                                        <div className="text-right">
                                            <div className="form-group custom labelup mb-0 d-inline-block w-70">
                                                <label>Move to</label>
                                                {this.queueTypes && (
                                                    <Select
                                                        placeholder={this.state.queueName}
                                                        keyName={"items"}
                                                        options={this.queueTypes}
                                                        controlFunc={e =>
                                                            this.changeQueue(e, "statusType")
                                                        }
                                                        error={this.validatorUpdate2.message(
                                                            "Reason",
                                                            this.state.statusType,
                                                            "required|string"
                                                        )}
                                                    />
                                                )}
                                            </div>
                                            <button type="button" className="btn btn-primary ml-3 d-inline-block" onClick={(e) => this.updateQueueType(e)}>move</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-padding pt-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="btn-group-margin">
                                        <button type="button" className="btn " data-toggle="modal" data-target="#validateFareModal">VALIDATE FARE</button>
                                        <button type="button" className="btn btn-warning bg-warning text-white" data-toggle="modal" data-target="#updateFareModal">UPDATE</button>
                                        <button type="button" className="btn" onClick={history.goBack}>Back</button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="btn-group-margin text-right">
                                        {/* <button type="button" className="btn " data-toggle="modal"
                                            data-target="#HotelETicketModal">E-Ticket</button> */}
                                        <button type="button" className="btn btn-success"
                                            data-toggle="modal" data-target="#markCompleteModal"
                                        >MARK COMPLETE</button>

                                        <button type="button" className="btn btn-danger mr-0" data-toggle="modal" data-target="#cancelBookingModal">CANCEL BOOKING</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.popUp && userDetails.userFields ?
                    <UserDetailsPage
                        popUp={this.state.popUp}
                        userDetails={userDetails}
                        closePopUp={this.closePopUp}
                        savedPassVisa={this.state.savedPassVisa}
                    />
                    : ''}
                <div className="modal fade" id="HotelETicketModal" tabIndex="-1" role="dialog" aria-labelledby="HotelETicketLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-head">
                                <h6 className="modal-subtitle">VIEW</h6>
                                <h5 className="modal-title text-capitalize text-white">Hotel E-Ticket</h5>
                            </div>
                            <div className="modal-middle pm-30">
                                <div className="card-header bg-white p-0 border-0">
                                </div>
                                <div className="card-body p-0">
                                    <div className="border pm-15 mb-3">
                                        <h6 className="font-weight-bold mb-3">Account Information</h6>
                                        <table className="table table-bordered mb-0">
                                            <tbody>
                                                <tr>
                                                    <th className="border-0">Account Name</th>
                                                    <td className="border-0"><b></b></td>
                                                </tr>
                                                <tr>
                                                    <th className="border-0">TR Reference No</th>
                                                    <td className="border-0"></td>
                                                </tr>
                                                <tr>
                                                    <th className="border-0">Service Reference No</th>
                                                    <td className="border-0"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h6 className="font-weight-bold my-3">Traveller Information</h6>
                                        <table className="table table-bordered mb-0">
                                            <thead>
                                                <tr className="bg-light text-uppercase">
                                                    <th>Passenger Name</th>
                                                    <th>Booking Ref #</th>
                                                    <th>Curio Ref #</th>
                                                    <th>Booking Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{hotelETicketData && hotelETicketData.travellerName}</td>
                                                    <td>{hotelETicketData.pnrData ? hotelETicketData.pnrData.bookingReferenceNumber : ''}</td>
                                                    <td>{hotelETicketData.pnrData ? hotelETicketData.pnrData.tripId : ''}</td>
                                                    <td>{hotelETicketData.data ? hotelETicketData.data.hotel_from : ''}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <h6 className="font-weight-bold mb-3">Hotel Information</h6>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="bg-light text-uppercase">
                                                <th>Hotel Name</th>
                                                <th>Room Category</th>
                                                <th>check-in</th>
                                                <th>check-out</th>
                                                <th>Total-fare</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{hotelETicketData.data ? hotelETicketData.data.hotel_name : ''}</td>
                                                <td>{hotelETicketData.data ? hotelETicketData.data.hotel_room_info.hotel_room_name : ''}</td>
                                                <td>{hotelETicketData.data ? hotelETicketData.data.hotel_from : ''}</td>
                                                <td>{hotelETicketData.data ? hotelETicketData.data.hotel_to : ''}</td>
                                                <td>{hotelETicketData.pnrData ? (hotelETicketData.pnrData.totalFare + hotelETicketData.pnrData.totalTax) + ' ' + hotelETicketData.data.hotel_currency_code : ''}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6 className="font-weight-bold mb-3">Cancellation Policy</h6>
                                    <p>{hotelETicketData.pnrData ? hotelETicketData.pnrData.cancellation : ''}</p>
                                </div>
                                <div className="modal-last btn-group-margin pt-3">
                                    <button type="button" className="btn mb-0" data-dismiss="modal" >Okay
                            <i className="icon ion-md-arrow-dropright icon-margin"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="updateFareModal" tabIndex="-1" role="dialog" aria-labelledby="updateFareModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-head">
                                <h6 className="modal-subtitle">Action</h6>
                                <h5 className="modal-title text-white" id="validateFareLabel">UPDATE</h5>
                            </div>
                            <div className="bg-light pm-30 py-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="unmuted-p lighter-p mb-1">CURIO Reference No</p>
                                        <p className="font-weight-bold text-uppercase mb-0">{data.tripId}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="unmuted-p lighter-p mb-1">Traveller Name</p>
                                        <p className="font-weight-bold text-uppercase mb-0">{data.travellerName}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-middle pm-15 modal-px-30">
                                <Input
                                    label="Booking Reference Number "
                                    name="bookingReferenceNumber"
                                    type="text"
                                    value={this.state.bookingReferenceNumber}
                                    controlFunc={(e) => this.handleUpdateChange(e)}
                                    error={this.validateForm.message(
                                        "Booking Reference Number",
                                        this.state.bookingReferenceNumber,
                                        "required|string"
                                    )}
                                />
                                <Input
                                    label="Hotel Pin Code "
                                    name="hotelPinCode"
                                    type="text"
                                    value={this.state.hotelPinCode}
                                    controlFunc={(e) => this.handleUpdateChange(e)}
                                    error={this.validateForm.message(
                                        "hotelPinCode",
                                        this.state.hotelPinCode,
                                        "required|integer"
                                    )}
                                />
                                <Input
                                    label="Hotel Phone "
                                    name="hotelPhone"
                                    type="phone"
                                    value={this.state.hotelPhone}
                                    controlFunc={(e) => this.handleUpdateChange(e)}
                                    error={this.validateForm.message(
                                        "Phone",
                                        this.state.hotelPhone,
                                        "required|phone"
                                    )}
                                />
                                <Input
                                    label="Contact Person"
                                    name="contactPerson"
                                    type="text"
                                    value={this.state.contactPerson}
                                    controlFunc={(e) => this.handleUpdateChange(e)}
                                    error={this.validateForm.message(
                                        "Contact person",
                                        this.state.contactPerson,
                                        "required|string"
                                    )}
                                />

                                <div className="modal-last btn-group-margin">
                                    <div className="row">
                                        <div className="col-md-5">
                                        </div>
                                        <div className="col-md-7">
                                            <div className="btn-group-margin">
                                                <button type="button" className="btn mb-0" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn mb-0"
                                                    data-dismiss={this.validateForm.allValid() ? "modal" : ''}
                                                    onClick={(e) => this.UpdateBasicData(e)}>Save
                                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                                                </button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="validateFareModal" tabIndex="-1" role="dialog" aria-labelledby="validateFareLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-head">
                                <h6 className="modal-subtitle">Action</h6>
                                <h5 className="modal-title text-white" id="validateFareLabel">VALIDATE FARE</h5>
                            </div>
                            <div className="bg-light pm-30 py-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="unmuted-p lighter-p mb-1">CURIO Reference No</p>
                                        <p className="font-weight-bold text-uppercase mb-0">{data.tripId}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="unmuted-p lighter-p mb-1">Traveller Name</p>
                                        <p className="font-weight-bold text-uppercase mb-0">{data.travellerName}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-middle pm-15 modal-px-30">
                                <Input
                                    label="Total Fare"
                                    name="totalFare"
                                    type="text"
                                    value={this.state.totalFare}
                                    controlFunc={(e) => this.handleFareChange(e)}
                                    error={this.validateForm2.message(
                                        "Total Fare",
                                        this.state.totalFare,
                                        "required|numeric"
                                    )}
                                />
                                <Input
                                    label="Total Tax"
                                    name="totalTax"
                                    type="text"
                                    value={this.state.totalTax}
                                    controlFunc={(e) => this.handleFareChange(e)}
                                    error={this.validateForm2.message(
                                        "Total tax",
                                        this.state.totalTax,
                                        "required|numeric"
                                    )}
                                />
                                <div className="form-group custom labelup" style={{ zIndex: 999999 }}>
                                    <label>Fare Currency</label>
                                    {this.fareCurrencyTypes && (
                                        <ReactSelect
                                            value={{ label: this.state.fareCurrency }}
                                            isSearchable={true}
                                            options={this.fareCurrencyTypes}
                                            onChange={(e) => this.changeFareCurrencyType2(e, "fareCurrency")}
                                        />
                                    )}
                                    <div style={{ fontSize: "12px", color: "#ff7979" }}>
                                        {this.validateForm2.message(
                                            "Fare currency",
                                            this.state.fareCurrency,
                                            "required"
                                        )}
                                    </div>

                                </div>

                                <Input
                                    label="Cancellation"
                                    name="cancellation"
                                    type="text"
                                    value={this.state.cancellation}
                                    controlFunc={(e) => this.handleFareChange(e)}
                                    error={this.validateForm2.message(
                                        "Cancellation",
                                        this.state.cancellation,
                                        "required|string"
                                    )}
                                />

                                <div className="form-group custom labelup">
                                    <label>Payment Type</label>
                                    {this.paymentTypes && (
                                        <Select
                                            placeholder={this.state.paymentName}
                                            keyName={"paymentType"}
                                            options={this.paymentTypes}
                                            controlFunc={e =>
                                                this.changePaymentType(e, "paymentType")
                                            }
                                            error={this.validateForm2.message(
                                                "paymentType",
                                                this.state.paymentType,
                                                "required"
                                            )}
                                        />
                                    )}
                                </div>
                                <div className="modal-last btn-group-margin">
                                    <div className="row">
                                        <div className="col-md-5">
                                        </div>
                                        <div className="col-md-7">
                                            <div className="btn-group-margin">
                                                <button type="button" className="btn mb-0" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn mb-0"
                                                    data-dismiss={this.validateForm2.allValid() ? "modal" : ''}
                                                    onClick={(e) => this.UpdateHotelFaredata(e)}>Save
                                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                                                </button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="cancelBookingModal" tabIndex="-1" role="dialog" aria-labelledby="cancelBookingLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-head">
                                <h6 className="modal-subtitle">ACTION</h6>
                                <h5 className="modal-title text-capitalize text-white" id="cancelBookingLabel"> Cancel Booking</h5>
                            </div>
                            <div className="modal-middle pm-30">
                                <div className="form-group custom labelup">
                                    <label>Reason</label>
                                    {this.reasonTypes && (
                                        <Select
                                            value={this.state.reasonName}
                                            placeholder={this.state.reasonName}
                                            keyName={"reason"}
                                            options={this.reasonTypes}
                                            controlFunc={e =>
                                                this.changeReason(e, "reasonType")
                                            }
                                            error={this.validateForm3.message(
                                                "Reason",
                                                this.state.reasonName,
                                                "required|string"
                                            )}
                                        />

                                    )}

                                </div>
                                <div className="form-group custom ">
                                    <input
                                        type="text"
                                        placeholder="&nbsp;"
                                        className="form-control"
                                        name="comments"
                                        value={this.state.comments}
                                        onChange={(e) => this.handleChangeComment(e)}
                                    />
                                    {this.validateForm3.message(
                                        "comments",
                                        this.state.comments,
                                        "required"
                                    )}
                                    <label>Comments</label>
                                </div>
                                <div className="travel_alert pm-15 rounded">
                                    <div className="d-inline-block alert-icon">
                                        <p><i className="icon ion-md-alert "></i></p>
                                    </div>
                                    <div className="d-inline-block alert-detail">
                                        <p>This flight is not in compliance with the following travel rule(s)</p>
                                    </div>
                                </div>
                                <div className="modal-last btn-group-margin pt-4">
                                    <button type="button" className="btn" data-dismiss="modal">Cancel</button>

                                    <button type="button" className="btn  mb-0"
                                        data-dismiss={this.validateForm3.allValid() ? "modal" : ''}
                                        onClick={(e) => this.updateCancelBookingData()}>Save<i className="icon ion-md-arrow-dropright icon-margin"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="markCompleteModal" tabIndex="-1" role="dialog" aria-labelledby="markCompleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-head">
                                <h6 className="modal-subtitle">ACTION</h6>
                                <h5 className="modal-title text-capitalize text-white" id="cancelBookingLabel"> Mark Complete</h5>
                            </div>
                            <div className="modal-middle pm-30">

                                <div className="d-inline-block alert-detail">
                                    <h5>Are you sure you want to mark it as complete?</h5>
                                </div>

                                <div className="modal-last btn-group-margin pt-4">
                                    <button type="button" className="btn" data-dismiss="modal">NO</button>

                                    <button type="button" className="btn  mb-0" onClick={(e) => this.handleMarkComplete(e)} data-dismiss="modal">YES
                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default BookedHotelDetailsPage;
