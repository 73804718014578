import PageNotFoundPage from "./page-not-found";
import DashboardMainPage from "./dashboard-main";
import UserPage from "./user";
import TermsPrivacyPage from "./terms-privacy";
import { ProjectsPage } from "./projects";
import { HistoryPage } from "./history";
import ClientPage from "./clients/main";
import ReportMainPage from "./reporting/main";
import ProfitableReportMainPage from "./profitable-reports/main";
import IntegrationMainPage from "./integration/main";
import DatabaseSettingsPage from "./database-settings/database-settings";
import MidOfficeMainPage from "./mid-office/mid-office";
import MidOfficeTestMainPage from "./test/mid-office/mid-office";
import { TestHistoryPage } from "./test-history/history";
import SAP from "./sap";
const routes = [
  {
    path: "/",
    exact: true,
    name: "Main Page",
    component: MidOfficeMainPage
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: DashboardMainPage
  },
  { path: "/users", exact: true, name: "User", component: UserPage },
  {
    path: "/mid-office/:sub",
    name: "User",
    component: MidOfficeMainPage
  },
  {
    path: "/test/mid-office/:sub",
    name: "User",
    component: MidOfficeTestMainPage
  },
  {
    path: "/terms-privacy",
    exact: true,
    name: "TermsPrivacy",
    component: TermsPrivacyPage
  },
  { path: "/history", name: "History", component: HistoryPage },
  { path: "/test-history/history", name: "History", component: TestHistoryPage },
  { path: "/projects", exact: true, name: "Projects", component: ProjectsPage },
  { path: "/projects/:subfolder", name: "Projects", component: ProjectsPage },
  { path: "/client", exact: true, name: "Clients", component: ClientPage },
  { path: "/client/:subfolder", name: "client", component: ClientPage },

  { path: "/reporting", exact: true, name: "Report", component: ReportMainPage },
  { path: "/reporting/:subfolder", name: "Report", component: ReportMainPage },

  { path: "/profitable-reports", exact: true, name: "Report", component: ProfitableReportMainPage },
  { path: "/profitable-reports/:subfolder", name: "Report", component: ProfitableReportMainPage },

  { path: "/integration", exact: true, name: "Report", component: IntegrationMainPage },
  { path: "/integration/:subfolder", name: "Report", component: IntegrationMainPage },

  {
    path: "/database-settings",
    exact: true,
    name: "DatabaseSettings",
    component: DatabaseSettingsPage
  },
  {
    path: "/sap",
    exact: true,
    name: "SAP",
    component: SAP
  },
  {
    path: "/database-settings/:subpath",
    name: "DatabaseSettings",
    component: DatabaseSettingsPage
  },
  { path: "**", name: "", component: PageNotFoundPage }
];
export default routes;
