import React from "react";
import { NavLink } from "react-router-dom";

const TableRow = props => {
  return (
    <>
      {props.loading === true ? (
        <tbody>
          <tr>
            <td>"Please wait..."</td>
          </tr>
        </tbody>
      ) : !props.dataList.length ? (
        <tbody>
          <tr>
            <td className="text-center" colSpan="6">
              No records found
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {props.dataList &&
            props.dataList.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.sendTo}</td>
                <td>{item.editableBy}</td>
                <td>{item.default ? "YES" : "NO"}</td>
                <td>
                  <NavLink
                    to={
                      item.activeStatus
                        ? `/database-settings/add-edit-template/${item.id}`
                        : "#"
                    }
                    className="btn btn-nothing hover-v"
                  >
                    <i className="material-icons fs-20">edit</i>
                    <span className="hover-name right-top">Edit</span>
                  </NavLink>
                </td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={()=>{props.statusHandler(item)}}
                      checked={item.activeStatus}
                    />
                    <span className="slider round" />
                  </label>
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </>
  );
};
export default TableRow;
