import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class MidOfficeMenu extends Component {
    render() {
        return (
            <div>
                <div className="booking-choice">
                    <NavLink to="/test/mid-office/flight" className="s5"><i className="icon ion-md-airplane"></i>Flight</NavLink>
                    <NavLink to="/test/mid-office/hotel" className="s5"><i className="icon ion-md-bed"></i>Hotel</NavLink>
                    <NavLink to="/test/mid-office/flight" className="s5"><i className="icon ion-md-bus"></i>Bus</NavLink>
                    <NavLink to="/test/mid-office/flight" className="s5"><i className="icon ion-md-train"></i>Train</NavLink>
                    <NavLink to="/test/mid-office/flight" className="s5"><i className="icon ion-logo-usd"></i>Forex</NavLink>
                </div>
            </div>
        )
    }
}
export default MidOfficeMenu