import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import FieldsPage from "./fields/fields";
import CreateFormsFieldsPage from "./create-form-fields/create-form-fields";
import ModifyFormsFields2Page from "./modify-form-fields2/modify-form-fields2";
class FeildsMainPage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/database-settings/admin-form/fields") {
      this.props.history.push("/database-settings/admin-form/fields/list");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname === "/database-settings/admin-form/fields"
    ) {
      this.props.history.push("/database-settings/admin-form/fields");
    }
  }
  render() {
    return (
      <div className="FFMainPage">
        <Switch>
          <Route
            path="/database-settings/admin-form/fields/list"
            component={FieldsPage}
          />
          <Route
            path="/database-settings/admin-form/fields/create-form-fields/:id"
            component={CreateFormsFieldsPage}
          />
          <Route
            path="/database-settings/admin-form/forms-fields/modify-form-fields/:id"
            component={ModifyFormsFields2Page}
          />
        </Switch>
      </div>
    );
  }
}
export default FeildsMainPage;
