
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { testMidOfficeServices, masterService } from "../../../_services";
import SimpleReactValidator from "simple-react-validator";
import { Input, Select } from "../../_common/_forms";
import $ from "jquery";
import { toast } from "react-toastify";
import NumberFormat from 'react-number-format';

class HotelBookingRequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalCount: null,
      pnrData: {},
      search: "",
      limit: 10,
      page: 1,
      pageItemsList: [{ items: 10 }, { items: 20 }, { items: 30 }],
      clientList: [],
      clientName: 'Filter items by company',
      clientType: ''

    }
    this.validator = new SimpleReactValidator;
    this.validatorUpdate = new SimpleReactValidator;
  }

  componentDidMount() {
    let data = {
      bookingType: this.props.match.params.bookingType,
      statusType: this.props.match.params.statusType,
      isInternational: this.props.match.params.find,
      search: this.state.search,
      limit: this.state.limit,
      page: this.state.page
    }
    this.getHotelBookingRequest(data);
    this.getClientList();
  }
  getHotelBookingRequest = async (data) => {
    var res = await testMidOfficeServices.getHotelBookingRequest(data)
    if (res) {
      this.setState({
        data: res.result,
        totalCount: res.count,
        search: "",
        limit: this.state.limit,
        page: this.state.page,
      })
    }
    else {
      this.setState({ data: [], totalCount: null })
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  searchHandler = (e) => {
    let search = e.target.value;
    if (search !== '') {
      let data = {
        bookingType: this.props.match.params.bookingType,
        statusType: this.props.match.params.statusType,
        isInternational: this.props.match.params.find,
        search: e.target.value,
        limit: this.state.limit,
        page: this.state.page
      }
      this.getHotelBookingRequest(data);
    }
    else {
      let data = {
        bookingType: this.props.match.params.bookingType,
        statusType: this.props.match.params.statusType,
        isInternational: this.props.match.params.find,
        search: this.state.search,
        limit: this.state.limit,
        page: this.state.page
      }
      this.getHotelBookingRequest(data);
    }
  }
  handleItemChange = limit => {
    this.setState({ limit: limit.items });
    let data = {
      bookingType: this.props.match.params.bookingType,
      statusType: this.props.match.params.statusType,
      isInternational: this.props.match.params.find,
      search: this.state.search,
      limit: limit.items,
      page: this.state.page
    }
    this.getHotelBookingRequest(data);
  };
  handlePageChange = (pageNumber) => {
    this.setState({
      page: pageNumber,
    })
    let data = {
      bookingType: this.props.match.params.bookingType,
      statusType: this.props.match.params.statusType,
      isInternational: this.props.match.params.find,
      search: this.state.search,
      limit: this.state.limit,
      page: pageNumber
    }
    this.getHotelBookingRequest(data);
  }
  getClientList = async () => {
    let res = await testMidOfficeServices.getClientList();
    if (res) {
      console.log(res);
      this.setState({ clientList: res.result });
    }
  }
  changeClient = (val, field) => {
    this.setState({ clientName: val.companyName, [field]: val.id });
    let data = {
      bookingType: this.props.match.params.bookingType,
      statusType: this.props.match.params.statusType,
      isInternational: this.props.match.params.find,
      search: this.state.search,
      limit: this.state.limit,
      page: this.state.page,
      companyId: val.id,
    }
    this.getHotelBookingRequest(data);
  }
  render() {
    let data = this.props;
    const datas = this.state.data;
    const { pnrData } = this.state;
    console.log(pnrData);
    const displayHotelData = (datas.length > 0) ? datas.map((item, i) => {
      return (
        <tr>
          <td className="text-primary"><NavLink to={`/test/mid-office/booked-hotel-details/${item.id}`}>{item.tripId}
          </NavLink></td>
          <td>{item.travellerName}</td>
          <td>{item.hotelData.hotel_name}</td>
          <td>{item.hotelData.hotel_room_info.hotel_room_name}</td>
          <td>
            <NumberFormat
              value={(Math.floor(item.hotelData.hotel_room_info.hotel_total_price * 100) / 100)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={item.hotelData.hotel_currency_code + ' '}
            />
          </td>
          <td>{item.bookingDate}</td>
          <td>{item.company}</td>
          <td>{item.hotelData.hotel_city}</td>
        </tr>
      )
    }) : <tr><td colSpan="8" className="text-center">No data is available</td></tr>;

    return (
      <div>
        <div className="page-header">
          <div class="resp-toggle-menu">
            <i className="material-icons">menu</i>
          </div>
          <h6 className="heading">Hotel Booking Request</h6>
        </div>
        <div className="page-content">
          <div className="admin-tabs">
            <div className="py-padding">
              <div className="table-container">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group ">
                        {this.state.clientList && (
                          <Select
                            placeholder={this.state.clientName}
                            keyName={"companyName"}
                            options={this.state.clientList}
                            controlFunc={e =>
                              this.changeClient(e, "clientType")
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 offset-5">
                      <div className="search-custom">
                        <input type="text" className="form-control" placeholder="Search"
                          onChange={(e) => this.searchHandler(e)} />
                        <span><i className="material-icons align-middle">search</i></span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>CURIO REF NO</th>
                        <th>NAME</th>
                        <th>HOTEL NAME</th>
                        <th>ROOM CATEGORY</th>
                        <th>TOTAL AMOUNT</th>
                        <th>BOOKING DATE</th>
                        <th>COMPANY</th>
                        <th>LOCATION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayHotelData}
                    </tbody>
                  </table>
                </div>
                <div className="table-footer">
                  <div className="row">
                    <div className="col-md-3">
                      {this.state.totalCount && (
                        <div className="page-count">
                          <div className="text">Items</div>
                          <div className="count">
                            <div className="dropdown">
                              <Select
                                placeholder={this.state.limit}
                                keyName={"items"}
                                options={this.state.pageItemsList}
                                controlFunc={e => this.handleItemChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-9 text-right">
                      {console.log(this.state.totalCount)}
                      {this.state.totalCount && <Pagination
                        activePage={this.state.page}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalCount}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="validateFareModal" tabIndex="-1" role="dialog" aria-labelledby="validateFareLabel" aria-hidden="true">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">Action</h6>
                <h5 className="modal-title text-white" id="validateFareLabel">VALIDATE FARE</h5>
              </div>
              <div className="bg-light pm-30 py-3">
                <div className="row">
                  <div className="col-md-6">
                    <p className="unmuted-p lighter-p mb-1">CURIO Reference No</p>
                    <p className="font-weight-bold text-uppercase mb-0">ABC258349-SF</p>
                  </div>
                  <div className="col-md-6">
                    <p className="unmuted-p lighter-p mb-1">Traveller Name</p>
                    <p className="font-weight-bold text-uppercase mb-0">Vishwa A</p>
                  </div>
                </div>
              </div>
              <div className="modal-middle pm-15 modal-px-30">
                <div className="form-group custom">
                  <input type="text" className="form-control"
                    placeholder="&nbsp;"
                    name="totalFare" />
                  <label>Total Fare </label>
                </div>
                <div className="form-group custom">
                  <input type="text" placeholder="&nbsp;" className="form-control"
                    name="totalTax"
                    onChange={(e) => this.handleChange(e)} />
                  <label>Total Tax </label>
                </div>

                <div className="form-group custom labelup">
                  <label>Fare Policy</label>
                  {this.farePolicyTypes && (
                    <Select
                      placeholder={this.policyName}
                      keyName={"items"}
                      options={this.farePolicyTypes}
                      controlFunc={e =>
                        this.changeFairPolicy(e, "farePolicy")
                      }
                      error={this.validator.message(
                        "Fare policy",
                        this.state.farePolicy,
                        "required"
                      )}
                    />
                  )}
                </div>
                <div className="form-group custom">
                  <input type="text" placeholder="&nbsp;" className="form-control"
                    name="cancellation" />
                  <label>Cancellation</label>
                </div>
                <div className="form-group custom labelup">
                  <label>Payment Type</label>
                  {this.paymentTypes && (
                    <Select
                      placeholder={this.paymentName}
                      keyName={"paymentType"}
                      options={this.paymentTypes}
                      controlFunc={e =>
                        this.changePaymentType(e, "paymentType")
                      }
                      error={this.validator.message(
                        "paymentType",
                        this.state.paymentType,
                        "required"
                      )}
                    />
                  )}
                </div>
                <div className="modal-last btn-group-margin">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="text-left">
                        <button type="button" className="btn bg-warning text-white" data-toggle="modal" data-target="#cancelBookingModal" data-dismiss="modal" >Cancel Booking</button>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="btn-group-margin">
                        <button type="button" className="btn  mb-0" data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn  mb-0" onClick={this.UpdateHotelPnrdata}>Save
                                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                        </button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="cancelBookingModal" tabIndex="-1" role="dialog" aria-labelledby="cancelBookingLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">ACTION</h6>
                <h5 className="modal-title text-capitalize text-white" id="cancelBookingLabel"> Cancel Booking</h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <label>Reason</label>
                  <div className="dropdown">
                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                      Other Reason
                                        </button>
                    <div className="dropdown-menu">
                      <button className="dropdown-item">1</button>
                      <button className="dropdown-item">2</button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <input type="text" placeholder="TEXT" className="form-control" />
                  <label>Comments</label>
                </div>
                <div className="travel_alert pm-15 rounded">
                  <div className="d-inline-block alert-icon">
                    <p><i className="icon ion-md-alert "></i></p>
                  </div>
                  <div className="d-inline-block alert-detail">
                    <p>This flight is not in compliance with the following travel rule(s)</p>
                  </div>
                </div>
                <div className="modal-last btn-group-margin pt-4">
                  <button type="button" className="btn mb-0" data-dismiss="modal">Cancel</button>

                  <button type="button" className="btn  mb-0">Save
                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HotelBookingRequestPage;
