import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import PropTypes from 'prop-types';

import TableRow from "./Table-row";

const EmailReminderPage = props => {
  const { navBar } = props;
  return (
    <div>
      <div className="page-header">
        <h6 className="heading">Email Reminders</h6>
      </div>
      <div className="page-content">
        <div className="admin-tabs">
          <div className="inner-header">
            <div className="shape-header">
              <div className="left">E</div>
              <div className="right">
                <h6 className="heading">Manage Email Reminders</h6>
                <p className="text">You can use email reminders to replace with your own content and customise to your requirement.</p>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
        <div className="p-padding pb-0">{navBar}</div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row justify-content-between">
                <div className="col-md-7">
                  <div className="btn-group-margin">
                    <NavLink
                      to="/database-settings/add-edit-reminder/"
                      className="btn"
                    >
                      Add Reminder
                  </NavLink>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="search-custom">
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Search"
                      onChange={e => props.onInputHandler(e)}
                    />
                    <span>
                      <i className="icon ion-md-search" />
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Template Name</th>
                    <th>Reminder Type</th>
                    <th>Frequency</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <TableRow {...props} />
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {props.itemsPerPage}
                        </button>
                        <div className="dropdown-menu">
                          <button
                            className="dropdown-item"
                            onClick={e => props.OnPageLimitHandler(10)}
                          >
                            10
                        </button>
                          <button
                            className="dropdown-item"
                            onClick={e => props.OnPageLimitHandler(25)}
                          >
                            25
                        </button>
                          <button
                            className="dropdown-item"
                            onClick={e => props.OnPageLimitHandler(50)}
                          >
                            50
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    activePage={props.currentPage}
                    itemsCountPerPage={props.itemsPerPage}
                    totalItemsCount={props.countTotalItems}
                    pageRangeDisplayed={3}
                    onChange={props.OnPageChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EmailReminderPage.propTypes = {
  OnPageChangeHandler: PropTypes.func,
  OnPageLimitHandler: PropTypes.func,
  onInputHandler: PropTypes.func,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number
};
export default EmailReminderPage;
