import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import IntegrationPage from "./home";

class IntegrationMainPage extends Component{
    constructor(props) {
        super(props)
        if (props.location.pathname === '/integration') {
            this.props.history.push('/integration/home')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/integration') {
            this.props.history.push('/integration/home')
        }
    }
    render() {
        return (
            <div className="ReportMainPage">
                <div className="InsideName">
                    <Switch>
                        <Route path='/integration/home' component={IntegrationPage} />                        
                    </Switch>
                </div>
            </div>
        );
    }
}

export default IntegrationMainPage ;