import { http } from "../_helpers";
import { config } from "../env";


const getCommonData = (data) => {
  console.log(data);
  return http.get(config.apiMidOfficeLive + `trips/history?bookingType=${data.bookingType}&search=${data.search}&page=${data.page}&limit=${data.limit}&companyId=${data.companyId ? data.companyId : ''}`)
    .then(data => {
      return data ? data : "";
    });
};

const getTripData = (id) => {
  let url = `${config.apiMidOfficeLive}bookings/get-one-trip/${id}`;
  return http.get(url)
    .then(res => res);
}

export const historyServices = {
  getCommonData,
  getTripData,
};
