import React from 'react';

const DropdownButton = props => {
    const {handleHeaderButtonClick,name,id} = props;
    return(
        <button type="button" onClick={e =>handleHeaderButtonClick(id,name)} className="dropdown-item">
            {name}
        </button>
    )
}

export default DropdownButton;