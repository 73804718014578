import React, { Component } from 'react';
import SystemListPage from './system-list-html';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import { expenseServices } from '../../../_services/expense.service';
import { systemListServices } from '../../../_services/systemList.service';
import { string } from 'prop-types';
import { toJS } from 'mobx';
import { toast } from 'react-toastify';
// import * as _ from 'underscore';

class SystemList extends Component {
  constructor(props) {
    super(props)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.validator = new SimpleReactValidator()
    this.state = {
      name: '',
      code: '',
      parentId: '',
      children: [],
      myId: ''
    }
  }
  componentDidMount() {
    this.getList()
  }
  type = '';
  className = '';
  title = '';
  search = '';
  parentId = '';
  mainId = '';
  btnType = (e) => {
    this.setState({
      name: '',
      code: '',
      parentId: '',
    })
    this.validator.hideMessages()
    if (this.type === e && this.type <= 3) {
      this.type = ''; this.className = ''; this.title = ''; this.forceUpdate(); return;
    } else {
      this.type = ''; this.className = ''; this.title = '';
      if (e === 1) {
        this.type = e; this.className = "add-new-popup"; this.title = "Add New";
      } else if (e === 2) {
        this.type = e; this.className = "add-new-popup plus"; this.title = "Add New Child";
      } else if (e === 3) {
        this.type = e; this.className = "add-new-popup edit"; this.title = "Edit Item";
        this.setValue()
      }
    }
    this.forceUpdate()
  }
  pastedItems = []
  destId = ''
  setValue = () => {
    this.listOfItems.forEach(item => {
      if (item.id === this.parentId) {
        console.log(item)
        this.state.name = item.name;
        this.state.code = item.code;
        this.state.id = item.id;
        if (item.parentId) {
          this.state.parentId = item.parentId;
          this.parentId = item.parentId;
        } else {
          this.state.parentId = '';
          this.parentId = '';
        }
      }
    });
    this.setState({
      ...this.state
    })
    this.forceUpdate();
  };
  handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      this.setState({
        [e.target.name]: e.target.checked
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }
  // for creation
  handleSubmit = async (e) => {
    if (this.validator.allValid()) {
      if (this.parentId) {
        var req = {
          name: this.state.name,
          code: this.state.code,
          parentId: this.parentId,
        }
      } else {
        var req = {
          name: this.state.name,
          code: this.state.code,
        }
      }
      console.log(req);
      var resp = await systemListServices.createListItem(req);
      if (resp) {
        if (this.parentId) {
          toast.success("Created Sucessfully")
          this.lanchBreadCum(this.detailView)
        }
        this.btnType()
        this.getList()
      }
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  listOfItems = []
  getList = async () => {
    this.listOfItems = [];
    this.mainId = this.parentId
    var resp = await systemListServices.getSystemList(this.search, this.parentId)
    if (resp) {
      this.parentStack = []
      this.detailView = ''
      this.listOfItems = resp;
      console.log("list of Items ", this.listOfItems);
    }
    this.forceUpdate()
  }
  choosedItem = [];
  parentStack = [];
  chooseFolder = (e, item) => {
    console.log(item.parentId);
    this.setState({ myId: item.parentId });
    const index = this.parentStack.indexOf(e);

    this.parentStack.length === 0 ? (this.parentId = e) : (this.parentId = "");
    this.parentStack.length === 0
      ? this.setState({ parentId: e })
      : this.setState({ parentId: "" });
    if (this.parentId) this.getParentDetail(this.parentId);
    if (index === -1) {
      if (this.parentStack.length > 0) {
        this.detailView = "";
      }
      this.parentStack.push(e);
    } else {
      this.detailView = "";
      this.parentStack.splice(index, 1);
      if (this.parentStack.length === 1) {
        this.parentId = this.parentStack[0];
        this.setState({ parentId: this.parentStack[0] });
        this.getParentDetail(this.parentId);
      } else if (this.parentStack.length === 0) {
        this.parentId = "";
        this.setState({
          parentId: ""
        });
      }
    }

    this.forceUpdate();
  };
  detailView = ''
  getParentDetail = async (parentId) => {
    var resp = await systemListServices.getParentDetailById(parentId);
    if (resp) {
      console.log("resp is ", resp);
      this.detailView = resp;
      this.getChildren(resp.id);
      this.forceUpdate()
    }
  }
  getChildren = async (parentId) => {
    let res = await systemListServices.getSubFields(parentId);
    if (res) {
      console.log(res);
      this.setState({ children: res.result });
    }
  }

  handleDoubleClick = (e, item) => {
    console.log(item);
    this.parentId = e;
    this.getSubFields(e);
    this.lanchBreadCum(item);
  }
  getSubFields = async (parentId) => {
    this.listOfItems = [];
    let res = await systemListServices.getSubFields(parentId);
    if (res) {
      this.parentStack = []
      this.detailView = ''
      this.listOfItems = res.result;
      console.log("list of Items ", this.listOfItems);

    }
    this.forceUpdate();
  }
  breadCum = []
  lanchBreadCum = (item) => {
    console.log(item);
    if (item) {
      var req = {
        name: item.name,
        code: item.code,
        id: item.id,
        parentId: item.parentId
      }
    }

    const inx = this.breadCum.indexOf(req)
    if (inx === -1) {
      this.breadCum.push(req)
    } else {
      this.breadCum.splice(inx, 1)
    }
    console.log(this.breadCum);
    this.forceUpdate()
  }
  navNew = async (e, key) => {
    console.log(e);
    console.log(key);
    console.log(this.breadCum);
    var items = this.breadCum;
    this.breadCum = [];
    console.log(items);
    await items.forEach((item, indexKey) => {
      if (indexKey < key) {
        this.breadCum.push(item)
      } else {
        return false
      }
    })
    console.log(this.breadCum);
    this.handleDoubleClick(e.id, e);
  }
  homeNav = () => {
    this.parentId = '';
    this.setState({
      parentId: ''
    })
    this.breadCum = []
    this.getList()
  }
  sideNavOpt = (e, item, detailView) => {
    this.lanchBreadCum(detailView);
    this.handleDoubleClick(e, item);
  }

  // for updating list item
  handleUpdateSubmit = async () => {
    if (this.validator.allValid()) {
      console.log(this.parentId);
      if (this.parentId) {
        var req = {
          id: this.state.id,
          name: this.state.name,
          code: this.state.code,
          parentId: this.parentId
        }
      } else {
        var req = {
          id: this.state.id,
          name: this.state.name,
          code: this.state.code,
        }
      }
      console.log(req);
      var resp = await systemListServices.updateListItem(req)
      if (resp) {
        toast.info("Updated Sucessfully")
        if (this.parentId) {
          this.parentId = this.detailView.parentId
        }
        this.btnType()
        this.getList()
      }
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  handleSearch = async (e) => {
    this.search = e.target.value;
    this.getList()
  }
  render() {
    return (
      <div>
        <SystemListPage
          myId={this.state.myId}
          children={this.state.children}
          error={this.validator}
          state={this.state}
          type={this.type}
          btnType={this.btnType}
          className={this.className}
          title={this.title}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          listOfItems={this.listOfItems}
          choosedItem={this.choosedItem}
          chooseFolder={this.chooseFolder}
          parentStack={this.parentStack}
          handleDoubleClick={this.handleDoubleClick}
          detailView={this.detailView}
          breadCum={this.breadCum}
          navNew={this.navNew}
          homeNav={this.homeNav}
          sideNavOpt={this.sideNavOpt}
          handleUpdateSubmit={this.handleUpdateSubmit}
          pastedItems={this.pastedItems}
          handleSearch={this.handleSearch}

        />
      </div>
    )
  }
}
export default SystemList;
