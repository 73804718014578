import React, { Component } from "react";
import ModifyFormsFieldsPage2 from "./modify-form-fields2-html";
import { companyAdmin } from "../../../../_services/companyAdmin";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { alertConstants } from "../../../../_constants";

class ModifyFormsFields2 extends Component {
  state = {
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    sort: "asc",
    fields: [],
    selectedItemId: [],
    selectedField: [],
    dataTypes: [
      { id: 1, dataType: "text" },
      { id: 2, dataType: "integer" },
      { id: 3, dataType: "connected_list" },
      { id: 4, dataType: "file" },
      { id: 5, dataType: "amount" },
      { id: 6, dataType: "boolean" },
      { id: 7, dataType: "checkbox" },
      { id: 8, dataType: "date" },
      { id: 9, dataType: "email" },
      { id: 10, dataType: "search" },
      { id: 11, dataType: "Different List" }
    ],
    displayTypes: [
      { id: 1, displayType: "text" },
      { id: 2, displayType: "number" },
      { id: 3, displayType: "email" },
      { id: 4, displayType: "fileUpload" },
      { id: 5, displayType: "profileUpload" },
      { id: 6, displayType: "select" },
      { id: 7, displayType: "checkbox" },
      { id: 8, displayType: "amount" },
      { id: 9, displayType: "boolean" },
      { id: 10, displayType: "date" },
      { id: 11, displayType: "search" },
      { id: 12, dataType: "Different List" }
    ],
    permissionCodes: [
      { id: 1, permission: "Read Only" },
      { id: 2, permission: "Write" },
      { id: 3, permission: "Modify" },
      { id: 4, permission: "Full Access" }
    ],
    //-------- create fields--------
    serviceModuleId: localStorage.getItem("serviceModuleId"),
    formHeaderId: sessionStorage.getItem("fieldsFormHeaderId"),
    redirectionPath: sessionStorage.getItem("redirectModify"),
    companyId: sessionStorage.getItem("companyId"),

    fieldName: "",
    labelName: "",
    columnName: "",
    tableName: "",
    dataType: "",
    displayType: "",
    maxLength: "",
    isAdmin: true,
    isMandatory: null,
    customField: null,
    isFormField: null,

    isPiiEncrpt: null, //is pi encrypt and ispinoencryt is not same, ie, if it is true, other is false,
    isPiiNotEncrypt: null,
    isNotPii: null, // also is this will be false if either of the above is true

    results: [],
    permission: "",

    roles: []
  };
  validator = new SimpleReactValidator();

  // ------------------Handle Form -------------------------------------------

  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  // ------------------------------------------------------------------

  // ------------------Handle checked -------------------------------------------

  onisMandatory = () =>
    this.setState({ ...this.state, isMandatory: !this.state.isMandatory });
  onisPiiEncrypt = () => {
    this.setState({ ...this.state, isPiiEncrpt: !this.state.isPiiEncrpt });
  };
  onisPiiNotEncrypt = () =>
    this.setState({
      ...this.state,
      isPiiNotEncrypt: !this.state.isPiiNotEncrypt
    });
  onisNotPii = () =>
    this.setState({ ...this.state, isNotPii: !this.state.isNotPii });

  // onisNotPii = () => this.setState({...this.state, isNotPii:!this.state.isNotPii})
  oncustomField = () => this.setState({ customField: !this.state.customField });
  onisFormField = () =>
    this.setState({ ...this.state, isFormField: !this.state.isFormField });

  // ------------------------------------------------------------------------
  // ---------------- creating forms --------------------------

  handleSubmit = event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let data = {
        fieldName: this.state.fieldName,
        labelName: this.state.labelName,
        columnName: this.state.columnName,
        tableName: this.state.tableName,
        dataType: this.state.dataType,
        displayType: this.state.displayType,
        maxLength: this.state.maxLength,
        isMandatory: this.state.isMandatory,
        customField: this.state.customField,
        isFormField: this.state.isFormField,
        isPiiEncrpt: this.state.isPiiEncrpt,
        isPiiNotEncrypt: this.state.isPiiNotEncrypt,
        isNotPii: this.state.isNotPii
      }; 
      if (this.props.match.params.id) {
        companyAdmin
          .modifyFields(this.props.match.params.id, data)
          .then(res => {
            toast.success(alertConstants.UPDATED_SUCCESSFULLY);
            this.redirect();
          })
          .catch(e => console.log("error", e));
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // ---------------------------------------------------

  // ---------------- select datatype -------------------

  handleDataType = dataType => {
    // if (this.state.dataType !== dataType) {
      
    // } else {
    //   toast.error(alertConstants.ERROR);
    // }
    this.setState({
      dataType: dataType
    });
  };

  // ---------------- ------------------- -------------------
  // ---------------- select display -------------------

  handleDisplayType = (id, displayType) => {
    // if (this.state.displayType !== displayType) {
    //   this.setState({
    //     ...this.state,
    //     displayType
    //   });
    // } else {
    //   toast.error(alertConstants.ERROR);
    // }
    this.setState({
      ...this.state,
      displayType
    });
  };

  // ---------------- ------------------- -------------------

  // ---------------- ------------------- -------------------
  // ---------------- get roles------------------- -------------------
  getRoles = () => {
    companyAdmin.getRoles().then(res => {
      this.setState({
        roles: res.result
      });
    });
  };
  // ------------Cancel------------------------------
  redirect = () => {
    if (this.state.redirectionPath === "fields") {
      this.props.history.push("/database-settings/admin-form/fields/list");
    } else {
      this.props.history.push(
        "/database-settings/admin-form/forms-fields/list"
      );
    }
  };
  getFieldDetails = () => {
    companyAdmin
      .getFieldsDetails(this.props.match.params.id)
      .then(res => {
        console.log(res);
        this.setState({
          serviceModuleId: res.result.serviceModuleId,
          formHeaderId: res.result.formHeaderId,
          fieldName: res.result.fieldName,
          labelName: res.result.labelName,
          columnName: res.result.columnName,
          tableName: res.result.tableName,
          dataType: res.result.dataType,
          displayType: res.result.displayType,
          maxLength: res.result.maxLength,
          isMandatory: res.result.isMandatory,
          customField: res.result.customField,
          isFormField: res.result.isFormField,
          isPiiEncrpt: res.result.isPiiEncrpt,
          isPiiNotEncrypt: res.result.isPiiNotEncrypt,
          isNotPii: res.result.isNotPii
        });
      })
      .catch(err => {
        toast.error(alertConstants.ERROR);
      });
  };
  componentDidMount() {
    this.getFieldDetails();
  }
  render() {
    return (
      <div>
        <ModifyFormsFieldsPage2
          modifyFields={this.state}
          formNameHandler={this.formNameHandler}
          handleSubmit={this.handleSubmit}
          onisMandatory={this.onisMandatory}
          oncustomField={this.oncustomField}
          onisFormField={this.onisFormField}
          onisPiiEncrypt={this.onisPiiEncrypt}
          onisPiiNotEncrypt={this.onisPiiNotEncrypt}
          onisNotPii={this.onisNotPii}
          handleDataType={this.handleDataType}
          handleDisplayType={this.handleDisplayType}
          redirect={this.redirect}
          validator={this.validator}
        />
      </div>
    );
  }
}
export default ModifyFormsFields2;
