import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { ReportPage, NewReportPage, CustomReportPage, CreateCustomReportPage} from './index'
class ReportMainPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/reporting') {
            this.props.history.push('/reporting/report')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/reporting') {
            this.props.history.push('/reporting/report')
        }
    }
    render() {
        return (
            <div className="ReportMainPage">
                <div className="InsideName">
                    <Switch>
                        <Route path='/reporting/report' component={ReportPage} />
                        <Route path='/reporting/new-report' component={NewReportPage} />
                        <Route path='/reporting/custom-report/:serviceModule/:expenseType' component={CustomReportPage} />
                        <Route path='/reporting/create-report' component={CreateCustomReportPage} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default ReportMainPage;
