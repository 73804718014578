import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { projectService, masterService } from "../../_services";
import { Input, Select } from "../_common/_forms";
import SimpleReactValidator from "simple-react-validator";

class ViewClientPage extends Component {
  projectDetail = "";
  projectId = "";
  constructor(props) {
    super(props);
    this.state = {
      contactInfo: {
        name: "",
        designation: "",
        email: "",
        phone: ""
      },
      subDomain: "",
      orderTypeId: "",
      billingCycleId: "",
      orderEffectiveDate: "",
      invoiceGenerationDate: "",
      companyName: "",
      testUrl: '',
      liveUrl: ''
    };
    let user = JSON.parse(localStorage.user)
    this.token = user.token;
    console.log('the user details are ', user);
    this.validator = new SimpleReactValidator();
    this.validatorUpdate = new SimpleReactValidator();
  }


  setTestConsoleUrl = () => {
    if (window.location.host === 'localhost:3000' || window.location.host === 'csa.curiotechnologies.in') {
      this.setState({ testUrl: `${window.location.protocol}//uat.curiotechnologies.in` })
    } else if (window.location.host == 'uatcsa.curiotechnologies.in') {
      this.setState({ testUrl: `${window.location.protocol}//uat.curiotechnologies.in` })
    } else if (window.location.host == 's-admin.curiotechnologies.com') {
      this.setState({ testUrl: `${window.location.protocol}//sandbox.curiotechnologies.com` })
    }
  }

  setLiveConsoleUrl = () => {
    if (window.location.host === 'localhost:3000' || window.location.host === 'csa.curiotechnologies.in') {
      this.setState({ liveUrl: `${window.location.protocol}//livedev.curiotechnologies.in` })
    } else if (window.location.host == 'uatcsa.curiotechnologies.in') {
      this.setState({ liveUrl: `${window.location.protocol}//uatlive.curiotechnologies.in` })
    } else if (window.location.host == 's-admin.curiotechnologies.com') {
      this.setState({ liveUrl: `${window.location.protocol}//te.curiotechnologies.com` })
    }
  }

  componentDidMount() {
    console.log('window ', window.location);
    this.setTestConsoleUrl();
    this.setLiveConsoleUrl();
    this.getOrderType();
    this.getBillingType();
    if (this.props.match.params.id === undefined) {
      this.props.history.push("/projects/list");
    }
    this.projectId = this.props.match.params.id;
    this.getList();
    this.getServiceModule();
  }
  getList() {
    projectService.getOneProjectDetail(this.projectId).then(
      data => {
        this.loading = false;
        if (data != null) {
          this.count = data.count;
          this.projectDetail = data.result;
          this.forceUpdate();
        }
      },
      error => {
        this.loading = false;
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  }
  listServiceModule = [];
  choosedService = [];
  getServiceModule = async () => {
    this.choosedService = [];
    var resp = await masterService.getServiceModuleList();
    if (resp) {
      this.projectDetail &&
        (await this.projectDetail.projectService.forEach((item, key) => {
          if (item.activeStatus === true) {
            this.choosedService.push(item.serviceId);
          }
        }));
      this.listServiceModule = resp.result;
      this.forceUpdate();
    }
    this.forceUpdate();
  };
  editPanel = false;
  editViewPanel = () => {
    this.editPanel = true;
    this.setState({
      contactInfo: {
        name: this.projectDetail.contactInfo["name"],
        phone: this.projectDetail.contactInfo["phone"],
        email: this.projectDetail.contactInfo["email"],
        designation: this.projectDetail.contactInfo["designation"]
      }
    });
    this.forceUpdate();
  };
  panleProject = false;
  editPanelProject = () => {
    this.panleProject = true;
    this.setState({
      subDomain: this.projectDetail.subDomain,
      orderEffectiveDate: this.projectDetail.orderEffectiveDate,
      invoiceGenerationDate: this.projectDetail.invoiceGenerationDate,
      companyName: this.projectDetail.companyName,
      orderTypeId: this.projectDetail.orderTypeId._id,
      billingCycleId: this.projectDetail.billingCycleId._id
    });
    this.orderName = this.projectDetail.orderTypeId.items;
    this.billingName = this.projectDetail.billingCycleId.billingType;
    this.forceUpdate();
  };
  orderTypes = [];
  orderName = "Order Type";
  getOrderType() {
    masterService.getOrderType().then(
      data => {
        if (data != null) {
          this.orderTypes = data.result;
          this.forceUpdate();
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  }
  changeOrder = (val, feild) => {
    this.orderName = val.items;
    this.setState({ [feild]: val.id });
    this.forceUpdate();
  };
  billingTypes = [];
  billingName = "Billing Type";
  getBillingType() {
    masterService.getBillingCycle().then(
      data => {
        if (data != null) {
          this.billingTypes = data.result;
          this.forceUpdate();
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  }
  changeBilling = (val, feild) => {
    this.billingName = val.billingType;
    this.state[feild] = val.id;
    this.forceUpdate();
  };
  handlePersonalInfo = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      var req = {
        contactInfo: { ...this.state.contactInfo }
      };
      var resp = await masterService.updateProject(this.projectId, req);
      if (resp) {
        this.getList();
        this.reset();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleChange = e => {
    this.state.contactInfo[e.target.name] = e.target.value;
    this.setState({
      ...this.state
    });
  };
  handleProfileChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleProfileInfo = async e => {
    e.preventDefault();
    if (this.validatorUpdate.allValid()) {
      var req = {
        subDomain: this.state.subDomain,
        orderTypeId: this.state.orderTypeId,
        billingCycleId: this.state.billingCycleId,
        orderEffectiveDate: this.state.orderEffectiveDate,
        invoiceGenerationDate: this.state.invoiceGenerationDate,
        companyName: this.state.companyName
      };
      var resp = await masterService.updateProject(this.projectId, req);
      if (resp) {
        this.getList();
        this.reset();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  reset = e => {
    this.editPanel = false;
    this.panleProject = false;
    this.forceUpdate();
  };
  changeMode = async e => {
    const index = this.choosedService.indexOf(e);
    if (index === -1) {
      var req = {
        activeStatus: true,
        serviceId: e
      };
      this.choosedService.push(e);
    } else {
      var req = {
        activeStatus: false,
        serviceId: e
      };
      this.choosedService.splice(index, 1);
    }
    var resp = await masterService.updateP2P(this.props.match.params.id, req);
    if (resp) {
      this.getList();
      this.forceUpdate();
    }
  };
  assignClient = async () => {
    var resp = await masterService.createClient(this.projectId);
    if (resp) {
      this.getList();
    }
  };
  render() {
    return (
      <div className="ViewProjectsPage">
        <div>
          <div className="page-header">
            <div class="resp-toggle-menu">
              <i className="material-icons">menu</i>
            </div>
            <h6 className="heading">
              Projects - {this.projectDetail && this.projectDetail.companyName}
            </h6>
          </div>
          <div className="page-content">
            <div className="p-padding">
              <div className="row">
                <div className="col-md-4 col-lg-3">
                  <div className="profile-card">
                    {!this.panleProject && (
                      <div className="details1">
                        <p className="label">Project Name</p>
                        <h6 className="value border-b">
                          {this.projectDetail && this.projectDetail.companyName}
                        </h6>
                        <p className="label">Invoice Date</p>
                        <h6 className="value border-b">
                          {this.projectDetail &&
                            this.projectDetail.invoiceGenerationDate}
                        </h6>
                        <p className="label">Billing Cycle</p>
                        <h6 className="value border-b">
                          {this.projectDetail &&
                            this.projectDetail.billingCycleId &&
                            this.projectDetail.billingCycleId.billingType}
                        </h6>
                        <p className="label">Order Type</p>
                        <h6 className="value border-b">
                          {this.projectDetail &&
                            this.projectDetail.orderTypeId &&
                            this.projectDetail.orderTypeId.items}
                        </h6>
                      </div>
                    )}
                    {!this.panleProject && (
                      <button
                        onClick={e => this.editPanelProject()}
                        className="edit-details"
                      >
                        Edit
                    </button>
                    )}
                  </div>
                  <div className="profile-card">
                    {this.panleProject && (
                      <div className="details">
                        <Input
                          label="Company Name"
                          className=""
                          name="companyName"
                          type="text"
                          value={this.state.companyName}
                          controlFunc={this.handleProfileChange}
                          error={this.validatorUpdate.message(
                            "name",
                            this.state.companyName,
                            "required"
                          )}
                        />
                        {/* <Input
                          label="Sub Domain"
                          className=""
                          name="subDomain"
                          type="text"
                          value={this.state.subDomain}
                          controlFunc={this.handleProfileChange}
                          error={this.validatorUpdate.message(
                            "name",
                            this.state.subDomain,
                            "required"
                          )}
                        /> */}
                        <Input
                          label="Invoice Generation"
                          className=""
                          name="invoiceGenerationDate"
                          type="date"
                          value={this.state.invoiceGenerationDate}
                          controlFunc={this.handleProfileChange}
                          error={this.validatorUpdate.message(
                            "name",
                            this.state.invoiceGenerationDate,
                            "required"
                          )}
                        />
                        <div className="form-group custom labelup">
                          <label>Order Type</label>

                          {this.orderTypes && (
                            <Select
                              placeholder={this.orderName}
                              keyName={"items"}
                              options={this.orderTypes}
                              controlFunc={e =>
                                this.changeOrder(e, "orderTypeId")
                              }
                              error={this.validatorUpdate.message(
                                "orderTypeId",
                                this.state.orderTypeId,
                                "required"
                              )}
                            />
                          )}
                        </div>
                        <div className="form-group custom labelup">
                          <label>Biling Cycle</label>
                          {this.billingTypes && (
                            <Select
                              placeholder={this.billingName}
                              keyName={"billingType"}
                              options={this.billingTypes}
                              controlFunc={e =>
                                this.changeBilling(e, "billingCycleId")
                              }
                              error={this.validatorUpdate.message(
                                "billingCycleId",
                                this.state.billingCycleId,
                                "required"
                              )}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {this.panleProject && (
                      <div className="container">
                        <div className="row">
                          <button
                            className="col-6 edit-details"
                            onClick={e => this.handleProfileInfo(e)}
                          >
                            {" "}
                            Update{" "}
                          </button>
                          <button
                            onClick={e => this.reset()}
                            className="col-6 edit-details"
                          >
                            Cancel
                        </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="profile-card">
                    {!this.editPanel && (
                      <div className="details">
                        <p className="label">Name</p>
                        <h6 className="value">
                          {this.projectDetail &&
                            this.projectDetail.contactInfo["name"]}
                        </h6>
                        <p className="label">Phone</p>
                        <h6 className="value">
                          {this.projectDetail &&
                            this.projectDetail.contactInfo["phone"]}
                        </h6>
                        <p className="label">Email</p>
                        <h6 className="value">
                          {this.projectDetail &&
                            this.projectDetail.contactInfo["email"]}
                        </h6>
                        <p className="label">Designation</p>
                        <h6 className="value">
                          {this.projectDetail &&
                            this.projectDetail.contactInfo["designation"]}
                        </h6>
                      </div>
                    )}
                    {!this.editPanel && (
                      <button
                        onClick={e => this.editViewPanel()}
                        className="col edit-details"
                      >
                        Edit
                      </button>
                    )}
                    {this.editPanel && (
                      <div className="details">
                        <Input
                          label="Name"
                          className=""
                          name="name"
                          type="text"
                          value={this.state.contactInfo.name}
                          controlFunc={this.handleChange}
                          error={this.validator.message(
                            "name",
                            this.state.contactInfo.name,
                            "required"
                          )}
                        />
                        <Input
                          label="Phone Number"
                          className=""
                          name="phone"
                          type="text"
                          value={this.state.contactInfo.phone}
                          controlFunc={this.handleChange}
                          error={this.validator.message(
                            "phone",
                            this.state.contactInfo.phone,
                            "required|max:10|integer"
                          )}
                        />
                        <Input
                          label="Email"
                          className=""
                          name="email"
                          type="email"
                          value={this.state.contactInfo.email}
                          controlFunc={this.handleChange}
                          error={this.validator.message(
                            "email",
                            this.state.contactInfo.email,
                            "required|email"
                          )}
                        />
                        <Input
                          label="Designation"
                          className=""
                          name="designation"
                          type="type"
                          value={this.state.contactInfo.designation}
                          controlFunc={this.handleChange}
                          error={this.validator.message(
                            "designation",
                            this.state.contactInfo.designation,
                            "required"
                          )}
                        />
                      </div>
                    )}
                    {this.editPanel && (
                      <div className="container">
                        <div className="row">
                          <button
                            className="col-6 edit-details"
                            onClick={e => this.handlePersonalInfo(e)}
                          >
                            {" "}
                            Update{" "}
                          </button>
                          <button
                            onClick={e => this.reset()}
                            className="col-6 edit-details"
                          >
                            Cancel
                        </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="profile-card">
                    <div className="details">
                      <h6 className="sub-heading">Implementation Status</h6>
                      <h6 className="mt-2">
                        {this.projectDetail.projectStatus === 0
                          ? "Inprogress"
                          : "Completed"}
                      </h6>
                      {this.projectDetail.projectStatus === 0 && (
                        <div className="right-icon">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={e => this.assignClient()}
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-lg-9">
                  <div className="mb-2">
                    <div className="float-right">
                      <div className="btn-group-margin">
                      <a className="btn" target="_blank" href={this.state.testUrl + "/super-admin/" + this.projectId + "/" + this.token} >Go to Test Console</a>
                      <a className="btn" target="_blank" href={this.state.liveUrl + "/super-admin/" + this.projectId + "/" + this.token} >Go to Live Console</a>
                        {/* <button type="button" className="btn">Go to Test Console</button> */}
                        {/* <button type="button" className="btn">Go to Live Console</button> */}
                      </div>
                    </div>
                    <h5 className="sub-heading float-left">Services to Deploy</h5>
                    <div className="clearfix"></div>
                  </div>

                  {this.listServiceModule &&
                    this.listServiceModule.map((item, key) => {
                      return (
                        <div key={key}>
                          <div className="box1">
                            <div className="left-icon">
                              {(item.serviceModuleName && item.serviceModuleName === "Travel") ?
                                <i class="material-icons">flight</i>
                                : (item.serviceModuleName === "Expense") ?
                                  <i class="material-icons">graphic_eq</i> : <i class="material-icons">money</i>
                              }
                            </div>
                            <div>
                              <div>
                                <h6 className="heading">
                                  {item.serviceModuleName}{" "}
                                  {/* <span>$299/user</span> */}
                                </h6>
                              </div>
                              <p className="m-0">
                                {(item.serviceModuleName && item.serviceModuleName === "Travel") ?
                                  "Experience smooth corporate travel booking along with advanced tools to manage company costs."
                                  : (item.serviceModuleName === "Expense") ?
                                    "Simplify your employee expense reports and claims with controls you need to make it your own" :
                                    "Manage Cash advance process completely from employee request to issuance with ease"
                                }
                              </p>
                            </div>
                            <div className="right-icon">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onClick={e => this.changeMode(item.id)}
                                  defaultChecked={
                                    this.choosedService.indexOf(item.id) !== -1
                                      ? true
                                      : false
                                  }
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewClientPage;
