import React from "react";
import { Input } from "../../../_common/_forms";
const FormItems = props => {
  const {
    error,
    state,
    title,
    isStatic,
    className,
    handleChange,
    handleSubmit,
    btnType,
    type,
    handleUpdateSubmit,
    dontAllow
  } = props;
  return (
    <div className={className}>
      <div className="box-head">
        <h6>
          <img src="/assets/images/item.svg" />
          <b>{title}</b>
        </h6>
      </div>
      <div className="box-content">
        <div>
          <h6>Item Name</h6>
          <div className="form-group custom bg-white">
            <Input
              type="text"
              name="itemName"
              value={state.itemName}
              error={error.message("itemName", state.itemName, "required")}
              controlFunc={e => handleChange(e)}
            />
          </div>
        </div>
        <div>
          {isStatic && title === "Edit Item" ? (
            ""
          ) : (
            <div>
              <h6>Item Code</h6>
              <div className="form-group custom bg-white">
                <Input
                  type="text"
                  name="itemCode"
                  value={state.itemCode}
                  error={error.message("itemCode", state.itemCode, "required")}
                  controlFunc={e => handleChange(e)}
                />
              </div>
            </div>
          )}
        </div>
        {isStatic && title === "Edit Item" ? (
          ""
        ) : (
          <div>
            <label className="checkbox-container">
              {" "}
              Is Static
              <input
                name="isStatic"
                value={state.isStatic}
                onChange={e => handleChange(e)}
                checked={state.isStatic}
                type="checkbox"
              />
              <span className="checkmark" />
            </label>
            <label className="checkbox-container">
              {" "}
              Is Visible
              <input
                name="isVisible"
                value={state.isVisible}
                onChange={e => handleChange(e)}
                checked={state.isVisible}
                type="checkbox"
              />
              <span className="checkmark" />
            </label>
            {/* <div className="form-group custom bg-white"> */}
          </div>
        )}
        <div>
          <div className="btn-group-margin">
            <button
              onClick={type === 3 ? handleUpdateSubmit : handleSubmit}
              className="btn btn-success save-button"
            >
              {type === 3 ? "Update" : "Save"}
            </button>
            <button onClick={btnType} className="btn btn-danger">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormItems;
