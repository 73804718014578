import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import "react-day-picker/lib/style.css";
import { NavLink } from 'react-router-dom';


class FlightDetails extends Component {
    getTimeForIntl = (t) => {
        let time = parseInt(t / 60);
        let hours = parseInt(time / 60);
        const minutes = parseInt(time) % 60;
        let days = 0;
        if (hours >= 24) {
            days = parseInt(hours / 24);
            hours = parseInt(hours % 24);
        }
        return (days === 0 ? '' : days + ' days : ') + hours + ' hrs : ' + minutes;
    }
    getTime = (time) => {
        let hours = parseInt(time / 60);
        const minutes = parseInt(time) % 60;
        let days = 0;
        if (hours >= 24) {
            days = parseInt(hours / 24);
            hours = parseInt(hours % 24);
        }
        return (days === 0 ? '' : days + ' day(s) : ') + hours + ' hrs : ' + minutes;
    }
    render() {
        const { data, eTicket, invoice, pnrDetails } = this.props;
        const flightDetails = (data.flightData != undefined) ? data.flightData.flights.map((flight, index) => {
            return (
                (Array.isArray(flight.fareContent) ?
                    <div className="card border-bottom mb-4">
                        <div className="card-header pm-15 bg-white" id="headingOne">
                            <h5 className="mb-0">
                                <div className="mb-0" data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="true" aria-controls="collapseOne">
                                    <div className="float-left">
                                        {
                                            (flight.flight_airport[flight.flight_from] && flight.flight_airport[flight.flight_from].c ? flight.flight_airport[flight.flight_from].c : flight.flight_from)
                                            + '-' + flight.flight_from + '-' + '  (' +
                                            (flight.flight_airport[flight.flight_from] && flight.flight_airport[flight.flight_from].n ? flight.flight_airport[flight.flight_from].n : flight.flight_from)
                                            + ') '
                                        }
                                        <i class="material-icons" style={{ verticalAlign: "middle" }}> arrow_right_alt</i>

                                        {
                                            (flight.flight_airport[flight.flight_to] && flight.flight_airport[flight.flight_to].c ? flight.flight_airport[flight.flight_to].c : flight.flight_to)
                                            + '-' + flight.flight_to + '-' + '  (' +
                                            (flight.flight_airport[flight.flight_to] && flight.flight_airport[flight.flight_to].n ? flight.flight_airport[flight.flight_to].n : flight.flight_to)
                                            + ') '
                                        }

                                    </div>
                                    <div className="float-right">
                                        {eTicket && invoice && <>
                                            <NavLink to={`/mid-office/flightSwitch/${"eticket"}/${data.tripId}/${flight.fareContent.UNIQUE_ID}/${"FLIGHT"}`} target="_blank" className="btn mr-2">E-Ticket</NavLink>
                                            <NavLink to={`/mid-office/flightSwitch/${"invoice"}/${data.tripId}/${flight.fareContent.UNIQUE_ID}/${"FLIGHT"}`} target="_blank" className="btn mr-2">Invoice</NavLink>
                                        </>
                                        }
                                        <i className="icon ion-md-arrow-dropdown icon-margin"></i>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </h5>
                        </div>
                        <div id={"collapse" + index} className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body pm-30 ">
                                {flight.fareContent.length > 0 && flight.fareContent.map((eachFareContent, i) => {
                                    return (
                                        <div key={i}>
                                            <h5>{flight.flight_airport[eachFareContent.from_city].c + '(' + eachFareContent.from_city + ')' + ' to ' + flight.flight_airport[eachFareContent.to_city].c + '(' + eachFareContent.to_city + ')'}</h5>
                                            <div className="border pm-15 mb-5">
                                                {eachFareContent.content.length > 0 && eachFareContent.content.map((con, myIndex) => {
                                                    return (
                                                        <div>
                                                            <h5 className={myIndex === 0 ? 'd-none' : 'text-center my-4 text-primary'}><b>Change flight here</b></h5>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr className="bg-light text-uppercase">
                                                                        <th>Flight <br /> Number</th>
                                                                        <th>Carrier</th>
                                                                        <th colSpan="2">Departing</th>
                                                                        <th className="text-success">Duration</th>
                                                                        <th colSpan="2">Arrival</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td rowSpan="2" className="text-primary"><b>{con.flight_number}</b></td>
                                                                        <td rowSpan="2">
                                                                            <img style={{ "height": "50px", "width": "auto" }} src={con.flight_image} alt="logo" />
                                                                            <p className="mt-1">{con.flight_operating_airline}</p>
                                                                        </td>
                                                                        <td colSpan="2" className="lighter-p">
                                                                            {
                                                                                con.flight_depature_time + ' - ' +
                                                                                con.flight_depature_date_utc}
                                                                        </td>
                                                                        <td rowSpan="2" >
                                                                            <p className="text-success">{this.getTimeForIntl(con.flight_duration) + ' ' + 'mins'}</p>
                                                                            <p>Non Stop</p></td>
                                                                        <td colSpan="2" className="">
                                                                            {con.flight_arrival_time + ' - ' +
                                                                                con.flight_arrival_date_utc}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="2" className="lighter-p">
                                                                            {
                                                                                (flight.flight_airport[con.flight_from_city] && flight.flight_airport[con.flight_from_city].c ? flight.flight_airport[con.flight_from_city].c : con.flight_from_city)

                                                                                + '-' +

                                                                                '(' +
                                                                                (flight.flight_airport[con.flight_from_city] && flight.flight_airport[con.flight_from_city].n ? flight.flight_airport[con.flight_from_city].n : con.flight_from_city) + ')'
                                                                            }
                                                                        </td>
                                                                        <td colSpan="2" className="">
                                                                            {
                                                                                (flight.flight_airport[con.flight_to_city] && flight.flight_airport[con.flight_to_city].c ? flight.flight_airport[con.flight_to_city].c : con.flight_to_city)
                                                                                + '-' +
                                                                                '(' +
                                                                                (flight.flight_airport[con.flight_to_city] && flight.flight_airport[con.flight_to_city].n ? flight.flight_airport[con.flight_to_city].n : con.flight_to_city) + ')'
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <h6 className="font-weight-bold mb-3">Additional Information</h6>
                                                            <table className="table table-bordered mb-0">
                                                                <thead>
                                                                    <tr className="bg-light text-uppercase">
                                                                        <th>Meal</th>
                                                                        <th>Baggage</th>
                                                                        <th>Hand Baggage</th>
                                                                        <th>Flight Equipment</th>
                                                                        <th>Flight Departure terminal</th>
                                                                        <th>class</th>
                                                                        <th>seat</th>
                                                                        <th>Flight Arrival terminal</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>--</td>
                                                                        <td>{con.flight_bagageInfo !== '' ? con.flight_bagageInfo : '--'}</td>
                                                                        <td>{"--"}</td>
                                                                        <td>{con.flight_eqiupment !== '' ? con.flight_eqiupment : '--'}</td>
                                                                        <td>{con.flight_depature_terminal !== '' ? con.flight_depature_terminal : '--'}</td>
                                                                        <td>{con.class}<br /></td>
                                                                        <td>--</td>
                                                                        <td>{con.flight_arrival_terminal !== '' ? con.flight_arrival_terminal : '--'}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })}

                                            </div>

                                        </div>
                                    )
                                })}
                                <h6 className="font-weight-bold mb-3">Fair Details</h6>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="bg-light text-uppercase">
                                            <th>Air Fare</th>
                                            <th>Airline tax & surcharges</th>
                                            <th>Total Fare</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <NumberFormat
                                                    value={(Math.floor(flight.fare.flight_base_price * 100) / 100)}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={data.baseCurrency + ' '}
                                                />
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    value={(Math.floor(flight.fare.flight_total_tax * 1000) / 1000)}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={data.baseCurrency + ' '}
                                                />
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    value={(Math.floor(flight.fare.flight_total_price * 1000) / 1000)}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={data.baseCurrency + ' '} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className="font-weight-bold mb-3">Tax Breakup</h6>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="text-uppercase">
                                            <td className="font-weight-bold bg-light">AIRLINE - MSC : <br /></td>
                                            <td>cgst:</td>
                                            <td>psf:</td>
                                            <td>sgst:</td>
                                            <td>udf:</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className="font-weight-bold mb-3">Fare Rules and Cancellation Policy</h6>
                                <p>- Use your airline PNR for all your communication for this booking with the airline.</p>
                                <p>- All the times indicated are the local time at the relevant airport and we recommend you check-In 2 hours prior to departure.</p>
                                <p>- All the passangers including Children and infants, must present valid photo identification at check-in.</p>
                                <p>- Carry a printout of this e-ticket and present it to the airline counter at the time of check-In.</p>
                                <p>- Kindly check airline website for baggage information.</p>

                                {
                                    (pnrDetails !== undefined) && (pnrDetails.pnrData.length) > 0 ? <>
                                        <h6 className="font-weight-bold mb-3">Airline Information</h6>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="bg-light text-uppercase">
                                                    <th>PCC</th>
                                                    <th>Vendor PNR</th>
                                                    <th>PNR</th>
                                                    <th>Ticket Number</th>
                                                    <th>Cancellation Charge</th>
                                                    <th>Reschedule Charge</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{pnrDetails.pnrData[index].pcc}</td>
                                                    <td>{pnrDetails.pnrData[index].vendorPnr}</td>
                                                    <td>{pnrDetails.pnrData[index].pnr}</td>
                                                    <td>{pnrDetails.pnrData[index].ticketNumber}</td>
                                                    <td>{pnrDetails.pnrData[index].cancellationCharge + ' ' + pnrDetails.fareCurrency}</td>
                                                    <td>{pnrDetails.pnrData[index].rescheduleCharge + ' ' + pnrDetails.fareCurrency}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </> : ''
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className="card border-bottom mb-4">
                        <div className="card-header pm-15 bg-white" id="headingOne">
                            <h5 className="mb-0">
                                <div className="mb-0" data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="true" aria-controls="collapseOne">
                                    <div className="float-left pt-2">
                                        <span>
                                            {(flight.flight_airport[flight.flight_from] && flight.flight_airport[flight.flight_from].c ? flight.flight_airport[flight.flight_from].c : flight.flight_from)

                                                + '-' + flight.flight_from + '-' + '  (' +
                                                (flight.flight_airport[flight.flight_from] && flight.flight_airport[flight.flight_from].n ? flight.flight_airport[flight.flight_from].n : flight.flight_from) + ') '}
                                        </span>
                                        <i class="material-icons" style={{ verticalAlign: "middle" }}> arrow_right_alt</i>
                                        <span>
                                            {(flight.flight_airport[flight.flight_to] && flight.flight_airport[flight.flight_to].c ? flight.flight_airport[flight.flight_to].c : flight.flight_to)
                                                + '-' + flight.flight_to + '-' + '  (' +
                                                (flight.flight_airport[flight.flight_to] && flight.flight_airport[flight.flight_to].n ? flight.flight_airport[flight.flight_to].n : flight.flight_to) + ')'}
                                        </span>
                                    </div>
                                    <div className="float-right">
                                        {eTicket && invoice && <>
                                            <NavLink to={`/mid-office/flightSwitch/${"eticket"}/${data.tripId}/${flight.fareContent.UNIQUE_ID}/${"FLIGHT"}`} target="_blank" className="btn mr-2">E-Ticket</NavLink>
                                            <NavLink to={`/mid-office/flightSwitch/${"invoice"}/${data.tripId}/${flight.fareContent.UNIQUE_ID}/${"FLIGHT"}`} target="_blank" className="btn mr-2">Invoice</NavLink>
                                        </>
                                        }
                                        <i className="icon ion-md-arrow-dropdown icon-margin"></i>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </h5>
                        </div>
                        <div id={"collapse" + index} className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body pm-30">
                                <div className="border pm-15 mb-3">
                                    {flight.fareContent.content.length > 0 && flight.fareContent.content.map((con, i) => {
                                        return (
                                            <div key={i}>
                                                <h5 className={i === 0 ? "d-none" : 'text-center my-4 text-primary'}><b>{(con.flight_number === (flight.fareContent.content[i - 1] !== undefined ? flight.fareContent.content[i - 1].flight_number : '')) ? "Flight Stop" : "Change flight here"}</b></h5>

                                                <table key={i} className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-light text-uppercase">
                                                            <th>Flight <br /> Number</th>
                                                            <th>Carrier</th>
                                                            <th colSpan="2">Departing</th>
                                                            <th className="text-success">Duration</th>
                                                            <th colSpan="2">Arrival</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td rowSpan="2" className="text-primary">{con.flight_number}</td>
                                                            <td rowSpan="2">
                                                                <img style={{ "height": "50px", "width": "auto" }} src={con.flight_image} alt="logo" />
                                                                <p className="mt-1">{con.flight_operating_airline}</p>
                                                            </td>
                                                            <td colSpan="2" className="lighter-p">
                                                                {
                                                                    con.flight_depature_time + ' - ' +
                                                                    con.flight_depature_date_utc}
                                                            </td>
                                                            <td rowSpan="2" >
                                                                <p className="text-success">{this.getTimeForIntl(con.flight_duration) + ' '}mins</p>
                                                                <p>Non Stop</p></td>
                                                            <td colSpan="2" className="">
                                                                {con.flight_arrival_time + ' - ' +
                                                                    con.flight_arrival_date_utc}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2" className="lighter-p">
                                                                {
                                                                    (flight.flight_airport[con.flight_from_city] && flight.flight_airport[con.flight_from_city].c ? flight.flight_airport[con.flight_from_city].c : con.flight_from_city)

                                                                    + '-' +

                                                                    '(' +
                                                                    (flight.flight_airport[con.flight_from_city] && flight.flight_airport[con.flight_from_city].n ? flight.flight_airport[con.flight_from_city].n : con.flight_from_city) + ')'
                                                                }
                                                            </td>
                                                            <td colSpan="2" className="">
                                                                {
                                                                    (flight.flight_airport[con.flight_to_city] && flight.flight_airport[con.flight_to_city].c ? flight.flight_airport[con.flight_to_city].c : con.flight_to_city)
                                                                    + '-' +
                                                                    '(' +
                                                                    (flight.flight_airport[con.flight_to_city] && flight.flight_airport[con.flight_to_city].n ? flight.flight_airport[con.flight_to_city].n : con.flight_to_city) + ')'
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <h6 className="font-weight-bold mb-3">Additional Information</h6>
                                                <table className="table table-bordered mb-0">
                                                    <thead>
                                                        <tr className="bg-light text-uppercase">
                                                            <th>Meal</th>
                                                            <th>Baggage</th>
                                                            <th>Hand Baggage</th>
                                                            <th>Flight Equipment</th>
                                                            <th>Flight Departure terminal</th>
                                                            <th>class</th>
                                                            <th>seat</th>
                                                            <th>Flight Arrival terminal</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>--</td>
                                                            <td>{con.flight_bagageInfo !== '' ? con.flight_bagageInfo : '--'}</td>
                                                            <td>{"--"}</td>
                                                            <td>{con.flight_eqiupment !== '' ? con.flight_eqiupment : '--'}</td>
                                                            <td>{con.flight_depature_terminal !== '' ? con.flight_depature_terminal : '--'}</td>
                                                            <td>{con.class}<br /></td>
                                                            <td>--</td>
                                                            <td>{con.flight_arrival_terminal !== '' ? con.flight_arrival_terminal : '--'}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })}

                                </div>
                                <h6 className="font-weight-bold mb-3">Fair Details</h6>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="bg-light text-uppercase">
                                            <th>Air Fare</th>
                                            <th>Airline tax & surcharges</th>
                                            <th>Total Fare</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <NumberFormat value={(Math.floor(flight.fareContent.fare.flight_base_price * 1000) / 1000)} displayType={'text'} thousandSeparator={true} prefix={data.baseCurrency + ' '} /></td>
                                            <td>
                                                <NumberFormat value={(Math.floor(flight.fareContent.fare.flight_total_tax * 1000) / 1000)} displayType={'text'} thousandSeparator={true} prefix={data.baseCurrency + ' '} />
                                            </td>
                                            <td>
                                                <NumberFormat value={(Math.floor(flight.fareContent.fare.flight_total_price * 1000) / 1000)} displayType={'text'} thousandSeparator={true} prefix={data.baseCurrency + ' '} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className="font-weight-bold mb-3">Tax Breakup</h6>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="text-uppercase">
                                            <td className="font-weight-bold bg-light">AIRLINE - MSC : <br /></td>
                                            <td>cgst:--</td>
                                            <td>psf:--</td>
                                            <td>sgst:--</td>
                                            <td>udf:--</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className="font-weight-bold mb-3">Fare Rules and Cancellation Policy</h6>
                                <p>- Use your airline PNR for all your communication for this booking with the airline.</p>
                                <p>- All the times indicated are the local time at the relevant airport and we recommend you check-In 2 hours prior to departure.</p>
                                <p>- All the passangers including Children and infants, must present valid photo identification at check-in.</p>
                                <p>- Carry a printout of this e-ticket and present it to the airline counter at the time of check-In.</p>
                                <p>- Kindly check airline website for baggage information.</p>
                                {
                                    (pnrDetails !== undefined) && (pnrDetails.pnrData !== undefined) && (pnrDetails.pnrData.length) > 0 ? <>
                                        <h6 className="font-weight-bold mb-3">Airline Information</h6>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className="bg-light text-uppercase">
                                                    <th>PCC</th>
                                                    <th>Vendor PNR</th>
                                                    <th>PNR</th>
                                                    <th>Ticket Number</th>
                                                    <th>Cancellation Charge</th>
                                                    <th>Reschedule Charge</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{pnrDetails.pnrData[index].pcc}</td>
                                                    <td>{pnrDetails.pnrData[index].vendorPnr}</td>
                                                    <td>{pnrDetails.pnrData[index].pnr}</td>
                                                    <td>{pnrDetails.pnrData[index].ticketNumber}</td>
                                                    <td>
                                                        <NumberFormat value={(Math.floor(pnrDetails.pnrData[index].cancellationCharge * 100) / 100)} displayType={'text'} thousandSeparator={true} />
                                                    </td>
                                                    <td>
                                                        <NumberFormat value={(Math.floor(pnrDetails.pnrData[index].rescheduleCharge * 100) / 100)} displayType={'text'} thousandSeparator={true} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </> : ''
                                }
                            </div>
                        </div>
                    </div>
                ))
        }) : "No Data is Available"
        return (
            <div>

                {flightDetails}

            </div >
        )
    }
}
export default FlightDetails;