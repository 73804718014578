export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',
    CREATED_SUCCESSFULLY: 'Created Successfully',
    UPDATED_SUCCESSFULLY: 'Updated Successfully',
    DELETED_SUCCESSFULLY: 'Deleted Successfully',
    ACTIVATED_SUCESSFULLY: 'Activated Successfully',
    DEACTIVATED_SUCCESSFULLY: 'Deactivated Successfully',
    REMOVED_SUCCESSFULLY:'Removed Successfully',
    ASSIGNED:'Assigned Successfully',
    FAILED_TO_FETCH: 'Failed To Fetch',
    ANY_ONE: 'Choose any one'
};