import React from "react";
import { Input } from "../../../_common/_forms";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NavLink } from "react-router-dom";

const AddEditTemplatePage = props => {
  const {
    inputChangeHandler,
    submitHandler,
    emailContentHandler,
    notificationBoxContentHandler,
    validator
  } = props;
  return (
    <div>
      <div className="page-header">
        <h6 className="heading">Email administration</h6>
      </div>
      <div className="page-content">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <h6 className="heading">
                {props.isEditable ? "Edit Template" : "New Template"}
              </h6>
              <p className="text">You can edit templates with your own content and customise to your requirement.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="row">
            <div className="col-md-7">
              <form onSubmit={e => submitHandler(e)} id="form">
                <Input
                  label={"Name"}
                  name="name"
                  type="text"
                  controlFunc={e => inputChangeHandler(e)}
                  value={props.name}
                  error={validator.message("name", props.name, "required")}
                />
                <Input
                  label={"Subject"}
                  name="subject"
                  type="text"
                  controlFunc={e => inputChangeHandler(e)}
                  value={props.subject}
                  error={validator.message(
                    "subject",
                    props.subject,
                    "required"
                  )}
                />
                <Input
                  label={"Copy to email"}
                  name="copyToEmail"
                  type="text"
                  controlFunc={e => inputChangeHandler(e)}
                  value={props.copyToEmail}
                  error={validator.message(
                    "copyToEmail",
                    props.copyToEmail,
                    "email"
                  )}
                />
                <CKEditor
                  editor={ClassicEditor}
                  data={props.content}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    emailContentHandler(data);
                  }}
                />
                <div className="form-group custom textarea mt-4">
                  <input className="form-control"
                    name="notificationBoxContent"
                    value={props.notificationBoxContent}
                    onChange={e => notificationBoxContentHandler(e)}
                    placeholder="&nbsp;"
                  />
                  <label>Notification</label>
                </div>
                {/* <Input
                    name="notificationBoxContent"
                    type="text"
                    controlFunc={e => notificationBoxContentHandler(e)}
                    value={props.notificationBoxContent}
                  /> */}
                <div className="btn-group-margin mt-4">
                  <button
                    type="submit"
                    to={`/daselectCountryHandlertabase-settings/email-administration/${
                      props.serviceModuleId
                      }`}
                    className="btn btn-border-success lg-padding"
                    disabled={props.loading}
                  >
                    {props.loading === true
                      ? "Please Wait"
                      : props.isEditable
                        ? "UPDATE"
                        : "SAVE"}
                  </button>
                  <NavLink
                    to={`/database-settings/email-administration/${
                      props.serviceModuleId
                      }`}
                    className="btn"
                  >
                    CANCEL
                  </NavLink>
                </div>
              </form>
            </div>
            <div className="col-md-5">
              <h6 className="sub-heading">
                <i className="material-icons align-middle">find_in_page</i>{" "}
                Preview
              </h6>
              <div className="template-output">
                <div dangerouslySetInnerHTML={{ __html: props.content }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddEditTemplatePage;
