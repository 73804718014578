import React from 'react';

const Input = (props) => (
  <div className={'form-group custom ' + props.className}>    
    <input className="form-control"
     name={props.name}
     type={props.type}
     value={props.value}
     onChange={e => props.controlFunc(e)}
     placeholder="&nbsp;"  
    />
    <label>{props.label}</label>
    <div className="help-block">{props.error}</div>
  </div>
); 

export default Input;  