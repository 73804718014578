import React, { Component } from "react";
import CountriesPage from "./countries-html";
import SimpleReactValidator from "simple-react-validator";
import { masterService } from '../../../_services/masterService';
import { toast } from "react-toastify";
import { alertConstants } from '../../../_constants/alert.constants';
import $ from 'jquery';

class Countries extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.validator1 = new SimpleReactValidator();
    this.validator2 = new SimpleReactValidator();


    this.state = {
        // Country
        countryName: '',
        countryCode:'',
        dialCode: '',
        currency: "5cab61a899946f7206d4fc19",
        countryList: [],
        selectedCountry:'',
        countryId: '',
      //State
      stateName:'',
      stateCode:'',
      stateList:[],
      selectedState: '',
      stateId:'' ,

      //City 
      cityName:'',
      cityCode:'',
      cityList:[],
      selectedCity:'',
      cityId:'',
      alsoKnownAs:[],
      hasAirport:'',
      hasStation:'',
      
    };
  }

  cancelCountry = () =>{
    this.setState({
      countryName: '',
      countryCode:'',
      dialCode: '',
    })
  }

  componentDidMount() {
    this.getCountryList();
  }

  getCountryList = async () => {
    let resp = await masterService.getCountryData();
    if(resp){
      this.setState({countryList: resp.result});
    }
  }

  handleInput = (e)=>{
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value});

  }

  saveCountry = async ()=> {
    let payload = {
      name: this.state.countryName,
      code: this.state.countryCode,
      dialCode: this.state.dialCode,
      currency:this.state.currency
    }
    if (this.validator.allValid()){
      let resp = await masterService.postCountryData(payload);
      if(resp){
        $('.close').click();
        this.cancelCountry();
        toast.info(alertConstants.CREATED_SUCCESSFULLY);
      }
    }
    else{
      this.validator.showMessages();
      this.forceUpdate();
    }  
  }

  selectCountryHandler=(e)=>{
    this.setState({
      selectedCountry: e.name,
      countryId : e.id,
      selectedState: '',
      stateId: '',
      stateList:[],
      selectedCity:'',
      cityId:'',
      cityList:[]
    });
    this.getStateList(e.id);
  }
  
  selectStateHandler = (e) => {
    this.setState({
      selectedState: e.name,
      stateId: e.id,
      selectedCity:'',
      cityId:'',
      cityList:[]
    });
    this.getCityList(e.id);
  }

  selectCityHandler=(e) => {
    this.setState({
      selectedCity: e.name,
      cityId: e.id,
      alsoKnownAs:e.alsoKnownAs,
    })
  }
  getStateList = async(countryId)=>{
    let resp = await masterService.getStateData(countryId);
    if(resp){
      this.setState({
        stateList: resp.result
      })
    }
  }

  getCityList = async(stateId) => {
    let resp = await masterService.getCityData(this.state.countryId, stateId);
    if(resp){
      this.setState({
        cityList: resp.result
      })
    }
  }

  saveState = async ()=>{
    if(this.state.countryId === ''){
      toast.warning("Please Select the Country first !!!!");
    }
    else{
      let payload = {
        name: this.state.stateName,
        code : this.state.stateCode,
        countryId: this.state.countryId
      }
      if (this.validator1.allValid()){
        let resp = await masterService.postStateData(payload);
        if(resp){
          $('.close').click();
          toast.info(alertConstants.CREATED_SUCCESSFULLY);
        }
      }
      else{
        this.validator1.showMessages();
        this.forceUpdate();
      }
    }
  }

  saveCity = async () => {
    if(this.state.countryId === '' && this.state.stateId ===''){
      toast.warning("Please Select the Country & State first !!!!");
    }
    else if(this.state.stateId ==='' && this.state.countryId !== ''){
      toast.warning("Please Select the State first !!!!");

    }
    else{
      let payload = {
        name: this.state.cityName,
        code: this.state.cityCode,
        alsoKnownAs: this.state.alsoKnownAs,
        hasAirport: this.state.hasAirport,
        hasStation:this.state.hasStation,
      }
      if (this.validator2.allValid()){
        let resp = await masterService.postCityData(payload);
        if(resp){
          $('.close').click();
          toast.info(alertConstants.CREATED_SUCCESSFULLY);
        }
      }
      else{
        this.validator2.showMessages();
        this.forceUpdate();
      }
    }
    
  }

  render() {
    return (
      <div>
        <CountriesPage 
        state={this.state}
        saveCountry={this.saveCountry}
        handleInput={this.handleInput}
        error={this.validator}
        selectCountryHandler={this.selectCountryHandler}
        saveState={this.saveState}
        error1={this.validator1}
        selectStateHandler={this.selectStateHandler}
        saveCity={this.saveCity}
        error2={this.validator2}
        selectCityHandler={this.selectCityHandler}
        />
      </div>
    );
  }
}

export default Countries;
