import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import { Select } from '../_common/_forms';

class NewReportPage extends Component {
    render() {
        return (
            <div>
                <div className="page-header">
                    <h6 className="heading">Create new Report</h6>
                </div>
                <div className="page-content">
                    <div className="p-padding">
                        <div className="bg-white py-padding">
                            <div className="center-heading text-center py-padding">
                                <div className="report_logo">
                                    <img src="/assets/images/report_icon.png" alt="" />
                                </div>
                                <h4>Choose Report Type</h4>
                                <p className="">What kind of report are you looking for</p>
                            </div>
                            <div className="pb-5">
                                <div className="row">
                                    <div className="col-md-10 offset-1">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <NavLink to="/reporting/custom-report">
                                                    <p className="mb-0">
                                                        <i className="material-icons fs-20 align-sub">attach_money</i>
                                                        <span>Expense Report</span>
                                                    </p>
                                                    </NavLink>
                                                </div>
                                                <div className="report__box">
                                                    <p className="mb-0">
                                                        <i className="material-icons fs-20 align-sub">location_on</i>
                                                        <span>Destination Report</span>
                                                    </p>
                                                </div>
                                                <div className="report__box">
                                                    <p className="mb-0">
                                                        <i className="material-icons fs-20 align-sub">repeat</i>
                                                        <span>Reimbursements Report</span>
                                                    </p>
                                                </div>
                                                <div className="report__box">
                                                    <p className="mb-0">
                                                        <i className="material-icons fs-20 align-sub">directions_car</i>
                                                        <span>Mileage Report</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="report__box">
                                                    <p className="mb-0">
                                                        <i className="material-icons fs-20 align-sub">person</i>
                                                        <span>Employee Report</span>
                                                    </p>
                                                </div>
                                                <div className="report__box">
                                                    <p className="mb-0">
                                                        <i className="material-icons fs-20 align-sub">flight</i>
                                                        <span>Travel Report</span>
                                                    </p>
                                                </div>
                                                <div className="report__box">
                                                    <p className="mb-0">
                                                        <i className="material-icons fs-20 align-sub">fast_forward</i>
                                                        <span>Cash Advance Report</span>
                                                    </p>
                                                </div>
                                                <div className="report__box">
                                                    <p className="mb-0">
                                                        <i className="material-icons fs-20 align-sub">report_problem</i>
                                                        <span>Policy Violations Report</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default NewReportPage;