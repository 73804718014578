import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { projectService } from '../../_services';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import SortHead from '../_common/table/sort';
import { Select} from '../_common/_forms';
import SimpleReactValidator from 'simple-react-validator'; 
class ClientListPage extends Component {
  constructor() {
    super()
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    this.getProjectsList(); 
    this.loading = false
  }
  itemsPerPage = 10;
  activePage = 1
  projectList = [];
  count = 0;
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  getProjectsList() {
    var data = {
      page: this.activePage,
      limit: this.itemsPerPage,
      sort: "asc",
      projectStatus :1
    }
    this.loading = true;
    projectService.getAllClientList(data)
      .then(
        data => {
          this.loading = false;
          if (data != null) {
            this.count = data.count
            this.projectList = data.result;
            this.forceUpdate()
          }
        }, error => {
          this.loading = false;
          if (error.statusCode === 422) {
            let errors = error.message
            Object.keys(errors).map(key => {
              return (
                toast.error(errors[key])
              );
            })
          } else {
            toast.error(error.message)
          }
        }
      )
  }

  sortBy = (e) => {
    let arrayCopy = this.projectList;
    arrayCopy.sort(this.compareBy(e));
    this.projectList = arrayCopy;
    this.forceUpdate()
  }
  column = ''
  isDesc = ''
  compareBy(property) {
    this.isDesc = !this.isDesc; //change the direction   
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    return function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    };
  }
  handlePageChange = (pageNumber) => {
    this.activePage = pageNumber
    this.getProjectsList()
  }
  handleItemChange = (limit) => {
    this.activePage = 1;
    this.itemsPerPage = limit['items'];
    this.getProjectsList();
  }

  render() {
    // const { country, countries, data, handleFormChange, orderTypes, billingCycle, handleSubmit } = this.props
    return (
      <div className="ProjectPage">
        <div className="page-header">
          <div className="resp-toggle-menu"><i className="material-icons">menu</i></div>
          <h6 className="heading">Manage Client</h6>
        </div>
        <div className="page-content">
          <div className="py-padding">
            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-md-10">
                    {/* <div className="btn-group-margin">
                      <NavLink to="/projects/create/1" className="btn ">Add Project</NavLink>
                    </div> */}
                  </div>
                  <div className="col-md-2">
                    {/* <div className="search-custom">
                      <input type="text" name="" className="form-control" placeholder="Search" />
                      <span><i className="material-icons align-middle">search</i></span>
                    </div> */}
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <SortHead
                        name="Name"
                        feild="name"
                        column={this.column}
                        isDesc={this.isDesc}
                        sortBy={this.sortBy}
                      />
                      <SortHead
                        name="Effective Date"
                        feild="email"
                        column={this.column}
                        isDesc={this.isDesc}
                        sortBy={this.sortBy}
                      />
                      <SortHead
                        name="Due Date"
                        feild="email"
                        column={this.column}
                        isDesc={this.isDesc}
                        sortBy={this.sortBy}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {this.loading === true &&
                      <tr>
                        <td colSpan="3">Loading</td>
                      </tr>
                    }
                    {!this.projectList && this.loading === false &&
                      <tr>
                        <td colSpan="3">No records found</td>
                      </tr>}
                    {this.projectList &&
                      this.projectList.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <NavLink className="text-capitalize" to={"/client/view/" + item.id}>
                                <h6>{item.companyName}</h6>
                              </NavLink>
                              <p>#{item.entityCode}</p>
                            </td>
                            <td><p className="td-padding">23rd Jan 2019</p></td>
                            <td>1st Feb 2019</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      {this.pageItemsList &&
                        <Select
                          placeholder={this.itemsPerPage}
                          keyName={'items'}
                          options={this.pageItemsList}
                          controlFunc={e => this.handleItemChange(e)}
                        />}
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={this.activePage}
                      itemsCountPerPage={this.itemsPerPage}
                      totalItemsCount={this.count}
                      pageRangeDisplayed={1}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientListPage;
