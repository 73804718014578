import React, { Component } from "react";
import { companyAdmin } from "../../../../_services/companyAdmin";
import { masterService } from "../../../../_services";

import { toast } from "react-toastify";
import FormsFieldsPage from "../forms-fields/forms-fields-html";
import { alertConstants } from "../../../../_constants";
import $ from "jquery";

class FormsFields extends Component {
  state = {
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,

    closeModal: false,
    editModal: false,
    // Add form header
    selectedItemId: [],
    formName: "",
    formHeaderId: null,
    formId: "",
    mappedFields: [],
    serviceModuleId: localStorage.getItem("serviceModuleId"),
    companyId: sessionStorage.getItem("companyId"),
    formAccess: "Global",
    selectedFormHeader: "",
    selectedForm: [],
    selectedFormField: [],
    selectedField: [],
    formHeaderName: [],
    forms: [],
    formFields: [],
    customFields: [],
    selectedItem: null,
    formType: "",
    pathCheck: sessionStorage.setItem("redirectModify", "formFields")
  };
  count = 0;
  loading = false;

  // ----------------------set from header session id-----------------

  setSession = () => {
    if (typeof Storage !== "undefined") {
      // Store
      sessionStorage.setItem("formsFieldsHeaderId", this.state.formHeaderId);
    }
  };

  // ------------------event handler-------------------------
  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  // ---------------- creating forms --------------------------

  handleSubmit = async event => {
    event.preventDefault();
    let data = {
      formName: this.state.formName,
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      formAccess: this.state.formAccess
    };

    let res = await companyAdmin
      .createForms(data)
      .catch(e => (this.loading = false));
    if (res) {
      this.loading = false;
      this.getForms();
      toast.success(alertConstants.SUCCESS);
    }
  };

  // ---------------- get forms ------------------------------
  getForms = (id = null) => {
    companyAdmin
      .listFormsPLSF(
        this.state.activePage,
        this.state.itemPerPage,
        this.state.serviceModuleId,
        this.state.formHeaderId,
        this.state.companyId
      )
      .then(res => {
        this.setState(
          {
            forms: res.result,
            totalItemsCount: res.count
          },
          () => { }
        );
      })
      .catch(err => console.log("error", err));
  };

  // ----------------------------------------------------------

  // -----------------get form fields-----------------------------

  getFormFields = () => {
    let data = {
      page: this.state.activePage,
      limit: this.state.itemPerPage,
      formId: this.state.formId,
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      companyId: this.state.companyId,
      sort: "asc"
    };
    companyAdmin.getFormFields(data).then(res => {
      this.setState({
        formFields: res.result
      });
    });
  };

  // ---------------custom fields for mapping--------------------
  onlyCustomFields = () => {
    this.flushNow()
    let data = {
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      // customField: true,
      // isAdmin: true,
      // isFormField: false,
      page: 1,
      limit: 50,
      sort: "asc"
    };
    companyAdmin
      .customFieldsAre(data)
      .then(res => {
        this.setState({
          customFields: res.result
        });
      })
      .catch(err => toast.error(alertConstants.ERROR, err));
  };

  // -----------------------------------------------------

  getMappedFields = form => {
    this.setState(
      {
        formId: form
      },
      () => {
        this.forceUpdate();
      }
    );
    let data = {
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      companyId: this.state.companyId,
      formId: form,
      page: 1,
      limit: 50,
      sort: "asc"
    };
    companyAdmin
      .getMappedFields(data)
      .then(res => {
        this.setState({
          mappedFields: res.result
        });
      })
  };

  // -----------------------------------------------------

  // ---------------Map fields to form--------------------

  mapFieldsToForm = async () => {
    try {
      let data = {
        formId: this.state.formId,
        fieldIds: this.state.selectedField
      };
      await companyAdmin.mapFieldsToForm(data);
      $(".close").click();
      this.resetSeletedField()
      toast.info(alertConstants.ASSIGNED);
      this.getMappedFields(this.state.formId);
    } catch (error) {
      toast.error(alertConstants.ERROR);
    }
  };

  // -----------------------------------------------------

  // -----------------------------------------------------

  removeFields = async () => {
    try {
      let id = this.state.selectedFormField[0].id;
      let companyId = {
        companyId: this.state.companyId
      };
      this.setState({
        selectedFormField: []
      })
      await companyAdmin.deleteFields(id, companyId);
      toast.info(alertConstants.REMOVED_SUCCESSFULLY);
      this.getMappedFields(this.state.formId);
    } catch (error) {
      toast.error(alertConstants.ERROR);
    }
  };

  flushNow = () => {
    this.setState({
      selectedFormField: []
    })
    this.forceUpdate()
  }
  // -----------------------------------------------------

  // -------------------change status ---------------------------

  handleStatusChange = form => {
    let newStatus = !form.activeStatus;
    let status = {
      activeStatus: JSON.stringify(newStatus),
      companyId: this.state.companyId
    };
    companyAdmin.changeFormStatus(form.id, status).then(
      res => {
        newStatus
          ? toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);
        let newItems = this.state.forms.map(forms => {
          if (forms.id === form.id) {
            forms.activeStatus = newStatus;
          }
          return forms;
        });
        this.setState({
          forms: newItems
        });
      },
      err => {
        toast.error(err, alertConstants.ERROR);
      }
    );
  };

  handleMappedMandatoryChange = mappedField => {
    let newStatus = !mappedField.isMandatory;
    let status = {
      isMandatory: newStatus
    };
    companyAdmin.modifyFields(mappedField.id, status).then(
      res => {
        console.log(res);
        newStatus
          ? toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);
        let newItems = this.state.mappedFields.map(mappedFields => {
          if (mappedFields.id === mappedField.id) {
            mappedFields.isMandatory = newStatus;
          }
          return mappedFields;
        });
        this.setState({
          mappedFields: newItems
        });
      },
      err => {
        toast.error(err, alertConstants.ERROR);
      }
    );
  };

  handleMappedStatusChange = mappedField => {
    let newStatus = !mappedField.activeStatus;
    let status = {
      activeStatus: newStatus,
      companyId: this.state.companyId
    };
    companyAdmin.changeFieldStatus(mappedField.id, status).then(
      res => {
        newStatus
          ? toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);
        let newItems = this.state.mappedFields.map(mappedFields => {
          if (mappedFields.id === mappedField.id) {
            mappedFields.activeStatus = newStatus;
          }
          return mappedFields;
        });
        this.setState({
          mappedFields: newItems
        });
      },
      err => {
        toast.error(err, alertConstants.ERROR);
      }
    );
  };

  //-------------------------------------------------------------

  // ---------------- get forms headers -------------------
  getFormHeader = () => {
    companyAdmin.getFormHeader(this.state.serviceModuleId).then(res => {
      this.setState(
        {
          ...this.state,
          formHeaderName: res.result && res.result,
          selectedFormHeader: res.result && res.result[0].formHeaderName,
          formHeaderId: res.result && res.result[0].id
        },
        () => {
          if (res) {
            this.getForms();
            this.setSession();
          }

        }
      );
    });
  };

  // ---------------- ------ ------ -------------------------

  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, () => {
      this.getForms();
    });
  };
  handleItemChange = limit => {
    this.setState({ itemPerPage: limit }, () => {
      this.getForms();
    });
  };

  // -------------------------------------------------------

  // ---------------- Create form headers -------------------

  handleHeaderButtonClick = (id, name) => {
    if (this.state.formHeaderId !== id) {
      this.setState(
        {
          forms: [],
          mappedFields: [],
          selectedFormHeader: name,
          formHeaderId: id
        },
        () => {
          this.forceUpdate()
          this.getForms();
        }
      );
    }
    this.setSession();
  };

  // ---------------- ------------------- -------------------

  // -------------------selectedForm select--------------------

  toggleFormSelect = field => {
    console.log(field);
    let id = field.id;
    let itemSelected = [...this.state.selectedForm];
    let indexSelected = itemSelected.indexOf(field);
    if (indexSelected === -1) {
      itemSelected = [field];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedForm: itemSelected });
  };

  // selected form field
  toggleFormFieldSelect = field => {
    let itemSelected = [...this.state.selectedFormField];
    let indexSelected = itemSelected.indexOf(field);
    if (indexSelected === -1) {
      itemSelected = [field];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedFormField: itemSelected });
  };

  // selected  field
  toggleFieldSelect = field => { 
    let id = field.id;
    let itemSelected = [...this.state.selectedField];
    let indexSelected = itemSelected.indexOf(field.id);
    if (indexSelected === -1) {
      itemSelected.push(id);
    } else {
      itemSelected.splice(indexSelected, 1);
    }
    this.setState({ selectedField: itemSelected });
  };

  // -----------------------------------------------------------------

  componentDidMount() {
    this.getService()
  }
  currentModule = ''
  getService = async () => {
    var resp = await masterService.getServiceModuleListFl()
    if (resp) {
      this.listService = resp.result;
      if (localStorage.serviceModule != undefined) {
        this.setState({
          serviceModuleId: localStorage.serviceModule
        })
        this.currentModule = localStorage.serviceModule
      } else {
        this.setState({
          serviceModuleId: resp.result && resp.result[0].id
        })
        this.currentModule = resp.result && resp.result[0].id;
      }
      this.forceUpdate()
      this.getFormHeader();
    }
  }

  closeToggleHandler = () => {
    this.setState({
      ...this.state,
      closeModal: !this.state.closeModal
    });
  };

  editToggle = () => {
    this.setState({
      ...this.state,
      closeModal: !this.state.editModal
    });
  };
  resetSeletedField = () => {
    this.setState({
      selectedField: []
    });
  };
  changeModule = type => {
    this.setState({
      serviceModuleId: type,
      forms: [],
      mappedFields: [],
    }, () => {
      this.currentModule = type;
      localStorage.setItem('serviceModule', type)
      this.getFormHeader();
    })
  };
  render() {
    return (
      <div>
        <FormsFieldsPage
          // state
          fullFormData={this.state}
          // event handlers
          handlePageChange={this.handlePageChange}
          handleStatusChange={this.handleStatusChange}
          handleMappedStatusChange={this.handleMappedStatusChange}
          handleMappedMandatoryChange={this.handleMappedMandatoryChange}
          closeToggleHandler={this.closeToggleHandler}
          handleItemChange={this.handleItemChange}
          pageRangeDisplayed={1}
          editToggleHandler={this.editToggle}
          formNameHandler={this.formNameHandler}
          handleSubmit={this.handleSubmit}
          setFormType={this.setFormType}
          handleHeaderButtonClick={this.handleHeaderButtonClick}
          toggleFormSelect={this.toggleFormSelect}
          toggleFormFieldSelect={this.toggleFormFieldSelect}
          getMappedFields={this.getMappedFields}
          getFormFields={this.getFormFields}
          toggleFieldSelect={this.toggleFieldSelect}
          removeFields={this.removeFields}
          mapFieldsToForm={this.mapFieldsToForm}
          resetSeletedField={this.resetSeletedField}
          onlyCustomFields={this.onlyCustomFields}
          changeModule={this.changeModule}
          listService={this.listService}
          currentModule={this.currentModule}
          loading={this.loading}
          flushNow={this.flushNow}
        />
      </div>
    );
  }
}
export default FormsFields;
