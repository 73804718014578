import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { ReportPage, NewReportPage, StandardReportPage, OperationReportPage, ServiceWiseReportPage, ClientWiseReportPage,
    ListofEmployeeReportPage, TopPerformerReportPage, SupplierReportPage, ErrorReportPage, CancellationReportPage,
    RescheduleReportPage, EmployeeEfficiencyReportPage, OfflineRequestPage} from './index'
class ProfitableReportMainPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/profitable-reports') {
            this.props.history.push('/profitable-reports/report')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/profitable-reports') {
            this.props.history.push('/profitable-reports/report')
        }
    }
    render() {
        return (
            <div className="ReportMainPage">
                <div className="InsideName">
                    <Switch>
                        <Route path='/profitable-reports/report' component={ReportPage} />
                        <Route path='/profitable-reports/new-report' component={NewReportPage} />
                        <Route path='/profitable-reports/standard-report' component={StandardReportPage} />
                        <Route path='/profitable-reports/operation-report' component={OperationReportPage} />
                        <Route path='/profitable-reports/service-wise-report' component={ServiceWiseReportPage} />
                        <Route path='/profitable-reports/client-wise-report' component={ClientWiseReportPage} />
                        <Route path='/profitable-reports/employee-list' component={ListofEmployeeReportPage} />
                        <Route path='/profitable-reports/top-performer' component={TopPerformerReportPage} />
                        <Route path='/profitable-reports/supplier-report' component={SupplierReportPage} />
                        <Route path='/profitable-reports/error-report' component={ErrorReportPage} />
                        <Route path='/profitable-reports/cancellation-report' component={CancellationReportPage} />
                        <Route path='/profitable-reports/reschedule-report' component={RescheduleReportPage} />
                        <Route path='/profitable-reports/employee-report' component={EmployeeEfficiencyReportPage} />
                        <Route path='/profitable-reports/offline-request' component={OfflineRequestPage} />                
                    </Switch>
                </div>
            </div>
        );
    }
}

export default ProfitableReportMainPage;
