import React, { Component } from 'react';
import { history } from '../../_helpers';
import { midOfficeServices, masterService } from "../../_services";
import SimpleReactValidator from "simple-react-validator";
import { Input, Select } from "../_common/_forms";
import $ from "jquery";
import { toast } from "react-toastify";
import NumberFormat from 'react-number-format';
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import FlightDetails from './flightDetails';
import UserDetailsPage from '../history/userDetails';
import CheckListPage from './check-list';
import ReactSelect from 'react-select';
import { getValue } from '../_common/loadash';

class FlightTripIdPage extends Component {
  constructor() {
    super();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.validatorUpdate = new SimpleReactValidator();
    this.validatorUpdate2 = new SimpleReactValidator();
    this.taxFieldValidator = new SimpleReactValidator();
    this.state = {
      data: {},
      id: '',
      priceData: {},
      pnr: {},
      payLoad: [],
      reasonType: "",
      comments: "",
      statusType: "",
      fareCurrency: "",
      paymentType: "",
      priceObject: [],
      currencyName: 'Select',
      paymentName: "Select",
      values: [],
      taxBreakup: [],
      vendorTypes: [],
      vendorName: 'Select',
      isLoading: true,
      popUp: false,
      userDetails: {},
      userId: "",
      queueName: "",
      savedPassVisa: {},
      checkList: false
    }
  }
  componentDidMount() {
    let data = {
      id: this.props.match.params.id
    }
    this.setState({ id: this.props.match.params.id });
    this.getTripDetail(data);
    this.getPnrData(data.id)
    this.getReasonType();
    this.getFareCurrencyType();
    this.getPaymentType();
    this.getVendorsDetails();
  }

  paymentTypes = [];
  getPaymentType() {
    midOfficeServices.getPaymentType().then(
      data => {
        if (data != null) {
          this.paymentTypes = data.result;
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  }
  changePaymentType = (val, feild) => {
    this.setState({ paymentName: val.paymentType, [feild]: val.paymentType });
  };

  fareCurrencyTypes = [];
  getFareCurrencyType() {
    masterService.getFareCurrencyType().then(
      data => {
        if (data != null) {
          data.result.forEach((item, i) => {
            this.fareCurrencyTypes.push({ label: item.itemName, value: item.itemCode })
          })
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  }
  changeFareCurrencyType = (val, feild) => {
    this.setState({ currencyName: val.label, [feild]: val.value });
  };

  getTripDetail = async (data) => {
    this.setState({ isLoading: true })
    var res = await midOfficeServices.getTripDetail(data)
    if (res) {
      this.setState({
        data: res.result,
        userId: res.result.userId,
        statusType: res.result.statusType,
        queueName: res.result.statusType,
        isLoading: false,
        vendorName: res.result.vendorId ? res.result.vendorId.vendorName : 'Select',
        //id: res.result.id
      })
      let pricedData = {
        id: res.result.id
      }
      this.getUpdatedPrice(pricedData);
      this.getChosenPassportVisa();
    }
  }
  getChosenPassportVisa = async () => {
    let res = await midOfficeServices.getChosenPassportVisa(this.state.data.tripId, this.state.data.userId);
    if (res) {
      this.setState({ savedPassVisa: res.result });
    }
  }

  getUpdatedPrice = async (data) => {
    var res = await midOfficeServices.getUpdatedPrice(data);
    let priceObject = [...this.state.priceObject]
    if (res) {
      this.setState({ priceData: res.result })
      const length = this.state.priceData.priceObject.length;
      let price = this.state.priceData.priceObject;
      for (var i = 0; i < length; i++) {
        priceObject[i] =
          {
            ...this.state.priceObject[i],
            sector: price[i].sector,
            airLine: price[i].airLine,
            baseFare: parseFloat(price[i].baseFare).toFixed(2),
            totalFare: price[i].totalFare,
            totalTax: price[i].totalTax,
            journeyId: price[i].journeyId,

            id: price[i].id,
            taxBreakup: price[i].taxBreakup.map((tax) => {
              return {
                ...tax,
                fieldValue: parseFloat(tax.fieldValue).toFixed(2)
              }
            }),
            values: price[i].values.map((value) => {
              return {
                ...value,
                taxValue: parseFloat(value.taxValue).toFixed(2)
              }
            }),
          }

        this.setState({
          priceObject
        })
      }
    }
  }
  getPnrData = async (id) => {
    var res = await midOfficeServices.getPnrdata(id)
    if (res) {
      const length = this.state.data.flightData.flights.length;
      let payLoad = [...this.state.payLoad];
      this.setState({
        paymentName: res.result.paymentType ? res.result.paymentType : 'Select',
        paymentType: res.result.paymentType,
        fareCurrency: res.result.fareCurrency,
        currencyName: res.result.fareCurrency ? res.result.fareCurrency : 'Select',
        bookingDate: res.result.bookingDate,
      })
      if (Array.isArray(res.result.pnrData)) {
        for (var i = 0; i < length; i++) {
          payLoad[i] = {
            ...this.state.payLoad[i],
            travellingFrom: res.result.pnrData[i].travellingFrom,
            travellingTo: res.result.pnrData[i].travellingTo,
            airLine: res.result.pnrData[i].airLine,
            airLineName: res.result.pnrData[i].airLineName,
            pcc: res.result.pnrData[i].pcc,
            vendorPnr: res.result.pnrData[i].vendorPnr,
            pnr: res.result.pnrData[i].pnr,
            ticketNumber: res.result.pnrData[i].ticketNumber,
            cancellationCharge: res.result.pnrData[i].cancellationCharge,
            rescheduleCharge: res.result.pnrData[i].rescheduleCharge,
            reason: res.result.pnrData[i].reason,
            journeyId: res.result.pnrData[i].journeyId
          }
          this.setState({
            payLoad,
          })
        }
      }
      this.setState({ pnr: res.result })
    }
  }

  updatePnrData = async (e) => {
    e.preventDefault();
    let extractPnr = this.state.pnr.pnrData.map((data, i) => {
      return {
        travellingFrom: data.travellingFrom,
        travellingTo: data.travellingTo,
        airLine: data.airLine,
        airLineName: data.airLineName,
        id: data.id
      }
    })
    const length = this.state.data.flightData.flights.length;
    var myArray = [];
    for (var i = 0; i < length; i++) {
      var finalPayload = {
        ...this.state.payLoad[i],
        ...extractPnr[i]
      }
      myArray.push(finalPayload);
    }
    let pnrdata = {
      pnrData: myArray,
      fareCurrency: this.state.fareCurrency,
      paymentType: this.state.paymentType,
      bookingDate: this.state.bookingDate
    }
    if (this.validator.allValid()) {
      var resp = await midOfficeServices.updatePnrdata(this.state.pnr.id, pnrdata);
      if (resp) {
        toast.success(resp.message);
        $(".close").click();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  reasonTypes = [];
  reasonName = "Reason";
  getReasonType() {
    masterService.getReasonType().then(
      data => {
        if (data != null) {
          this.reasonTypes = data.result;
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  }
  changeReason = (val, feild) => {
    this.reasonName = val.reason;
    this.setState({ [feild]: val.reason });
  };


  queueTypes = [
    { "items": "Move to Non-API booking", "id": "Non_Api_Booking" },
    // { items: "Move to Queue Processing", id: "Queue_Processing" },
    // { items: "Move to Vendor Queue", id: "Vendor_Queue" },
    { items: "Move to Cancellation Queue", id: "Cancellation_Queue" }];
  updateQueueType = async (e) => {
    e.preventDefault();
    let data = {
      statusType: this.state.statusType
    }
    if (this.validatorUpdate2.allValid()) {
      var resp = await midOfficeServices.updateQueueType(this.state.data.id, data);
      if (resp) {
        toast.success(resp.message);
        $(".close").click();
        this.props.history.push(
          "/mid-office/home"
        );
      }
    } else {
      this.validatorUpdate2.showMessages();
      this.forceUpdate();
    }

  };
  changeQueue = (val, feild) => {
    this.setState({ queueName: val.items, [feild]: val.id });
  };
  updateBookingCancelData = async (id) => {
    let data = {
      reasonType: this.state.reasonType,
      comments: this.state.comments
    }

    if (this.validatorUpdate.allValid()) {
      var resp = await midOfficeServices.updateBookingCancelData(this.state.data.id, data);
      if (resp) {
        toast.success(resp.message);
        $(".close").click();
        this.props.history.push(
          "/mid-office/home"
        );
      }
    } else {
      this.validatorUpdate.showMessages();
      this.forceUpdate();
    }
  }

  updateValidateFareData = async (e) => {
    e.preventDefault();
    let priceObject = this.state.priceObject;
    for (var i = 0; i < priceObject.length; i++) {
      priceObject[i] = {
        ...priceObject[i],
        totalTax: this.getTotalTax(priceObject[i]),
        totalFare: this.getTotalFare(priceObject[i])
      }
      this.setState({
        priceObject: priceObject
      })
    }


    if (this.taxFieldValidator.allValid()) {
      var resp = await midOfficeServices.updateValidateFareData(this.state.priceData.id, { priceObject });
      if (resp) {
        toast.success(resp.message);
        $(".close").click();
        let data = {
          id: this.props.match.params.id
        }
        this.getTripDetail(data);
      }
    }
    else {
      this.taxFieldValidator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange2 = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleChange = (e, id) => {
    let payLoad = [...this.state.payLoad];
    payLoad[id] = {
      ...this.state.payLoad[id],
      [e.target.name]: e.target.value,
    }
    this.setState({
      payLoad
    });
  }
  handleChange3 = (e, id) => {
    let priceObject = [...this.state.priceObject];
    priceObject[id] = {
      ...this.state.priceObject[id],
      [e.target.name]: e.target.value,
    }
    this.setState({
      priceObject
    });
  }
  handleMarkComplete = async (id) => {
    let res = await midOfficeServices.UpdateAsMarkComplete(id);
    if (res) {
      toast.success(res.message);
      this.props.history.push(
        "/mid-office/home"
      );
      this.setState({ checkList: false });

    }
  }
  removeClick = (i, key) => {
    let assume = this.state.priceObject;
    assume[i].values = assume[i].values.filter((item, x) => x !== key)
    this.setState({ ...this.state })
  }
  addClick = (i) => {
    let assume = this.state.priceObject;
    assume[i].values.push({ taxName: "", taxValue: "" })
    this.setState({ ...this.state })
  }
  handleNameChange = (e, i, key) => {
    const newPriceObject = this.state.priceObject[i].values.map((values, sidx) => {
      if (key !== sidx) return values;
      return { ...values, [e.target.name]: e.target.value };
    });
    this.state.priceObject[i].values = newPriceObject;
    this.setState({ ...this.state });
  }
  getTotalTax = (price) => {
    let staticTax = 0;
    (price.taxBreakup.length > 0 && price.taxBreakup.forEach((tax, i) => {
      staticTax += parseFloat(tax.fieldValue);
    }));

    let dynamicTax = price.values.reduce((sum, item) => sum += Number(item.taxValue), 0);
    return (staticTax + dynamicTax);

  }
  getTotalFare = (price) => {
    return (this.getTotalTax(price) + Number(price.baseFare));
  }
  getVendorsDetails = async () => {
    let res = await midOfficeServices.getVendorsDetails();
    if (res) {
      this.setState({ vendorTypes: res.result });
    }
  }
  changeVendor = async (val, feild) => {
    let data = {
      vendorId: val.id
    }
    let res = await midOfficeServices.changeVendorType(this.props.match.params.id, data);
    if (res) {
      toast.success(res.message);
    }
    this.setState({ vendorName: val.vendorName, vendorType: val.vendorName });
  };
  enablePopUp = () => {
    this.setState({ popUp: true })
    this.getUserDetails();

  }
  closePopUp = () => {
    this.setState({ popUp: false })
  }
  getUserDetails = async () => {
    let res = await midOfficeServices.getUserDetails(this.state.userId);
    if (res) {
      this.setState({ userDetails: res.result })
    }
  }

  handleDayChange = (date, field) => {
    this.setState({ [field]: date });
  }
  enableCheckList = () => {
    this.setState({ checkList: true })
  }
  closeCheckList = () => {
    this.setState({ checkList: false });
  }

  handleTaxChange = (e, i, key) => {
    const newPriceObject = this.state.priceObject[i].taxBreakup.map((taxBreakup, sidx) => {
      if (key !== sidx) return taxBreakup;
      return { ...taxBreakup, [e.target.name]: e.target.value };
    });
    this.state.priceObject[i].taxBreakup = newPriceObject
    this.setState({ ...this.state });
  }

  render() {

    let { data, priceData, pnr, payLoad, priceObject, userDetails } = this.state;
    var flightsOne = (data.flightData != undefined) ? data.flightData.flights[0] : "";
    var flightFare = (data.flightData != undefined && flightsOne.fareContent != undefined) ? flightsOne.fare : "";

    const modelUpdatedPrice = (priceObject != undefined) ? priceObject.map((price, i) => {
      return (
        <div key={i} className="card border-bottom">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <div className="mb-0" data-toggle="collapse" data-target={"#collapse" + i + 33} aria-expanded="true" aria-controls="collapseOne">
                <div className="float-left"> {price.sector + ' @ ' + price.airLine}</div>
                <div className="float-right rounded-circle bg-white collapse-icon">
                  <i className="icon ion-md-remove add-modal-minus"></i>
                  <i className="icon ion-md-add add-modal-plus"></i>
                </div>
                <div className="clearfix"></div>
              </div>
            </h5>
          </div>

          <div id={"collapse" + i + 33} className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div className="card-body pm-30">
              <div className="row">
                <div className="col-md-4">
                  <Input
                    label={`Base Fare in (${data.baseCurrency})`}
                    name="baseFare"
                    type="text"
                    value={price.baseFare}
                    controlFunc={(e) => this.handleChange3(e, i)}
                    error={this.taxFieldValidator.message(
                      "Base Fare",
                      price.baseFare,
                      "required|numeric"
                    )}
                  />
                  <div className="pm-15 pt-0 pb-0">
                    <p className="font-weight-bold   mb-0">Total Tax</p>
                    <p className="lighter-p">
                      <NumberFormat
                        value={this.getTotalTax(price).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={data.baseCurrency + ' '}
                      />
                    </p>
                  </div>
                  <div className="pm-15 pt-0 pb-0">
                    <p className="font-weight-bold   mb-0">Total Fare</p>
                    <p className="lighter-p">
                      <NumberFormat
                        value={this.getTotalFare(price).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={data.baseCurrency + ' '}
                      />
                    </p>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="bg-light">
                    <table className="table">
                      <tbody>
                        {(price.taxBreakup != undefined) ? price.taxBreakup.map((tax, keyTax) => {
                          return (
                            <tr key={keyTax}>
                              <td className="border p-3">
                                <Input
                                  label="Tax Name"
                                  name="flieldName"
                                  type="text"
                                  value={tax.flieldName}
                                />
                              </td>
                              <td className="border p-3">
                                <Input
                                  label={`Tax value in (${data.baseCurrency})`}
                                  name="fieldValue"
                                  type="text"
                                  value={tax.fieldValue}
                                  controlFunc={(e) => this.handleTaxChange(e, i, keyTax)}
                                  error={this.taxFieldValidator.message(
                                    "fieldValue",
                                    tax.fieldValue,
                                    "required|numeric"
                                  )}
                                />
                              </td>
                              <td>
                                <div className="px-3" ></div>
                              </td>
                            </tr>
                          )
                        }) : 'No data'}


                        {(price.values != undefined) ? price.values.map((tax, keyTax) => {
                          return (
                            <tr key={keyTax}>
                              <td className="border p-3">
                                <Input
                                  label="Tax Name"
                                  name="taxName"
                                  type="text"
                                  value={tax.taxName}
                                  controlFunc={(e) => this.handleNameChange(e, i, keyTax)}
                                  error={this.taxFieldValidator.message(
                                    "Tax Name",
                                    tax.taxName,
                                    "required|string"
                                  )}
                                />
                              </td>
                              <td className="border p-3">
                                <Input
                                  label={`Tax Value in (${data.baseCurrency})`}
                                  name="taxValue"
                                  type="text"
                                  value={tax.taxValue}
                                  controlFunc={(e) => this.handleNameChange(e, i, keyTax)}
                                  error={this.taxFieldValidator.message(
                                    "Tax Value",
                                    tax.taxValue,
                                    "required|numeric"
                                  )}
                                />

                              </td>
                              <td>
                                <div className="float-right rounded-circle bg-white collapse-icon border mt-2" onClick={(e) => this.removeClick(i, keyTax)}>
                                  <i className="icon ion-md-remove add-modal-minus"></i>
                                </div>
                                <div className="clearfix"></div>
                              </td>
                            </tr>

                          )
                        }) : ''}

                      </tbody>
                    </table>
                    <button type="button" className="btn btn-primary " onClick={(e) => this.addClick(i)}>Add</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }) : "No data is available";


    const modelPnrData = (payLoad != undefined) ? payLoad.map((data, i) => {
      return (
        <div key={i} className="pt-3 border-bottom modal-px-30 ">
          <p className="font-weight-bold text-uppercase">{data.travellingFrom + ' - ' + data.travellingTo + ' @' + data.airLine}</p>
          <div className="row">
            <div className="col-md-3">
              <p className="font-weight-bold mb-0">Airline Name</p>
              <p className="lighter-p">{data.airLineName}</p>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-6">
                  <Input
                    label="PCC"
                    name="pcc"
                    type="text"
                    value={this.state.payLoad[i].pcc}
                    controlFunc={(e) => this.handleChange(e, i)}
                    error={this.validator.message(
                      "PCC",
                      this.state.payLoad[i] &&
                      this.state.payLoad[i].pcc,
                      "required",
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Vendor PNR"
                    name="vendorPnr"
                    type="text"
                    value={this.state.payLoad[i].vendorPnr}
                    controlFunc={(e) => this.handleChange(e, i)}
                    error={this.validator.message(
                      "Vendor Pnr",
                      this.state.payLoad[i] &&
                      this.state.payLoad[i].vendorPnr,
                      "required"
                    )}
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <Input
                    label="Airline PNR"
                    name="pnr"
                    type="text"
                    value={this.state.payLoad[i].pnr}
                    controlFunc={(e) => this.handleChange(e, i)}
                    error={this.validator.message(
                      "PNR",
                      this.state.payLoad[i] &&
                      this.state.payLoad[i].pnr,
                      "required"
                    )}
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <Input
                    label="Ticket number"
                    name="ticketNumber"
                    type="text"
                    value={this.state.payLoad[i].ticketNumber}
                    controlFunc={(e) => this.handleChange(e, i)}
                    error={this.validator.message(
                      "Ticket Number",
                      this.state.payLoad[i] &&
                      this.state.payLoad[i].ticketNumber,
                      "required"
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-2">
                  <Input
                    label="Cancellation Charge"
                    name="cancellationCharge"
                    type="text"
                    value={this.state.payLoad[i].cancellationCharge}
                    controlFunc={(e) => this.handleChange(e, i)}
                    error={this.validator.message(
                      "Charge",
                      this.state.payLoad[i] &&
                      this.state.payLoad[i].cancellationCharge,
                      "required|numeric"
                    )}
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <Input
                    label="Reschedule Charge/Date Change"
                    name="rescheduleCharge"
                    type="text"
                    value={this.state.payLoad[i].rescheduleCharge}
                    controlFunc={(e) => this.handleChange(e, i)}
                    error={this.validator.message(
                      "Charge",
                      this.state.payLoad[i] &&
                      this.state.payLoad[i].rescheduleCharge,
                      "required|numeric"
                    )}
                  />
                </div>
                <div className="col-md-12 mt-2">
                  <Input
                    label="Reason"
                    name="reason"
                    type="text"
                    value={this.state.payLoad[i].reason}
                    controlFunc={(e) => this.handleChange(e, i)}
                    error={this.validator.message(
                      "Reason",
                      this.state.payLoad[i] &&
                      this.state.payLoad[i].reason,
                      "required"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }) :
      <div className="text-center">
        NO Data is found
      </div>

    var totalBaseFare = 0;
    data.flightData && data.flightData.flights.forEach((flight, i) => {
      totalBaseFare += flight.fareContent.fare ? parseFloat(flight.fareContent.fare.flight_base_price) : 0
    });
    var totalTaxOfAll = 0;
    data.flightData && data.flightData.flights.forEach((flight, i) => {
      totalTaxOfAll += flight.fareContent.fare ? parseFloat(flight.fareContent.fare.flight_total_tax) : 0
    });
    var totalPrice = 0;
    data.flightData && data.flightData.flights.forEach((flight, i) => {
      totalPrice += flight.fareContent.fare ? parseFloat(flight.fareContent.fare.flight_total_price) : 0
    });


    // Main return
    return (
      <div>
        <div className="admin-tabs">
          <div className="inner-header">
            <div className="shape-header">
              <div className="left">F</div>
              <div className="right">
                <h6 className="heading">{data.tripId}<br />{data.travellerName}</h6>
              </div>
            </div>
          </div>
          <div className="booking-bg">
            <div className="p-padding">
              <div className="traveller-details">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <td>TRAVELER NAME <br /><b>{data.travellerName}</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right">
                          <button type="button" className="btn" onClick={(e) => this.enablePopUp(e)} >User Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td>BOOKED BY <br /><b>{data.travellerName}</b></td>
                        <td>EMAIL ID <br /><b>{data.email}</b></td>
                        <td>MOBILE NUMBER <br /><b>--</b></td>
                        <td>SEAT PREFERENCE <br /><b>{data.seatPreference ? data.seatPreference : '--'}</b></td>
                        <td>MEAL PREFERENCE <br /><b>{data.mealRequest ? data.mealRequest : '--'}</b></td>
                      </tr>
                      {(data.flightData != undefined && Array.isArray(data.flightData.flights[0].fareContent)) ?
                        <tr>
                          <td>BASE FARE <br /><b>
                            <NumberFormat
                              value={getValue(data.flightData,"flights[0].fare.flight_base_price).toFixed(3)")} displayType={'text'}
                              thousandSeparator={true}
                              prefix={data.baseCurrency + ' '}
                            /></b>
                          </td>
                          <td>TOTAL TAX <br /><b>
                            <NumberFormat value={(getValue(data.flightData,"flights[0].fare.flight_total_tax.toFixed(3)"))}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={data.baseCurrency + ' '}
                            /></b>
                          </td>
                          <td>TOTAL FARE <br /><b>
                            <NumberFormat
                              value={getValue(data.flightData,"flights[0].fare.flight_total_price).toFixed(3)")} displayType={'text'}
                              thousandSeparator={true}
                              prefix={data.baseCurrency + ' '}
                            /></b>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        :
                        <tr>
                          <td>BASE FARE <br />
                            <b>
                              <NumberFormat
                                value={parseFloat(totalBaseFare).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={data.baseCurrency + ' '} />
                            </b>
                          </td>
                          <td>TOTAL TAX <br />
                            <b>
                              <NumberFormat
                                value={parseFloat(totalTaxOfAll).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={data.baseCurrency + ' '} />
                            </b>
                          </td>
                          <td>TOTAL FARE <br />
                            <b>
                              <NumberFormat
                                value={parseFloat(totalPrice).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={data.baseCurrency + ' '} />
                            </b>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="p-padding pt-0">
              <div className="accordion" id="accordionExample">
                {(this.state.isLoading) ?
                  <div className="mx-auto text-center">
                    <img
                      width="200"
                      className="img-fluid "
                      alt="no flight"
                      src="/assets/images/loader.gif"
                    />
                    <p>Loading please wait...</p>
                  </div>
                  :
                  <FlightDetails
                    data={data} />}
              </div>
            </div>
            <div className="p-padding pt-0">
              <div className="px-15 py-padding bg-white">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group custom labelup mb-0">
                      <label>Where was this ticket booked</label>
                      {this.state.vendorTypes && (
                        <Select
                          placeholder={this.state.vendorName}
                          keyName={"vendorName"}
                          options={this.state.vendorTypes}
                          controlFunc={e =>
                            this.changeVendor(e, "vendorType")
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 offset-4 border-left">
                    <div className="text-right">
                      <div className="form-group custom labelup mb-0 d-inline-block w-70">
                        <label>Move to</label>
                        {this.queueTypes && (
                          <Select
                            value={this.state.statusType}
                            placeholder={this.state.queueName}
                            keyName={"items"}
                            options={this.queueTypes}
                            controlFunc={e =>
                              this.changeQueue(e, "statusType")
                            }
                            error={this.validatorUpdate2.message(
                              "Reason",
                              this.state.statusType,
                              "required|string"
                            )}
                          />
                        )}
                      </div>
                      <button type="button" className="btn  ml-3 d-inline-block" onClick={(e) => this.updateQueueType(e)}>move</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-padding pt-0">
              <div className="row">
                <div className="col-md-6">
                  <div className="btn-group-margin">
                    <button type="button" className="btn " data-toggle="modal" data-target="#validateFareModal">VALIDATE FARE</button>
                    <button type="button" className="btn" data-toggle="modal" data-target={(payLoad != undefined) ? "#airlineNameModal" : ''}
                      onClick={(e) => this.getPnrData(this.state.id)}
                    >UPDATE</button>
                    <button className="btn" onClick={history.goBack} >Back</button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="btn-group-margin text-right">
                    <button type="button" className="btn" onClick={(e) => this.enableCheckList(e)}>CHECK LIST</button>
                    {/* <button type="button" className="btn btn-success"
                      data-toggle="modal" data-target="#markCompleteModal"
                    >MARK COMPLETE</button> */}
                    <button type="button" className="btn mr-0" data-toggle="modal" data-target="#cancelBookingModal">CANCEL BOOKING</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.popUp && userDetails.userFields ?
          <UserDetailsPage
            popUp={this.state.popUp}
            userDetails={userDetails}
            closePopUp={this.closePopUp}
            savedPassVisa={this.state.savedPassVisa}
          />
          : ''}
        {
          <CheckListPage
            checkList={this.state.checkList}
            closeCheckList={this.closeCheckList}
            id={data.id}
            handleMarkComplete={this.handleMarkComplete}
          />
        }

        <div className="modal fade" id="validateFareModal" tabIndex="-1" role="dialog" aria-labelledby="validateFareLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">VALIDATE FARE</h6>
                <h5 className="modal-title text-white" id="validateFareLabel">CTFRKY2RAXS2</h5>
              </div>
              <div className="modal-middle pm-15">
                <div className="fare-validate accordion" id="accordionExample">
                  {modelUpdatedPrice}
                </div>
                <div className="modal-last btn-group-margin pt-4">
                  <button type="button" className="btn mb-0" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn  mb-0" onClick={this.updateValidateFareData} data-dismiss={this.taxFieldValidator.allValid() ? "modal" : ''}>Save
                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="airlineNameModal" tabIndex="-1" role="dialog" aria-labelledby="airlineNameLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">UPDATE</h6>
                <h5 className="modal-title text-white" id="airlineNameLabel">CTFRKY2RAXS2</h5>
              </div>
              <div className="modal-middle">
                {modelPnrData}
                <div className="pt-4  modal-px-30 bg-light">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group custom labelup">
                        <label>Fare Currency</label>
                        {this.fareCurrencyTypes && (
                          <ReactSelect
                            value={{ label: this.state.fareCurrency }}
                            isSearchable={true}
                            placeholder={this.state.currencyName}
                            options={this.fareCurrencyTypes}
                            onChange={(e) => this.changeFareCurrencyType(e, "fareCurrency")}

                          />
                        )}
                        <div style={{ fontSize: "12px", color: "#ff7979" }}>
                          {this.validator.message(
                            "fare currency",
                            this.state.fareCurrency,
                            "required"
                          )}
                        </div>

                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group custom labelup">
                        <label>Payment Type</label>
                        {this.paymentTypes && (
                          <Select
                            placeholder={this.state.paymentName}
                            keyName={"paymentType"}
                            options={this.paymentTypes}
                            controlFunc={e =>
                              this.changePaymentType(e, "paymentType")
                            }
                            error={this.validator.message(
                              "Payment Type",
                              this.state.paymentType,
                              "required|string"
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group custom labelup">
                        <label>Booking Date</label>
                        <DayPickerInput
                          placeholder='DD MMM YYYY'
                          formatDate={formatDate}
                          parseDate={parseDate}
                          format="LL"
                          value={
                            this.state.bookingDate
                              ? `${formatDate(
                                new Date(this.state.bookingDate),
                                "LL",
                                "it"
                              )}`
                              : ""
                          }
                          onDayChange={date =>
                            this.handleDayChange(
                              date,
                              "bookingDate"
                            )
                          }

                        />
                        <span className="icon-custom">
                          <i className="material-icons">calendar_today</i>
                        </span>
                        <div style={{ fontSize: "12px", color: "#ff7979" }}>
                          {this.validator.message(
                            "Booking Date",
                            this.state.bookingDate,
                            "required"
                          )}
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
                <div className="modal-last btn-group-margin py-3 modal-px-30">
                  <button type="button" className="btn" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn" data-dismiss={this.validator.allValid() ? "modal" : ''}
                    onClick={(e) => this.updatePnrData(e)} > Save
                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="cancelBookingModal" tabIndex="-1" role="dialog" aria-labelledby="cancelBookingLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">ACTION</h6>
                <h5 className="modal-title text-capitalize text-white" id="cancelBookingLabel"> Cancel Booking</h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <label>Reason</label>
                  {this.reasonTypes && (
                    <Select
                      placeholder={this.reasonName}
                      keyName={"reason"}
                      options={this.reasonTypes}
                      controlFunc={e =>
                        this.changeReason(e, "reasonType")
                      }
                      error={this.validatorUpdate.message(
                        "Reason",
                        this.state.reasonType,
                        "required"
                      )}
                    />
                  )}
                </div>
                <div className="form-group custom ">
                  <input type="text" placeholder="&nbsp;" className="form-control"
                    name="comments"
                    value={this.state.comments}
                    onChange={(e) => this.handleChange2(e)} />
                  {this.validatorUpdate.message(
                    "Comments",
                    this.state.comments,
                    "required"
                  )}
                  <label>Comments</label>
                </div>
                <div className="travel_alert pm-15 rounded">
                  <div className="d-inline-block alert-icon">
                    <p><i className="icon ion-md-alert "></i></p>
                  </div>
                  <div className="d-inline-block alert-detail">
                    <p>This flight is not in compliance with the following travel rule(s)</p>
                  </div>
                </div>
                <div className="modal-last btn-group-margin pt-4">
                  <button type="button" className="btn" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn mb-0" data-dismiss={this.validatorUpdate.allValid() ? "modal" : ''} onClick={(e) => this.updateBookingCancelData(data.id)}>Save
                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="modal fade" id="markCompleteModal" tabIndex="-1" role="dialog" aria-labelledby="markCompleteModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">ACTION</h6>
                <h5 className="modal-title text-capitalize text-white" id="cancelBookingLabel"> Mark Complete</h5>
              </div>
              <div className="modal-middle pm-30">

                <div className="d-inline-block alert-detail">
                  <h5>Are you sure you want to mark it as complete?</h5>
                </div>

                <div className="modal-last btn-group-margin pt-4">
                  <button type="button" className="btn" data-dismiss="modal">No</button>

                  <button type="button" className="btn  mb-0" onClick={(e) => this.handleMarkComplete(data.id)} data-dismiss="modal">YES
                    <i className="icon ion-md-arrow-dropright icon-margin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}


      </div >
    );
  }
}

export default FlightTripIdPage;
