import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import FormsMainPage from "./forms";
import FormsFeildsMainPage from "./forms-fields";
import FeildsMainPage from "./feilds";
import ConnectedMainPage from "./connected-list";
import DropdownButton from "./forms-fields/components/DropDownButton";

class FFMainPage extends Component {
  componentDidMount() {
    if (this.props.location.pathname === "/database-settings/admin-form") {
      this.props.history.push("/database-settings/admin-form/forms");
    }
    else {
      this.props.history.push(this.props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/database-settings/admin-form") {
      this.props.history.push("/database-settings/admin-form/forms");
    }
  }
  render() {
    return (
      <div className="FFMainPage">
        <Switch>
          <Route
            path="/database-settings/admin-form/forms"
            component={FormsMainPage}
          />
          <Route
            path="/database-settings/admin-form/forms-fields"
            component={FormsFeildsMainPage}
          />
          <Route
            path="/database-settings/admin-form/fields"
            component={FeildsMainPage}
          />{" "}
          <Route
            path="/database-settings/admin-form/connected-lists"
            component={ConnectedMainPage}
          />
        </Switch>
      </div>
    );
  }
}
export default FFMainPage;
