import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { testMidOfficeServices } from "../../../_services";
class TrainBookingRequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalCount: "",
      search: "",
      limit: 10,
      page: 1,
    }
  }
  componentDidMount() {
    let data = {
      bookingType: this.props.match.params.bookingType,
      statusType: this.props.match.params.statusType,
      isInternational: this.props.match.params.find,
      search: this.state.search,
      limit: this.state.limit,
      page: this.state.page
    }
    this.getTrainBookingRequest(data)
  }

  getTrainBookingRequest = async (data) => {
    var res = await testMidOfficeServices.getTrainBookingRequest(data)
    if (res) {
      console.log(res.result);
      this.setState({
        data: res.result,
        totalCount: res.count,
        search: "",
        limit: 10,
        page: 1,
      })
    } else {
      this.setState({ data: [], totalCount: "" })
    }
  }
  searchHandler = (e) => {
    this.setState({ search: e.target.value })
    let data = {
      bookingType: this.props.match.params.statusType,
      statusType: this.props.match.params.bookingType,
      isInternational: this.props.match.params.isInternational === "true" ? true : false,
      search: e.target.value,
      limit: this.state.limit,
      page: this.state.page
    }
    this.getTrainBookingRequest(data)
  }

  handleItemChange = limit => {
    this.setState({ limit: limit.items, page: 1 });
    let data = {
      bookingType: this.props.match.params.statusType,
      statusType: this.props.match.params.bookingType,
      isInternational: this.props.match.params.isInternational === "true" ? true : false,
      search: this.state.search,
      limit: limit.items,
      page: this.state.page
    }
    this.getTrainBookingRequest(data);
  };

  pageClickHandler = page => {
    this.setState({ page: page });
    let data = {
      bookingType: this.props.match.params.statusType,
      statusType: this.props.match.params.bookingType,
      isInternational: this.props.match.params.isInternational === "true" ? true : false,
      search: this.state.search,
      limit: this.state.limit,
      page: page
    }
    this.getTrainBookingRequest(data);
  };


  render() {
    let data = this.props;
    return (
      <div>
      <div className="page-header">
          <div className="resp-toggle-menu"><i className="material-icons">menu</i></div>
          <h6 className="heading">Train Booking Request</h6>
        </div>
        <div className="page-content">
        <div className="admin-tabs">
          {/* <div className="inner-header">
            <div className="shape-header ml-5">
              <div className="left">F</div>
              <div className="right">
                <h6 className="heading">Train Booking Request</h6>
                <p className="text">Has been the industry’s standard dummy text ever since the 1500s.</p>
              </div>
            </div>
          </div> */}

              <div className="py-padding">
                <div className="table-container">
                  <form>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group ">
                          <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle bg-light border-0 " data-toggle="dropdown">All Clients
                            </button>
                            <div className="dropdown-menu">
                              <button className="dropdown-item">Expense Entry Form</button>
                              <button className="dropdown-item">Expense Entry Form</button>
                              <button className="dropdown-item">Expense Entry Form</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 offset-md-5 col-lg-2 offset-lg-6">
                        <div className="search-custom">
                          <input type="text" name="" className="form-control" placeholder="Search" />
                          <span><i className="material-icons align-middle">search</i></span>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>CURIO REF NO</th>
                          <th>BOOKING REF</th>
                          <th>TRAVELER NAME</th>
                          <th>BOOKING DATE</th>
                          <th>CLASS</th>
                          <th>SECTOR</th>
                          <th>TRAVEL DATE</th>
                          <th>COMPANY</th>
                          <th>FARE TYPE</th>
                          <th>IS QC DONE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-primary"><NavLink to="#">CTFRKY2RAXS2</NavLink></td>
                          <td>34536753247</td>
                          <td>Vishwa</td>
                          <td>08/03/2019</td>
                          <td>Sleeper</td>
                          <td>BLR - DEL</td>
                          <td>8/8/2088</td>
                          <td>FSL</td>
                          <td>Corporate fair</td>
                          <td>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVender2Modal">UPDATE TICKET/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#cancelBookingModal">NO/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVenderModal">CHANGE TRAIN</button>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-primary"><NavLink to="#">CTFRKY2RAXS2</NavLink></td>
                          <td>34536753247</td>
                          <td>Vishwa</td>
                          <td>08/03/2019</td>
                          <td>Sleeper</td>
                          <td>BLR - DEL</td>
                          <td>8/8/2088</td>
                          <td>FSL</td>
                          <td>Corporate fair</td>
                          <td>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVender2Modal">UPDATE TICKET/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#cancelBookingModal">NO/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVenderModal">CHANGE TRAIN</button>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-primary"><NavLink to="#">CTFRKY2RAXS2</NavLink></td>
                          <td>34536753247</td>
                          <td>Vishwa</td>
                          <td>08/03/2019</td>
                          <td>Sleeper</td>
                          <td>BLR - DEL</td>
                          <td>8/8/2088</td>
                          <td>FSL</td>
                          <td>Corporate fair</td>
                          <td>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVender2Modal">UPDATE TICKET/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#cancelBookingModal">NO/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVenderModal">CHANGE TRAIN</button>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-primary"><NavLink to="#">CTFRKY2RAXS2</NavLink></td>
                          <td>34536753247</td>
                          <td>Vishwa</td>
                          <td>08/03/2019</td>
                          <td>Sleeper</td>
                          <td>BLR - DEL</td>
                          <td>8/8/2088</td>
                          <td>FSL</td>
                          <td>Corporate fair</td>
                          <td>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVender2Modal">UPDATE TICKET/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#cancelBookingModal">NO/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVenderModal">CHANGE TRAIN</button>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-primary"><NavLink to="#">CTFRKY2RAXS2</NavLink></td>
                          <td>34536753247</td>
                          <td>Vishwa</td>
                          <td>08/03/2019</td>
                          <td>Sleeper</td>
                          <td>BLR - DEL</td>
                          <td>8/8/2088</td>
                          <td>FSL</td>
                          <td>Corporate fair</td>
                          <td>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVender2Modal">UPDATE TICKET/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#cancelBookingModal">NO/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVenderModal">CHANGE TRAIN</button>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-primary"><NavLink to="#">CTFRKY2RAXS2</NavLink></td>
                          <td>34536753247</td>
                          <td>Vishwa</td>
                          <td>08/03/2019</td>
                          <td>Sleeper</td>
                          <td>BLR - DEL</td>
                          <td>8/8/2088</td>
                          <td>FSL</td>
                          <td>Corporate fair</td>
                          <td>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVender2Modal">UPDATE TICKET/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#cancelBookingModal">NO/</button>
                            <button type="button" className="btn text-primary btn-nothing" data-toggle="modal" data-target="#sendToVenderModal">CHANGE TRAIN</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table-footer">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="page-count">
                          <div className="text">Items</div>
                          <div className="count">
                            <div className="dropdown">
                              <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                10</button>
                              <div className="dropdown-menu">
                                <button type="button" className="dropdown-item">10</button>
                                <button type="button" className="dropdown-item">20</button>
                                <button type="button" className="dropdown-item">30</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <Pagination
                          activePage={data.active}
                          itemsCountPerPage={10}
                          totalItemsCount={10}
                          pageRangeDisplayed={1}
                          onChange={data.handlePageChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="modal fade" id="sendToVender2Modal" tabIndex="-1" role="dialog" aria-labelledby="sendToVender2Lable" aria-hidden="true">
          <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">Action</h6>
                <h5 className="modal-title text-white text-uppercase" id="sendToVender2Lable"> ksdjksjaksn</h5>
              </div>
              <div className="modal-middle pm-15 modal-px-30">
                <div className="white-card info-box pm-15 py-3">
                  <div className="row">
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Traveller name</p>
                      <p className="text-capitalize font-weight-bold">marilyn pierce</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Date of birth</p>
                      <p className="text-capitalize font-weight-bold">26-03-1974</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Mobile</p>
                      <p className="text-capitalize font-weight-bold">+918888888888</p>
                    </div>
                  </div>
                </div>
                <div className="white-card info-box pm-15 py-3 mt-2">
                  <div className="row">
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase">Booked by</p>
                      <p className="text-capitalize font-weight-bold">Nicolas Richards</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase mb-0">Email Id</p>
                      <p className="text-capitalize font-weight-bold">NicolasRichards@gmail.com</p>
                    </div>
                    <div className="col-md-4">
                      <p className="lighter-p text-uppercase mb-0">Mobile Number</p>
                      <p className="text-capitalize font-weight-bold">+918885555888</p>
                    </div>
                  </div>
                </div>
                <div className="fare-validate accordion" id="accordionExample">
                  <div className="card border-0">
                    <div className="card-header border-0" id="headingOne">
                      <h5 className="mb-0">
                        <div className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                          aria-controls="collapseOne">
                          <div className="float-left">
                            <label className="radio-container checkbox-padding mb-1 mt-2">
                              BANGALORE CY JN, SBC - CHENNAI CENTRAL,MAS
                                                <input type="radio" name="radio" />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="float-right">
                            <button type="button" className="btn mr-4">Awaiting Response
                                                    <i className="icon ion-md-hourglass ml-3"></i>
                            </button>
                            <i className="icon ion-md-arrow-dropdown icon-margin"></i>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </h5>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body pm-15 pt-4 pb-0">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Source</label>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group custom labelup">
                                  <label>Booking Class</label>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                      Third AC
                                                                    </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">Third AC</button>
                                      <button className="dropdown-item">Third AC</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Departure Date & Time</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>21:00</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Destination</label>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group custom labelup">
                                  <label>Quota</label>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                      Third AC
                                                                    </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">Third AC</button>
                                      <button className="dropdown-item">Third AC</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Arrival Date & Time</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>21:00</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group custom textarea ">
                              <textarea rows="2" type="text" placeholder="&nbsp;" className="form-control" />
                              <label>Reason</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Train Name & Number</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>22</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0 mt-1">
                    <div className="card-header border-0" id="headingTwo">
                      <h5 className="mb-0">
                        <div className="mb-0" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                          aria-controls="collapseTwo">
                          <div className="float-left">
                            <label className="radio-container checkbox-padding mb-1 mt-2">
                              BANGALORE CY JN, SBC - CHENNAI CENTRAL,MAS
                                                <input type="radio" name="radio" />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="float-right">
                            <button type="button" className="btn btn-border-success mr-4">Success
                                                </button>
                            <i className="icon ion-md-arrow-dropdown icon-margin"></i>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </h5>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body pm-15 pt-4 pb-0">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Source</label>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group custom labelup">
                                  <label>Booking Class</label>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                      Third AC
                                                                </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">Third AC</button>
                                      <button className="dropdown-item">Third AC</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Departure Date & Time</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>21:00</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Destination</label>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group custom labelup">
                                  <label>Quota</label>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                      Third AC
                                                                </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">Third AC</button>
                                      <button className="dropdown-item">Third AC</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Arrival Date & Time</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>21:00</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group custom textarea ">
                              <textarea rows="2" type="text" placeholder="&nbsp;" className="form-control" />
                              <label>Reason</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Train Name & Number</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>22</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-last btn-group-margin mt-4">
                  <button type="button" className="btn mb-0" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn  mb-0">Send to vendor
                            <i className="icon ion-md-arrow-dropright icon-margin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade" id="sendToVenderModal" tabIndex="-1" role="dialog" aria-labelledby="sendToVenderLable" aria-hidden="true">
          <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">Action</h6>
                <h5 className="modal-title text-uppercase text-white" id="sendToVenderLable"> ksdjksjaksn</h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="fare-validate accordion" id="accordionExample">
                  <div className="card border-0">
                    <div className="card-header border-0" id="headingOne">
                      <h5 className="mb-0">
                        <div className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                          aria-controls="collapseOne">
                          <div className="float-left">
                            <label className="radio-container checkbox-padding mb-1">
                              BANGALORE CY JN, SBC - CHENNAI CENTRAL,MAS
                                                <input type="radio" name="radio" />
                              <span className="checkmark"></span>
                            </label></div>
                          <div className="float-right">
                            <i className="icon ion-md-arrow-dropdown icon-margin"></i>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </h5>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body pm-15 pt-4 pb-0">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Source</label>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group custom labelup">
                                  <label>Booking Class</label>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                      Third AC
                                                                    </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">Third AC</button>
                                      <button className="dropdown-item">Third AC</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Departure Date & Time</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>21:00</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Destination</label>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group custom labelup">
                                  <label>Quota</label>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                      Third AC
                                                                    </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">Third AC</button>
                                      <button className="dropdown-item">Third AC</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Arrival Date & Time</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>21:00</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group custom textarea ">
                              <textarea rows="2" type="text" placeholder="&nbsp;" className="form-control" />
                              <label>Reason</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Train Name & Number</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>22</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0 mt-1">
                    <div className="card-header border-0" id="headingTwo">
                      <h5 className="mb-0">
                        <div className="mb-0" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                          aria-controls="collapseTwo">
                          <div className="float-left">
                            <label className="radio-container checkbox-padding mb-1">
                              BANGALORE CY JN, SBC - CHENNAI CENTRAL,MAS
                                                <input type="radio" name="radio" />
                              <span className="checkmark"></span>
                            </label></div>
                          <div className="float-right">
                            <i className="icon ion-md-arrow-dropdown icon-margin"></i>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </h5>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body pm-15 pt-4 pb-0">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Source</label>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group custom labelup">
                                  <label>Booking Class</label>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                      Third AC
                                                                </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">Third AC</button>
                                      <button className="dropdown-item">Third AC</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Departure Date & Time</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>21:00</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Destination</label>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group custom labelup">
                                  <label>Quota</label>
                                  <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                      Third AC
                                                                </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">Third AC</button>
                                      <button className="dropdown-item">Third AC</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Arrival Date & Time</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>21:00</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group custom textarea ">
                              <textarea rows="2" type="text" placeholder="&nbsp;" className="form-control" />
                              <label>Reason</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="BANGALORE CY JN,SBC" className="form-control" />
                                  <label>Train Name & Number</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group custom">
                                  <input type="text" placeholder="&nbsp;" className="form-control" />
                                  <label>22</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-last btn-group-margin mt-4">
                  <button type="button" className="btn mb-0" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn  mb-0">Send to vendor
                            <i className="icon ion-md-arrow-dropright icon-margin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="cancelBookingModal" tabIndex="-1" role="dialog" aria-labelledby="cancelBookingLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">ACTION</h6>
                <h5 className="modal-title text-capitalize text-white" id="cancelBookingLabel"> Cancel Booking</h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <label>Reason</label>
                  <div className="dropdown">
                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                      Other Reason
                                </button>
                    <div className="dropdown-menu">
                      <button className="dropdown-item">1</button>
                      <button className="dropdown-item">2</button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <input type="text" placeholder="TEXT" className="form-control" />
                  <label>Comments</label>
                </div>
                <div className="travel_alert pm-15 rounded">
                  <div className="d-inline-block alert-icon">
                    <p><i className="icon ion-md-alert"></i>
                    </p>
                  </div>
                  <div className="d-inline-block alert-detail">
                    <p>This flight is not in compliance with the following travel rule(s)</p>
                  </div>
                </div>
                <div className="modal-last btn-group-margin pt-3">
                  <button type="button" className="btn mb-0" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn  mb-0">Save
                            <i className="icon ion-md-arrow-dropright icon-margin"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default TrainBookingRequestPage;
