import React, { Component } from "react";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

import AddEmailReminderPage from "./add-edit-reminder-html";
import { companyEmailReminderServices } from "../../../../_services";
// import { get } from "mobx";

class AddEmailReminder extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      serviceModuleId: localStorage["serviceModuleId"],
      startDate:null,
      isLoading: false,
      isEditable: false,
      notificationBoxContent: "",
      displayfrequency: "",
      emailTemplateId: "",
      frequencyValue: "",
      copyToApproval: false,
      displayApproval: '',
      displayType: "",
      templateId: "",
      olderThanType: "",
      olderThan: "",
      frequency: "",
      subject: "",
      contentAfterExpiry: "",
      content: "",
      name: "",
      type: "",
      time: "",
      notificationBoxAfterExpiry : ''
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({
        templateId: this.props.match.params.id,
        isEditable: true,
        time: this.state.startDate && this.state.startDate.toLocaleString().split("/")[0]
      });
      this.getEmailReminderTemplateById();
    }
  }
  // date change handler
  handleChange = date => {
    let exactDate = date.toLocaleString().split("/")[0];
    this.setState({
      startDate: date,
      frequencyValue: exactDate
    });
  };
  // get email template details
  getEmailReminderTemplateById = async () => {
    const res = await companyEmailReminderServices.getEmailReminderDeatilsById(
      this.props.match.params.id
    );
      this.setState({
        emailTemplateId: res ? res.result.id : "",
        name: res ? res.result.name : "",
        subject: res ? res.result.subject : "",
        olderThan: res ? res.result.olderThan : "",
        olderThanType: res ? res.result.olderThanType : "",
        type: res ? res.result.type : "",
        frequency: res ? res.result.frequency : "",
        content: res ? res.result.content : "",
        notificationBoxContent: res ? res.result.notificationContent:"",
        contentAfterExpiry: res ? res.result.contentAfterExpiry:"",
        notificationBoxAfterExpiry : res ? res.result.notificationContentAfterExpiry:'',
        copyToApproval : res ? res.result.copyToApproval : ''
      })
    if (res.result.frequency === "SPECIFIC_DAY_OF_MONTH") {
      let getDate = new Date().toLocaleString().split("/")[0]
      let changeDate = Math.abs(getDate - res.result.frequencyValue);
      let frequencyUpdatedDate = new Date();
      let updatedDate = frequencyUpdatedDate.setDate(changeDate + +getDate);
      this.setState({
        startDate: res ? frequencyUpdatedDate : ""
      });

    } else {
      this.setState({
        frequencyValue: res.result.frequencyValue? res.result.frequencyValue:""
      });
    }
  };
  // Reminder type handler
  onSelectCurrencyHandler = (displayType, type) => {
    this.setState({
      displayType: displayType,
      type: type
    });
  };
  // Frequency select handler
  onFrequencySelectHandler = (displayfrequency, frequency) => {
    this.setState({
      displayfrequency: displayfrequency,
      frequency: frequency
    });
  };
  // email content handler
  emailContentHandler = content => {
    this.setState({
      content: content
    });
  };

  emailAfterExpiryContentHandler = emailcontentAfterExpiry => {
    this.setState({
      contentAfterExpiry: emailcontentAfterExpiry
    });
  };

  notificationContentHandler = data => {
    this.setState({
      notificationBoxContent: data.target.value
    });
  };
  
  // handling input of diffrent fields
  onInputChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // handling time of email expire date
  onTimeSelectHandler = time => {
    this.setState({ olderThanType: time });
  };

  // handling approver permission
  isApproverSelecthandler = (displayApproval, isSelect) => {
    this.setState({
      displayApproval: displayApproval,
      copyToApproval: isSelect
    });
  };

  notificationAterExpiryContentHandler = (data) => {
    this.setState({
      notificationBoxAfterExpiry : data.target.value
    })
  }
  // create email reminder
  onSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        isLoading: true
      });
      const payload = {
        name: this.state.name,
        subject: this.state.subject,
        content: this.state.content,
        serviceModuleId: this.state.serviceModuleId,
        olderThan: +this.state.olderThan,
        frequency: this.state.frequency,
        type: this.state.type,
        frequencyValue: this.state.frequencyValue? this.state.frequencyValue: "",
        olderThanType: this.state.olderThanType,
        copyToApproval: this.state.copyToApproval ,
        notificationContent: this.state.notificationBoxContent,
        notificationContentAfterExpiry: this.state.notificationBoxAfterExpiry ? this.state.notificationBoxAfterExpiry: "",
        contentAfterExpiry: this.state.contentAfterExpiry?this.state.contentAfterExpiry:""
      };
      const res = await companyEmailReminderServices.createEmailReminder(
        payload
      );
      this.setState({ isLoading: false });
      toast.success(res.message);
      this.props.history.push("../email-reminders");
    } else {
      this.validator.showMessages();
      this.setState({ loading: false });
    }
  };
  // update email reminder
  onUpdatehandler = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const payload = {
        name: this.state.name,
        subject: this.state.subject,
        content: this.state.content,
        olderThan: +this.state.olderThan,
        frequency: this.state.frequency,
        frequencyValue: this.state.frequencyValue?this.state.frequencyValue:"",
        olderThanType: this.state.olderThanType,
        copyToApproval: this.state.copyToApproval ,
        notificationContent: this.state.notificationBoxContent,
        contentAfterExpiry: this.state.contentAfterExpiry?this.state.contentAfterExpiry:"",
        notificationContentAfterExpiry: this.state.notificationBoxAfterExpiry ? this.state.notificationBoxAfterExpiry: "",
        type: this.state.type,
        isSet: true
      };
      const res = await companyEmailReminderServices.updateEmailReminder(
        this.state.emailTemplateId,
        payload
      );
      this.setState({ isLoading: false });
      toast.success(res.message);
      this.props.history.push("../email-reminders");
    } else {
      this.validator.showMessages();
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <div>
        <AddEmailReminderPage
          {...this.state}
          onSelectCurrencyHandler={this.onSelectCurrencyHandler}
          onFrequencySelectHandler={this.onFrequencySelectHandler}
          emailContentHandler={this.emailContentHandler}
          onInputChangeHandler={this.onInputChangeHandler}
          onSubmit={this.onSubmit}
          validator={this.validator}
          onUpdatehandler={this.onUpdatehandler}
          onTimeSelectHandler={this.onTimeSelectHandler}
          handleChange={this.handleChange}
          isApproverSelecthandler={this.isApproverSelecthandler}
          notificationContentHandler={this.notificationContentHandler}
          emailAfterExpiryContentHandler={this.emailAfterExpiryContentHandler}
          notificationAterExpiryContentHandler={this.notificationAterExpiryContentHandler}
        />
      </div>
    );
  }
}
export default AddEmailReminder;
