import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Select from "../../_common/_forms/Select";

const CountriesPage = props => {
  const {
    state,
    saveCountry,
    handleInput,
    selectCountryHandler,
    saveState,
    selectStateHandler,
    saveCity,
    selectCityHandler
  } = props;

  return (
    <div className="master">
      <div className="page-header">
        <h6 className="heading">Countries</h6>
      </div>
      <div className="page-content">
        <div className="p-padding bg-white">
          <div className="row">
            <div className="col-md-5">
              <div className="form-group custom labelup mb-3">
                <label>Country</label>
                <div className="dropdown show">
                  <Select
                    className="bg-white"
                    placeholder={
                      state.selectedCountry
                        ? state.selectedCountry
                        : "Select an option"
                    }
                    options={state.countryList ? state.countryList : []}
                    keyName={"name"}
                    controlFunc={e => selectCountryHandler(e)}
                  />
                </div>
              </div>
              <button
                className="btn  mb-4"
                data-toggle="modal"
                data-target="#AddCountryModal"
              >
                Add Country
              </button>

              <div className="form-group custom labelup mb-3 mt-2">
                <label>State/Province</label>
                <div className="dropdown show">
                  <Select
                    className="bg-white"
                    placeholder={
                      state.selectedState
                        ? state.selectedState
                        : "Select an option"
                    }
                    options={state.stateList ? state.stateList : []}
                    keyName={"name"}
                    controlFunc={e => selectStateHandler(e)}
                  />
                </div>
              </div>
              <button
                className="btn  mb-4"
                data-toggle="modal"
                data-target="#AddStateModal"
              >
                Add State/Province
              </button>
              <div class="form-group custom labelup mb-3 mt-2">
                <label>City</label>
                <div class="dropdown show">
                  <Select
                    className="bg-white"
                    placeholder={
                      state.selectedCity
                        ? state.selectedCity
                        : "Select an option"
                    }
                    options={state.cityList ? state.cityList : []}
                    keyName={"name"}
                    controlFunc={e => selectCityHandler(e)}
                  />
                </div>
              </div>
              <button
                className="btn  mb-4"
                data-toggle="modal"
                data-target="#AddCityModal"
              >
                Add City
              </button>
            </div>
            <div className="col-md-7">
              <div className="country-info">
                <div className=" bg-light px-20 py-3 border text-primary mb-2">
                  <p className="mb-0 font-weight-bold">
                    {props.state.selectedCountry
                      ? props.state.selectedCountry + "-"
                      : ""}{" "}
                    {props.state.selectedState}
                    <span className="text-dark">
                      {" "}
                      {props.state.selectedCity
                        ? "," + props.state.selectedCity
                        : ""}
                    </span>
                  </p>
                </div>
                <div className="border mb-2 bg-light">
                  <table className="table mb-0 country__table">
                    <thead>
                      <tr>
                        <th className="border-0">LAT</th>
                        <th className="border-0">LON</th>
                        <th className="border-0" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td>9.29288</td>
                        <td>9.29288</td>
                        <td className="text-right">
                          <button
                            className="btn "
                            data-toggle="modal"
                            data-target="#AddGeoCodeModal"
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="bg-light px-20 py-3 border-top">
                    <button
                      className="btn "
                      data-toggle="modal"
                      data-target="#AddGeoCodeModal"
                    >
                      Add Geo Code
                    </button>
                  </div>
                </div>
                <div className="border mb-2 bg-light">
                  <table className="table mb-0 country__table">
                    <thead>
                      <tr>
                        <th className="border-0">ALSO KNOW AS</th>
                        <th className="border-0" />
                        <th className="border-0" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {props.state.alsoKnownAs
                        ? props.state.alsoKnownAs.map(item => {
                            return (
                              <tr className="bg-light">
                                <td>{item}</td>
                                <td />
                                <td className="text-right">
                                  <button
                                    className="btn "
                                    data-toggle="modal"
                                    data-target="#AlsoKnownAsModal"
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                  <div className="bg-light px-20 py-3 border-top">
                    <button
                      className="btn "
                      data-toggle="modal"
                      data-target="#AlsoKnownAsModal"
                    >
                      Add AKA Name
                    </button>
                  </div>
                </div>
                <div id="accordion">
                  <div className="card mb-2">
                    <div
                      className="card-header bg-light border-bottom-0"
                      id="headingOne"
                    >
                      <div
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                        <span>
                          <strong>Has Airport</strong>
                        </span>
                      </div>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="bg-light">
                        <table className="table mb-0 country__table">
                          <thead>
                            <tr>
                              <th className="border-0">AIRPORT CODE</th>
                              <th className="border-0">AIRPORT NAME</th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            <tr>
                              <td>BLR</td>
                              <td>Bangalore International Airport</td>
                              <td className="text-right">
                                <button
                                  className="btn "
                                  data-toggle="modal"
                                  data-target="#AddAirportCodeModal"
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="bg-light px-20 py-3 border-top">
                          <button
                            className="btn "
                            data-toggle="modal"
                            data-target="#AddAirportCodeModal"
                          >
                            Add Airport Code
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div
                      className="card-header bg-light border-bottom-0"
                      id="headingTwo"
                    >
                      <div
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                        <span>
                          <strong>Has Station</strong>
                        </span>
                      </div>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div className="bg-light">
                        <table className="table mb-0 country__table">
                          <thead>
                            <tr>
                              <th className="border-0">STATION CODE</th>
                              <th className="border-0">STATION NAME</th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            <tr>
                              <td>CONT</td>
                              <td>Bangalore Cantonment</td>
                              <td className="text-right">
                                <button
                                  className="btn "
                                  data-toggle="modal"
                                  data-target="#AddStationCodeModal"
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="bg-light px-20 py-3 border-top">
                          <button
                            className="btn "
                            data-toggle="modal"
                            data-target="#AddStationCodeModal"
                          >
                            Add Airport Code
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border mb-2 bg-light">
                  <table className="table mb-0 country__table">
                    <thead>
                      <tr>
                        <th className="border-0">TIME ZONE</th>
                        <th className="border-0" />
                        <th className="border-0" />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td>+5:30 - Chennai, Kolkata, Mumbai</td>
                        <td />
                        <td className="text-right">
                          <button
                            className="btn "
                            data-toggle="modal"
                            data-target="#AddTimeZoneModal"
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="bg-light px-20 py-3 border-top">
                    <button
                      className="btn "
                      data-toggle="modal"
                      data-target="#AddTimeZoneModal"
                    >
                      Add Time Zone
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddCountryModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="AddCountryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddCountryLabel">
                Add Country
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" />
              </button>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom labelup">
                <label>Country Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="countryName"
                  onChange={e => handleInput(e)}
                  placeholder="&nbsp;"
                />
                <div className="help-block">
                  {props.error.message(
                    "Country Name",
                    state.countryName,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group custom labelup">
                <label>Country Code</label>
                <input
                  type="text"
                  onChange={e => handleInput(e)}
                  className="form-control"
                  name="countryCode"
                  placeholder="&nbsp;"
                />
                <div className="help-block">
                  {props.error.message(
                    "Country Code",
                    state.countryCode,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group custom labelup">
                <label>Dial Code</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={e => handleInput(e)}
                  name="dialCode"
                  placeholder="&nbsp;"
                />
                <div className="help-block">
                  {props.error.message("Dial Code", state.dialCode, "required")}
                </div>
              </div>
              <div className="modal-last btn-group-margin">
                <button
                  type="submit"
                  className="btn  lg-padding"
                  onClick={saveCountry}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddStateModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="AddStateLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddStateLabel">
                Add State/Province
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom labelup">
                <label>State/Province Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={e => handleInput(e)}
                  name="stateName"
                  placeholder="&nbsp;"
                />
                <div className="help-block">
                  {props.error1.message(
                    "State name",
                    state.stateName,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group custom labelup">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={e => handleInput(e)}
                  name="stateCode"
                  placeholder="&nbsp;"
                />
                <div className="help-block">
                  {props.error1.message(
                    "State Code",
                    state.stateCode,
                    "required"
                  )}
                </div>
              </div>
              <div className="modal-last btn-group-margin">
                <button
                  type="submit"
                  className="btn  lg-padding"
                  onClick={saveState}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddCityModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="AddCityLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddCityLabel">
                Add City
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom labelup">
                <label>City Name</label>
                <input
                  type="text"
                  name="cityName"
                  className="form-control"
                  onChange={e => handleInput(e)}
                  placeholder="&nbsp;"
                />
                <div className="help-block">
                  {props.error2.message(
                    "City Name",
                    state.cityName,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group custom labelup">
                <label>City Code</label>
                <input
                  type="text"
                  name="cityCode"
                  className="form-control"
                  onChange={e => handleInput(e)}
                  placeholder="&nbsp;"
                />
                <div className="help-block">
                  {props.error2.message(
                    "City Code",
                    state.cityCode,
                    "required"
                  )}
                </div>
              </div>

              <div className="modal-last btn-group-margin">
                <button
                  type="submit"
                  className="btn  lg-padding"
                  onClick={saveCity}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddGeoCodeModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="AddGeoCodeLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddGeoCodeLabel">
                Add Geo Code
              </h5>
              {/* <button type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  placeholder="&nbsp;"
                />
                <label>Lat</label>
              </div>
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  placeholder="&nbsp;"
                />
                <label>Lon</label>
              </div>

              <div className="modal-last btn-group-margin">
                <button type="submit" className="btn  lg-padding">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AlsoKnownAsModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="AlsoKnownAsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AlsoKnownAsLabel">
                Also Know As
              </h5>
              {/* <button type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  placeholder="&nbsp;"
                />
                <label>Also Know As Name</label>
              </div>

              <div className="modal-last btn-group-margin">
                <button type="submit" className="btn  lg-padding">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddTimeZoneModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="AddTimeZoneLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddTimeZoneLabel">
                Add Time Zone
              </h5>
              {/* <button type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom labelup">
                <label>City</label>
                <div className="dropdown show">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                    aria-expanded="true"
                  >
                    Time Zone
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">
                      Time Zone
                    </button>
                    <button type="button" className="dropdown-item">
                      Time Zone
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-last btn-group-margin">
                <button type="submit" className="btn  lg-padding">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddAirportCodeModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="AddAirportCodeLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddAirportCodeLabel">
                Add Airport Code
              </h5>
              {/* <button type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  placeholder="&nbsp;"
                />
                <label>Airport Name</label>
              </div>
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  placeholder="&nbsp;"
                />
                <label>Code</label>
              </div>

              <div className="modal-last btn-group-margin">
                <button type="submit" className="btn  lg-padding">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddStationCodeModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="AddStationCodeLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddStationCodeLabel">
                Add Station Code
              </h5>
              {/* <button type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  placeholder="&nbsp;"
                />
                <label>Station Name</label>
              </div>
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  placeholder="&nbsp;"
                />
                <label>Code</label>
              </div>

              <div className="modal-last btn-group-margin">
                <button type="submit" className="btn  lg-padding">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CountriesPage;
