import React from 'react';

const DataType = props => {
    const {handleDataType,dataType} = props;
    return(
        <button type="button" onClick={e =>handleDataType(dataType)} className="dropdown-item">
            {dataType}
        </button>
    )
}

export default DataType;