import { http } from "../_helpers";
import { config } from "../env";

const getEmailTemplate = data => {
  return http
    .get(
      config.apiUrlCM +
        `email-administration/list-all?serviceModuleId=${data.serviceModuleId}&limit=${data.limit}&page=${data.page}`
    )
    .then(res => {
      return res ? res : "";
    });
};

const getFilterEmailTemplate = data => {
  return http
    .get(
      config.apiUrlCM +
        `email-administration/list-all?serviceModuleId=${data.serviceModuleId}&limit=${data.limit}&page=${data.page}&search=${data.search}`
    )
    .then(res => {
      return res ? res : "";
    });
};

export const companyEmailTravelServices = { getEmailTemplate, getFilterEmailTemplate };
