import React from 'react';
import ReactToPrint from 'react-to-print';
import FlightETicketPage from './flightETicket';
import FlightInvoicePage from './flightInvoice';
import { testMidOfficeServices } from "../../../_services";

class FlightSwitch extends React.Component {
    state = {
        data: [],
        type1: ''
    }
    componentDidMount = () => {
        let data = {
            type1: this.props.match.params.type1,
            tripId: this.props.match.params.tripId,
            journeyId: this.props.match.params.journeyId,
            type2: this.props.match.params.type,
        }
        this.setState({ type1: this.props.match.params.type1 });
        console.log(data);
        this.getFlightETicket(data);
    }
    getFlightETicket = async (data) => {
        console.log(data);
        let res = await testMidOfficeServices.getFlightETicketnInvoice(data);
        if (res) {
            console.log(res.result);
            this.setState({ data: res.result });
        }
    }
    render() {
        return (
            <div>
                <ReactToPrint
                    trigger={() => <div className=""><a href="#" className="btn btn-primary">{(this.state.type1 === 'eticket') ? "Print E-Ticket" : "Print Invoice"}</a></div>}
                    content={() => this.componentRef}
                />
                {(this.state.type1 === 'eticket') ?
                    <FlightETicketPage ref={el => (this.componentRef = el)}
                        eTicketData={this.state.data} />
                    :
                    <FlightInvoicePage ref={el => (this.componentRef = el)}
                        invoiceData={this.state.data} />
                }
            </div>
        );
    }
}
export default FlightSwitch;