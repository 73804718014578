import { http } from '../_helpers';
import { config } from '../env';

const getAllProjectsList = (data) =>{
    return http.post(config.apiUrl+'projects/listAll',data)
    .then(data => {
        return data;
    });
}

const getAllClientList = (data) =>{
    return http.post(config.apiUrl+'projects/listAll',data)
    .then(data => {
        return data;
    });
}
const addProjects = (data) => {
    return http.post(config.apiUrl+'projects',data)
    .then(data => {
        return data;
    });
}

const getOneProjectDetail = (id) =>{
    return http.get(config.apiUrl+'project/'+id)
    .then(data => {
        return data;
    });
}
const getLogo = (id) =>{
    return http.get(config.apiUrl+`/projects/${id}/get-logo`)
    .then(data => {
        return data;
    });
}

const uploadLogo = (id,data) =>{
    console.log(data)
    let formData = new FormData()
    formData.append('image',data)
    console.log(formData)
    return http.fileUpload(config.apiUrl+`/projects/upload-logo/${id}`,formData)
    .then(data => {
        return data;
    });
}

const removeLogo = (id) => {
    let payload = {
        companyLogo: ""
    }
    let url = `${config.apiUrl}projects/${id}`;

    return http.patch(url,payload).then(data => data);
}

export const projectService = {
    getAllProjectsList,
    addProjects,
    getOneProjectDetail,
    getAllClientList,
    getLogo,
    uploadLogo,
    removeLogo
};