import { http } from "../_helpers";
import { config } from "../env";
const login = data => {
  return http.post(config.apiUrl + "login", data).then(data => {
    // login successful if there's a jwt token in the response
    if (data.result.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(data.result));
      localStorage.setItem("token", JSON.stringify(data.result.token));
    }

    return data;
  });
};
const logout = () => {
  localStorage.removeItem("user");
};
const getUserList = (page, items, search) => {
  return http
    .get(
      config.apiUrl +
      "users?page=" +
      page +
      "&limit=" +
      items +
      "&search=" +
      search
    )
    .then(data => {
      return data;
    });
};

const changeUserStatus = (user, newStatus) => {
  let url = config.apiUrl + "users/" + user.id;
  return http
    .patch(url, { activeStatus: newStatus })
    .then(data => data);
};

const getUserDetails = userId => {
  let url = config.apiUrl + "users/" + userId;
  return http.get(url).then(data => data);
};

const createUser = userDetails => {
  let url = config.apiUrl + "users";
  return http.post(url, userDetails).then(data => data);
};

const deleteUser = userId => {
  let url = config.apiUrl + "users/" + userId;
  return http.patch(url, { deleteStatus: "true" }).then(data => data);
};

const updateUser = user => {
  let url = config.apiUrl + "users/" + user.id;
  return http.patch(url, { ...user }).then(data => data);
};
export const userService = {
  login,
  logout,
  getUserList,
  changeUserStatus,
  getUserDetails,
  createUser,
  updateUser,
  deleteUser
};
