import React from "react";
import FormItems from "./components/Forms";
const SystemListPage = props => {
  const {
    title,
    className,
    error,
    state,
    handleChange,
    type,
    btnType,
    handleSubmit,
    listOfItems,
    chooseFolder,
    parentStack,
    handleDoubleClick,
    handleUpdateSubmit,
    detailView,
    breadCum,
    navNew,
    homeNav,
    sideNavOpt,
    pastedItems,
    parentId,
    pasteHere,
    destId,
    handleSearch,
    children,
    myId
  } = props;
  console.log(children);
  console.log(listOfItems);
  console.log(myId);
  console.log(typeof (myId));
  console.log(breadCum.length);
  return (
    <div>
      <div className="page-header">
        <h6 className="heading">System List</h6>
      </div>
      <div className="page-content">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">System List</h6>
              <p className="text">System List settings</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="folder-container">
            <div className="heading">
              {type !== "" && type < 4 && (
                <FormItems
                  title={title}
                  className={className}
                  handleChange={handleChange}
                  error={error}
                  state={state}
                  handleUpdateSubmit={handleUpdateSubmit}
                  handleSubmit={handleSubmit}
                  btnType={btnType}
                  type={type}
                />
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="btn-group-margin">
                    <button
                      disabled={parentStack.length === 0 ? false : true}
                      onClick={e => btnType(1)}
                      className="btn "
                    >
                      New
                    </button>
                    {(breadCum.length <= 1) ?
                      <>
                        {(breadCum.length < 1) ?
                          <button
                            className={parentStack.length !== 1 ? "btn" : "btn btn-action active"}
                            onClick={parentStack.length !== 1 ? '' : e => btnType(2)}
                          >
                            <i className="icon ion-md-add" />
                          </button>
                          :
                          ''
                        }


                        <button
                          className={parentStack.length !== 1 ? "btn" : "btn btn-action active"}
                          onClick={parentStack.length !== 1 ? '' : e => btnType(3)}
                        >
                          <i className="icon ion-md-brush" />
                        </button>
                      </>
                      : ''
                    }

                  </div>
                </div>
                <div className="col-md-4 offset-md-2">
                  <div className="search-custom">
                    {/* <input type="text" name=""
                      onChange={e => handleSearch(e)}
                      className="form-control" placeholder="Search" />
                    <span><i className="material-icons align-middle">search</i></span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="path">
              <button className="btn btn-nothing" onClick={homeNav}>
                <i className="icon ion-md-home" />
              </button>
              {breadCum &&
                breadCum.map((item, key) => {
                  return (
                    <button
                      key={key}
                      onClick={e => navNew(item, key)}
                      className="btn btn-nothing"
                    >
                      <img src="/assets/images/subfolder.svg" alt="" />
                      <span>
                        {item.name} ({item.code})
                      </span>
                    </button>
                  );
                })}
            </div>
            <div className="folder-list">
              <div className="row">
                <div className="col-md-8">
                  <div className="column-reset">
                    {listOfItems.length === 0 && (
                      <p className="text-center mt-3">No items found</p>
                    )}
                    {listOfItems &&
                      listOfItems.map((item, key) => {
                        // console.log(listOfItems);
                        return (
                          <div
                            className={
                              parentStack.indexOf(item.id) === -1
                                ? "folder"
                                : "folder selected"
                            }
                            onDoubleClick={(breadCum.length < 1) ? e =>
                              handleDoubleClick(item.id,
                                item) : ''
                            }
                            onClick={e => chooseFolder(item.id, item)}
                            key={key}
                          >
                            <img
                              src={
                                item.childCount > 0
                                  ? "/assets/images/folder.svg"
                                  : "/assets/images/item.svg"
                              }
                              alt=""
                            />
                            <p className="name">{item.name}</p>
                            <p>({item.code})</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="folder-info">
                    {console.log(detailView)}
                    {!detailView && (
                      <div className="empty">
                        <span>
                          <i className="icon ion-md-close" />
                        </span>
                        Nothing Selected
                      </div>
                    )}
                    {listOfItems.length !== 0 && detailView && (
                      <div>
                        <div className="folder-heading">
                          <img
                            src={
                              detailView && children && children.length > 0
                                ? "/assets/images/folder.svg"
                                : "/assets/images/item.svg"
                            }
                            alt=""
                          />
                          <div className="right">
                            <h6 className="heading">{detailView.name}</h6>
                            {detailView && children && children.length > 0 ? (
                              <p className="text">Parent Folder</p>
                            ) :
                              <p className="text">No Children</p>
                            }
                            {detailView && children && children.length > 0 ? (
                              <p className="text mb-2">
                                Item Code ({detailView.code})
                              </p>
                            ) :
                              <p className="text mb-2">
                                Item Code ({detailView.code})
                              </p>}
                          </div>
                        </div>
                        <div className="custom-dropdown">
                          {detailView && children && children.length > 0 && (
                            <button className="btn btn-nothing">
                              Children
                            </button>
                          )}
                          <div className="custom-dropdown-inner white d-block">
                            {detailView && children && children.length > 0 && children.map((child, key) => {
                              return (
                                <div key={key} className="folder-heading mb-5">
                                  <div>
                                    <img
                                      src={
                                        child.isFolder
                                          ? "/assets/images/folder.svg"
                                          :
                                          "/assets/images/item.svg"
                                      }
                                      alt=""
                                    />
                                    <div className="right">
                                      <h6 className="heading ">
                                        {child.name}
                                        <br />({child.code})
                                      </h6>
                                    </div>
                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SystemListPage;
