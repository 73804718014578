import React, { Component } from 'react';  
class PageNotFoundPage extends Component {
  render() {
    return (
      <div className="PageNotFoundPage">
        page not found works
      </div>
    );
  }
}

export default PageNotFoundPage;
